import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import { CryptoTransactionDetails, Transaction } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { getImportantLabelForSendCrypto } from 'modules/payment/constants/cryptoCurrenciesDetails';
import useAllowedCryptoNetworksByCurrencyCode from 'modules/payment/hooks/useAllowedCryptoNetworksByCurrencyCode';
import { requestRefundRejectedCryptoTransaction } from 'modules/payment/store/thunks';
import { CryptoNetworkId, CurrencyNetwork } from 'modules/payment/types';
import {
  CryptoWalletForm,
  CryptoWalletFormValues,
  cryptoWalletFormInitialValues,
  getCryptoWalletFormValidationSchema,
} from 'modules/payment/views/components/CryptoWalletForm';
import { useDispatch } from 'store';

import { SubmitButton } from 'components/form';
import { ImportantCard } from 'components/ui';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import { makeValidate } from 'libs/yup';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CryptoCurrencyCode } from 'types';

interface RefundRejectedCryptoTransactionProps {
  transaction: Transaction;
}

interface FormValues extends CryptoWalletFormValues {}

interface RefundRejectedCryptoTransactionFormProps extends FormRenderProps<FormValues> {
  networkSelectingAllowed: boolean;
  transaction: Transaction;
  allowedNetworks: CurrencyNetwork[];
}

const RefundRejectedCryptoTransactionForm: FC<RefundRejectedCryptoTransactionFormProps> = ({
  handleSubmit,
  networkSelectingAllowed,
  transaction,
  values,
  allowedNetworks,
}) => {
  const translate = useTranslation();

  const currentNetwork = useMemo(
    () => allowedNetworks.find((n) => n.network === values.walletNetwork),
    [allowedNetworks, values.walletNetwork],
  );

  const importantText = useMemo(() => {
    let result = translate('PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL', {
      currencyAmountLabel: formatCurrencyWithSymbol(transaction.amount, transaction.currencyCode),
    });

    if (currentNetwork) {
      result += `<br/><br/>${getImportantLabelForSendCrypto(
        transaction.currencyCode as CryptoCurrencyCode,
        currentNetwork.networkName,
      )}`;
    }

    return result;
  }, [translate, currentNetwork, transaction]);

  return (
    <form onSubmit={handleSubmit} className="column gap-2">
      <ImportantCard className="p-2" title={translate('ACTION_REQUIRED')} text={importantText}>
        <CryptoWalletForm networkSelectingAllowed={networkSelectingAllowed} />
        <SubmitButton fullWidth>
          {translate('PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL_SUBMIT_BUTTON')}
        </SubmitButton>
      </ImportantCard>
    </form>
  );
};

const validate = (values: FormValues) =>
  makeValidate(getCryptoWalletFormValidationSchema(values))(values);

export const RefundRejectedCryptoTransaction: FC<RefundRejectedCryptoTransactionProps> = ({
  transaction,
}) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success } = await dispatch(
        requestRefundRejectedCryptoTransaction({
          requestIdentifier: transaction.requestIdentifier,
          address: values.walletAddress,
          network: values.walletNetwork as CryptoNetworkId,
        }),
      );
      if (success) {
        successToast();
        drawer.pop();
      }
    },
    [drawer, dispatch, transaction],
  );

  const { allowedNetworks } = useAllowedCryptoNetworksByCurrencyCode(
    transaction.currencyCode as CryptoCurrencyCode,
    { isDeposit: false },
  );

  const transactionNetworkForRefund = useMemo(
    () =>
      transaction.additionalInfo && 'network' in transaction.additionalInfo
        ? allowedNetworks.find(
            (n) =>
              n.networkName === (transaction.additionalInfo as CryptoTransactionDetails).network,
          )?.network
        : null,
    [allowedNetworks, transaction.additionalInfo],
  );

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <RefundRejectedCryptoTransactionForm
        {...formProps}
        allowedNetworks={allowedNetworks}
        networkSelectingAllowed={!transactionNetworkForRefund}
        transaction={transaction}
      />
    ),
    [transactionNetworkForRefund, allowedNetworks, transaction],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      ...cryptoWalletFormInitialValues,
      currencyCode: transaction.currencyCode as CryptoCurrencyCode,
      walletNetwork: transactionNetworkForRefund || null,
    }),
    [transaction, transactionNetworkForRefund],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={renderForm}
      validate={validate}
    />
  );
};
