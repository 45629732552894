import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountWallets, selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import { DigitalWallet, TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import { requestCreateInvoice, requestSaveClient } from 'modules/invoicing/store/thunks';
import { Invoice } from 'modules/invoicing/types';
import { CreateInvoiceFormValues } from 'modules/invoicing/views/CreateInvoice';
import { PaymentType } from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm';
import FullInvoiceView from 'modules/invoicing/views/components/FullInvoiceView';
import useDefaultCryptoNetwork from 'modules/payment/hooks/useDefaultCryptoNetwork';
import usePaymentsDetails from 'modules/payment/hooks/usePaymentsDetails';
import { PaymentOperationId } from 'modules/payment/types';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { Button } from 'components/ui';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { findWallet } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

import classes from './CreateInvoiceConfirmation.module.scss';

const getOperationIdByPaymentTypeAndCurrency = (type: PaymentType, currencyCode: CurrencyCode) => {
  if (type === 'nebeusIbanAccount') {
    if (currencyCode === 'EUR') {
      return PaymentOperationId.depositFromSepaToMoneyWallet;
    }
    return PaymentOperationId.depositFromWireToMoneyWallet;
  }
  if (type === 'nebeusSepaOrWireTransfer') {
    if (currencyCode === 'EUR') {
      return PaymentOperationId.depositFromSepaToTradingWallet;
    }
    return PaymentOperationId.depositFromWireToTradingWallet;
  }

  return PaymentOperationId.depositCryptoFromExternalWallet;
};

export interface InvoiceStepConfigItem {
  label: string;
  id: 'issuer' | 'client' | 'details' | 'items' | 'discount' | 'notes' | 'paymentDetails';
  canEdit?: boolean;
  config: SummaryCardItem[];
}
export interface CreateInvoiceConfirmationProps {
  createInvoiceValues: CreateInvoiceFormValues;
}
const CreateInvoiceConfirmation: FC<CreateInvoiceConfirmationProps> = ({ createInvoiceValues }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const drawer = useDrawer();

  const userProfile = useSelector(selectUserProfile);

  // @ts-ignore
  const wallets: TradingWallet[] = useSelector(selectWallets);
  // @ts-ignore
  const moneyAccountWallets: DigitalWallet[] = useSelector(selectDigitalAccountWallets);

  const [finalValues, setFinalValues] = useState<CreateInvoiceFormValues>(createInvoiceValues);

  const invoicePaymentWallet = useMemo<TradingWallet | DigitalWallet | undefined>(() => {
    switch (finalValues.payment.type) {
      case 'nebeusCryptoAccount': {
        return findWallet<TradingWallet>(wallets, finalValues.payment.currencyCode!);
      }
      case 'nebeusSepaOrWireTransfer': {
        return findWallet<TradingWallet>(wallets, finalValues.payment.currencyCode!);
      }
      case 'nebeusIbanAccount': {
        return findWallet<DigitalWallet>(moneyAccountWallets, finalValues.payment.currencyCode!);
      }
      default: {
        return undefined;
      }
    }
  }, [finalValues.payment, moneyAccountWallets, wallets]);

  useEffect(() => {
    if (invoicePaymentWallet && !invoicePaymentWallet.exist) {
      if (
        finalValues.payment.type === 'nebeusCryptoAccount' ||
        finalValues.payment.type === 'nebeusSepaOrWireTransfer'
      ) {
        // @ts-ignore
        dispatch(requestCreateWallet({ issuerId: invoicePaymentWallet.issuerId }));
      }
    }
    // eslint-disable-next-line
  }, [invoicePaymentWallet?.exist]);

  const { details: paymentDetails, reference: paymentReference } = usePaymentsDetails({
    operationId: getOperationIdByPaymentTypeAndCurrency(
      finalValues.payment.type!,
      finalValues.payment.currencyCode!,
    ),
    currencyCode: finalValues.payment.currencyCode!,
    variant: 'short',
  });

  const { defaultNetwork } = useDefaultCryptoNetwork(
    finalValues.payment.currencyCode as CryptoCurrencyCode,
  );

  // @ts-ignore
  const payment = useMemo<Invoice['payment'] | null>(() => {
    switch (finalValues.payment.type) {
      case 'nebeusCryptoAccount': {
        return {
          type: finalValues.payment.type,
          currencyCode: finalValues.payment.currencyCode,
          address:
            invoicePaymentWallet && 'address' in invoicePaymentWallet
              ? invoicePaymentWallet.address
              : '-',
          network: defaultNetwork?.networkName || '-',
        };
      }
      case 'nebeusIbanAccount': {
        if (!paymentDetails) {
          return null;
        }
        if (
          finalValues.payment.currencyCode === 'EUR' &&
          'iban' in paymentDetails &&
          'bankName' in paymentDetails
        ) {
          return {
            type: finalValues.payment.type,
            currencyCode: finalValues.payment.currencyCode,
            iban: paymentDetails.iban,
            bic: paymentDetails.bic,
            bankName: paymentDetails.bankName,
            beneficiary: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '-',
          };
        }
        if (
          finalValues.payment.currencyCode === 'GBP' &&
          'sortCode' in paymentDetails &&
          'bankName' in paymentDetails
        ) {
          return {
            type: finalValues.payment.type,
            currencyCode: finalValues.payment.currencyCode,
            accountNumber: paymentDetails.accountNumber,
            sortCode: paymentDetails.sortCode,
            bankName: paymentDetails.bankName,
            accountHolderName: userProfile
              ? `${userProfile.firstName} ${userProfile.lastName}`
              : '-',
          };
        }
        return null;
      }
      case 'nebeusSepaOrWireTransfer': {
        if (!paymentDetails) {
          return null;
        }

        if (finalValues.payment.currencyCode === 'EUR' && 'iban' in paymentDetails) {
          return {
            type: finalValues.payment.type,
            currencyCode: finalValues.payment.currencyCode,
            paymentReference,
            beneficiary: paymentDetails.beneficiary,
            iban: paymentDetails.iban,
            bic: paymentDetails.bic,
          };
        }
        if (finalValues.payment.currencyCode === 'GBP' && 'accountNumber' in paymentDetails) {
          return {
            type: finalValues.payment.type,
            currencyCode: finalValues.payment.currencyCode,
            paymentReference,
            beneficiary: paymentDetails.beneficiary,
            sortCode: paymentDetails.sortCode,
            accountNumber: paymentDetails.accountNumber,
          };
        }
        // Corporate wallet for USD in not available
        // if (finalValues.payment.currencyCode === 'USD' && 'beneficiaryAccount' in paymentDetails) {
        //   return {
        //     type: finalValues.payment.type,
        //     currencyCode: finalValues.payment.currencyCode,
        //     paymentReference,
        //     beneficiary: paymentDetails.beneficiary,
        //     iban: paymentDetails.beneficiaryAccount,
        //     swift: paymentDetails.beneficiaryBankSwift,
        //     bankName: paymentDetails.beneficiaryBank,
        //   };
        // }
        return null;
      }
      default: {
        return null;
      }
    }
  }, [
    paymentReference,
    defaultNetwork,
    paymentDetails,
    userProfile,
    invoicePaymentWallet,
    finalValues.payment,
  ]);

  const onEdit = useCallback(
    (id: InvoiceStepConfigItem['id'], label: string) => {
      drawer.open(
        invoicingDrawerTemplates.editInvoiceStep({
          stepId: id,
          stepName: label,
          values: finalValues,
          onFinish: (newValues: CreateInvoiceFormValues) => {
            setFinalValues(newValues);
            drawer.pop();
          },
        }),
      );
    },
    [drawer, finalValues],
  );

  const submitting = useFlag(false);

  const handleSubmit = useCallback(async () => {
    submitting.on();
    const { success, data: invoice } = await dispatch(
      requestCreateInvoice({
        client: {
          name: finalValues.clientName,
          email: finalValues.clientEmail,
          postCode: finalValues.clientPostCode,
          country: finalValues.clientCountry,
          city: finalValues.clientCity,
          address: finalValues.clientAddress1,
          address2: finalValues.clientAddress2,
        },
        invoiceDetails: {
          number: +finalValues.invoiceNumber.replace('#', ''),
          fromDate: finalValues.invoiceDate,
          toDate: finalValues.invoiceDueDate,
          currencyCode: finalValues.invoiceCurrency!,
          reference: finalValues.invoiceReference,
        },
        invoiceServiceItems: finalValues.invoiceServiceItems.map((i) => ({
          ...i,
          quantity: +i.quantity,
          amount: +i.amount,
        })),

        invoiceDiscount: {
          amount: +finalValues.discountAmount,
          reason: finalValues.discountReason,
        },
        notes: finalValues.invoiceNotes,
        payment: {
          type: finalValues.payment.type!,
          currencyCode: finalValues.payment.currencyCode!,
        },
      }),
    );

    if (success && invoice) {
      if (finalValues.saveClient) {
        const newClient = {
          email: finalValues.clientEmail,
          postCode: finalValues.clientPostCode,
          country: finalValues.clientCountry,
          city: finalValues.clientCity,
          name: finalValues.clientName,
          address: finalValues.clientAddress1,
          address2: finalValues.clientAddress2,
        };
        await dispatch(requestSaveClient(newClient));
      }
      drawer.replaceAll(invoicingDrawerTemplates.invoiceCreated({ invoice }));
    }
    submitting.off();
  }, [drawer, submitting, finalValues, dispatch]);

  const invoice = useMemo<Invoice | null>(() => {
    if (!payment) {
      return null;
    }
    return {
      id: 0,
      issuer: {
        name: finalValues.issuerName,
        country: finalValues.issuerCountry,
        city: finalValues.issuerCity,
        address: finalValues.issuerAddress,
        address2: '',
        email: '',
        postCode: finalValues.issuerPostCode,
      },
      client: {
        name: finalValues.clientName,
        country: finalValues.clientCountry,
        city: finalValues.clientCity,
        address: finalValues.clientAddress1,
        address2: finalValues.clientAddress2,
        postCode: finalValues.clientPostCode,
        email: finalValues.clientEmail,
      },
      invoiceDetails: {
        number: +finalValues.invoiceNumber.replace('#', ''),
        fromDate: finalValues.invoiceDate,
        toDate: finalValues.invoiceDueDate,
        currencyCode: finalValues.invoiceCurrency!,
        reference: finalValues.invoiceReference,
      },
      invoiceServiceItems: finalValues.invoiceServiceItems.map((i) => ({
        ...i,
        amount: +i.amount,
        quantity: +i.quantity,
      })),
      invoiceDiscount: {
        amount: finalValues.discountAmount ? +finalValues.discountAmount : null,
        reason: finalValues.discountReason || null,
      },
      notes: finalValues.invoiceNotes || null,
      payment: payment,
    } as Invoice;
  }, [finalValues, payment]);

  return (
    <div className={classes.root}>
      <div className="column gap-2">
        {invoice && <FullInvoiceView invoice={invoice} onEdit={onEdit} showEmptyFields />}
      </div>

      <Button
        disabled={
          !invoicePaymentWallet ||
          !invoicePaymentWallet.exist ||
          (payment?.type === 'nebeusCryptoAccount' &&
            'address' in invoicePaymentWallet &&
            !invoicePaymentWallet.address)
        }
        loading={submitting.state}
        onClick={handleSubmit}
      >
        {translate('CONFIRM_AND_FINISH')}
      </Button>
    </div>
  );
};

export default CreateInvoiceConfirmation;
