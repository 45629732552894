import { createSelector } from '@reduxjs/toolkit';

import { DigitalWallet, Issuer, TradingWallet, Wallet } from 'modules/accounts/types';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import { AppStore } from 'store';

import { getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CurrencyCode, FiatCurrencyCode } from 'types';

export const selectExistingWallets = (state: AppStore) => state.accounts.wallets.data;
export const selectWalletsMeta = (state: AppStore) => state.accounts.wallets.meta;
export const selectWalletsReducer = (state: AppStore) => state.accounts.wallets;

export const selectIssuers = (state: AppStore) => state.accounts.issuers.data;
export const selectIssuersMeta = (state: AppStore) => state.accounts.issuers.meta;
export const selectIssuersReducer = (state: AppStore) => state.accounts.issuers;
export const selectIssuersByCurrencyCode = createSelector(selectIssuers, (issuers) =>
  issuers.reduce<{ [key in CurrencyCode]?: Issuer }>((acc, issuer) => {
    acc[issuer.currencyCode] = issuer;
    return acc;
  }, {}),
);

export const selectActiveAccountType = (state: AppStore) => state.accounts.activeAccountType;

const walletsOrderByCurrencyCode: { [key in CurrencyCode]?: number } = {
  EUR: 1,
  USD: 2,
  GBP: 3,
  BTC: 4,
  ETH: 5,
  USDT: 6,
  USDC: 7,
};

const sortWalletsByPriority = (a: Wallet, b: Wallet) => {
  if (walletsOrderByCurrencyCode[b.currencyCode] || walletsOrderByCurrencyCode[a.currencyCode]) {
    return (
      (walletsOrderByCurrencyCode[a.currencyCode] || 10) -
      (walletsOrderByCurrencyCode[b.currencyCode] || 10)
    );
  }
  return 0;
};

const sortWalletsByAmount = (a: Wallet, b: Wallet) => {
  if (isFiat(a.currencyCode) || isFiat(b.currencyCode)) {
    return 0;
  }

  return b.amountInDefaultCurrency - a.amountInDefaultCurrency;
};

export const selectWallets = createSelector(
  selectExistingWallets,
  selectIssuers,
  selectUserDefaultCurrencyCode,
  (wallets: TradingWallet[], issuers: Issuer[], defaultCurrencyCode: FiatCurrencyCode) =>
    issuers
      .map(
        (issuer) =>
          wallets.find((wallet) => wallet.currencyCode === issuer.currencyCode) ||
          ({
            amount: 0,
            amountInDefaultCurrency: 0,
            currencyCode: issuer.currencyCode,
            currencyLabel: getCurrencyLabelByCode(issuer.currencyCode),
            defaultCurrencyCode: defaultCurrencyCode,
            issuerId: issuer.id,
            coinSerial: null,
            exist: false,
          } as TradingWallet),
      )
      .filter((wallet) => {
        if (wallet.currencyCode === 'NBT' && !wallet.exist) {
          return false;
        }
        return true;
      })
      .sort(sortWalletsByPriority)
      .sort(sortWalletsByAmount),
  // .map((w) => ({ ...w, amount: 100 })),
);

// export const selectWalletsReducer = createSelector(
//   selectWallets,
//   selectWalletsMeta,
//   (wallets, meta) => ({ data: wallets, meta } as IApiRequestState<TradingWallet[]>),
// );
export const selectCryptoWallets = createSelector(selectWallets, (wallets) =>
  wallets.filter((w) => !isFiat(w.currencyCode)),
);
export const selectFiatWallets = createSelector(selectWallets, (wallets) =>
  wallets.filter((w) => isFiat(w.currencyCode)),
);

const digitalAccountAllowedCurrencies: CurrencyCode[] = ['EUR', 'GBP'];
export const selectDigitalAccountExistWallets = (state: AppStore) =>
  state.accounts.digitalWallets.data;
export const selectDigitalAccountExistWalletsReducer = (state: AppStore) =>
  state.accounts.digitalWallets;

export const selectDefaultDigitalWallet = createSelector(
  selectDigitalAccountExistWallets,
  (wallets) => wallets.find((w) => w.default),
);

export const selectDigitalAccountWallets = createSelector(
  selectDigitalAccountExistWallets,
  selectUserDefaultCurrencyCode,
  (wallets, defaultCurrencyCode) =>
    digitalAccountAllowedCurrencies.map(
      (currency) =>
        wallets.find((w) => w.currencyCode === currency) ||
        ({
          amount: 0,
          amountInDefaultCurrency: 0,
          currencyCode: currency,
          currencyLabel: getCurrencyLabelByCode(currency),
          defaultCurrencyCode,
          issuerId: '',
          coinSerial: '',
          exist: false,
          isDigitalWallet: true,
        } as DigitalWallet),
    ),
);
export const selectDigitalAccountWalletsMeta = (state: AppStore) =>
  state.accounts.digitalWallets.meta;

export const selectWalletsMap = createSelector(selectWallets, (wallets) =>
  wallets.reduce((acc, wallet) => ({ ...acc, [wallet.currencyCode]: wallet }), {}),
);

export const selectLastTransactionsReducer = (state: AppStore) => state.accounts.lastTransactions;
