import clsx from 'clsx';
import { CountUp } from 'use-count-up';

import { FC, useMemo } from 'react';

import { CurrencyIcon, CurrencyIconProps } from 'components/ui/CurrencyIcon';

import {
  FormatCurrencyOptions,
  formatCurrency,
  getCurrencyLabelByCode,
  getCurrencySymbolByCode,
} from 'utils/currency';

import { CurrencyCode, Size } from 'types';

import classes from './AmountLabel.module.scss';

interface AmountLabelProps {
  amount: string | number;
  currencyCode: CurrencyCode;
  className?: string;
  size?: Size | 'xl2' | 'xl4' | 'xl5';
  showCurrencyCode?: boolean;
  showCurrencySymbol?: boolean;
  showCurrencyLabel?: boolean;
  showPlusOrMinus?: boolean;
  font?: 'main' | 'title';
  formatOptions?: FormatCurrencyOptions;
  currencyIcon?: Omit<CurrencyIconProps, 'code'>;
  useCounter?: boolean;
}

export const AmountLabel: FC<AmountLabelProps> = ({
  amount,
  currencyCode,
  className,
  size = 'md',
  showCurrencySymbol,
  showCurrencyLabel,
  showCurrencyCode,
  showPlusOrMinus,
  font = 'title',
  formatOptions,
  currencyIcon,
  useCounter,
}) => {
  const formattedAmount = formatCurrency(amount, currencyCode, {
    separateThousands: true,
    withZeros: true,
    ...formatOptions,
  });

  const currencySymbol = getCurrencySymbolByCode(currencyCode);
  const showCurrencySymbolLeft = showCurrencySymbol && currencySymbol !== currencyCode;

  const [integer, decimal, integerLabel, decimalLabel] = useMemo(() => {
    let [intLabel, dec] = `${formattedAmount}`.split('.');
    intLabel = intLabel.replace('-', '');
    const int = intLabel.replaceAll(',', '');

    let decLabel = '';
    if (dec) {
      decLabel = '.' + dec;
    }

    return [+(int || 0), +(dec || 0), intLabel, decLabel];
  }, [formattedAmount]);

  const preSymbol = useMemo(() => {
    const amountNumber = Number(amount);
    if (showPlusOrMinus) {
      return amountNumber < 0 ? '- ' : '+ ';
    }
    if (amountNumber < 0) {
      return '- ';
    }
  }, [showPlusOrMinus, amount]);

  return (
    <div
      translate="no"
      className={clsx(
        classes.root,
        className,
        classes[`size-${size}`],
        classes[`font-${font}`],
        showPlusOrMinus && classes[`plus-${+formattedAmount > 0}`],
      )}
    >
      {currencyIcon && <CurrencyIcon className="mr-1" code={currencyCode} {...currencyIcon} />}
      <span>
        {preSymbol}
        {showCurrencySymbolLeft ? currencySymbol : null}
        <b>
          {useCounter ? (
            <CountUp
              isCounting
              start={0}
              end={integer}
              duration={1}
              decimalPlaces={0}
              thousandsSeparator=","
            />
          ) : (
            integerLabel
          )}
        </b>
        {useCounter ? (
          decimal ? (
            <span style={{ display: 'inline-block' }}>
              <span style={{ display: 'inline-block' }}>.</span>
              <CountUp
                isCounting
                start={0}
                end={decimal}
                duration={1}
                decimalPlaces={0}
                thousandsSeparator=","
              />
            </span>
          ) : null
        ) : (
          decimalLabel
        )}{' '}
        {showCurrencySymbol && !showCurrencySymbolLeft ? currencySymbol : null}
        {showCurrencyCode && currencyCode}
        {showCurrencyLabel && getCurrencyLabelByCode(currencyCode)}
      </span>
    </div>
  );
};
