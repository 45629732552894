import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectOperationCommissionsMeta } from 'modules/app/store/selectors';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectIsDigitalAccountAllowed,
  selectUserDASubscriptionType,
} from 'modules/digitalAccount/store/selectors';
import { BANK_CARD_OPERATION_BLOCKED_COUNTRIES } from 'modules/payment/constants';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import useDefaultCryptoNetwork from 'modules/payment/hooks/useDefaultCryptoNetwork';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import { PaymentOperationId } from 'modules/payment/types';
import { getBlockedOperationIdByPaymentOperationId } from 'modules/payment/utils';
import { Operation, paymentOperations } from 'modules/payment/views/PickOperation/config';
import { CommissionLabel } from 'modules/payment/views/components/CommissionLabel';
import { selectUserCountryCode, selectUserProfile } from 'modules/user/store/selectors';

import routesByName from 'constants/routesByName';

import { ErrorCard } from 'components/common';
import { MenuList } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import { useTranslation } from 'libs/i18n';

import { isFiat } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode } from 'types';

import classes from './PickOperation.module.scss';

export interface PickOperationProps {
  currencyCode: CurrencyCode;
  isDeposit: boolean;
  isDigitalAccount: boolean;
}

const PickOperation: FC<PickOperationProps> = ({ currencyCode, isDigitalAccount, isDeposit }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const navigate = useNavigate();

  const user = useSelector(selectUserProfile);

  usePaymentInit();

  const { defaultNetwork } = useDefaultCryptoNetwork(currencyCode as CryptoCurrencyCode);

  const commissionsMeta = useSelector(selectOperationCommissionsMeta);

  const isDAAllowed = useSelector(selectIsDigitalAccountAllowed);
  const isUserHasDigitalAccount = useSelector(selectUserDASubscriptionType);
  const userCountry = useSelector(selectUserCountryCode);

  const handlePickOperation = useCallback(
    (operationId: PaymentOperationId) => {
      if (
        isDAAllowed &&
        (operationId === PaymentOperationId.sendFromTradingToSepa ||
          // @ts-ignore
          operationId === PaymentOperationId.sendFromTradingToWire)
      ) {
        drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
        return;
      }

      if (
        (operationId === PaymentOperationId.depositFromWireToTradingWallet ||
          operationId === PaymentOperationId.depositFromSepaToTradingWallet) &&
        isDAAllowed
      ) {
        if (isUserHasDigitalAccount) {
          drawer.close();
          navigate(routesByName.iban);
          return;
        } else {
          drawer.open(ibanAccountDrawerTemplates.accountCreatingPrompt());
          return;
        }
      }

      const paymentOperationTemplate = paymentDrawerTemplates.paymentOperation({
        operationId,
        currencyCode,
        isDigitalAccount,
      });
      if (paymentOperationTemplate) {
        drawer.open(paymentOperationTemplate);
      }
    },
    [isUserHasDigitalAccount, navigate, isDAAllowed, drawer, currencyCode, isDigitalAccount],
  );

  const operations = useMemo<Operation[]>(() => {
    if (!isDigitalAccount) {
      if (isDeposit) {
        const depositFiatOperations = [
          // paymentOperations.deposit.applePay, // TODO: Apple pay
        ];
        // if (currencyCode === 'EUR') {
        //   depositFiatOperations.unshift(paymentOperations.deposit.SEPA);
        // }
        // if (currencyCode === 'GBP') {
        //   depositFiatOperations.unshift(paymentOperations.deposit.wire);
        // }
        // Corporate wallet for USD in not available
        // if (currencyCode === 'USD') {
        //   depositFiatOperations.unshift(paymentOperations.deposit.wireUsd);
        // }
        if (userCountry && !BANK_CARD_OPERATION_BLOCKED_COUNTRIES.includes(userCountry)) {
          depositFiatOperations.unshift(paymentOperations.deposit.bankCard);
        }

        return depositFiatOperations;
      } else {
        if (isFiat(currencyCode)) {
          const fiatOperations: Operation[] = [paymentOperations.send.nebeusUser];
          if (userCountry && !BANK_CARD_OPERATION_BLOCKED_COUNTRIES.includes(userCountry)) {
            fiatOperations.unshift(paymentOperations.send.bankCard);
          }

          if (currencyCode !== 'USD') {
            if (isUserHasDigitalAccount) {
              fiatOperations.unshift(paymentOperations.send.toDigitalAccount);
            } else {
              // TODO: Remove hardcode
              if (userCountry === 'GB') {
                fiatOperations.unshift(
                  currencyCode === 'EUR'
                    ? paymentOperations.send.SEPA
                    : paymentOperations.send.wire,
                );
              }
              // TODO: Unblock when method from trading wallet to SEPA & Wire will be allowed
              // fiatOperations.unshift(
              //   currencyCode === 'EUR' ? paymentOperations.send.SEPA : paymentOperations.send.wire,
              // );
            }
          }
          return fiatOperations;
        } else {
          // TODO: BLOCKED CELO (?)
          if (currencyCode === 'NBT' || currencyCode === 'CELO') {
            return [paymentOperations.send.nebeusUser];
          }
          return [paymentOperations.send.externalWallet, paymentOperations.send.nebeusUser];
        }
      }
    } else {
      if (isDeposit) {
        return [
          currencyCode === 'GBP'
            ? paymentOperations.deposit.wire_DA
            : paymentOperations.deposit.SEPA_DA,
        ];
      } else {
        return [
          currencyCode === 'GBP' ? paymentOperations.send.wire_DA : paymentOperations.send.SEPA_DA,
          paymentOperations.send.toCryptoAccount,
        ];
      }
    }
  }, [userCountry, isUserHasDigitalAccount, isDigitalAccount, currencyCode, isDeposit]);

  const allowedOperations = useMemo<MenuListItem[]>(
    () =>
      operations
        .filter((o) => {
          const blockedFeatureId = getBlockedOperationIdByPaymentOperationId(o.operationId);
          if (!blockedFeatureId || !user) {
            return true;
          }
          return !user.blockedFeatures.includes(blockedFeatureId);
        })
        .map((o) => ({
          key: o.nameKey,
          label: translate(o.nameKey),
          handler: () => {
            handlePickOperation(o.operationId);
          },
          className: classes.menuListItem,
          icon: o.iconName,
          iconProps: { size: 32 },
          underLabelComponent: (
            <CommissionLabel
              className={classes.commissionLabel}
              feeFreeClassName={classes.free}
              currencyCode={currencyCode}
              operationId={o.operationId}
              cryptoNetwork={
                o.operationId === PaymentOperationId.sendCryptoToExternalWallet && defaultNetwork
                  ? defaultNetwork.network
                  : undefined
              }
            />
          ),
        })),
    [currencyCode, handlePickOperation, defaultNetwork, user, translate, operations],
  );
  return (
    <div className="mt-1 column gap-1">
      <MenuList
        menu={allowedOperations}
        loading={!commissionsMeta.loaded && commissionsMeta.loading}
      />
      {allowedOperations.length ? null : <ErrorCard label={translate('USER_FEATURE_BLOCKED')} />}
    </div>
  );
};

export default PickOperation;
