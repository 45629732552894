import clsx from 'clsx';
import { EffectCoverflow } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import { requestBankCardDetails as requestCryptoBankCardDetails } from 'modules/cryptoBankCard/store/thunks';
import { BankCardDetails, CryptoBankCard } from 'modules/cryptoBankCard/types';
import { isReactNative } from 'modules/reactNative/utils';
import { appVerificationService } from 'modules/reactNative/utils/appVerification';
import { useDispatch } from 'store';

import { Button, CopyButton, FlipCard, Icon, Image, Loader } from 'components/ui';
import { FlipSide } from 'components/ui/FlipCard';

import useFlag, { Flag } from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { stopPropagation } from 'utils/common';
import { formatBankCardNumber } from 'utils/formatters';

import classes from './BankCardsCarousel.module.scss';

interface BankCardsCarouselProps {
  bankCards: CryptoBankCard[];
  frontSide: Flag;
  activeBankCardIndex: number;
  onBankCardChanged: (index: number) => void;
}
interface BankCardItemProps {
  card: CryptoBankCard;
  active: boolean;
  frontSide: Flag;
}

interface SideProps {
  card: CryptoBankCard;
  isFocused: boolean;
}

const Front: FC<SideProps> = ({ card }) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  return (
    <div
      className={clsx(
        classes.card,
        classes.front,
        classes['cardType-' + card.type],
        isReactNative && classes.reactNative,
        card.isFrozen && !card.isBlocked && classes.frozen,
      )}
    >
      {card.isBlocked && (
        <div className={clsx(classes.cardMask, card.isBlocked && classes.active)}>
          <Image
            key={card.id}
            className={classes.blockedImage}
            name="bankCardBgBlocked"
            path="cryptoBankCard"
          />

          <div className={classes.cardMaskInfo}>
            <Icon name="lockOutlined" color="white" size={32} />
            <span>{translate('BANK_CARD_BLOCKED')}</span>
          </div>
        </div>
      )}
      {card.type === 'crypto' && !card.isBlocked && (
        <div className={clsx(classes.cardMask, !card.isActivated && classes.active)}>
          <Image
            key={`${card.type}${card.id}`}
            className={classes.blockedImage}
            name="bankCardBgBlocked"
            path="cryptoBankCard"
          />

          <div className={classes.cardMaskInfo}>
            <Icon name="warnCircleOutlined" color="white" size={32} />
            <span>
              {translate(card.isVirtual ? 'ACCOUNT_CONNECTION_REQUIRED' : 'ACTIVATION_REQUIRED')}
            </span>
            <Button
              className={classes.activateButton}
              variant="greenWhite"
              size="xs"
              onClick={() => {
                if (card.isVirtual) {
                  drawer.open(cryptoBankCardDrawerTemplates.connectCryptoAccount({ card }));
                } else {
                  drawer.open(
                    cryptoBankCardDrawerTemplates.activateCard({
                      cardId: card.id,
                      isVirtual: card.isVirtual,
                    }),
                  );
                }
              }}
            >
              {translate(card.isVirtual ? 'CRYPTO_BANK_CARD_CONNECT' : 'ACTIVATE')}
            </Button>
          </div>
        </div>
      )}

      <div className={clsx(classes.cardMask, !card.isBlocked && card.isFrozen && classes.active)}>
        <Image name="bankCardBgFrozen" path="digitalAccount" />
        <div className={classes.cardMaskInfo}>
          <Icon name="freeze" color="white" size={32} />
          <span>{translate('BANK_CARD_FROZEN')}</span>
        </div>
      </div>
      <div className="row jcfe">
        {!card.isAdvertisementCard && card.currencyCode ? (
          <div className={classes.mark}>
            <span>{card.currencyCode}</span>
          </div>
        ) : null}
      </div>
      {(card.isFrozen || card.isBlocked) && !isReactNative ? (
        <div className={classes.blurMask} />
      ) : null}
      <div className="row aife jcsb">
        {card.isAdvertisementCard ? (
          <span />
        ) : (
          <span className={classes.label}>•• {card.lastDigits}</span>
        )}

        <div className="column gap-1">
          {card.isVirtual && (
            <span className={classes.cardPayType}>{translate('BANK_CARD_VIRTUAL_WORD')}</span>
          )}
          <Image name="MCLogo" path="common" type="svg" />
        </div>
      </div>
    </div>
  );
};

interface CardInfoItemProps {
  label: string;
  value?: string;
  copyValue?: string;
  loading: boolean;
  canBeHidden?: boolean;
}

const CardInfoItem: FC<CardInfoItemProps> = ({ label, value, copyValue, loading, canBeHidden }) => {
  const hidden = useFlag(canBeHidden || false);

  useEffect(() => {
    if (canBeHidden && !hidden.state) {
      setTimeout(hidden.on, 1000 * 10);
    }
    // eslint-disable-next-line
  }, [hidden.state]);

  return (
    <div className={clsx(classes.cardInfoItem, 'column flex-1')}>
      <span className={clsx(classes.label, 'label mb-1')}>{label}</span>
      <div onClick={stopPropagation} className={classes.cardInfoRow}>
        <span>{loading ? '' : hidden.state ? '***' : value}</span>
        {loading ? (
          <Loader size="xs" className="mr-0-5" />
        ) : hidden.state ? (
          <Icon onClick={hidden.off} className="mr-1" name="eyeFilled" color="greyishGreen" />
        ) : (
          <CopyButton value={copyValue || value} size="sm" label={label} />
        )}
      </div>
    </div>
  );
};
const Back: FC<SideProps> = ({ card, isFocused }) => {
  const dispatch = useDispatch();

  const translate = useTranslation();

  const loading = useFlag(false);
  const [details, setDetails] = useState<BankCardDetails | null>(null);

  const getCardDetails = useCallback(async () => {
    loading.on();

    const { data, success } = await dispatch(requestCryptoBankCardDetails(card.id));

    if (success) {
      setDetails(data);
    }
    loading.off();
  }, [card, loading, dispatch]);

  useEffect(() => {
    if (isFocused) {
      getCardDetails();
    } else {
      setDetails(null);
    }
    // eslint-disable-next-line
  }, [isFocused]);

  return (
    <div
      className={clsx(
        classes.card,
        classes.back,
        card.isFrozen && classes.frozen,
        classes['cardType-' + card.type],
      )}
    >
      <CardInfoItem
        copyValue={details?.number}
        value={details?.number ? formatBankCardNumber(details.number) : undefined}
        label={translate('PAYMENT_BANK_CARD_NUMBER')}
        loading={loading.state}
      />
      <div className={classes.row}>
        <CardInfoItem loading={loading.state} value={details?.expDate} label={translate('DATE')} />
        <CardInfoItem
          loading={loading.state}
          value={details?.cvc}
          label={translate('BANK_CARD_CVC_LONG')}
          canBeHidden
        />
      </div>
    </div>
  );
};
const ONE_MINUTE_IN_MS = 1000 * 60;
const BankCardItem: FC<BankCardItemProps> = ({ card, frontSide, active }) => {
  const handleClick = useCallback(async () => {
    if (
      card.isAdvertisementCard ||
      ((card.isFrozen || card.isBlocked || (card.type === 'crypto' && !card.isActivated)) &&
        frontSide.state)
    ) {
      return;
    }
    if (frontSide.state) {
      const success = await appVerificationService.checkBioVerification();
      if (success) {
        frontSide.toggle();
      }
    } else {
      frontSide.toggle();
    }
  }, [card, frontSide]);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!frontSide.state && active) {
      timeoutId.current = setTimeout(frontSide.on, ONE_MINUTE_IN_MS);
    }
    // eslint-disable-next-line
  }, [frontSide.state]);

  useEffect(() => {
    if (!active && timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, [active]);

  return (
    <FlipCard
      className={classes.flipCard}
      onClick={handleClick}
      side={!frontSide.state && active ? FlipSide.BACK : FlipSide.FRONT}
      front={<Front card={card} isFocused={frontSide.state && active} />}
      back={<Back card={card} isFocused={!frontSide.state && active} />}
    />
  );
};
interface NavigationDotsProps {
  activeIndex: number;
  quantity: number;
}

const NavigationDots: FC<NavigationDotsProps> = ({ quantity, activeIndex }) => {
  const arrayOfDots = useMemo(() => new Array(quantity).fill(null), [quantity]);
  return (
    <div className={classes.dots}>
      {arrayOfDots.map((_, index) => (
        <div key={index} className={clsx(classes.dot, index === activeIndex && classes.active)} />
      ))}
    </div>
  );
};

export const BankCardsCarousel: FC<BankCardsCarouselProps> = ({
  bankCards,
  frontSide,
  activeBankCardIndex,
  onBankCardChanged,
}) => {
  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      frontSide.on();
      onBankCardChanged(swiper.activeIndex);
    },
    [frontSide, onBankCardChanged],
  );

  const [slider, setSlider] = useState<SwiperRef['swiper'] | null>(null);

  return (
    <div className={classes.root}>
      <NavigationDots activeIndex={activeBankCardIndex} quantity={bankCards.length} />
      <Swiper
        initialSlide={activeBankCardIndex}
        className={classes.slider}
        spaceBetween={25}
        slidesPerView={1.2}
        onSlideChange={handleSlideChange}
        centeredSlides
        onSwiper={setSlider}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          scale: 0.95,
          slideShadows: false,
        }}
        modules={[EffectCoverflow]}
      >
        {bankCards.map((card, index) => (
          <SwiperSlide className={classes.slide} key={`${card.type}-${card.id}`}>
            <BankCardItem
              frontSide={frontSide}
              card={card}
              active={index === activeBankCardIndex}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={classes.arrowsContainer}>
        <Icon
          className={clsx(classes.arrow, slider?.activeIndex !== 0 && classes.canPrev)}
          onClick={() => slider?.slidePrev()}
          name="arrowLeftSafe"
        />
        <Icon
          className={clsx(
            classes.arrow,
            slider?.activeIndex !== bankCards.length - 1 && classes.canNext,
          )}
          onClick={() => slider?.slideNext()}
          name="arrowRightSafe"
        />
      </div>
    </div>
  );
};
