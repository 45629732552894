import clsx from 'clsx';

import { ChangeEventHandler, useCallback, useRef } from 'react';

import { Icon } from 'components/ui/Icon';

import useFlag, { Flag } from 'hooks/useFlag';

import classes from './WalletsFilters.module.scss';

export interface WalletsFiltersProps<AccountType> {
  accountTypes: { id: AccountType; label: string; disabled?: boolean }[];
  currentAccountType: string;
  changeCurrentAccountType: (id: AccountType) => void;
  hideZeroAmountsFlag: Flag;
  searchQuery: string;
  changeSearchQuery: (query: string) => void;
}

export const WalletsFilters = <T extends string>({
  accountTypes,
  currentAccountType,
  changeCurrentAccountType,
  changeSearchQuery,
  searchQuery,
  hideZeroAmountsFlag,
}: WalletsFiltersProps<T>) => {
  const showSearchInput = useFlag(false);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const onSearchInputButtonClick = useCallback(() => {
    if (!showSearchInput.state) {
      showSearchInput.on();

      searchInputRef.current?.focus();
    } else {
      changeSearchQuery('');
      showSearchInput.off();
    }
  }, [changeSearchQuery, showSearchInput]);

  const handleSearchInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      changeSearchQuery(event.currentTarget.value);
    },
    [changeSearchQuery],
  );
  return (
    <div className="row gap-3 jcsb">
      <div className="row gap-0-5">
        {accountTypes.map((at) => (
          <div
            key={at.id}
            onClick={at.disabled ? undefined : () => changeCurrentAccountType(at.id)}
            className={clsx(
              classes.button,
              currentAccountType === at.id && classes.active,
              at.disabled && 'disabled',
            )}
          >
            <span>{at.label}</span>
          </div>
        ))}
      </div>
      <div className="row aic gap-0-5 flex-1 jcfe relative">
        <Icon
          onClick={hideZeroAmountsFlag.toggle}
          className={classes.showZeroAmountsButton}
          name={hideZeroAmountsFlag.state ? 'showZero' : 'hideZero'}
          size={24}
        />
        <Icon
          onClick={onSearchInputButtonClick}
          className={classes.showSearchInputButton}
          name={showSearchInput.state ? 'cross' : 'search'}
          size={16}
        />
        <input
          ref={searchInputRef}
          type="text"
          className={clsx(classes.searchInput, showSearchInput.state && classes.active)}
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
      </div>
    </div>
  );
};
