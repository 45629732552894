import axios from 'axios';

import appConfig from 'constants/appConfig';

const UtilitiesAxiosInstance = axios.create({
  baseURL: appConfig.apiUrl + '/v1/utilities',
});

UtilitiesAxiosInstance.interceptors.response.use((response) => {
  return response.data;
});

export default UtilitiesAxiosInstance;
