import { PaymentOperationId } from 'modules/payment/types';

import { TranslationKey } from 'libs/i18n';

export interface Operation {
  nameKey: TranslationKey;
  iconName: string;
  operationId: PaymentOperationId;
}

export const paymentOperations: {
  [key in 'deposit' | 'send']: {
    [operationName: string]: Operation;
  };
} = {
  deposit: {
    bankCard: {
      nameKey: 'PAYMENT_BANK_CARD',
      iconName: 'bankCard2',
      operationId: PaymentOperationId.depositFromBankCardToTradingWallet,
    },
    // applePay: {
    //   nameKey: 'PAYMENT_APPLE_PAY',
    //   iconName: 'paymentIconApplePay',
    //   operationId: PaymentOperationId.depositApplePay,
    // },
    SEPA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_SEPA',
      iconName: 'paymentSEPA',
      operationId: PaymentOperationId.depositFromSepaToTradingWallet,
    },
    wire: {
      nameKey: 'PAYMENT_BANK_TRANSFER_WIRE',
      iconName: 'paymentWire',
      operationId: PaymentOperationId.depositFromWireToTradingWallet,
    },
    wireUsd: {
      nameKey: 'PAYMENT_BANK_TRANSFER_WIRE',
      iconName: 'paymentWire',
      operationId: PaymentOperationId.depositFromWireToTradingWallet,
    },
    SEPA_DA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_SEPA',
      iconName: 'paymentSEPA',
      operationId: PaymentOperationId.depositFromSepaToMoneyWallet,
    },
    wire_DA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_WIRE',
      iconName: 'paymentWire',
      operationId: PaymentOperationId.depositFromWireToMoneyWallet,
    },
  },
  send: {
    bankCard: {
      nameKey: 'PAYMENT_BANK_CARD',
      iconName: 'bankCard2',
      operationId: PaymentOperationId.sendFromTradingWalletToBankCard,
    },
    toDigitalAccount: {
      nameKey: 'PAYMENT_NEBEUS_TO_MONEY_ACCOUNT',
      iconName: 'paymentNebeus',
      operationId: PaymentOperationId.sendFromTradingToMoney,
    },
    toCryptoAccount: {
      nameKey: 'PAYMENT_NEBEUS_TO_CRYPTO_ACCOUNT',
      iconName: 'paymentNebeus',
      operationId: PaymentOperationId.sendFromMoneyToTrading,
    },
    SEPA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_SEPA',
      iconName: 'paymentSEPA',
      operationId: PaymentOperationId.sendFromTradingToSepa,
    },
    wire: {
      nameKey: 'PAYMENT_BANK_TRANSFER_WIRE',
      iconName: 'paymentWire',
      operationId: PaymentOperationId.sendFromTradingToWire,
    },
    SEPA_DA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_SEPA',
      iconName: 'paymentSEPA',
      operationId: PaymentOperationId.sendFromMoneyToSepa,
    },
    wire_DA: {
      nameKey: 'PAYMENT_BANK_TRANSFER_WIRE',
      iconName: 'paymentWire',
      operationId: PaymentOperationId.sendFromMoneyToWire,
    },
    externalWallet: {
      nameKey: 'PAYMENT_TO_EXTERNAL_WALLET',
      iconName: 'paymentExternalWallet',
      operationId: PaymentOperationId.sendCryptoToExternalWallet,
    },
    nebeusUser: {
      nameKey: 'PAYMENT_TO_NEBEUS_USER',
      iconName: 'paymentNebeus',
      operationId: PaymentOperationId.sendToNebeusUser,
    },
  },
};
