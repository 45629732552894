import { memo } from 'react';

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

export type Paths<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<Paths<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never;

export const genericMemo: <T>(component: T) => T = memo;
