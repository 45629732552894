import clsx from 'clsx';

import { FC, useCallback, useEffect, useState } from 'react';

import { isReactNative, isReactNativeIOS, sendToReactNative } from 'modules/reactNative/utils';

import localStorageKeys from 'constants/localStorageKeys';

import { Smooth } from 'components/common';
import { Button, Icon } from 'components/ui';

import useFlag from 'hooks/useFlag';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import eventEmitter from 'utils/eventEmitter';
import {
  LINK_ANDROID_APP,
  LINK_IOS_APP_REVIEW,
  LINK_NEBEUS_TRUSTPILOT_RATE,
  openExternalLink,
} from 'utils/links';
import { openContactSupport } from 'utils/thirdPartyLibs';

import classes from './RateApp.module.scss';

const TWO_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000;

enum ReactNativeAnswers {
  trustPilotAgree = 'rateTrustPilotAgree',
  trustPilotLater = 'rateTrustPilotLater',
  trustPilotNever = 'rateTrustPilotNever',
  appAgree = 'rateAppAgree',
  appLater = 'rateAppLater',
  appNever = 'rateAppNever',
}

enum SavedAnswers {
  later = '1',
  never = '2',
}

interface RateAppProps {}
const RateApp: FC<RateAppProps> = () => {
  const translate = useTranslation();

  const [rate, setRate] = useState<number | null>(null);
  const trustPilotButton = useFlag(false);

  const handleRate = useCallback(
    (rateNumber: number) => {
      if (rate !== null) {
        return;
      }
      analytic.sendEvent(AnalyticEvents.rateApp, { rate: rateNumber });
      setRate(rateNumber);

      if (rateNumber < 4) {
        openContactSupport();
      } else {
        successToast(translate('RATE_APP_SUCCESS'));

        const trustPilotRateAnswerTime = localStorage.getItem(
          localStorageKeys.trustPilotRateAppAnswerTime,
        );
        const trustPilotRateAnswerType = localStorage.getItem(
          localStorageKeys.trustPilotRateAppAnswer,
        );
        if (isReactNative) {
          const nativeAppAnswerType = localStorage.getItem(localStorageKeys.nativeAppRateAnswer);

          const askForRateApp = () => {
            sendToReactNative('OPEN_ALERT', {
              title: translate('RATE_APP_REQUEST_TITLE'),
              message: translate(
                isReactNativeIOS
                  ? 'RATE_APP_REQUEST_SUBTITLE_APP_STORE'
                  : 'RATE_APP_REQUEST_SUBTITLE_GOOGLE_PLAY',
              ),
              buttons: [
                {
                  text: translate('RATE_APP_AGREE'),
                  handlerId: ReactNativeAnswers.appAgree,
                },
                {
                  text: translate('RATE_APP_LATER'),
                  handlerId: ReactNativeAnswers.appLater,
                },
                {
                  text: translate('RATE_APP_NEVER'),
                  handlerId: ReactNativeAnswers.appNever,
                  style: 'destructive',
                },
              ],
            });
          };

          const trustPilotProcess = () => {
            const askForRateOnTrustPilot = () => {
              sendToReactNative('OPEN_ALERT', {
                title: translate('RATE_APP_REQUEST_TITLE'),
                message: translate('RATE_APP_REQUEST_SUBTITLE_TRUSTPILOT'),
                buttons: [
                  {
                    text: translate('RATE_APP_AGREE'),
                    handlerId: ReactNativeAnswers.trustPilotAgree,
                  },
                  {
                    text: translate('RATE_APP_LATER'),
                    handlerId: ReactNativeAnswers.trustPilotLater,
                  },
                  {
                    text: translate('RATE_APP_NEVER'),
                    handlerId: ReactNativeAnswers.trustPilotNever,
                    style: 'destructive',
                  },
                ],
              });
            };
            switch (trustPilotRateAnswerType) {
              case SavedAnswers.never: {
                break;
              }
              case SavedAnswers.later: {
                if (
                  trustPilotRateAnswerTime &&
                  Date.now() - +trustPilotRateAnswerTime > TWO_DAYS_IN_MS
                ) {
                  askForRateOnTrustPilot();
                }
                break;
              }
              default: {
                askForRateOnTrustPilot();
              }
            }
          };

          switch (nativeAppAnswerType) {
            case SavedAnswers.never: {
              trustPilotProcess();
              break;
            }
            case SavedAnswers.later: {
              const nativeAppAnswerTime = localStorage.getItem(
                localStorageKeys.nativeAppRateAnswerTime,
              );
              if (nativeAppAnswerTime && Date.now() - +nativeAppAnswerTime > TWO_DAYS_IN_MS) {
                askForRateApp();
              } else {
                trustPilotProcess();
              }
              break;
            }
            default: {
              askForRateApp();
            }
          }
        } else {
          if (
            localStorage.getItem(localStorageKeys.trustPilotRateAppAnswer) === SavedAnswers.never
          ) {
            return;
          }
          trustPilotButton.on();
        }
      }
    },
    [translate, trustPilotButton, rate],
  );

  const handleAlertButtonPress = useCallback((payload: { handlerId: ReactNativeAnswers }) => {
    switch (payload.handlerId) {
      case ReactNativeAnswers.appAgree: {
        if (isReactNativeIOS) {
          openExternalLink(LINK_IOS_APP_REVIEW);
        } else {
          openExternalLink(LINK_ANDROID_APP);
        }
        localStorage.setItem(localStorageKeys.nativeAppRateAnswer, SavedAnswers.never);
        break;
      }
      case ReactNativeAnswers.appLater: {
        localStorage.setItem(localStorageKeys.nativeAppRateAnswerTime, Date.now().toString());
        localStorage.setItem(localStorageKeys.nativeAppRateAnswer, SavedAnswers.later);
        break;
      }
      case ReactNativeAnswers.appNever: {
        localStorage.setItem(localStorageKeys.nativeAppRateAnswer, SavedAnswers.never);
        break;
      }
      case ReactNativeAnswers.trustPilotAgree: {
        openExternalLink(LINK_NEBEUS_TRUSTPILOT_RATE);
        localStorage.setItem(localStorageKeys.trustPilotRateAppAnswer, SavedAnswers.never);
        break;
      }
      case ReactNativeAnswers.trustPilotLater: {
        localStorage.setItem(localStorageKeys.trustPilotRateAppAnswerTime, Date.now().toString());
        localStorage.setItem(localStorageKeys.trustPilotRateAppAnswer, SavedAnswers.later);
        break;
      }
      case ReactNativeAnswers.trustPilotNever: {
        localStorage.setItem(localStorageKeys.trustPilotRateAppAnswer, SavedAnswers.never);
        break;
      }
    }
  }, []);

  useEffect(() => {
    const unsub = eventEmitter.subscribe('ALERT_HANDLER', handleAlertButtonPress);

    return () => {
      unsub();
    };
  }, [handleAlertButtonPress]);

  const handleClickTrustpilot = useCallback(() => {
    openExternalLink(LINK_NEBEUS_TRUSTPILOT_RATE);
    localStorage.setItem(localStorageKeys.trustPilotRateAppAnswer, SavedAnswers.never);
  }, []);

  return (
    <div className={classes.root}>
      <span className={classes.title}>{translate('RATE_APP_TITLE')}</span>
      <div className={clsx(classes.stars, rate !== null && classes.locked)}>
        {new Array(5).fill(null).map((_, i) => (
          <Icon
            className={clsx(classes.icon, rate != null && i < rate ? classes.active : null)}
            onClick={() => {
              handleRate(i + 1);
            }}
            key={i}
            name="starFilled"
            size="xl"
          />
        ))}
      </div>
      <Smooth isVisible={trustPilotButton.state}>
        <Button fullWidth onClick={handleClickTrustpilot}>
          {translate('RATE_APP_TRUSTPILOT_BUTTON')}
        </Button>
      </Smooth>
    </div>
  );
};

export default RateApp;
