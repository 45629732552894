import clsx from 'clsx';

import { FC } from 'react';

import { Transaction } from 'modules/accounts/types';

import { TranslationKey, useTranslation } from 'libs/i18n';

import classes from './TransactionStatusTag.module.scss';

export const transactionLabelByStatus: { [key in Transaction['status']]: TranslationKey } = {
  created: 'ACCOUNT_TRANSACTION_STATUS_PROCESSING',
  waitingForApproval: 'ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_APPROVE',
  waitingForBlockConfirmation: 'ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_BLOCK_CONFIRM',
  completed: 'ACCOUNT_TRANSACTION_STATUS_COMPLETED',
  cancelled: 'ACCOUNT_TRANSACTION_STATUS_CANCELLED',
  failed: 'ACCOUNT_TRANSACTION_STATUS_FAIL',
  pending: 'ACCOUNT_TRANSACTION_STATUS_PROCESSING',
  rejected: 'ACCOUNT_TRANSACTION_STATUS_REJECTED',
};

interface TransactionStatusTagProps {
  className?: string;
  status: Transaction['status'];
}
export const TransactionStatusTag: FC<TransactionStatusTagProps> = ({ status, className }) => {
  const translate = useTranslation();

  const statusLabel = transactionLabelByStatus[status];

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.dot, classes[status.toLowerCase()])} />
      <span>{statusLabel ? translate(statusLabel) : status}</span>
    </div>
  );
};
