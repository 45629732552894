import { ComponentType, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectAppLanguage } from 'modules/app/store/selectors';
import { LangCode, defaultLanguage } from 'modules/user/constants/language';
import store from 'store';

import appConfig from 'constants/appConfig';

import en from './translations/en';
import es from './translations/es';

const translationsByCode: { [key in LangCode]: { [key: string]: string } } = {
  ENG: en,
  ESP: es,
};

export type TranslationKey = keyof typeof en;
export type TranslateFunc = (
  key: TranslationKey,
  options?: { [key: string]: string | number },
) => string;

const receiveTranslation = (
  key: TranslationKey,
  lang: LangCode = 'ENG',
  options?: { [key: string]: string | number },
) => {
  let translation =
    translationsByCode[lang]?.[key] ||
    translationsByCode[defaultLanguage.code][key] ||
    (appConfig.isDev ? 'NO TRANSLATION FOR KEY: ' + key : '');

  if (options) {
    Object.entries(options).forEach(
      ([optionsKey, value]) =>
        (translation = translation.replaceAll(`%{${optionsKey}}`, `${value}`)),
    );
  }
  return translation;
};

export const useTranslation = () => {
  const appLanguage = useSelector(selectAppLanguage);

  return useCallback<TranslateFunc>(
    (key, options) => receiveTranslation(key, appLanguage.code, options),
    [appLanguage],
  );
};

export const getTranslation: TranslateFunc = (key, options) => {
  const appLanguage = selectAppLanguage(store.getState());

  return receiveTranslation(key, appLanguage.code, options);
};

// eslint-disable-next-line react/display-name
export const withTranslations = (Component: ComponentType<any>) => (props: any) => {
  const translate = useTranslation();
  return <Component {...props} translate={translate} />;
};
