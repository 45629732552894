import { useSwiperSlide } from 'swiper/react';

import { useEffect, useRef } from 'react';

import { isSafari } from 'utils/device';

const useSwiperInputFocus = (options?: { additionalCondition?: boolean }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isActive } = useSwiperSlide();

  useEffect(() => {
    const focusInput = () => {
      if (options?.additionalCondition !== false && isActive) {
        inputRef.current?.focus({ preventScroll: true });
      }
    };
    if (isSafari) {
      setTimeout(focusInput, 100);
    } else {
      focusInput();
    }

    // eslint-disable-next-line
  }, [isActive, options?.additionalCondition]);

  return { inputRef, isActive };
};

export default useSwiperInputFocus;
