import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { AllowedCountry } from 'modules/app/types';
import IbanAccountSubscriptionMark from 'modules/digitalAccount/views/IbanAccountSubscriptionMark';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import {
  requestSendFromDigitalAccountToSEPAOrWire,
  requestSendFromTradingAccountToSEPAOrWire,
} from 'modules/payment/store/thunks';
import { PaymentOperationId, transactionTypesForOtpSending } from 'modules/payment/types';
import { AmountForm, WalletBalanceCard } from 'modules/payment/views/components';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import { RecipientAddress } from 'modules/payment/views/components/RecipientAddress';
import {
  WireDetailsForm,
  WireDetailsFormValues,
  wireDetailsInitialValues,
  wireDetailsValidationSchema,
} from 'modules/payment/views/components/WireDetailsForm';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { selectSelectedRecipientForSendingFunds } from 'modules/phonebook/store/selectors';
import RecipientSelector from 'modules/phonebook/views/RecipientSelector';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { AutoCompleteField } from 'components/form';
import { addressFormInitialValues, addressFormValidationSchema } from 'components/form/AddressForm';
import { DottedLine } from 'components/ui';
import { Address } from 'components/ui/AddressAutoComplete';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { getTranslation, useTranslation } from 'libs/i18n';
import { MoneyOutcomeTransactionRequest } from 'libs/swagger/nebeusApiTypes';
import yup, { makeValidate } from 'libs/yup';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

export interface SendWireProps {
  isDigitalAccount: boolean;
}

type RecipientType = MoneyOutcomeTransactionRequest['recipientType'];
interface RecipientTypeOption {
  value: RecipientType;
  label: string;
}

interface FormValues extends PaymentAmountFormValues, WireDetailsFormValues {
  recipientType: RecipientTypeOption | null;
  recipientAddress: Omit<Address, 'country'> & { country: AllowedCountry | null };
}

interface SendWireFormProps extends FormRenderProps<FormValues> {
  operationId: PaymentOperationId;
  recipientTypeOptions: RecipientTypeOption[];
  isDigitalAccount: boolean;
}

const initialValues: FormValues = {
  currencyAmount: { amount: '', currency: 'GBP' },
  commissionAmount: null,
  recipientType: null,
  recipientAddress: addressFormInitialValues,
  ...wireDetailsInitialValues,
};

const recipientTypeKeyAccessor = (option: RecipientTypeOption) => option.value;
const recipientTypeLabelAccessor = (option: RecipientTypeOption) => option.label;

const SendWireForm: FC<SendWireFormProps> = ({
  handleSubmit,
  operationId,
  isDigitalAccount,
  recipientTypeOptions,
}) => {
  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <RecipientSelector paymentType="WIRE">
        <WireDetailsForm />
      </RecipientSelector>

      {isDigitalAccount && (
        <>
          <DottedLine />
          <Field
            name="recipientType"
            component={AutoCompleteField}
            options={recipientTypeOptions}
            keyAccessor={recipientTypeKeyAccessor}
            labelAccessor={recipientTypeLabelAccessor}
            autocomplete={false}
            placeholder={getTranslation('PAYMENT_RECIPIENT_TYPE')}
          />
          <RecipientAddress />
          <DottedLine />
        </>
      )}
      <AmountForm
        operationId={operationId}
        beforeButtonComponent={
          <p className="font-s grey-300">{getTranslation('PAYMENT_SEND_WIRE_DA_FAQ_STEPS_1')}</p>
        }
      />
    </form>
  );
};

const SendWire: FC<SendWireProps> = ({ isDigitalAccount }) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  usePaymentInit();

  const userProfile = useSelector(selectUserProfile);

  const getSummaryBlocks = useCallback(
    (values: FormValues): SummaryCardItem[][] => {
      const result = [
        [
          {
            label: translate('PAYMENT_RECIPIENTS_GETS'),
            value: formatCurrencyWithSymbol(
              values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
          },
          {
            label: translate('PAYMENT_NEBEUS_FEE'),
            value: formatCurrencyWithSymbol(
              values.commissionAmount || 0,
              values.currencyAmount.currency,
            ),
          },
          {
            label: translate('PAYMENT_TOTAL_WITHDRAWN'),
            value: formatCurrencyWithSymbol(
              values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
          },
        ],
      ];

      const firstBlock = [
        {
          label: translate('SENDER'),
          value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
        },
        { label: translate('RECIPIENT'), value: values.accountName },
        { label: translate('RECIPIENTS_ACCOUNT_NUMBER'), value: values.accountNumber },
        { label: translate('PAYMENT_SORT_CODE'), value: values.sortCode },
        {
          label: translate('PAYMENT_REFERENCE'),
          value: values.reference || '-',
        },
      ];

      if (isDigitalAccount) {
        firstBlock.push(
          {
            label: translate('PAYMENT_RECIPIENT_TYPE'),
            value: values.recipientType?.label || '-',
          },
          {
            label: translate('RECIPIENT_ADDRESS'),
            value: [
              values.recipientAddress.address1,
              values.recipientAddress.address2,
              values.recipientAddress.city,
              values.recipientAddress.country!.name,
              values.recipientAddress.postCode,
            ]
              .filter((i) => !!i)
              .join(', '),
          },
        );
      }

      result.unshift(firstBlock);

      return result;
    },
    [isDigitalAccount, translate, userProfile],
  );

  const phonebookRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const sendFunds = useCallback(
    async (values: FormValues, otpCode: string) => {
      const {
        currencyAmount,
        accountName,
        accountNumber,
        sortCode,
        reference,
        currencyAmountWithCommission,
      } = values;

      let response = null;
      if (isDigitalAccount) {
        response = await dispatch(
          requestSendFromDigitalAccountToSEPAOrWire({
            amount: +currencyAmount.amount,
            currency: 'GBP',
            gbpDestinationAccountNumber: accountNumber,
            gbpDestinationAccountName: accountName,
            gbpDestinationReferenceMessage: reference,
            gbpDestinationSortCode: sortCode,
            otpCode,
            recipientType: values.recipientType!.value,
            address: {
              line1: values.recipientAddress!.address1,
              line2: values.recipientAddress!.address2,
              postalCode: values.recipientAddress!.postCode,
              country: values.recipientAddress!.country!.code,
              city: values.recipientAddress!.city,
              state: '',
            },
            // @ts-ignore For analytic purpose
            analyticField_phonebookRecipient: phonebookRecipient,
          }),
        );
      } else {
        response = await dispatch(
          requestSendFromTradingAccountToSEPAOrWire({
            amount: +currencyAmount.amount,
            currency: 'GBP',
            gbpAccountNumber: accountNumber,
            gbpAccountName: accountName,
            gbpReferenceMessage: reference,
            gbpSortCode: sortCode,
            otpCode,
            // @ts-ignore For analytic purpose
            analyticField_phonebookRecipient: phonebookRecipient,
          }),
        );
      }

      if (!response) {
        return;
      }

      const desc = translate('PAYMENT_SEND_TO_WIRE_SUCCESS_MESSAGE', {
        currencyAmountLabel: formatCurrencyWithLabel(
          currencyAmountWithCommission?.amount || currencyAmount.amount,
          currencyAmount.currency,
        ),
        accountName: accountName,
      });

      const { success, error } = response;

      const drawerTemplate = paymentDrawerTemplates.finishedOperation({
        currencyCode: currencyAmount.currency,
        amount: +currencyAmount.amount * -1,
        isDeposit: false,
        isSuccess: success,
        description: success ? desc : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
        summaryBlocks: [
          [
            {
              label: translate('DESCRIPTION'),
              value: translate('PAYMENT_SEND_BY_WIRE'),
            },
            { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
            {
              label: translate('ACCOUNT_OPERATION_STATUS'),
              value: translate(
                success ? 'PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY' : 'PAYMENT_OPERATION_FAILED',
              ),
            },
          ],
          ...getSummaryBlocks(values),
        ],
      });

      if (success) {
        drawer.replaceAll(drawerTemplate);
      } else {
        drawer.replace(drawerTemplate);
      }
    },
    [phonebookRecipient, drawer, getSummaryBlocks, translate, dispatch, isDigitalAccount],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      drawer.open(
        paymentDrawerTemplates.confirmOTPCode({
          summary: {
            currencyAmount: values.currencyAmount,
            currencyAmountWithCommission: values.currencyAmountWithCommission,
          },
          importantLabel: getTranslation('PAYMENT_SEND_FIAT_IMPORTANT_NOTE'),
          onConfirmed: (code: string) => sendFunds(values, code),
          summaryBlocks: getSummaryBlocks(values),
          transactionType:
            transactionTypesForOtpSending[
              isDigitalAccount
                ? PaymentOperationId.sendFromMoneyToWire
                : PaymentOperationId.sendFromTradingToWire
            ],
        }),
      );
    },
    [drawer, isDigitalAccount, getSummaryBlocks, sendFunds],
  );

  const operationId = isDigitalAccount
    ? PaymentOperationId.sendFromMoneyToWire
    : PaymentOperationId.sendFromTradingToWire;

  const recipientTypeOptions = useMemo<RecipientTypeOption[]>(
    () => [
      { value: 'PERSON', label: translate('PAYMENT_RECIPIENT_TYPE_PERSON') },
      { value: 'BUSINESS', label: translate('PAYMENT_RECIPIENT_TYPE_BUSINESS') },
    ],
    [translate],
  );

  const { amountFormSchema } = useAmountFormValidation({
    operationId,
    isDigitalAccount,
    currencyCode: 'GBP',
  });

  const recipientAdditionalFormSchema = useMemo(
    () =>
      yup.object().shape({
        recipientType: yup.object().required(translate('VALIDATION_REQUIRED')),
        recipientAddress: addressFormValidationSchema,
      }),
    [translate],
  );

  const schema = useMemo(() => {
    const result = amountFormSchema.concat(wireDetailsValidationSchema);
    if (isDigitalAccount) {
      return result.concat(recipientAdditionalFormSchema);
    }
    return result;
  }, [isDigitalAccount, recipientAdditionalFormSchema, amountFormSchema]);

  const validate = useMemo(() => makeValidate(schema), [schema]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <SendWireForm
        operationId={operationId}
        isDigitalAccount={isDigitalAccount}
        recipientTypeOptions={recipientTypeOptions}
        {...formProps}
      />
    ),
    [operationId, isDigitalAccount, recipientTypeOptions],
  );
  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode="GBP" isDigitalAccount={isDigitalAccount} />
      <WithdrawalLimitsCard
        rightAdornment={isDigitalAccount ? <IbanAccountSubscriptionMark /> : undefined}
        currencyCode="GBP"
        operationId={
          isDigitalAccount
            ? PaymentOperationId.sendFromMoneyToWire
            : PaymentOperationId.sendFromTradingToWire
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={renderForm}
        validate={validate}
      />
    </div>
  );
};

export default SendWire;
