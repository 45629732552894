import sidebarScreensClasses from 'modules/app/views/Sidebar/SidebarScreens.module.scss';
import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { ChooseSubscriptionProps } from 'modules/digitalAccount/views/ChooseSubscription';
import { SubscriptionManagementProps } from 'modules/digitalAccount/views/SubscriptionManagement';
import { SubscriptionVerificationPendingProps } from 'modules/digitalAccount/views/SubscriptionVerificationPending';

import { getTranslation } from 'libs/i18n';

import classes from './DigitalAccount.module.scss';

const digitalAccountChooseSubscription = (
  props?: ChooseSubscriptionProps,
): DrawerTemplateReturnType => ({
  key: 'digitalAccountChooseSubscription',
  props,
  sideBarProps: {
    contentClassName: classes.digitalAccountChooseSubscription,
    showBackButton: false,
    navigationVariant: 'secondary',
    secondaryNavigationProps: { variant: 'light' },
  },
});
const switchDefaultIban = (): DrawerTemplateReturnType => ({
  key: 'switchDefaultIban',
  sideBarProps: {
    showBackButton: false,
    swipeModal: true,
  },
});

const subscriptions = (): DrawerTemplateReturnType => ({
  key: 'subscriptions',
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('SUBSCRIPTIONS'),
  },
});
const digitalAccountSubscriptionVerificationPending = (
  props: SubscriptionVerificationPendingProps,
): DrawerTemplateReturnType => ({
  key: 'digitalAccountSubscriptionVerificationPending',
  props,
  sideBarProps: {
    showBackButton: false,
    navigationVariant: 'secondary',
    secondaryNavigationProps: { variant: 'light' },
    contentClassName: sidebarScreensClasses['digitalAccountSubLayout-' + props.subscriptionType],
  },
});

const digitalAccountSubscriptionManagement = (
  props: SubscriptionManagementProps,
): DrawerTemplateReturnType => ({
  key: 'digitalAccountSubscriptionManagement',
  props,
  sideBarProps: { swipeModal: true, navigationHeaderLabel: getTranslation('DIGITAL_ACCOUNT') },
});

// TODO: Return when corp details will be available
// const accountCreatingPrompt = (): DrawerTemplateReturnType => ({
//   key: 'ibanAccountCreatingPrompt',
//   sideBarProps: { showBackButton: false },
// });

const accountCreatingPrompt = digitalAccountChooseSubscription;

const accountCreatingDeposit = (): DrawerTemplateReturnType => ({
  key: 'ibanAccountCreatingDeposit',
  sideBarProps: { showBackButton: false },
});
const accountCreatingStatus = (): DrawerTemplateReturnType => ({
  key: 'ibanAccountCreatingStatus',
  sideBarProps: { showBackButton: false },
});

const ibanAccountDrawerTemplates = {
  digitalAccountChooseSubscription,
  digitalAccountSubscriptionVerificationPending,
  digitalAccountSubscriptionManagement,
  switchDefaultIban,
  subscriptions,
  accountCreatingPrompt,
  accountCreatingDeposit,
  accountCreatingStatus,
};

export default ibanAccountDrawerTemplates;
