enum LocalStorageKeys {
  moneyBankCardAdvertisementRejectTime = '235n34jk5h4j389dsa',
  cryptoBankCardAdvertisementRejectTime = 'ngjksdf8a9sdas90fuas',
  cryptoBankCardAdvertisementSidebarRejectTime = 'cryUpd1Sidejkln3j2k4hj23kb4h',
  persistVersion = 'per7g8sd6f87sdt67f8',
  lastBirthdayYearCelebrated = 'HByearsbfads90gfd9a023jnk',
  referralLinksLastShowedTime = 'ref5h2i34kg2j3h423klj42',
  referralLinksBannerCloseTime = 'refBan8g9sdf677sa8f345b',
  rentingNewCoinsBannerCloseTime = 'renNewCoinBan8g9sdf677sa8f345b',
  curveAppAdRejectedTime = 'curv3274892348726387421hk',
  trustPilotRateAppAnswerTime = 'trstpltd6a7s86f7as5f67as5df67a',
  trustPilotRateAppAnswer = 'trstpltRejTpd879asfas6d8as6',
  nativeAppRateAnswerTime = 'natAppRateaysd87as6f87as6d7a',
  nativeAppRateAnswer = 'natAppRateTpg78s90fuys8d9f79sa',
  virtualCardGoogleWalletAdShowedTime = 'viCaGoWa2l3kh4j3k25hjk234j23k423',
  maintenanceScheduleRead = 'mainRead_',
}

export default LocalStorageKeys;
