import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountWallets } from 'modules/accounts/store/selectors';
import { requestCreateDigitalWallet } from 'modules/accounts/store/thunks';
import useSideBar from 'modules/app/hooks/useSideBar';
import { SideBarProps } from 'modules/app/types';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import { getSubscriptionBenefitsList } from 'modules/digitalAccount/constants/config';
import { requestDigitalAccountSubscriptions } from 'modules/digitalAccount/store/thunks';
import { DigitalSubscription } from 'modules/digitalAccount/types';
import { SubscriptionLayout } from 'modules/digitalAccount/views/components/SubscriptionLayout';
import { SubscriptionPriceCard } from 'modules/digitalAccount/views/components/SubscriptionPriceCard';
import { SubscriptionProcessTitle } from 'modules/digitalAccount/views/components/SubscriptionProcessTitle';
import { selectIsUserVerified, selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { Button, DescriptionList } from 'components/ui';

import useFlag from 'hooks/useFlag';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { useTranslation } from 'libs/i18n';

import classes from './GetSubscription.module.scss';

export interface GetSubscriptionProps {
  subscription: DigitalSubscription;
}

const sideBarProps: SideBarProps = {
  contentClassName: classes.descriptionListBg,
  variant: 'transparent',
  secondaryNavigationProps: { variant: 'light' },
};
const GetSubscription: FC<GetSubscriptionProps> = ({ subscription }) => {
  const translate = useTranslation();
  const sidebar = useSideBar();
  const dispatch = useDispatch();

  const user = useSelector(selectUserProfile);
  const digitalWallets = useSelector(selectDigitalAccountWallets);
  const userVerified = useSelector(selectIsUserVerified);

  const benefitsList = useMemo(
    () => getSubscriptionBenefitsList(subscription.type, translate),
    [subscription.type, translate],
  );

  const loading = useFlag(false);

  const handleSubmit = useCallback(async () => {
    if (!userVerified) {
      sidebar.open(...sidebarTemplates.verificationID());
      return;
    }
    if (!user) {
      return;
    }
    if (subscription.type === 'standard') {
      // return;
      // loading.on();
      //
      // analytic.sendEvent(AnalyticEvents.userRequestModulRAccount);
      //
      // const { success } = await dispatch(
      //   requestCreateMoneyAccount({ email: user.email, id: user.id }),
      // );
      //
      // loading.off();
      //
      // if (success) {
      //   sidebar.replaceAll(
      //     ...sidebarTemplates.digitalAccountSubscriptionRequested({
      //       subscription,
      //     }),
      //   );
      // }

      loading.on();
      // For standard we open modulr account and backend create standard subs
      const { success } = await dispatch(requestCreateDigitalWallet('EUR'));

      const { success: subSuccess, data: subscriptions } = await dispatch(
        requestDigitalAccountSubscriptions(),
      );
      const userHasDigitalWallet = digitalWallets.find((w) => w.currencyCode === 'EUR' && w.exist);

      loading.off();
      if (success && subSuccess && subscriptions?.length) {
        analytic.sendEvent(AnalyticEvents.userSuccessfullyRequestIbanAccount);

        sidebar.replaceAll(
          ...sidebarTemplates.digitalAccountGetSubscriptionSuccess({
            subscription: subscriptions.find((s) => s.type === 'standard')!,
            showWalletDetails: !userHasDigitalWallet,
          }),
        );
      }
    } else {
      sidebar.open(...sidebarTemplates.digitalAccountGetSubscriptionConfirm({ subscription }));
    }
  }, [digitalWallets, user, dispatch, userVerified, sidebar, loading, subscription]);

  return (
    <SubscriptionLayout subscriptionType={subscription.type}>
      <div>
        <SubscriptionProcessTitle subscriptionType={subscription.type} />
        <SubscriptionPriceCard subscription={subscription} />
        <DescriptionList className="mt-3" list={benefitsList} sideBarProps={sideBarProps} />
      </div>
      <div>
        <Button loading={loading.state} onClick={handleSubmit} fullWidth variant="gold" showShadow>
          {translate('CONTINUE')}
        </Button>

        <AgreementText variant="modulR" light className="mt-1-5" />
      </div>
    </SubscriptionLayout>
  );
};

export default GetSubscription;
