import { selectIssuers } from 'modules/accounts/store/selectors';
import { Wallet } from 'modules/accounts/types';
import store from 'store';

import { isInteger } from 'utils/common';

import { CurrencyCode } from 'types';

export const fiatCurrencies = ['EUR', 'USD', 'GBP'] as CurrencyCode[];
export const cryptoCurrencies: CurrencyCode[] = [
  'AAVE',
  'ALGO',
  'APE',
  'AVAX',
  'BCH',
  'BTC',
  'BUSD',
  'CELO',
  'CHZ',
  'DASH',
  'EOS',
  'ETH',
  'FTM',
  'GRT',
  'LINK',
  'LTC',
  'MATIC',
  'SAND',
  'UNI',
  'USDC',
  'USDT',
  'XLM',
  'XRP',
  'XTZ',
  '1INCH',
];

const currencyLabelByCode: { [key in CurrencyCode]?: string } = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USD: 'Dollar',
  EUR: 'Euro',
  GBP: 'British pound',
  USDC: 'USDC',
  USDT: 'USDT',
  XRP: 'Ripple',
  EOS: 'EOS',
  LTC: 'Litecoin',
  NBT: 'NBTK',
};
const currencySymbolByCode: { [key in CurrencyCode]?: string } = {
  BTC: 'Ƀ',
  ETH: 'Ξ',
  USD: '$',
  EUR: '€',
  GBP: '£',
};

export const getCurrencyLabelByCode = (
  code: CurrencyCode,
  options?: { withBrackets?: boolean },
) => {
  let result = currencyLabelByCode[code] || '';

  if (!result) {
    const issuers = selectIssuers(store.getState());
    result = issuers.find((i) => i.currencyCode === code)?.currencyLabel || '';
  }
  if (!result) {
    result = code;
  }

  if (options?.withBrackets && result !== code) {
    result += ` (${code})`;
  }
  return result;
};

export const getCurrencySymbolByCode = (code?: CurrencyCode): string => {
  if (!code) {
    return '';
  }
  let result = currencySymbolByCode[code] || '';
  if (!result) {
    const issuers = selectIssuers(store.getState());
    result = issuers.find((i) => i.currencyCode === code)?.currencySymbol || '';
  }
  if (!result) {
    result = code;
  }
  return result;
};

export const isCurrencyCode = (code: string): boolean =>
  // @ts-ignore
  fiatCurrencies.includes(code) || cryptoCurrencies.includes(code);
export const isFiat = (code: CurrencyCode): boolean => fiatCurrencies.includes(code);
export const MINIMUM_AMOUNT_FIAT = 0.01;
export const MINIMUM_AMOUNT_CRYPTO = 0.000001;

export interface FormatCurrencyOptions {
  round?: 'default' | 'down' | 'up';
  withZeros?: boolean;
  separateThousands?: boolean;
}

export const formatCurrency = (
  amount: string | number = 0,
  isFiatOrCurrencyCode: boolean | CurrencyCode = false,
  { round = 'default', withZeros = false, separateThousands = false }: FormatCurrencyOptions = {
    round: 'default',
    withZeros: false,
    separateThousands: false,
  },
): string => {
  let result: string | number = +amount || 0;

  const fiat =
    typeof isFiatOrCurrencyCode === 'boolean' ? isFiatOrCurrencyCode : isFiat(isFiatOrCurrencyCode);

  const roundAccuracy = fiat ? 2 : 6;

  const accuracy = Math.pow(10, roundAccuracy);
  if (round === 'up') {
    result = Math.ceil(+result * accuracy) / accuracy;
  } else if (round === 'down') {
    result = Math.floor(+result * accuracy) / accuracy;
  } else {
    result = Math.round(+result * accuracy) / accuracy;
  }

  const isInt = isInteger(result);
  if (withZeros || !isInt) {
    result = result.toFixed(roundAccuracy);
  } else {
    result = result.toString();
  }

  if (separateThousands) {
    let [int, dec] = `${result}`.split('.');
    const intThousandSeparated = int.length > 3 ? int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : int;

    result = intThousandSeparated;
    if (dec) {
      result += `.${dec}`;
    }
  }

  return result;
};

export const formatCurrencyWithLabel = (
  amount: string | number,
  currencyCode: CurrencyCode,
  options?: FormatCurrencyOptions,
) => {
  return `${currencyCode} ${formatCurrency(amount, currencyCode, {
    separateThousands: true,
    ...options,
  })}`;
};

export const formatCurrencyWithSymbol = (
  amount: string | number,
  currencyCode: CurrencyCode,
  options?: FormatCurrencyOptions,
) => {
  const formattedAmount = formatCurrency(amount, currencyCode, {
    separateThousands: true,
    ...options,
  });

  return `${Number(amount) >= 0 ? '' : '-'}${
    isFiat(currencyCode) ? getCurrencySymbolByCode(currencyCode) : `${currencyCode} `
  }${formattedAmount.replace('-', '')}`;
};

export const findWallet = <T extends Wallet>(wallets: T[], code: CurrencyCode) =>
  wallets.find((w) => w.currencyCode === code);

export const stableCoinsCodes = ['USDT', 'USDC'];
export const mainCryptoCodes: CurrencyCode[] = ['BTC', 'ETH'];

// export const isStableCoin = (code: CurrencyCode) => stableCoinsCodes.includes(code);
// export const isMainCrypto = (code: CurrencyCode) => mainCryptoCodes.includes(code);
//
// export const digitalAccountCurrenciesCodes: FiatCurrencyCode[] = ['EUR', 'GBP'];
// export const cryptoAccountCurrenciesCodes: CurrencyCode[] = [
//   ...fiatCurrencies,
//   ...cryptoCurrencies,
// ];

export const sortCurrencies = (
  currencies: CurrencyCode[],
  { last, first }: { last?: CurrencyCode[]; first?: CurrencyCode[] },
) => {
  const copy = [...currencies];

  const firstCurrencies = new Set(first?.filter((c) => currencies.includes(c)) || []);
  const lastCurrencies = new Set(last?.filter((c) => currencies.includes(c)) || []);

  const middleCurrencies = copy.filter(
    (code) => !firstCurrencies.has(code) && !lastCurrencies.has(code),
  );

  return [...firstCurrencies, ...middleCurrencies, ...lastCurrencies];
};
