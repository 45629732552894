// import cryptoBankCardServicesMocked from 'modules/cryptoBankCard/services/mocked';
import {
  transformAllowedCurrencies,
  transformBankCards,
  transformBankCardsOrders,
  transformTransactions,
} from 'modules/cryptoBankCard/services/transformers';
import {
  BankCardDetails,
  Config,
  CryptoBankCard,
  CryptoBankCardFeeConfig,
  CryptoBankCardOrder,
  CryptoBankCardTransaction,
  CryptoCardLimits,
  DeliveryPlan,
} from 'modules/cryptoBankCard/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  CryptoCardActivationRequest,
  CryptoCardAllowedCurrencyResponse,
  CryptoCardChangePinRequest,
  CryptoCardClientLimitResponse,
  CryptoCardConfigResponse,
  CryptoCardDetailsResponse,
  CryptoCardExpenseResponse,
  CryptoCardLinkingWalletRequest,
  CryptoCardListResponse,
  CryptoCardMonthlyFeeConfigResp,
  CryptoCardOrderAllowedCountryResponse,
  CryptoCardOrderPhysicalOnboardingReq,
  CryptoCardOrderPhysicalRequest,
  CryptoCardOrderVirtualOnboardingReq,
  CryptoCardOrderVirtualRequest,
  CryptoCardPreOrderedInfoResponse,
  CryptoCardReadPreOrderedInfoRequest,
  CryptoCardShippingResponse,
  CryptoCardTransactionListResponse,
  GetShippingDetailsUsingQueryParams,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode, FiatCurrencyCode } from 'types';

// cli-import

const cryptoBankCardServices = {
  orderDebitCard: (payload: CryptoCardOrderPhysicalRequest) =>
    AxiosInstance.post<CryptoCardOrderPhysicalRequest, CryptoCardOrderPhysicalRequest>(
      '/v1/crypto-card/order',
      payload,
    ),
  orderVirtualCard: (payload: CryptoCardOrderVirtualRequest) =>
    AxiosInstance.post<CryptoCardOrderVirtualRequest, CryptoCardOrderVirtualRequest>(
      '/v1/crypto-card/order-virtual',
      payload,
    ),
  orderVirtualCardFromOnboarding: (payload: CryptoCardOrderVirtualOnboardingReq) =>
    AxiosInstance.post<CryptoCardOrderVirtualOnboardingReq, CryptoCardOrderVirtualOnboardingReq>(
      '/v1/crypto-card/onboarding/order-virtual',
      payload,
    ),

  orderPhysicalCardFromOnboarding: (payload: CryptoCardOrderPhysicalOnboardingReq) =>
    AxiosInstance.post('/v1/crypto-card/onboarding/order-physical', payload),

  getAllowedDebitCardPaymentCurrencyCodes: (): Promise<CurrencyCode[]> =>
    AxiosInstance.get<CryptoCardAllowedCurrencyResponse, CryptoCardAllowedCurrencyResponse>(
      '/v1/crypto-card/currency/allowed',
    ).then(transformAllowedCurrencies),
  getBankCardOrders: (): Promise<CryptoBankCardOrder[]> =>
    AxiosInstance.get<CryptoCardPreOrderedInfoResponse, CryptoCardPreOrderedInfoResponse>(
      '/v1/crypto-card/pre-ordered-info',
    ).then(transformBankCardsOrders),
  getConfig: (): Promise<Config> =>
    AxiosInstance.get<CryptoCardConfigResponse, CryptoCardConfigResponse>(
      '/v1/crypto-card/config',
    ).then((response) => ({
      deliveryPriceInEur: response.configInfo.deliveryPriceInEur,
      cardReleasePriceInEur: response.configInfo.debitCardReleasePriceInEur,
      virtualCardReleasePriceInEur: response.configInfo.debitVirtualCardReleasePriceInEur,
      minAmountToProcessCardOrderInEur: response.configInfo.physicalCardReleaseMinDeposit,
      minAmountToProcessVirtualCardOrderInEur: response.configInfo.virtualCardReleaseMinDeposit,
    })),
  readBankCardOrder: (id: CryptoBankCardOrder['id']) =>
    AxiosInstance.post('/v1/crypto-card/pre-ordered-info/read', {
      cardId: id,
    } as CryptoCardReadPreOrderedInfoRequest),
  getAllowedCountries: () =>
    AxiosInstance.get<CryptoCardOrderAllowedCountryResponse, CryptoCardOrderAllowedCountryResponse>(
      '/v1/crypto-card/order/allowed-country',
    ).then((response) => response.allowedCountriesForCryptoCardOrder),

  getBankCards: (): Promise<CryptoBankCard[]> =>
    AxiosInstance.get<CryptoCardListResponse, CryptoCardListResponse>('/v1/crypto-card').then(
      transformBankCards,
    ),

  getBankCardDetails: (id: number): Promise<BankCardDetails> =>
    AxiosInstance.get<CryptoCardDetailsResponse, CryptoCardDetailsResponse>(
      `/v1/crypto-card/${id}/details`,
    ).then((response) => response.details),

  freezeBankCard: (id: number): Promise<void> => AxiosInstance.post(`/v1/crypto-card/${id}/freeze`),

  unfreezeBankCard: (id: number): Promise<void> =>
    AxiosInstance.post(`/v1/crypto-card/${id}/unfreeze`),

  terminateBankCard: (id: number): Promise<void> =>
    AxiosInstance.post(`/v1/crypto-card/${id}/close`),

  activateBankCard: ({
    id,
    cvc,
    linkedCurrencyCode,
  }: {
    id: number;
    cvc: string;
    linkedCurrencyCode: CurrencyCode;
  }) =>
    AxiosInstance.post(`/v1/crypto-card/${id}/activate`, {
      cvcCode: cvc,
      linkingWalletCurrencyCode: linkedCurrencyCode,
    } as CryptoCardActivationRequest),
  getAllowedLinkingCurrencies: (): Promise<CurrencyCode[]> =>
    AxiosInstance.get<CryptoCardAllowedCurrencyResponse, CryptoCardAllowedCurrencyResponse>(
      '/v1/crypto-card/currency/allowed',
    ).then((response) => response.allowedCurrencies as CurrencyCode[]),
  linkTradingWalletToCryptoCard: ({
    bankCardId,
    currencyCode,
  }: {
    bankCardId: number;
    currencyCode: CurrencyCode;
  }) =>
    AxiosInstance.post(`/v1/crypto-card/${bankCardId}/trading-wallet/link`, {
      linkingWalletCurrencyCode: currencyCode,
    } as CryptoCardLinkingWalletRequest),
  getTransactionHistory: ({
    id,
    pageSize,
    pageNumber,
  }: {
    id: number;
    pageSize: number;
    pageNumber: number;
  }): Promise<{ transactions: CryptoBankCardTransaction[] }> =>
    AxiosInstance.get<CryptoCardTransactionListResponse, CryptoCardTransactionListResponse>(
      `/v1/crypto-card/${id}/transaction`,
      {
        params: { pageNumber, pageSize },
      },
    ).then(transformTransactions),

  getCardExpenses: (
    id: number,
  ): Promise<{
    expenses: {
      monthly: number;
    };
    defaultCurrency: FiatCurrencyCode;
  }> =>
    AxiosInstance.get<CryptoCardExpenseResponse, CryptoCardExpenseResponse>(
      `/v1/crypto-card/${id}/expense`,
    ).then((response) => response.expenses),

  sendCardPinCode: ({ id }: { id: number }) => AxiosInstance.post(`/v1/crypto-card/${id}/send-pin`),
  changeCardPinCode: ({ id, oldPin, newPin }: { id: number; oldPin: string; newPin: string }) =>
    AxiosInstance.post<void, void, CryptoCardChangePinRequest>(`/v1/crypto-card/${id}/change-pin`, {
      oldPin,
      newPin,
    }),
  sendCardPassCode: ({ id }: { id: number }) =>
    AxiosInstance.post(`/v1/crypto-card/${id}/send-passcode`),
  getDeliveryPlans: ({
    countryIso2Code,
  }: GetShippingDetailsUsingQueryParams): Promise<DeliveryPlan[]> =>
    AxiosInstance.get<CryptoCardShippingResponse, CryptoCardShippingResponse>(
      `/v1/crypto-card/shipping/${countryIso2Code}`,
    ).then((res) => res.shippingInfo),

  getCardLimits: (id: CryptoBankCard['id']): Promise<CryptoCardLimits> =>
    AxiosInstance.get<CryptoCardClientLimitResponse, CryptoCardClientLimitResponse>(
      `/v1/crypto-card/${id}/client-limits`,
    ).then((res) => res.clientLimits),
  getFeeConfig: (): Promise<CryptoBankCardFeeConfig> =>
    AxiosInstance.get<CryptoCardMonthlyFeeConfigResp, CryptoCardMonthlyFeeConfigResp>(
      '/v1/crypto-card/monthly-fee/config',
    ).then((res) => res.monthlyFeeConfig),
  // cli-service
};

export default cryptoBankCardServices;
// export default cryptoBankCardServicesMocked;
