import clsx from 'clsx';

import {
  FC,
  HTMLProps,
  ReactElement,
  TouchEventHandler,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';

import useAnchor from 'hooks/useAnchor';
import useFlag from 'hooks/useFlag';

import { voidFunc } from 'types';

import classes from './SwipeModal.module.scss';

export interface SwipeModalProps extends HTMLProps<HTMLDivElement> {
  isVisible: boolean;
  canClose?: boolean;
  maxHeight?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
  contentClassName?: string;
  onClose: voidFunc;
  variant?: 'creamy' | 'creamyWhite' | 'white' | 'transparent';
  index?: number;
}

const closedTransform = 'translateY(calc(100% + 56px))';
const visibleTransform = 'translateY(0px)';

export const SwipeModal: FC<SwipeModalProps> = ({
  children,
  className,
  isVisible,
  onClose,
  variant = 'white',
  index = 1,
  canClose = true,
  maxHeight,
  contentClassName,
  ...props
}) => {
  const draggable = useFlag(false);

  const [rootRef, setRootRef] = useAnchor();

  const memoizedChildren = useMemo(() => children, [children]);

  const sliderOffset = useRef<number | null>(null);

  const handleStart = useCallback<TouchEventHandler<HTMLDivElement>>(
    (event) => {
      draggable.on();
      if (!rootRef) {
        return;
      }

      sliderOffset.current = Math.round(
        event.touches[0].clientY - (window.innerHeight - rootRef?.clientHeight),
      );
    },
    [rootRef, draggable],
  );

  const handleTouchMoveSlider = useCallback<TouchEventHandler<HTMLDivElement>>(
    (event) => {
      if (!rootRef) {
        return;
      }

      const y = event.touches[0].clientY;
      const modalPosition = window.innerHeight - y;
      let offset = Math.round(rootRef?.clientHeight - modalPosition) - (sliderOffset.current || 0);

      if (offset < 0) {
        return;
      }

      if (rootRef) {
        rootRef.style.transform = `translateY(${offset}px)`;
      }
    },
    [rootRef],
  );

  const handleRelease = useCallback<TouchEventHandler<HTMLDivElement>>(
    (event) => {
      draggable.off();

      if (rootRef) {
        sliderOffset.current = null;

        const y = event.changedTouches[0].clientY;
        const modalPosition = window.innerHeight - y;
        const offset = Math.round(rootRef?.clientHeight - modalPosition);

        if (offset > 0 && (offset / rootRef?.clientHeight) * 100 > 30) {
          onClose();
          rootRef.style.transform = closedTransform;
        } else {
          rootRef.style.transform = visibleTransform;
        }
      }
    },
    [onClose, rootRef, draggable],
  );

  return createPortal(
    <>
      <div
        onClick={canClose ? onClose : undefined}
        className={clsx(classes.darkLayer, {
          [classes.visible]: isVisible,
        })}
        style={{ zIndex: 100 + index + 1 }}
      />
      <div
        ref={setRootRef}
        className={clsx(
          classes.root,
          className,
          maxHeight && classes.maxHeight,
          isVisible && classes.visible,
          classes[variant],
        )}
        style={{
          zIndex: 100 + index + 2,
          transition: draggable.state
            ? undefined
            : isVisible
            ? 'all 0.35s ease-out'
            : 'all 0.2s ease-in',
          transform: isVisible ? visibleTransform : closedTransform,
        }}
        {...props}
      >
        <div
          onTouchStartCapture={canClose ? handleStart : undefined}
          onTouchEndCapture={canClose ? handleRelease : undefined}
          onTouchMoveCapture={canClose ? handleTouchMoveSlider : undefined}
          className={classes.swipeModalSlider}
        />
        <div className={clsx(classes.content, contentClassName)}>{memoizedChildren}</div>
      </div>
    </>,
    document.getElementById('root')!,
  );
};
