import clsx from 'clsx';

import { ChangeEventHandler, FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCryptoWallets,
  selectDigitalAccountWallets,
  selectFiatWallets,
} from 'modules/accounts/store/selectors';
import { requestCreateDigitalWallet } from 'modules/accounts/store/thunks';
import { Wallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import useRentingPromoWallets from 'modules/cryptoRenting/hooks/useRentingPromoWallets';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import { AppStore, useDispatch } from 'store';

import { CurrencyIcon, Icon, Loader, Mark, NoResultLabel, TextInput } from 'components/ui';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './PickCurrency.module.scss';

export interface PickCurrencyProps {
  isDeposit?: boolean;
  isDigitalAccount?: boolean;
}

const PickCurrency: FC<PickCurrencyProps> = ({ isDeposit = true, isDigitalAccount = false }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const isCrypto = useFlag(false);
  const showSearchInput = useFlag(false);

  const [filter, setFilter] = useState('');

  const selectWallets = useCallback(
    (state: AppStore) => {
      if (isDigitalAccount) {
        return selectDigitalAccountWallets(state);
      }
      return isCrypto.state ? selectCryptoWallets(state) : selectFiatWallets(state);
    },
    [isCrypto.state, isDigitalAccount],
  );
  const wallets: Wallet[] = useSelector(selectWallets);

  const buttons = useMemo(
    () => [
      {
        id: '1',
        children: translate('PAYMENT_CASH'),
        onClick: isCrypto.off,
        active: !isCrypto.state,
      },
      {
        id: '2',
        children: translate('PAYMENT_CRYPTO'),
        onClick: isCrypto.on,
        disabled: isDigitalAccount,
        active: isCrypto.state,
      },
    ],
    [isDigitalAccount, translate, isCrypto],
  );

  const { potentialWalletsForRentingMap } = useRentingPromoWallets(wallets);

  const filteredWallets = useMemo(() => {
    const result = wallets.filter((w) => w.currencyCode !== 'NBT');
    if (!filter) {
      return result;
    }
    const filterUppercase = filter.toUpperCase();

    return result.filter(
      (w) =>
        w.currencyCode.includes(filterUppercase) ||
        w.currencyLabel.toUpperCase().includes(filterUppercase),
    );
  }, [wallets, filter]);

  const [walletCreatingCurrencyCode, setWalletCreatingCurrencyCode] = useState<CurrencyCode | null>(
    null,
  );

  const handlePickCurrency = useCallback(
    async (e: SyntheticEvent<HTMLDivElement>) => {
      const code = e.currentTarget.dataset.code as CurrencyCode;
      if (code) {
        // Create digital wallet before operations
        if (
          code === 'GBP' &&
          isDigitalAccount &&
          !wallets.find((w) => w.currencyCode === code)?.exist
        ) {
          setWalletCreatingCurrencyCode(code);
          const { success } = await dispatch(requestCreateDigitalWallet(code));
          setWalletCreatingCurrencyCode(null);
          if (!success) {
            return;
          }
        }
        drawer.open(
          paymentDrawerTemplates.paymentProcess({
            isDeposit,
            currencyCode: code,
            isDigitalAccount,
          }),
        );
      }
    },
    [dispatch, wallets, drawer, isDigitalAccount, isDeposit],
  );

  const handleFilterInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setFilter(e.target.value);
  }, []);

  return (
    <div className="mt-1 flex-1 column gap-3 ">
      <div className="row gap-3 jcsb">
        <div className="row gap-0-5">
          {buttons.map((b) => (
            <div
              key={b.id}
              onClick={b.disabled ? undefined : b.onClick}
              className={clsx(
                classes.pickerButton,
                b.active && classes.active,
                b.disabled && classes.disabled,
              )}
            >
              <span>{b.children}</span>
            </div>
          ))}
        </div>
        <Icon
          onClick={showSearchInput.toggle}
          className={classes.showSearchInputButton}
          name={showSearchInput.state ? 'cross' : 'search'}
          size={16}
        />
      </div>
      <div className="column gap-3 flex-1">
        {showSearchInput.state && (
          <TextInput
            value={filter}
            onChange={handleFilterInputChange}
            startAdornment={
              <Icon className={classes.textInputStartAdornment} name="search" size={16} />
            }
            placeholder={translate('SEARCH')}
          />
        )}
        <div className={classes.currenciesList}>
          {filteredWallets.map((w) => {
            const potentialWalletForRenting = potentialWalletsForRentingMap[w.currencyCode];

            return (
              <div
                onClick={handlePickCurrency}
                data-code={w.currencyCode}
                key={w.currencyCode}
                className={classes.item}
              >
                <div className="row aic gap-2">
                  <CurrencyIcon size={40} code={w.currencyCode} />
                  <div className="column gap-0-5">
                    <div className="row gap-2 aic">
                      <span className={classes.label}>{w.currencyLabel}</span>
                      {isDeposit && potentialWalletForRenting ? (
                        <Mark
                          onClick={(e) => {
                            e.stopPropagation();
                            drawer.open(
                              rentingDrawerTemplates.rentingSetup({
                                templateName: potentialWalletForRenting.templateName,
                                rentCurrency: potentialWalletForRenting.currencyCode,
                              }),
                            );
                          }}
                          rounded
                          className={clsx(
                            classes.rentingPromoMark,
                            classes[`variant-${potentialWalletForRenting.templateName}`],
                          )}
                        >
                          {translate('RENTING_EARN_PERCENT', {
                            percent: potentialWalletForRenting.percent,
                          })}
                        </Mark>
                      ) : null}
                    </div>
                    <span className={classes.sublabel}>{w.currencyCode}</span>
                  </div>
                </div>
                <div className="column gap-0-5">
                  <span className={clsx(classes.label, 'tar')}>
                    {formatCurrencyWithSymbol(w.amount, w.currencyCode)}
                  </span>
                  <span className={clsx(classes.sublabel, 'tar')}>
                    {formatCurrencyWithSymbol(w.amountInDefaultCurrency, w.defaultCurrencyCode)}
                  </span>
                </div>
                <Loader overlap active={walletCreatingCurrencyCode === w.currencyCode} />
              </div>
            );
          })}
          {!filteredWallets.length && <NoResultLabel findString={filter} />}
        </div>
      </div>
    </div>
  );
};

export default PickCurrency;
