import { noop } from 'lodash';

import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import authServices from 'modules/auth/services';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import { isReactNative } from 'modules/reactNative/utils';

import eventEmitter from 'utils/eventEmitter';

const TEN_HOURS_IN_SECONDS = 10 * 60 * 60;

const useSessionExpiredWarning = () => {
  const drawer = useDrawer();

  const timeoutId = useRef<number | null>(null);
  const isUserAuthed = useSelector(selectIsUserAuthed);

  const openWarningDrawer = useCallback(() => {
    drawer.open(commonDrawerTemplates.sessionExpiredWarning());
  }, [drawer]);

  const setWarningTimeout = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    const refreshTokenExpTimestamp = authServices.getRefreshTokenPayload()?.exp;
    const currentTimestamp = Math.round(Date.now() / 1000);

    if (!refreshTokenExpTimestamp || refreshTokenExpTimestamp < currentTimestamp) {
      return;
    }

    const restLifeTimeInSeconds = refreshTokenExpTimestamp - currentTimestamp;
    const showWarningTimeout = Math.min(restLifeTimeInSeconds - 80, TEN_HOURS_IN_SECONDS);

    timeoutId.current = window.setTimeout(openWarningDrawer, showWarningTimeout * 1000);
  }, [openWarningDrawer]);

  const clearWarningTimeout = useCallback(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
    }
  }, []);

  useEffect(() => {
    if (isUserAuthed) {
      setWarningTimeout();
    } else {
      clearWarningTimeout();
    }

    return () => {
      clearWarningTimeout();
    };
  }, [isUserAuthed, setWarningTimeout, clearWarningTimeout]);

  useEffect(() => {
    const unsub = eventEmitter.subscribe('REFRESH_TOKEN_REFRESHED', setWarningTimeout);

    return () => {
      unsub();
    };
  }, [setWarningTimeout]);
};

export default isReactNative ? noop : useSessionExpiredWarning;
