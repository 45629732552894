export const BANK_CARD_OPERATION_BLOCKED_COUNTRIES = [
  'UA', // Ukraine
  'ID', // Indonesia
  'VN', // Vietnam
  'IT', // Italy
  'PL', // Poland
  'RO', // Romania
  'BO', // Bolivia
  'AR', // Argentina
  'CH', // Switzerland
  'HK', // Hong Kong
  'CA', // Canada
  'SG', // Singapore
  'EG', // Egypt,
  'NG', // Nigeria
  'AL', // Albania
  'MD', // Moldova
  'MD', // Moldova
  'HN', // Honduras
  'PR', // Puerto Rico
];
