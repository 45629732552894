import ibanLib from 'iban';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectDigitalAccountExistWallets } from 'modules/accounts/store/selectors';
import { DigitalWallet } from 'modules/accounts/types';
import { PaymentOperationId } from 'modules/payment/types';
import { selectUserProfile } from 'modules/user/store/selectors';

import { DetailsCardProps } from 'components/ui/DetailsCard';

import { useTranslation } from 'libs/i18n';

import { CurrencyCode } from 'types';

interface DepositFromSepaToTrading {
  operationId: PaymentOperationId.depositFromSepaToTradingWallet;
  currencyCode: 'EUR';
  beneficiary: string;
  iban: string;
  bic: string;
  bankName: string;
}
interface DepositFromWireToTradingGBP {
  operationId: PaymentOperationId.depositFromWireToTradingWallet;
  currencyCode: 'GBP';
  beneficiary: string;
  sortCode: string;
  accountNumber: string;
  bankName: string;
}
// Corporate wallet for USD in not available
// interface DepositFromWireToTradingUSD {
//   operationId: PaymentOperationId.depositFromWireToTradingWallet;
//   currencyCode: 'USD';
//   beneficiary: string;
//   beneficiaryAccount: string;
//   beneficiaryBank: string;
//   beneficiaryBankSwift: string;
//   intermediaryBank: string;
//   intermediaryBankSwift: string;
// }

interface DepositFromSepaToMoney {
  operationId: PaymentOperationId.depositFromSepaToMoneyWallet;
  currencyCode: 'EUR';
  beneficiary: string;
  iban: string;
  bic: string;
  bankName: string;
}
interface DepositFromWireToMoneyGBP {
  operationId: PaymentOperationId.depositFromWireToMoneyWallet;
  currencyCode: 'GBP';
  beneficiary: string;
  accountNumber: string;
  sortCode: string;
  bankName: string;
}
export type PaymentDetailsByOperationId =
  | DepositFromSepaToTrading
  // | DepositFromWireToTradingUSD
  | DepositFromWireToTradingGBP
  | DepositFromSepaToMoney
  | DepositFromWireToMoneyGBP;

const usePaymentsDetails = ({
  operationId,
  currencyCode,
  variant,
}: {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  variant?: 'short' | 'full';
}) => {
  const translate = useTranslation();

  const digitalWallets = useSelector(selectDigitalAccountExistWallets);
  const user = useSelector(selectUserProfile);

  const details = useMemo<PaymentDetailsByOperationId | undefined>(() => {
    switch (operationId) {
      case PaymentOperationId.depositFromSepaToTradingWallet: {
        return {
          operationId: PaymentOperationId.depositFromSepaToTradingWallet,
          currencyCode: 'EUR',
          beneficiary: 'Rintral Trading S.L.',
          iban: 'LU264080000028326958',
          bic: 'BCIRLULLXXX',
          bankName: 'Banking Circle S.A., Luxembourg, Luxembourg',
        };
      }

      case PaymentOperationId.depositFromWireToTradingWallet: {
        // Corporate wallet for USD in not available
        // if (currencyCode === 'USD') {
        //   return {
        //     operationId: PaymentOperationId.depositFromWireToTradingWallet,
        //     currencyCode: 'USD',
        //     beneficiary: 'Dzing Finance Ltd',
        //     beneficiaryAccount: 'GB37IFXS23229034101383',
        //     beneficiaryBank: 'IFX Payments',
        //     beneficiaryBankSwift: 'IFXSGB2L',
        //     intermediaryBank: 'Barclays Bank',
        //     intermediaryBankSwift: 'BARCGB22',
        //   };
        // }

        return {
          operationId: PaymentOperationId.depositFromWireToTradingWallet,
          currencyCode: 'GBP',
          beneficiary: 'Rintral Trading S.L.',
          sortCode: '608382',
          accountNumber: '28317688',
          bankName: 'Banking Circle S.A., Luxembourg, Luxembourg',
        };
      }
      case PaymentOperationId.depositFromSepaToMoneyWallet ||
        PaymentOperationId.depositFromWireToMoneyWallet: {
        const wallet = digitalWallets.find((w) => w.currencyCode === currencyCode) as DigitalWallet;

        if (!wallet) {
          return;
        }
        if (currencyCode === 'EUR' && 'iban' in wallet) {
          return {
            operationId: PaymentOperationId.depositFromSepaToMoneyWallet,
            currencyCode: 'EUR',
            beneficiary: wallet.ownerName,
            iban: wallet.iban,
            bic: wallet.bic,
            bankName: wallet.bankName,
          };
        }

        if (currencyCode === 'GBP' && 'accountNumber' in wallet) {
          return {
            operationId: PaymentOperationId.depositFromWireToMoneyWallet,
            currencyCode: 'GBP',
            beneficiary: wallet.ownerName,
            accountNumber: wallet.accountNumber,
            sortCode: wallet.sortCode,
            bankName: wallet.bankName,
          };
        }
      }
    }
  }, [currencyCode, digitalWallets, operationId]);

  const detailsConfig = useMemo<DetailsCardProps['details'] | undefined>(() => {
    if (!details) {
      return;
    }
    switch (details.operationId) {
      case PaymentOperationId.depositFromSepaToTradingWallet: {
        return [
          { label: translate('PAYMENT_RECEIVER_OR_BENEFICIARY_NAME'), value: details.beneficiary },
          { label: translate('BANK_NAME'), value: details.bankName },
          {
            label: translate('PAYMENT_IBAN'),
            copyValue: details.iban,
            value: ibanLib.printFormat(details.iban),
          },
          { label: translate('PAYMENT_BIC'), value: details.bic },
        ];
      }

      case PaymentOperationId.depositFromWireToTradingWallet: {
        // Corporate wallet for USD in not available
        // if (currencyCode === 'USD' && 'beneficiaryAccount' in details) {
        //   return [
        //     {
        //       label: translate('PAYMENT_BENEFICIARY'),
        //       value: details.beneficiary,
        //     },
        //     {
        //       label: translate('PAYMENT_BENEFICIARY_ACCOUNT'),
        //       copyValue: details.beneficiaryAccount,
        //       value: ibanLib.printFormat(details.beneficiaryAccount),
        //     },
        //     {
        //       label: translate('PAYMENT_BENEFICIARY_BANK'),
        //       value: details.beneficiaryBank,
        //     },
        //     {
        //       label: translate('PAYMENT_BENEFICIARY_BANK_SWIFT'),
        //       value: details.beneficiaryBankSwift,
        //     },
        //     {
        //       label: translate('PAYMENT_INTERMEDIARY_BANK'),
        //       value: details.intermediaryBank,
        //     },
        //     {
        //       label: translate('PAYMENT_INTERMEDIARY_BANK_SWIFT'),
        //       value: details.intermediaryBankSwift,
        //     },
        //   ];
        // }

        if (currencyCode === 'GBP' && 'sortCode' in details) {
          return [
            {
              label: translate('PAYMENT_RECEIVER_OR_BENEFICIARY_NAME'),
              value: details.beneficiary,
            },
            { label: translate('BANK_NAME'), value: details.bankName },
            {
              label: translate('PAYMENT_SORT_CODE'),
              value: details.sortCode,
            },
            { label: translate('PAYMENT_ACCOUNT_NUMBER'), value: details.accountNumber },
          ];
        }
        return;
      }
      case PaymentOperationId.depositFromSepaToMoneyWallet ||
        PaymentOperationId.depositFromWireToMoneyWallet: {
        if (currencyCode === 'EUR' && 'iban' in details) {
          const result = [
            {
              label: translate('PAYMENT_RECEIVER_OR_BENEFICIARY_NAME'),
              value: details.beneficiary,
            },
            {
              label: translate('PAYMENT_IBAN'),
              copyValue: details.iban,
              value: ibanLib.printFormat(details.iban),
            },
          ];

          if (variant === 'full') {
            result.push(
              { label: translate('PAYMENT_BIC'), value: details.bic },
              { label: translate('BANK_NAME'), value: details.bankName },
            );
          }

          return result;
        }
        if (currencyCode === 'GBP' && 'accountNumber' in details) {
          const result = [
            {
              label: translate('PAYMENT_RECEIVER_OR_BENEFICIARY_NAME'),
              value: details.beneficiary,
            },
            {
              label: translate('PAYMENT_ACCOUNT_NUMBER'),
              value: details.accountNumber,
            },
            { label: translate('PAYMENT_SORT_CODE'), value: details.sortCode },
          ];

          if (variant === 'full') {
            result.push({ label: translate('BANK_NAME'), value: details.bankName });
          }
          return result;
        }
      }
    }
  }, [currencyCode, variant, details, translate]);

  const reference = useMemo(() => {
    if (
      (operationId === PaymentOperationId.depositFromWireToTradingWallet &&
        currencyCode === 'GBP') ||
      operationId === PaymentOperationId.depositFromSepaToTradingWallet
    ) {
      return user?.publicId;
    }
    if (
      operationId === PaymentOperationId.depositFromWireToTradingWallet &&
      currencyCode === 'USD'
    ) {
      return `Rintral Trading SL, GB86DZNN04003107816480, Purpose of payment REF ${user?.publicId}`;
    }
  }, [user, operationId, currencyCode]);

  return { detailsConfig, details, reference };
};

export default usePaymentsDetails;
