import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import useRateCalculator from 'modules/exchange/hooks/useRateCalculator';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import { requestSendToNebeusUser } from 'modules/payment/store/thunks';
import { PaymentOperationId, transactionTypesForOtpSending } from 'modules/payment/types';
import { WalletBalanceCard } from 'modules/payment/views/components';
import { AmountForm, PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { selectSelectedRecipientForSendingFunds } from 'modules/phonebook/store/selectors';
import RecipientSelector from 'modules/phonebook/views/RecipientSelector';
import { selectUserDefaultCurrencyCode, selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { TextInputField } from 'components/form';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup, { makeValidate } from 'libs/yup';

import { formatCurrencyWithLabel } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import { CurrencyCode } from 'types';

const OPERATION_ID = PaymentOperationId.sendToNebeusUser;

interface SendNebeusUserProps {
  currencyCode: CurrencyCode;
}

interface FormValues extends PaymentAmountFormValues {
  email: string;
}

interface SendNebeusUserFormProps extends FormRenderProps<FormValues> {}

const currencyAmountFieldProps = {
  placeholder: getTranslation('PAYMENT_RECIPIENT_WILL_GET'),
  label: getTranslation('PAYMENT_RECIPIENT_WILL_GET'),
};

const SendNebeusUserForm: FC<SendNebeusUserFormProps> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <RecipientSelector isNebeusUserForm>
        <Field
          name="email"
          render={TextInputField}
          autoComplete="email"
          inputMode="email"
          placeholder={getTranslation('PAYMENT_NU_EMAIL_LABEL')}
        />
      </RecipientSelector>
      <AmountForm operationId={OPERATION_ID} currencyAmountFieldProps={currencyAmountFieldProps} />
    </form>
  );
};

const SendNebeusUser: FC<SendNebeusUserProps> = ({ currencyCode }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  usePaymentInit();

  const userProfile = useSelector(selectUserProfile);

  const initialValues = useMemo<FormValues>(
    () => ({
      email: '',
      currencyAmount: { currency: currencyCode, amount: '' },
      currencyAmountWithCommission: { currency: currencyCode, amount: '' },
      commissionAmount: null,
    }),
    [currencyCode],
  );

  const userDefaultCurrencyCode = useSelector(selectUserDefaultCurrencyCode);
  const convertToDefaultCurrency = useRateCalculator(currencyCode, userDefaultCurrencyCode);

  const convertToDefaultCurrencyWithSymbol = useCallback(
    (amount: string | number) =>
      formatCurrencyWithLabel(convertToDefaultCurrency(+amount), userDefaultCurrencyCode),
    [convertToDefaultCurrency, userDefaultCurrencyCode],
  );

  const phonebookRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const summaryBlocks: SummaryCardItem[][] = [
        [
          {
            label: translate('SENDER'),
            value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
          },
          { label: translate('PAYMENT_NU_EMAIL_LABEL'), value: values.email },
        ],
        [
          {
            label: translate('PAYMENT_RECIPIENTS_GETS'),
            value: formatCurrencyWithLabel(
              values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
              currencyCode,
            ),
            subValue: convertToDefaultCurrencyWithSymbol(
              +(values.currencyAmountWithCommission?.amount || 0) || values.currencyAmount.amount,
            ),
          },
          {
            label: translate('PAYMENT_NEBEUS_FEE'),
            value: formatCurrencyWithLabel(values.commissionAmount || 0, currencyCode),
            subValue: convertToDefaultCurrencyWithSymbol(values.commissionAmount || 0),
          },
          {
            label: translate('PAYMENT_TOTAL_WITHDRAWN'),
            value: formatCurrencyWithLabel(
              values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
            subValue: convertToDefaultCurrencyWithSymbol(+values.currencyAmount.amount),
          },
        ],
      ];
      drawer.open(
        paymentDrawerTemplates.confirmOTPCode({
          transactionType: transactionTypesForOtpSending[PaymentOperationId.sendToNebeusUser],
          summary: {
            currencyAmount: values.currencyAmount,
            currencyAmountWithCommission: values.currencyAmountWithCommission,
          },
          summaryBlocks,
          onConfirmed: async (code: string) => {
            const { success, error } = await dispatch(
              requestSendToNebeusUser({
                amount: +values.currencyAmount.amount,
                currency: values.currencyAmount.currency,
                destinationEmail: values.email,
                otpCode: code,
                // @ts-ignore For analytic purpose
                analyticField_phonebookRecipient: phonebookRecipient,
              }),
            );

            const text = translate('PAYMENT_SEND_TO_NEBEUS_USER_SUCCESS', {
              currency: values.currencyAmount.currency,
              amount: values.currencyAmount.amount,
              email: values.email,
            });

            const drawerTemplate = paymentDrawerTemplates.finishedOperation({
              currencyCode: values.currencyAmount.currency,
              amount: +values.currencyAmount.amount * -1,
              isDeposit: false,
              isSuccess: success,
              description: success
                ? text
                : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
              summaryBlocks: [
                [
                  {
                    label: translate('DESCRIPTION'),
                    value: translate('PAYMENT_SEND_TO_NEBEUS_USER'),
                  },
                  { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
                  {
                    label: translate('ACCOUNT_OPERATION_STATUS'),
                    value: translate(
                      success
                        ? 'PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY'
                        : 'PAYMENT_OPERATION_FAILED',
                    ),
                  },
                ],
                ...summaryBlocks,
              ],
            });

            if (success) {
              drawer.replaceAll(drawerTemplate);
            } else {
              drawer.replace(drawerTemplate);
            }
          },
        }),
      );
    },
    [
      phonebookRecipient,
      currencyCode,
      userProfile,
      convertToDefaultCurrencyWithSymbol,
      dispatch,
      drawer,
      translate,
    ],
  );

  const contactSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(getTranslation('VALIDATION_EMAIL'))
          .withoutSpaces()
          .requiredDefault(),
      }),
    [],
  );

  const { amountFormSchema } = useAmountFormValidation({
    operationId: PaymentOperationId.sendToNebeusUser,
    isDigitalAccount: false,
    currencyCode,
  });

  const validate = useMemo(() => {
    return makeValidate(amountFormSchema.concat(contactSchema));
  }, [contactSchema, amountFormSchema]);

  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode={currencyCode} />
      <WithdrawalLimitsCard currencyCode={currencyCode} operationId={OPERATION_ID} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={SendNebeusUserForm}
        validate={validate}
      />
    </div>
  );
};

export default SendNebeusUser;
