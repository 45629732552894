import { addMonths } from 'date-fns';

import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import { TradingWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import { getAgreement as getCommonAgreement } from 'modules/loans/constants/contracts/commonAgreement';
import { getAgreement as getMirrorLoanAgreement } from 'modules/loans/constants/contracts/mirrorLoanAgreement';
import { getPreContract } from 'modules/loans/constants/contracts/preContract';
import loanDrawerTemplates from 'modules/loans/constants/drawerTemplates';
import { selectTemplates } from 'modules/loans/store/selectors';
import { requestCreateLoan } from 'modules/loans/store/thunks';
import { GetLoanFormValues } from 'modules/loans/views/LoanCalculator';
import useLoanCheckList from 'modules/loans/views/LoanCalculator/components/CheckList/useLoanCheckList';
import useUserCountry from 'modules/user/hooks/useUserCountry';
import { selectExtendedUserProfileReducer, selectUserProfile } from 'modules/user/store/selectors';
import { requestUserExtendedProfile } from 'modules/user/store/thunks';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { Button, ButtonGroup, NavigationHeader } from 'components/ui';

import useFlag from 'hooks/useFlag';
import useStoreEntity from 'hooks/useStoreEntity';

import { useTranslation } from 'libs/i18n';

import { findByProperty } from 'utils/arrayUtils';
import { findWallet, formatCurrency, formatCurrencyWithLabel } from 'utils/currency';
import { formatDDMMYY } from 'utils/date';

import classes from './LoanConfirmation.module.scss';

export interface LoanAgreementProps {
  values: GetLoanFormValues;
}
const LoanAgreement: FC<LoanAgreementProps> = ({ values }) => {
  const translate = useTranslation();
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUserProfile);
  const tradingWallets = useSelector(selectWallets);

  const userCountry = useUserCountry();

  const {
    entityReducer: { data: extendedUserProfile },
  } = useStoreEntity(selectExtendedUserProfileReducer, requestUserExtendedProfile, {
    staleAllowed: true,
  });

  const templates = useSelector(selectTemplates);
  const template = useMemo(
    () => findByProperty(templates, 'id', values.templateId),
    [values.templateId, templates],
  );

  const agreementTab = useFlag(false);
  const loading = useFlag(false);

  const handleSubmit = useCallback(async () => {
    loading.on();
    const loanWallet = findWallet<TradingWallet>(tradingWallets, values.loan.currency);
    if (loanWallet && !loanWallet.exist) {
      const { success: loanWalletCreated } = await dispatch(
        requestCreateWallet({ issuerId: loanWallet.issuerId }),
      );
      if (!loanWalletCreated) {
        loading.off();
        return;
      }
    }

    const { success, data: loan } = await dispatch(
      requestCreateLoan({
        autoMarginCallEnabled: values.autoManagementMC,
        collateralAmount: +values.collateral.amount,
        collateralCurrencyCode: values.collateral.currency,
        excessCollateralReleaseEnabled: values.excessCollateralRelease,
        loanAmount: +values.loan.amount,
        loanCurrencyCode: values.loan.currency,
        loanTermInMonths: values.termMonth,
        ltvValuePercentage: values.ltv,
        templateId: values.templateId,
      }),
    );
    loading.off();

    if (success) {
      navigate(routesByName.loans('active'));
      drawer.replace(
        loanDrawerTemplates.loanGetSuccess({
          loan,
        }),
      );
    }
  }, [tradingWallets, navigate, drawer, loading, dispatch, values]);

  const { totalLoanCost, monthlyRepayment, totalLoanRepayment, interestPercent } = useLoanCheckList(
    values,
    template!,
  );

  const agreementHtml = useMemo(() => {
    if (!user || !extendedUserProfile || !template) {
      return;
    }
    const commonPayload = {
      userName: `${user.firstName} ${user.lastName}`,
      userEmail: user.email,
      userAddress: [
        userCountry?.name,
        extendedUserProfile.city,
        extendedUserProfile.postCode,
        extendedUserProfile.address,
      ]
        .filter((i) => !!i)
        .join(', '),
      loanAmount: formatCurrency(values.loan.amount, values.loan.currency),
      loanCurrency: values.loan.currency,
      interestRate: interestPercent.toString(),
    };

    if (template.type === 'MIRROR') {
      return getMirrorLoanAgreement({
        ...commonPayload,
        collateralCurrency: values.collateral.currency,
        collateralAmount: formatCurrency(values.collateral.amount, values.collateral.currency),
        originationFee: (template.originationFeePercent || 0).toString(),
        termMonth: values.termMonth.toString(),
        endDate: formatDDMMYY(addMonths(new Date(), values.termMonth)),
        monthlyPayment: formatCurrency(monthlyRepayment, values.loan.currency),
        totalRepaymentAmount: formatCurrency(totalLoanRepayment, values.loan.currency),
      });
    } else {
      return getCommonAgreement(commonPayload);
    }
  }, [
    interestPercent,
    monthlyRepayment,
    totalLoanRepayment,
    template,
    user,
    userCountry,
    extendedUserProfile,
    values,
  ]);

  const preContractHtml = useMemo(
    () =>
      getPreContract({
        ltvPercent: (values.ltv / 100).toFixed(2),
        interestAmountCurrency: formatCurrencyWithLabel(totalLoanCost, values.loan.currency),
        loanAmountCurrency: formatCurrencyWithLabel(values.loan.amount, values.loan.currency),
        term: values.termMonth.toString(),
        monthlyPayment: formatCurrencyWithLabel(monthlyRepayment, values.loan.currency),
        totalAmountCurrency: formatCurrencyWithLabel(totalLoanRepayment, values.loan.currency),
        annualPercent: interestPercent.toString(),
      }),
    [interestPercent, monthlyRepayment, values, totalLoanRepayment, totalLoanCost],
  );

  const buttons = useMemo(
    () => [
      {
        id: '1',
        onClick: agreementTab.off,
        children: translate('LOAN_AGREEMENT_PRE_CONTRACT_INFO'),
      },
      {
        id: '2',
        onClick: agreementTab.on,
        children: translate('LOAN_AGREEMENT'),
      },
    ],
    [translate, agreementTab],
  );

  return (
    <div className={classes.root}>
      <div className={classes.titles}>
        <NavigationHeader>{translate('CONFIRMATION')}</NavigationHeader>
        <p className={classes.desc}>{translate('LOAN_AGREEMENT_DESC')}</p>
      </div>
      <ButtonGroup
        className={classes.buttons}
        variant="creamyGreen"
        buttons={buttons}
        activeButtonIndex={agreementTab.state ? 1 : 0}
      />

      <iframe
        className={classes.frame}
        srcDoc={agreementTab.state ? agreementHtml : preContractHtml}
      />
      <div className={classes.confirmation}>
        <Button className={classes.button} loading={loading.state} onClick={handleSubmit} fullWidth>
          {translate('ACCEPT')}
        </Button>
        <span className={classes.agreementText}>{translate('LOAN_AGREEMENT_IMPORTANT_TEXT')}</span>
      </div>
    </div>
  );
};
const LoanConfirmation = () => {
  const location = useLocation();

  const values = location.state as GetLoanFormValues;

  return values ? (
    <LoanAgreement values={values} />
  ) : (
    <Navigate to={routesByName.loans('new')} replace />
  );
};

export default LoanConfirmation;
