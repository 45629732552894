import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { AllowedCountry } from 'modules/app/types';
import IbanAccountSubscriptionMark from 'modules/digitalAccount/views/IbanAccountSubscriptionMark';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import {
  requestSendFromDigitalAccountToSEPAOrWire,
  requestSendFromTradingAccountToSEPAOrWire,
} from 'modules/payment/store/thunks';
import { PaymentOperationId, transactionTypesForOtpSending } from 'modules/payment/types';
import { AmountForm, SEPADetailsForm, WalletBalanceCard } from 'modules/payment/views/components';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import { RecipientAddress } from 'modules/payment/views/components/RecipientAddress';
import {
  SEPADetailsFormValues,
  SEPADetailsInitialValues,
  sepaDetailsFormSchema,
} from 'modules/payment/views/components/SEPADetailsForm';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { selectSelectedRecipientForSendingFunds } from 'modules/phonebook/store/selectors';
import RecipientSelector from 'modules/phonebook/views/RecipientSelector';
import { selectUserProfile } from 'modules/user/store/selectors';
import { useDispatch } from 'store';

import { AutoCompleteField } from 'components/form';
import { addressFormInitialValues, addressFormValidationSchema } from 'components/form/AddressForm';
import { DottedLine } from 'components/ui';
import { Address } from 'components/ui/AddressAutoComplete';
import { SummaryCardItem } from 'components/ui/SummaryCard';

import { getTranslation, useTranslation } from 'libs/i18n';
import { MoneyOutcomeTransactionRequest } from 'libs/swagger/nebeusApiTypes';
import yup, { makeValidate } from 'libs/yup';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

export interface SendSEPAProps {
  isDigitalAccount: boolean;
}

type RecipientType = MoneyOutcomeTransactionRequest['recipientType'];
interface RecipientTypeOption {
  value: RecipientType;
  label: string;
}

interface FormValues extends PaymentAmountFormValues, SEPADetailsFormValues {
  recipientType: RecipientTypeOption | null;
  recipientAddress: Omit<Address, 'country'> & { country: AllowedCountry | null };
}

interface SendSEPAFormProps extends FormRenderProps<FormValues> {
  isDigitalAccount: boolean;
  recipientTypeOptions: RecipientTypeOption[];
}

const initialValues: FormValues = {
  currencyAmount: { amount: '', currency: 'EUR' },
  commissionAmount: null,
  recipientType: null,
  recipientAddress: addressFormInitialValues,
  ...SEPADetailsInitialValues,
};

const recipientTypeKeyAccessor = (option: RecipientTypeOption) => option.value;
const recipientTypeLabelAccessor = (option: RecipientTypeOption) => option.label;

const SendSEPAForm: FC<SendSEPAFormProps> = ({
  handleSubmit,
  isDigitalAccount,
  recipientTypeOptions,
}) => {
  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <RecipientSelector paymentType="SEPA">
        <SEPADetailsForm />
      </RecipientSelector>
      {isDigitalAccount && (
        <>
          <DottedLine />
          <Field
            name="recipientType"
            component={AutoCompleteField}
            options={recipientTypeOptions}
            keyAccessor={recipientTypeKeyAccessor}
            labelAccessor={recipientTypeLabelAccessor}
            autocomplete={false}
            placeholder={getTranslation('PAYMENT_RECIPIENT_TYPE')}
          />
          <RecipientAddress />
          <DottedLine />
        </>
      )}

      <AmountForm
        operationId={
          isDigitalAccount
            ? PaymentOperationId.sendFromMoneyToSepa
            : PaymentOperationId.sendFromTradingToSepa
        }
        beforeButtonComponent={
          <p className="font-s grey-300">{getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_1')}</p>
        }
      />
    </form>
  );
};

const SendSEPA: FC<SendSEPAProps> = ({ isDigitalAccount }) => {
  const dispatch = useDispatch();
  const translate = useTranslation();
  const drawer = useDrawer();

  usePaymentInit();

  const userProfile = useSelector(selectUserProfile);

  const getSummaryBlocks = useCallback(
    (values: FormValues): SummaryCardItem[][] => {
      const result = [
        [
          {
            label: translate('PAYMENT_RECIPIENTS_GETS'),
            value: formatCurrencyWithSymbol(
              values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
          },
          {
            label: translate('PAYMENT_NEBEUS_FEE'),
            value: formatCurrencyWithSymbol(
              values.commissionAmount || 0,
              values.currencyAmount.currency,
            ),
          },
          {
            label: translate('PAYMENT_TOTAL_WITHDRAWN'),
            value: formatCurrencyWithSymbol(
              values.currencyAmount.amount,
              values.currencyAmount.currency,
            ),
          },
        ],
      ];

      const firstBlock = [
        {
          label: translate('SENDER'),
          value: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
        },
        { label: translate('RECIPIENT'), value: values.accountName },
        { label: translate('RECIPIENTS_IBAN'), value: values.iban },
        {
          label: translate('PAYMENT_REFERENCE'),
          value: values.reference || '-',
        },
      ];

      if (isDigitalAccount) {
        firstBlock.push(
          {
            label: translate('PAYMENT_RECIPIENT_TYPE'),
            value: values.recipientType?.label || '-',
          },
          {
            label: translate('RECIPIENT_ADDRESS'),
            value: [
              values.recipientAddress.address1,
              values.recipientAddress.address2,
              values.recipientAddress.city,
              values.recipientAddress.country!.name,
              values.recipientAddress.postCode,
            ]
              .filter((i) => !!i)
              .join(', '),
          },
        );
      }

      result.unshift(firstBlock);
      return result;
    },
    [isDigitalAccount, translate, userProfile],
  );

  const phonebookRecipient = useSelector(selectSelectedRecipientForSendingFunds);

  const sendFunds = useCallback(
    async (values: FormValues, otpCode: string) => {
      const { currencyAmount, accountName, iban, reference, currencyAmountWithCommission } = values;
      let response = null;

      if (isDigitalAccount) {
        response = await dispatch(
          requestSendFromDigitalAccountToSEPAOrWire({
            amount: +currencyAmount.amount,
            currency: 'EUR',
            eurDestinationAccountName: accountName,
            eurDestinationIban: iban,
            eurDestinationReferenceMessage: reference,
            otpCode,
            recipientType: values.recipientType!.value,
            address: {
              line1: values.recipientAddress!.address1,
              line2: values.recipientAddress!.address2,
              postalCode: values.recipientAddress!.postCode,
              country: values.recipientAddress!.country!.code,
              city: values.recipientAddress!.city,
              state: '',
            },
            // @ts-ignore For analytic purpose
            analyticField_phonebookRecipient: phonebookRecipient,
          }),
        );
      } else {
        response = await dispatch(
          requestSendFromTradingAccountToSEPAOrWire({
            amount: +currencyAmount.amount,
            currency: 'EUR',
            eurAccountName: accountName,
            eurIban: iban,
            eurReferenceMessage: reference,
            otpCode,
            // @ts-ignore For analytic purpose
            analyticField_phonebookRecipient: phonebookRecipient,
          }),
        );
      }
      if (!response) {
        return;
      }

      const { success, error } = response;

      const desc = translate('PAYMENT_SEND_TO_SEPA_SUCCESS_MESSAGE', {
        currencyAmountLabel: formatCurrencyWithLabel(
          currencyAmountWithCommission?.amount || currencyAmount.amount,
          currencyAmount.currency,
        ),
        accountName,
      });

      const drawerTemplate = paymentDrawerTemplates.finishedOperation({
        currencyCode: currencyAmount.currency,
        amount: +currencyAmount.amount * -1,
        isDeposit: false,
        isSuccess: success,
        description: success ? desc : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
        summaryBlocks: [
          [
            {
              label: translate('DESCRIPTION'),
              value: translate('PAYMENT_SEND_BY_SEPA'),
            },
            { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
            {
              label: translate('ACCOUNT_OPERATION_STATUS'),
              value: translate(
                success ? 'PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY' : 'PAYMENT_OPERATION_FAILED',
              ),
            },
          ],
          ...getSummaryBlocks(values),
        ],
      });

      if (success) {
        drawer.replaceAll(drawerTemplate);
      } else {
        drawer.replace(drawerTemplate);
      }
    },
    [phonebookRecipient, drawer, getSummaryBlocks, translate, dispatch, isDigitalAccount],
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const summaryBlocks = getSummaryBlocks(values);
      drawer.open(
        paymentDrawerTemplates.confirmOTPCode({
          onConfirmed: (code: string) => sendFunds(values, code),
          summaryBlocks,
          summary: {
            currencyAmount: values.currencyAmount,
            currencyAmountWithCommission: values.currencyAmountWithCommission,
          },
          importantLabel: getTranslation('PAYMENT_SEND_FIAT_IMPORTANT_NOTE'),
          otpByEmail: userProfile?.otpByEmail,
          transactionType:
            transactionTypesForOtpSending[
              isDigitalAccount
                ? PaymentOperationId.sendFromMoneyToSepa
                : PaymentOperationId.sendFromTradingToSepa
            ],
        }),
      );
    },
    [isDigitalAccount, getSummaryBlocks, drawer, userProfile, sendFunds],
  );

  const recipientTypeOptions = useMemo<RecipientTypeOption[]>(
    () => [
      { value: 'PERSON', label: translate('PAYMENT_RECIPIENT_TYPE_PERSON') },
      { value: 'BUSINESS', label: translate('PAYMENT_RECIPIENT_TYPE_BUSINESS') },
    ],
    [translate],
  );

  const { amountFormSchema } = useAmountFormValidation({
    operationId: isDigitalAccount
      ? PaymentOperationId.sendFromMoneyToSepa
      : PaymentOperationId.sendFromTradingToSepa,
    isDigitalAccount,
    currencyCode: 'EUR',
  });

  const recipientAdditionalInfoSchema = useMemo(
    () =>
      yup.object().shape({
        recipientType: yup.object().required(translate('VALIDATION_REQUIRED')),
        recipientAddress: addressFormValidationSchema,
      }),
    [translate],
  );

  const schema = useMemo(() => {
    const result = amountFormSchema.concat(sepaDetailsFormSchema);
    if (isDigitalAccount) {
      return result.concat(recipientAdditionalInfoSchema);
    }
    return result;
  }, [recipientAdditionalInfoSchema, isDigitalAccount, amountFormSchema]);

  const validate = useMemo(() => makeValidate(schema), [schema]);

  const renderForm = useCallback(
    (formProps: FormRenderProps<FormValues>) => (
      <SendSEPAForm
        {...formProps}
        isDigitalAccount={isDigitalAccount}
        recipientTypeOptions={recipientTypeOptions}
      />
    ),
    [isDigitalAccount, recipientTypeOptions],
  );
  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode="EUR" isDigitalAccount={isDigitalAccount} />

      <WithdrawalLimitsCard
        currencyCode="EUR"
        rightAdornment={isDigitalAccount ? <IbanAccountSubscriptionMark /> : undefined}
        operationId={
          isDigitalAccount
            ? PaymentOperationId.sendFromMoneyToSepa
            : PaymentOperationId.sendFromTradingToSepa
        }
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        render={renderForm}
      />
    </div>
  );
};

export default SendSEPA;
