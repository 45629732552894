import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  selectBankCards as selectCryptoBankCards,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';
import { AdvertisementCard } from 'modules/cryptoBankCard/views/BankCards/components/AdvertisementCard';
import { CryptoBankCardPart } from 'modules/cryptoBankCard/views/BankCards/components/CryptoBankCardPart';
import CurveAppAdCard from 'modules/cryptoBankCard/views/BankCards/components/CurveAppAdCard';
import { selectUserProfile } from 'modules/user/store/selectors';

import { europeanUnionCountries } from 'constants/countries';
import routesByName from 'constants/routesByName';

import useFlag from 'hooks/useFlag';

import classes from './BankCards.module.scss';
import { BalanceCard } from './components/BalanceCard';
import { BankCardsCarousel } from './components/BankCardsCarousel';
import { BlockedCard } from './components/BlockedCard';
import { OperationButtons } from './components/OperationButtons';

interface BlockedBankCardReasonProps {
  isBlocked: boolean;
}
const BlockedBankCardReason: FC<BlockedBankCardReasonProps> = ({ isBlocked }) => {
  return isBlocked ? <BlockedCard /> : null;
};

export interface BankCardsProps {
  initialBankCardId?: number;
}
const BankCards: FC<BankCardsProps> = ({ initialBankCardId }) => {
  const navigate = useNavigate();
  const drawer = useDrawer();

  const cryptoBankCards = useSelector(selectCryptoBankCards);

  const isCryptoBankCardAllowed = useSelector(selectIsCryptoBankCardAllowed);

  const bankCards = useMemo(() => {
    const result = [...cryptoBankCards];

    const userHasVirtualCryptoCard = cryptoBankCards.find((card) => card.isVirtual);

    if (!userHasVirtualCryptoCard && isCryptoBankCardAllowed) {
      result.unshift({
        id: -2,
        type: 'crypto',
        lastDigits: '',
        isFrozen: false,
        isBlocked: false,
        currencyCode: 'EUR',
        isAdvertisementCard: true,
        isVirtual: true,
        isActivated: true,
      });
    }

    return result;
  }, [isCryptoBankCardAllowed, cryptoBankCards]);

  const initialBankCardIndex = useMemo(() => {
    if (typeof initialBankCardId === 'number') {
      const index = bankCards.findIndex((bc) => bc.id === initialBankCardId);
      return index;
    }
    return 0;
  }, [initialBankCardId, bankCards]);

  const [activeBankCardIndex, setActiveBankCardIndex] = useState(initialBankCardIndex);
  const frontSide = useFlag(true);

  const activeBankCard = bankCards[activeBankCardIndex];

  const handleWalletBalanceClick = useCallback(() => {
    if (activeBankCard.type === 'crypto') {
      if (activeBankCard.isFrozen) {
        return;
      }

      drawer.open(cryptoBankCardDrawerTemplates.connectCryptoAccount({ card: activeBankCard }));
      return;
    }
    if (activeBankCard.currencyCode) {
      navigate(
        routesByName.accountPage(activeBankCard.currencyCode, activeBankCard.type === 'iban'),
      );
      drawer.close();
    }
  }, [navigate, drawer, activeBankCard]);

  const user = useSelector(selectUserProfile);

  const canShowCurveProviderAd = useMemo(() => {
    const isCryptoCard = activeBankCard?.type === 'crypto';

    if (!isCryptoCard) {
      return false;
    }
    const countryAllowed =
      user && (europeanUnionCountries.includes(user.countryCode) || user.countryCode === 'GB');

    if (!countryAllowed) {
      return false;
    }

    return countryAllowed && isCryptoCard;
  }, [activeBankCard?.type, user]);

  return activeBankCard ? (
    <div className={classes.root}>
      <BankCardsCarousel
        activeBankCardIndex={activeBankCardIndex}
        onBankCardChanged={setActiveBankCardIndex}
        bankCards={bankCards}
        frontSide={frontSide}
      />
      {activeBankCard.isAdvertisementCard ? (
        <div className="px-3 column flex-1">
          <AdvertisementCard />
        </div>
      ) : (
        <>
          <div className="px-3 column gap-3">
            <BlockedBankCardReason isBlocked={activeBankCard.isBlocked} />
            <OperationButtons frontSide={frontSide} bankCard={activeBankCard} />
            {canShowCurveProviderAd && <CurveAppAdCard />}
            {activeBankCard.currencyCode && (
              <BalanceCard
                currencyCode={activeBankCard.currencyCode}
                onClick={handleWalletBalanceClick}
                disabled={
                  activeBankCard.isBlocked ||
                  (activeBankCard.type === 'crypto' && activeBankCard.isFrozen)
                }
              />
            )}
            {activeBankCard.type === 'crypto' && (
              <CryptoBankCardPart key={activeBankCard.id} card={activeBankCard} />
            )}
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default BankCards;
