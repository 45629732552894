import { useCallback } from 'react';

const useDateParser = <T>(values: T) => {
  const parseDateInput = useCallback(
    (currentValue: string, fieldName: keyof T) => {
      const prevValue = values[fieldName] as string;
      const goForward = prevValue.length < currentValue.length;

      const lastChar = currentValue[currentValue.length - 1];

      if (isNaN(+lastChar) && goForward) {
        return currentValue.slice(0, -1);
      }
      if (currentValue.length > 10) {
        return currentValue.substring(0, 10);
      }

      if (goForward) {
        if (currentValue.length === 2 || currentValue.length === 5) {
          return currentValue + '.';
        }
      } else {
        if (prevValue[prevValue.length - 1] === '.') {
          return currentValue.slice(0, -1);
        }
      }

      return currentValue;
    },
    [values],
  );

  return parseDateInput;
};

export default useDateParser;
