import { uniq } from 'lodash';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { selectAllowedDirectionsReducer } from 'modules/exchange/store/selectors';
import { requestAllowedDirections } from 'modules/exchange/store/thunks';
import { CurrenciesCard } from 'modules/exchange/views/ExchangePage/components/CurrenciesCard';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';

import useStoreEntity from 'hooks/useStoreEntity';

import { CurrencyCode } from 'types';

export const Markets = () => {
  const wallets = useSelector(selectWallets);
  const defaultCurrency = useSelector(selectUserDefaultCurrencyCode);

  const {
    entityReducer: { data: allowedDirections },
  } = useStoreEntity(selectAllowedDirectionsReducer, requestAllowedDirections);

  const allowedList = useMemo(() => {
    let allowedCurrencies: CurrencyCode[] = [];
    allowedDirections.forEach((direction) => {
      const [from, to] = direction.split('/');

      if (to === defaultCurrency && from !== to) {
        allowedCurrencies.push(from as CurrencyCode);
      }
    });

    allowedCurrencies = uniq(allowedCurrencies);

    return wallets
      .filter((w) => allowedCurrencies.includes(w.currencyCode))
      .sort((a, b) => b.amountInDefaultCurrency - a.amountInDefaultCurrency)
      .map((w) => w.currencyCode);
  }, [allowedDirections, wallets, defaultCurrency]);

  return (
    <div className="px-2 pb-2">
      <CurrenciesCard list={allowedList} />
    </div>
  );
};
