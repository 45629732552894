export default {
  // auth
  SIGN_IN_ENTER_OTP_TITLE: 'Ingresa el código de inicio de sesión',
  SIGN_ENTER_OTP_SUBTITLE:
    'Copia y pega el código de inicio de sesión temporal que se envió a tu correo electrónico.',
  SIGN_UP_SELECT_COUNTRY_TITLE: '¿Cuál es tu país de residencia?',
  SIGN_UP_SELECT_COUNTRY_SUBTITLE:
    'Esta información se utilizará más adelante para verificar tu identidad.',
  SIGN_ENTER_EMAIL_TITLE: '¿Cuál es tu correo electrónico?',
  SIGN_ENTER_EMAIL_SUBTITLE:
    'Ingresa tu correo electrónico para recibir un código de inicio de sesión temporal.',
  SIGN_WITH_TFA_TITLE: 'Iniciar sesión con 2FA',
  SIGN_WITH_TFA_SUBTITLE: 'Ingresa el código 2FA de tu aplicación de autentificación.',
  SIGN_WITH_TFA_HAVING_ISSUES: '¿Tienes problemas con 2FA?',
  SIGN_WITH_TFA_HAVING_ISSUES_SUBTITLE: 'Elige cómo recuperar tu 2FA para iniciar sesión.',
  SIGN_SIGN_UP: 'Abrir cuenta',
  SIGN_SIGN_IN: 'Iniciar sesión',
  SIGN_UP_EMAIL_SENT: 'Correo electrónico con código de confirmación enviado',
  SIGN_UP_CONFIRM_TITLE: 'Confirma tu correo electrónico',
  SIGN_UP_SUCCESS: 'Te has registrado correctamente',
  SIGN_UP_EMAIL_NOTE:
    'Por favor, utiliza un correo electrónico que esté registrado en tus redes sociales y varios servicios.',
  BACK_TO_LOGIN: 'Volver a iniciar sesión',
  BACK_TO_SIGN_UP: 'Volver a registrarse',
  SIGN_IN_OTP_EMAIL_SENT: 'Correo electrónico con contraseña de un solo uso enviado',
  SIGN_IN_OTP_CONFIRM: 'Ingresa la contraseña de un solo uso',
  // tfa
  TFA_TITLE: 'Autentificación de dos factores',
  TFA_DISABLE_TITLE: 'Desactivar la autentificación de dos factores',
  TFA_DISABLE_SUBTITLE:
    'Ingresa el código de 6 dígitos de Google Authenticator para desactivar la autentificación de dos factores.',
  TFA_DISABLE_SUCCESS: '2FA desactivado',
  TFA_RESET: 'Restablecimiento de emergencia',
  TFA_RESET_TITLE: 'Ingresa el código de respaldo de emergencia',
  TFA_RESET_SUBTITLE:
    'Copia y pega el código de respaldo de emergencia que se generó al configurar 2FA.',
  TFA_EMERGENCY_RESET_CODE_TITLE: 'Código de respaldo de emergencia',
  TFA_EMERGENCY_RESET_CODE_SUBTITLE:
    'Guarda este código de respaldo de emergencia en un lugar seguro.',
  TFA_EMERGENCY_RESET_CODE_PLACEHOLDER: 'Código de respaldo de emergencia',
  TFA_EMERGENCY_RESET_SUCCESS: 'Restablecimiento de 2FA completo',
  TFA_SETUP_TITLE: 'Usar una aplicación de autentificación',
  // NO TRADUCIR de "<a" a ">"
  TFA_SETUP_SUBTITLE:
    'Descarga la aplicación gratuita de Google Authenticator <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US" rel="noopener noreferrer" target="_blank" </a>, agrega una nueva cuenta y pega esta clave o escanea el código QR para configurar tu cuenta.',
  TFA_SETUP_KEY: 'Clave de configuración',
  TFA_SETUP_ALTERNATIVE: 'o escanea el código QR',
  TFA_SETUP_CONFIRM_CODE_SUBTITLE:
    'Ingresa el código de 6 dígitos de Google Authenticator para completar la configuración de tu cuenta.',
  TFA_ENTER_CODE_PLACEHOLDER: 'Ingresa el código de autentificación',
  TFA_SETUP_SUCCESS: 'Configuración de 2FA completada',
  // terms
  SIGN_AGREEMENT_P1: 'Al abrir una cuenta en Nebeus, aceptas nuestros ',
  SIGN_IN_AGREEMENT_P1: 'Al iniciar sesión, aceptas nuestros ',
  PROCEEDING_AGREEMENT_P1: 'Al proceder, aceptas nuestros ',
  TERMS_OF_USE: 'Términos de uso',
  TERMS_OF_MODULR_BUSINESS: 'Términos comerciales de Modulr',
  // placeholders
  PLACEHOLDER_TFA_CODE: 'Código de 6 dígitos',
  PLACEHOLDER_COUNTRY: 'País',
  PLACEHOLDER_HIDE_ZERO_BALANCES: 'Ocultar saldos en cero',
  PLACEHOLDER_SHOW_ZERO_BALANCES: 'Mostrar saldos en cero',
  PLACEHOLDER_HISTORY: 'Historial',
  PLACEHOLDER_ENTER_AMOUNT: 'Ingresar cantidad',
  PLACEHOLDER_SUFFIX_MONTHS: 'mes(es)',
  PLACEHOLDER_SEARCH_CURRENCIES: 'Buscar divisas',
  PLACEHOLDER_LIMITS_LABEL: 'Entre %{minLabel} y %{maxLabel}',
  PLACEHOLDER_YOUR_COUNTRY: 'Tu país',
  PLACEHOLDER_OTP_CODE: 'Código de inicio de sesión temporal',

  // dates
  DATE_TODAY: 'Hoy',
  DATE_YESTERDAY: 'Ayer',

  // common
  ACCEPT: 'Aceptar',
  DENY: 'Negar',
  DENY_QUESTION: '¿Negar?',
  SUBMIT: 'Enviar',
  CONFIRM: 'Confirmar',
  CONFIRMATION: 'Confirmación',
  CONTINUE: 'Continuar',
  ENABLED: 'Activado',
  DISABLED: 'Desactivado',
  CLOSE: 'Cerrar',
  BACK: 'Atrás',
  SUCCESS: 'Éxito',
  'SUCCESS!': '¡Éxito!',
  COPIED: 'Copiado',
  NEXT: 'Siguiente',
  CANCEL: 'Cancelar',
  CANCELLED: 'Cancelado',
  SAVE: 'Guardar',
  MORE: 'Más',
  DASHBOARD: 'Panel',
  ACCOUNTS: 'Cuentas',
  ACCOUNT: 'Cuenta', // Cuenta como billetera. Por ejemplo, cuenta comercial. - ¡NO CUENTA DE USUARIO!
  EXCHANGE_RATE: 'Tasa de cambio',
  RATE: 'Tasa',
  DEFAULT_CURRENCY: 'Divisa predeterminada',
  LANGUAGE: 'Idioma',
  LEARN_MORE: 'Más información',
  NEW: 'Nuevo',
  ACTIVE: 'Activo',
  CLOSED: 'Cerrado', // por ejemplo, cuenta cerrada
  EXPIRED: 'Caducado',
  TERM: 'Plazo', // por ejemplo (Plazo: 1 mes)
  IN_CURRENCY: 'En', // por ejemplo (En EUR, en USD, etc.)
  SEARCH: 'Buscar', // se usa con el ícono de la lupa

  LONG_PRESS_TO_CONFIRM: 'Mantén presionado para confirmar',
  FEE: 'Comisión', // comisión
  FREE: 'Gratis',
  IMPORTANT: 'Importante',
  GET_CONFIRMATION_CODE: 'Obtener código de confirmación', // por ejemplo, en formularios para retirar fondos
  ASSETS: 'Activos',
  OVERVIEW: 'Resumen',
  ALL_CURRENCIES: 'Todas las divisas',
  BUY: 'Comprar',
  SELL: 'Vender',
  HISTORY: 'Historial', // Context: Historial de transacciones
  AMOUNT: 'Cantidad',
  YES: 'Sí',
  NO: 'No',
  NEXT_PAYMENT_AMOUNT: 'Cantidad del próximo pago',
  NEXT_PAYMENT_DATE: 'Fecha del próximo pago',
  NEXT_PAYMENT: 'Próximo pago',
  TRANSACTION: 'Transacción',
  TRANSACTIONS: 'Transacciones',
  PAID: 'Pagado',
  MINIMUM_DEPOSIT: 'Depósito mínimo',
  DEPOSIT_CURRENCY: 'Depósito en %{currencyLabel}', // Ejemplo: Depósito en Bitcoin
  NEBEUS_WALLET: 'Billetera Nebeus %{currency}', // Ejemplo: Billetera Nebeus BTC
  PENDING: 'Pendiente',
  PAYOUT_INTERVAL: 'Intervalo de pago',
  CHOOSE_YOUR_SUBSCRIPTION: 'Elige tu suscripción',
  UPGRADE_YOUR_SUBSCRIPTION: 'Mejora tu suscripción',
  DOWNGRADE_YOUR_SUBSCRIPTION: 'Modifica tu suscripción',
  PRICE_PER_MONTH_SHORT: '%{currency}%{amount}/mes.',
  PRICE_PER_MONTH: '%{currency}%{amount}/mes',
  FREE_LIFETIME_PLAN: 'Plan de por vida gratuito',
  SUBSCRIPTIONS: 'Suscripciones',
  SUBSCRIPTION: 'Suscripción',
  MONTHLY_PAYMENT: 'Pago mensual',
  PAYMENT_ACCOUNT: 'Cuenta de pago',
  SUBSCRIPTION_MONTHLY_AND_CANCEL_ANYTIME: 'Mensual, cancela en cualquier momento',
  PURCHASED: 'Comprado',
  OPENED: 'Abierto', // Ejemplo: cuenta abierta
  PAYMENT_PENDING: 'Pago pendiente',
  BACK_TO_DASHBOARD: 'Volver al panel',
  BACK_TO_SUBSCRIPTIONS: 'Volver a las suscripciones',
  ACTIVE_UNTIL: 'Activo hasta',
  PURCHASE_NEW_PLAN: 'Comprar un nuevo plan',
  VERIFICATION_PENDING: 'Verificación pendiente',
  CANCEL_SUBSCRIPTION: 'Cancelar suscripción',
  UPGRADE: 'Mejorar',
  DOWNGRADE: 'Cambiar',
  GO_TO_CARD: 'Ir a la tarjeta', // Tarjeta bancaria
  YOUR_TOTAL_VALUE: 'Tu saldo total',
  BANK_NAME: 'Nombre del banco',
  ACCOUNT_DETAILS: 'Detalles de la cuenta',
  GET_MORE_FROM_NEBEUS: 'Saca provecho a Nebeus',
  CARDS: 'Tarjetas',
  DOWNGRADE_SUBSCRIPTION: 'Modificar suscripción',
  COMPLETED: 'Completado',
  ENTER_AMOUNT: 'Ingresar cantidad',
  SELECT_PAIR: 'Seleccionar par',
  SELECT_ASSET: 'Seleccionar activo',
  SUMMARY: 'Resumen',
  ALLOW_PUSH_NOTIFICATIONS: 'Permitir notificaciones',
  FROM_ADDRESS: 'Dirección de origen',
  TO_ADDRESS: 'Dirección de destino',
  NETWORK: 'Red',
  TRANSACTION_HASH: 'Hash de transacción',
  TRANSACTION_ID: 'ID de transacción',
  'DATE_&_TIME': 'Fecha y hora',
  OPERATION_AMOUNT: 'Cantidad de la operación',
  DESCRIPTION: 'Descripción',
  DOWNLOAD_PDF: 'Descargar PDF',
  RECIPIENT: 'Destinatario',
  RECIPIENTS_IBAN: 'IBAN del destinatario',
  SELECT_A_QUESTION: 'Seleccionar una pregunta',
  QUESTION: 'Pregunta',
  WRITE_AN_ANSWER: 'Escribir una respuesta',
  ENTER_THE_FIELDS: 'Ingresar los campos',
  CONTACT_SUPPORT: 'Contactar soporte',
  ENTER_BACKUP_CODE: 'Ingresar código de respaldo',

  // validation
  VALIDATION_LENGTH: 'Por favor, ingresa %{length} caracteres',
  VALIDATION_REQUIRED: 'Campo requerido',
  VALIDATION_FIELD_INVALID: 'Campo no válido',
  VALIDATION_MIN: 'El campo debe tener al menos %{min} caracteres',
  VALIDATION_CONTAIN_NUMBER: 'El campo debe contener un número',
  VALIDATION_EMAIL: 'Correo electrónico no válido',
  VALIDATION_SELECT_NO_OPTIONS: 'No encontrado',
  VALIDATION_COUNTRY_INCORRECT: 'País no válido',
  VALIDATION_INSUFFICIENT_FUNDS: 'Fondos insuficientes',
  VALIDATION_MIN_AMOUNT: 'La cantidad debe ser mayor o igual a %{minLabel}',
  VALIDATION_MAX_AMOUNT: 'La cantidad debe ser menor o igual a %{maxLabel}',
  VALIDATION_WITHDRAW_LIMITS_REACHED: 'Se alcanzaron los límites de retiro',
  VALIDATION_BANK_CARD_NUMBER_INVALID: 'Número de tarjeta no válido',
  VALIDATION_BANK_CARD_EXP_DATE_INVALID: 'Fecha de vencimiento no válida',
  VALIDATION_DIGITS_COUNT: 'El campo debe contener %{count} dígitos',
  VALIDATION_INVALID_IBAN: 'IBAN no válido',
  HELP_CENTER: 'Centro de ayuda',

  // layout
  HEADER_OPERATIONS: 'Operaciones',

  WALLETS: 'Billeteras',
  EARNING: 'Ganancias',
  VAULTS: 'Vaults',
  LOAN_COLLATERAL: 'Colateral del préstamo',

  // payment
  SEND: 'Enviar',
  DEPOSIT: 'Depositar',
  WITHDRAW: 'Retirar',
  PAYMENT_METHODS: 'Métodos de pago',

  // exchange
  EXCHANGE: 'Intercambio',
  EXCHANGE_FROM: 'De',
  EXCHANGE_TO: 'A',
  ENTER_MAX_AMOUNT_BUTTON: 'Ingresar monto máximo',
  BALANCE: 'Saldo',

  // user
  PROFILE: 'Perfil',
  SETTINGS: 'Configuración',
  PROFILE_LOGOUT: 'Cerrar sesión',
  SECURITY: 'Seguridad',
  NOTIFICATIONS: 'Notificaciones',
  PUSH_NOTIFICATIONS: 'Notificaciones push',

  PROFILE_CHAT_SUPPORT: 'Soporte de chat',
  PROFILE_PRIVACY_POLICY: 'Política de privacidad',
  PROFILE_CLOSE_ACCOUNT: 'Cerrar cuenta',

  PROFILE_SAVED_BANK_CARDS_TITLE: 'Tarjetas bancarias',
  PROFILE_SAVED_CRYPTO_WALLETS_TITLE: 'Billeteras digitales',
  PROFILE_SAVED_CRYPTO_WALLETS_ADD_TITLE: 'Agrega una billetera digital',
  PROFILE_SAVED_CRYPTO_WALLETS_ADD_SUBTITLE:
    'Ingresa aquí tu dirección de billetera digital para guardarla en tu cuenta de Nebeus.',

  // verification
  VERIFICATION: 'Verificación',
  VERIFICATION_ID_VERIFICATION: 'Verificación de identidad',
  VERIFICATION_TOKEN_EXPIRED:
    'Tu token de verificación ha expirado. Por favor, contacta a nuestro soporte',
  VERIFICATION_VERIFIED: 'Verificado',
  VERIFICATION_VERIFY: 'Verificar',

  // accounts
  CRYPTO_ACCOUNT: 'Cuenta Cripto',
  ACCOUNTS_TOTAL_VALUE: 'Valor total',
  ACCOUNTS_FUND_DA: 'Cuenta IBAN del fondo',
  ACCOUNTS_FUND_CA: 'Cuenta Cripto del fondo',
  ACCOUNTS_ACCOUNT_STATEMENT: 'Extracto de cuenta',
  ACCOUNT_STATEMENTS: 'Extractos',
  ACCOUNT_STATEMENT: 'Extracto de cuenta',
  ACCOUNT_TRANSACTIONS_HISTORY: 'Historial de transacciones',
  ACCOUNT_TRANSACTIONS_HISTORY_EMPTY: 'Aún no tienes transacciones',
  ACCOUNT_TRANSACTIONS_SEE_ALL: 'Ver todas',
  ACCOUNTS_WALLET_NOT_FOUND: 'Billetera para %{currencyCode} no encontrada',
  ACCOUNT_TRANSACTION_STATUS_PROCESSING: 'Procesando',
  ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_APPROVE: 'Esperando aprobación',
  ACCOUNT_TRANSACTION_STATUS_WAIT_FOR_BLOCK_CONFIRM: 'Esperando confirmación de bloque',
  ACCOUNT_TRANSACTION_STATUS_COMPLETED: 'Completada',
  ACCOUNT_TRANSACTION_STATUS_FAIL: 'Fallida',

  // crypto-renting
  CRYPTO_RENTING: 'Cripto Renting',
  RENTING: 'Renting',
  RENTING_TOTAL_ASSETS_LABEL: 'Valor total en Cripto Renting',
  RENTING_REWARDS_30DAYS: 'Recompensas a 30 días',
  RENTING_REWARDS_60DAYS: 'Recompensas a 60 días',
  RENTING_NEXT_REWARDS: 'Recompensa (en %{days} días)',
  RENTING_FAQ_TITLE: '¿Qué es el Cripto Renting?',
  RENTING_APP_OPERATIONS_DESC: 'Gana hasta un %{rpy}% RPY',
  RENTING_FAQ_SUBTITLE: 'Descubre cómo ganar recompensas<br/>con tus criptomonedas.',
  RENTING_FAQ_DESCRIPTION:
    'El Cripto Renting te permite ganar recompensas en tus criptomonedas al alquilar tus activos a Nebeus. Puedes ganar recompensas en la misma criptomoneda depositada o en una moneda completamente diferente de tu elección.<br/>' +
    '<br/>' +
    'Nebeus tiene cuatro opciones diferentes de Cripto Renting, cada una con sus períodos de bloqueo únicos y requisitos mínimos de depósito.<br/>' +
    '<br/>' +
    '<b>RPY = Recompensas Por Año.</b> <br/>' +
    '<br/>' +
    '<b>RPY</b> significa el monto total pagado por Nebeus por el alquiler de tus criptomonedas, expresado como un porcentaje anual del valor de los activos.',
  RENTING_FAQ_DESCRIPTION_ALTERNATIVE:
    'El Cripto Renting te permite <b>ganar recompensas en tus criptomonedas al alquilar tus activos a Nebeus.</b> Puedes ganar recompensas en la misma criptomoneda depositada o en una moneda completamente diferente de tu elección.<br/>' +
    '<br/>' +
    'Nebeus tiene cuatro opciones diferentes de Crypto Renting, cada una con sus períodos de bloqueo únicos y requisitos mínimos de depósito.',
  RENTING_TAB_NEW: 'Nuevo',
  RENTING_TAB_ACTIVE: 'Activo',
  RENTING_TAB_HISTORY: 'Historial',
  RENTING_RPY: 'RPY',
  RENTING_AVAILABLE_CURRENCIES: '%{count} criptomonedas disponibles',
  RENTING_WITHDRAW_CURRENCIES: 'Pago disponible en %{count} criptomonedas',
  RENTING_RENT_AMOUNT: 'Monto del Renting',
  RENTING_RENT_CURRENCY: 'Moneda a alquilar',
  RENTING_MONTHLY_PAYOUT: 'Pago mensual',
  RENTING_LOCKUP_PERIOD: 'Período de bloqueo',
  RENTING_YEARLY_PERCENTAGE: 'Porcentaje anual',
  RENTING_PAYOUT_SCHEDULE: 'Programa de pagos',
  RENTING_REWARD_PAYOUT: 'Pago de recompensa',
  RENTING_MINIMUM_DEPOSIT: 'Depósito mínimo',
  RENTING_PAYOUT_MONTHLY: 'Mensual',
  RENTING_SELECT_REWARDS_CURRENCY: 'Selecciona la moneda de recompensa',
  RENTING_REWARDS_PER_MONTH: 'Recompensas por mes',
  RENTING_TOTAL_REWARDS: 'Recompensas totales',
  RENTING_MONTHLY_PAYOUT_FAQ_TITLE: 'Pagos mensuales en euros o stablecoins',
  RENTING_MONTHLY_PAYOUT_FAQ_DESCRIPTION:
    'Todos los pagos de alquiler de criptomonedas se realizan en euros o stablecoins, y tus ganancias se enviarán a tu cuenta de Nebeus en la próxima fecha de pago.',
  RENTING_LOCKUP_PERIOD_FAQ_TITLE: '¿Qué es un período de bloqueo?',
  RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION:
    'Tus fondos estarán bloqueados durante %{months} mes(es). Al finalizar el período de bloqueo, podrás retirar tus fondos en cualquier momento de forma gratuita.',
  RENTING_LOCKUP_PERIOD_FAQ_DESCRIPTION_ALTERNATIVE:
    'Un período de bloqueo es un momento durante el cual tus activos cripto depositados estarán bloqueados. No podrás retirar tus activos durante el período de bloqueo. <br/>' +
    '<br/>' +
    'Una vez finalizado el período de bloqueo, tu programa de Cripto Renting seguirá activo y generará recompensas mensuales durante la duración seleccionada. Después del fin del período de bloqueo, podrás retirar tus fondos en cualquier momento.',
  RENTING_TOTAL_EARNINGS_FAQ_TITLE: '¿Cómo se calculan las ganancias?',
  RENTING_TOTAL_EARNINGS_FAQ_DESCRIPTION:
    'Todas las ganancias se calculan en función de los tipos de cambio vigentes en la fecha y hora de pago de las ganancias del Cripto Renting.',
  RENTING_MINIMUM_DEPOSIT_FAQ_TITLE: '¿Qué es un depósito mínimo?',
  RENTING_MINIMUM_DEPOSIT_FAQ_DESCRIPTION:
    'Esta es la cantidad mínima que debes depositar en un programa de Cripto Renting para poder comenzar a alquilar. Los depósitos mínimos varían según el programa de Cripto Renting que elijas.',
  RENTING_REWARD_PAYOUT_FAQ_TITLE: '¿Cómo se pagan las recompensas?',
  RENTING_REWARD_PAYOUT_FAQ_DESCRIPTION:
    '<b>RPY = Recompensas Por Año.</b><br/>' +
    '<br/>' +
    'RPY significa el monto total pagado por Nebeus por el alquiler de tus criptomonedas, expresado como un porcentaje anual del valor de los activos. <br/>' +
    '<br/>' +
    'Las recompensas se pagan mensualmente: cada 30 días a partir del día de inicio de tu programa de Cripto Renting.<br/>' +
    '<br/>' +
    '<b>Pago de recompensa = día de inicio del alquiler de criptomonedas + 30 días.</b>',
  RENTING_WITHDRAW_CURRENCY_FAQ_TITLE: '¿En qué moneda puedo recibir recompensas?',
  RENTING_WITHDRAW_CURRENCY_FAQ_TITLE_ALTERNATIVE:
    '¡Elige en qué criptomoneda quieres ganar recompensas!',
  RENTING_WITHDRAW_CURRENCY_FAQ_DESCRIPTION:
    'Puedes ganar recompensas en la misma criptomoneda depositada o en una moneda completamente diferente de tu elección.<br/>' +
    '<br/>' +
    'Por ejemplo, puedes depositar USDT y elegir ganar recompensas en esta misma moneda, o puedes elegir ganar recompensas en BTC, ETH o cualquier otra moneda disponible. ',
  RENTING_FAQ_RPY_TITLE: 'RPY = Recompensas Por Año.',
  RENTING_FAQ_RPY_DESCRIPTION:
    'Nebeus ofrece cuatro opciones de Cripto Renting. <br/>' +
    '<br/>' +
    'Dos se centran en criptomonedas como Bitcoin, Ethereum, Avalanche y Ripple, lo que te permite ganar hasta un 6,5% RPY. <br/>' +
    '<br/>' +
    'Las otras dos se centran en el alquiler de stablecoins como USDT y USDC, lo que te permite ganar hasta un 8,2% RPY. ',
  RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_TITLE: '¿Cómo utiliza Nebeus los activos alquilados?',
  RENTING_FAQ_HOW_NEBEUS_USED_ASSETS_DESCRIPTION:
    'Nebeus utiliza los activos alquilados de criptomonedas para la liquidez de préstamos y la rehipotecación interna para el desarrollo de productos. <br/>' +
    '<br/>' +
    'Nebeus nunca ha reinvertido las criptomonedas alquiladas de forma externa y siempre mantiene el nivel necesario de liquidez para cumplir con todas las obligaciones.',

  RENTING_TERMS: 'Términos',
  RENTING_YOUR_REWARDS: 'Tus recompensas',
  RENTING_SETUP_SUCCESS: 'Renting iniciado',
  RENTING_SETUP_SUCCESS_TEXT:
    '¡Felicidades! Acabas de comenzar a alquilar tus criptomonedas. Puedes ver los detalles de tu programa de Cripto Renting en tu lista de programas activos.',
  RENTING_PAYOUT: 'Pago',
  RENTING_TOTAL_CUMULATIVE_PAYOUT: 'Pago total acumulado',
  RENTING_START_RENTING: 'Comenzar Cripto Renting',
  RENTING_NO_HISTORY:
    'No tienes historial de Cripto Renting. Pero eso puede cambiar si abres un programa de alquiler de criptomonedas ahora.',
  RENTING_NO_ACTIVE_RENTINGS:
    'No tienes programas de Cripto Renting activos. Pero puedes abrir uno ahora y comenzar a ganar ingresos pasivos con tus activos digitales.',

  // payment
  PAYMENT_CASH: 'Efectivo',
  PAYMENT_CRYPTO: 'Cripto',
  PAYMENT_DEPOSIT_FUNDS: 'Depositar Fondos',
  PAYMENT_SEND_FUNDS: 'Enviar Fondos',
  PAYMENT_BANK_CARD: 'Tarjeta Bancaria',
  PAYMENT_APPLE_PAY: 'Apple Pay',
  PAYMENT_APPLE_PAY_FAQ_DESCRIPTION:
    'Con Apple Pay, puedes depositar instantáneamente dinero FIAT en tu cuenta de Nebeus. Apple Pay te evita la molestia de tener que esperar hasta 24 horas, como en el caso de una transferencia bancaria.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_TITLE: 'Información sobre los depósitos con Apple Pay:',
  PAYMENT_APPLE_PAY_FAQ_STEPS_1:
    'Puedes depositar fondos en tu cuenta de Nebeus utilizando cualquier tarjeta bancaria que tengas guardada en tu billetera de Apple Pay.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_2:
    'Los fondos depositados serán procesados y aparecerán en tu cuenta de Nebeus en un máximo de 15 minutos.',
  PAYMENT_APPLE_PAY_FAQ_STEPS_3:
    'Los límites para depositar fondos utilizando Apple Pay están entre %{minAmount} y %{maxAmount} por transacción.',
  PAYMENT_TO_EXTERNAL_WALLET: 'A billetera externa',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_TITLE: 'Acerca del envío a billeteras externas',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_DESCRIPTION:
    'Puedes enviar y retirar criptomonedas a cualquier billetera externa, y se enviará directamente a través de la cadena de bloques nativa de la criptomoneda.',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEPS_TITLE: 'Información sobre el envío a billeteras externas:',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_1:
    'Recomendamos escanear el código QR o copiar/pegar la dirección de la billetera a la que deseas enviar tus criptomonedas, ya que esto ayudará a evitar errores. Ten en cuenta que no nos hacemos responsables de las criptomonedas enviadas por error a una dirección de billetera incorrecta.',
  PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_2:
    'Ten siempre cuidado al enviar criptomonedas a billeteras externas. Debido a la naturaleza de la tecnología blockchain, una vez que realizas una transacción, es imposible cancelarla o revertirla.',

  // NU - Usuario de Nebeus
  PAYMENT_NU_NAVIGATION_TITLE: 'A Usuario de Nebeus',
  PAYMENT_NU_FAQ_TITLE: 'Envío a Usuarios de Nebeus',
  PAYMENT_NU_FAQ_DESCRIPTION:
    'Puedes enviar criptomonedas a cualquier usuario de Nebeus de forma instantánea y gratuita. Lo único que necesitas saber es el correo electrónico asociado a la cuenta del usuario de Nebeus.',
  PAYMENT_NU_FAQ_STEPS_TITLE: 'Información sobre el envío a un usuario de Nebeus:',
  PAYMENT_NU_FAQ_STEP_1: 'Es totalmente gratuito.',
  PAYMENT_NU_FAQ_STEP_2: 'No hay límites en la cantidad que puedes enviar.',
  PAYMENT_NU_FAQ_STEP_3:
    'Todas las transferencias se realizan dentro de la plataforma de Nebeus, por lo que son seguras e instantáneas.',
  PAYMENT_NU_EMAIL_LABEL: 'Correo electrónico del usuario destinatario',

  PAYMENT_DIRECT_BANK_TRANSFER: 'Transferencia Bancaria Directa',
  PAYMENT_NEBEUS_TO_MONEY_ACCOUNT: 'A Cuenta IBAN',
  PAYMENT_NEBEUS_TO_CRYPTO_ACCOUNT: 'A Cuenta Cripto',
  PAYMENT_BANK_TRANSFER: 'Transferencia Bancaria',
  PAYMENT_BANK_TRANSFER_SEPA: 'Transferencia Bancaria (SEPA)',
  PAYMENT_BANK_TRANSFER_WIRE: 'Transferencia Bancaria (Wire)',
  PAYMENT_SEND_TO_NEBEUS_USER: 'Enviar a Usuario de Nebeus',
  PAYMENT_SEND_TO_NEBEUS_USER_SUCCESS:
    'Has enviado con éxito %{amount} %{currency} al usuario de Nebeus con la dirección de correo electrónico: <b>%{email}</b>',
  PAYMENT_TO_NEBEUS_USER: 'A usuario de Nebeus',
  PAYMENT_WALLET_BALANCE: 'Tu saldo de %{currencyCode}',
  PAYMENT_DEPOSIT_CRYPTO_QR_LABEL:
    'Escanea este código QR o comparte tu dirección de billetera para recibir %{currencyCode}.',
  PAYMENT_WALLET_ADDRESS: 'Dirección de la billetera',
  PAYMENT_WALLET_CURRENCY: 'Moneda de la billetera',
  PAYMENT_WALLET_NAME: 'Nombre de la billetera',
  PAYMENT_WALLET_ADDRESS_INVALID: 'Dirección de billetera %{currencyCode} no válida',
  PAYMENT_DEPOSIT_CRYPTO_IMPORTANT_LABEL:
    'Solo envía %{currencyCode} a esta dirección de billetera. El envío de cualquier otra moneda puede provocar una pérdida permanente.',
  PAYMENT_AMOUNT_FORM_AMOUNT: 'Cantidad',
  PAYMENT_YOU_WILL_GET: 'Recibirás',
  PAYMENT_RECIPIENT_WILL_GET: 'El destinatario recibirá',
  PAYMENT_LIMITS_LABEL: 'Entre %{minLabel} y %{maxLabel}',
  PAYMENT_LIMITS_LABEL_MINIMUM: 'Mínimo %{minLabel}',
  PAYMENT_BANK_CARD_NUMBER: 'Número de tarjeta',
  PAYMENT_BANK_CARD_EXP_DATE: 'Fecha de vencimiento',
  PAYMENT_BANK_CARD_EXP_DATE_PLACEHOLDER: 'Fecha --/--',
  PAYMENT_BANK_CARD_CVC: 'CVC',
  PAYMENT_BANK_CARD_FULL_NAME: 'Nombre completo',
  PAYMENT_SAVE_BANK_CARD: 'Guardar tarjeta bancaria',
  PAYMENT_SEND_WIRE_FAQ_TITLE: 'Acerca de las transferencias bancarias',
  PAYMENT_SEND_WIRE_FAQ_DESC:
    'Las transferencias bancarias (Wire) son la forma estándar y más económica de transferir dinero internacionalmente. Las transferencias bancarias (Wire) son más lentas que el envío de dinero a una tarjeta bancaria (la transferencia puede tardar hasta 48 horas), pero son menos costosas, por lo que si no necesitas recibir tus fondos con urgencia, utilizar una transferencia bancaria es una excelente opción.',
  PAYMENT_SEND_WIRE_FAQ_STEPS_TITLE:
    'Información importante sobre las transferencias bancarias (Wire):',
  PAYMENT_SEND_WIRE_DA_FAQ_STEPS_1:
    'Las transferencias bancarias pueden tardar hasta 2 días hábiles en llegar y ser procesadas por tu banco.',
  PAYMENT_SEND_WIRE_DA_FAQ_STEPS_2:
    'Nebeus no tiene un límite para la cantidad de fondos que puedes enviar a través de una transferencia bancaria (Wire).',
  PAYMENT_SEND_WIRE_FAQ_STEPS_1:
    'Las transferencias bancarias (Wire) pueden tardar hasta 2 días hábiles en llegar y ser procesadas por tu banco.',
  PAYMENT_SEND_WIRE_FAQ_STEPS_2:
    'Nebeus no tiene un límite para la cantidad de fondos que puedes enviar a través de una transferencia bancaria (Wire).',
  PAYMENT_SEND_WIRE_FAQ_STEPS_3:
    'Si estás enviando una suma grande de dinero, el proceso puede llevar más de 2 días hábiles y el equipo de atención al cliente de Nebeus se pondrá en contacto contigo para ayudarte en el proceso.',
  PAYMENT_SEND_WIRE_DA_FAQ_DESC:
    'Las transferencias bancarias son la forma estándar de transferir dinero.',
  PAYMENT_DEPOSIT_WIRE_SEPA_IMPORTANT_NOTICE: 'Solo se Aceptan Pagos de Cuentas a Tu Nombre.',
  PAYMENT_DEPOSIT_WIRE_FAQ_TITLE: 'Acerca de las transferencias bancarias',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE:
    'Información importante sobre las transferencias bancarias:',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_2:
    'Nebeus no tiene un límite para la cantidad de fondos que puedes depositar a través de una transferencia bancaria.',
  PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_1:
    'Dependiendo de tu banco, las transferencias bancarias pueden tardar hasta 24 horas en llegar y ser procesadas.',
  PAYMENT_DEPOSIT_SEPA_FAQ_TITLE: 'Acerca de las transferencias bancarias SEPA',
  PAYMENT_DEPOSIT_SEPA_DIGITAL_DESC:
    'Utiliza estos datos de cuenta para depositar euros en tu Cuenta IBAN de Nebeus',
  PAYMENT_DEPOSIT_WIRE_DIGITAL_DESC:
    'Utiliza estos datos de cuenta para depositar libras esterlinas en tu Cuenta IBAN de Nebeus',
  PAYMENT_DEPOSIT_SEPA_CRYPTO_DESC:
    'Utiliza estos datos bancarios para realizar una transferencia SEPA. Ten en cuenta que solo se aceptan euros para transferencias SEPA.',
  PAYMENT_DEPOSIT_SEPA_FAQ_DESC:
    'SEPA (o Área Única de Pagos en Euros) es una transferencia bancaria en euros (EUR) que funciona dentro de la Unión Europea y varios otros estados miembros que forman parte de la Asociación Europea de Libre Comercio.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE: 'Información importante sobre las transferencias SEPA',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_1:
    'Cuando realices una transferencia bancaria a Nebeus, el nombre en tu cuenta bancaria debe coincidir con tu nombre registrado en Nebeus.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2:
    'Dependiendo de tu banco, las transferencias SEPA pueden tardar hasta 24 horas en llegar y ser procesadas.',
  PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_3:
    'Nebeus no tiene un límite para la cantidad de fondos que puedes depositar a través de una transferencia SEPA.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_1:
    'Las transferencias SEPA pueden tardar hasta 2 días hábiles en llegar y ser procesadas por tu banco.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_2:
    'Nebeus no tiene un límite para la cantidad de fondos que puedes enviar a través de una transferencia SEPA.',
  PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_3:
    'Si estás enviando una suma grande de dinero, el proceso puede llevar más de 2 días hábiles y el equipo de atención al cliente de Nebeus se pondrá en contacto contigo para ayudarte en el proceso.',
  PAYMENT_RECEIVER_OR_BENEFICIARY_NAME: 'Nombre del receptor/beneficiario',
  PAYMENT_IBAN: 'IBAN',
  PAYMENT_REFERENCE: 'Referencia',
  PAYMENT_SWIFT_OR_BIC: 'SWIFT/BIC',
  PAYMENT_SORT_CODE: 'Sort code',
  PAYMENT_ACCOUNT_NUMBER: 'Número de cuenta',
  PAYMENT_ACCOUNT_NAME: 'Nombre de la cuenta',
  PAYMENT_BIC: 'BIC',
  PAYMENT_SEPA_REFERENCE_CODE: 'Código de referencia de la transferencia',
  PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT:
    'Indica este código de referencia al realizar tu transferencia',
  PAYMENT_SEPA_REFERENCE_CODE_IMPORTANT_DESC:
    'Asegúrate de indicar este código de referencia al realizar tu transferencia. Si no indicas el código de referencia de la transferencia, es posible que la transacción tarde más en procesarse.',

  PAYMENT_DEPOSIT_BANK_CARD_IMPORTANT_INFO:
    'Puede tomar hasta 15 minutos para que Nebeus procese tu transacción y refleje los fondos en tu cuenta',
  PAYMENT_SEND_BANK_CARD_IMPORTANT_INFO:
    'Enviar fondos a una tarjeta bancaria generalmente toma un máximo de 15 minutos para procesarse por tu banco.',

  PAYMENT_BANK_CARD_DEPOSIT_FAQ_TITLE: 'Acerca de los depósitos con tarjeta bancaria',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_DESCRIPTION:
    'Los depósitos con tarjeta bancaria son una forma excelente y rápida de depositar dinero FIAT en tu Cuenta Cripto. Te evitan la molestia de esperar a que se realice una transferencia bancaria.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEPS_TITLE:
    'Información sobre los depósitos con tarjeta bancaria:',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_1:
    'Puedes depositar fondos en tu Cuenta Cripto utilizando cualquier tarjeta bancaria.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_2:
    'Tus fondos depositados serán procesados y aparecerán en tu Cuenta Cripto en un máximo de 15 minutos.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_3:
    'Los límites para depositar fondos con tarjetas bancarias son entre %{minLabel} y %{maxLabel} por transacción.',
  PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_4:
    'Por razones de prevención de lavado de dinero, solo permitimos dos transacciones de depósito exitosas cada 24 horas.',

  PAYMENT_BANK_CARD_SEND_FAQ_TITLE: 'Acerca del envío a tarjetas bancarias',
  PAYMENT_BANK_CARD_SEND_FAQ_DESCRIPTION:
    'Enviar fondos a tarjetas bancarias es una excelente forma de enviar y retirar fondos de tu cuenta de Nebeus de manera muy rápida.',
  PAYMENT_BANK_CARD_SEND_FAQ_STEPS_TITLE: 'Información sobre el envío a tarjetas bancarias:',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_1:
    'Puedes enviar fondos a cualquier tarjeta MasterCard o Maestro.',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_2:
    'Los fondos enviados llegarán a tu tarjeta en un máximo de 15 minutos. Ten en cuenta que este proceso a veces puede retrasarse si tu banco no trabaja con tecnología de pago directo.',
  PAYMENT_BANK_CARD_SEND_FAQ_STEP_3:
    'Los límites para enviar fondos a una tarjeta bancaria son entre %{minLabel} y %{maxLabel} por transacción.',
  PAYMENT_SAVED_BANK_CARDS: 'Tarjetas bancarias guardadas',
  PAYMENT_SAVED_WALLETS: 'Billeteras guardadas',
  PAYMENT_SAVED_WALLETS_SUCCESS: 'Billetera digital agregada',
  PAYMENT_SAVED_BANK_CARDS_ADD_CARD: 'Agregar tarjeta',
  PAYMENT_SAVED_WALLETS_ADD_WALLET: 'Agregar billetera',
  PAYMENT_SEND_EXTERNAL_WALLET_LABEL: 'Etiqueta',

  PAYMENT_RECIPIENTS_WALLET: 'Billetera del destinatario',
  PAYMENT_RECIPIENTS_STH: '%{label} del destinatario',
  PAYMENT_RECIPIENTS_GETS: 'El destinatario recibe',
  PAYMENT_NEBEUS_FEE: 'Tasa de Nebeus',
  PAYMENT_TOTAL_WITHDRAWN: 'Total retirado',
  PAYMENT_CRYPTO_WITHDRAW_INITIATED: 'Retiro iniciado',
  PAYMENT_CRYPTO_WITHDRAW_INITIATED_SUBTITLE:
    'Sigue el progreso de tu retiro en tu panel de cuentas.',
  PAYMENT_SEND_CURRENCY_IMPORTANT_LABEL:
    'Envía solo %{currencyCode} a esta dirección utilizando la red %{network}. Enviar cualquier otra moneda o utilizar una red diferente puede resultar en una pérdida permanente.',
  PAYMENT_SEND_BUSD_IMPORTANT_LABEL:
    'No intentes enviar fondos utilizando las redes BSC, BEP2 o BEP20. Nebeus no está conectado a ninguna cadena de bloques de Binance, por lo que enviar fondos utilizando una red de Binance resultará en una pérdida permanente.',
  PAYMENT_BACK_TO_PROCESS: 'Seguir con el proceso',
  PAYMENT_SEND_TO_DA_FAQ_TITLE: 'Ingresa fondos a tu Cuenta IBANo',
  PAYMENT_SEND_TO_CA_FAQ_TITLE: 'Ingresa fondos a tu Cuenta Cripto',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_TITLE:
    'Transfiere dinero entre tu Cuenta IBAN y tu Cuenta Cripto.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_1:
    'Recibe criptomonedas en tu Cuenta Cripto desde cualquier billetera, cámbialas por dinero FIAT y envía instantáneamente los fondos a tu Cuenta IBAN.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_2:
    'Viceversa, envía fondos desde tu Cuenta IBAN a tu Cuenta Cripto para comprar criptomonedas al instante.',
  PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_3:
    'Las transferencias están limitadas según tu suscripción actual de cuenta. Si deseas aumentar tus límites, mejora tu cuenta.',
  PAYMENT_FEE_MIN: 'Mínimo',
  PAYMENT_ADD_BANK_CARD: 'Agregar tarjeta bancaria',
  PAYMENT_FROM: 'Desde',
  PAYMENT_TO: 'Hasta',
  PAYMENT_SEND_TO_SEPA_SUCCESS_MESSAGE:
    'Has enviado exitosamente %{currencyAmountLabel} a %{accountName}\n' +
    '\n' +
    'Ten en cuenta que las transferencias SEPA pueden tardar hasta 2 días hábiles en llegar y ser procesadas por el banco del destinatario.',
  PAYMENT_SEND_TO_CRYPTO_ACCOUNT:
    'Tus fondos se han enviado correctamente a tu Cuenta Cripto de Nebeus.<br/>' +
    '<br/>' +
    'Ten en cuenta que la transferencia es instantánea, pero a veces puede tardar hasta 10 minutos en procesarse.',
  PAYMENT_SEND_TO_DIGITAL_ACCOUNT:
    'Tus fondos se han enviado correctamente a tu Cuenta IBAN de Nebeus.<br/>' +
    '<br/>' +
    'Ten en cuenta que la transferencia es instantánea, pero a veces puede tardar hasta 10 minutos en procesarse.',
  PAYMENT_LIMIT_DAILY: 'Diario',
  PAYMENT_LIMIT_MONTHLY: 'Mensual',
  PAYMENT_CURRENCY_LIMIT: 'Límites de %{currencyCode}',
  PAYMENT_MIN_WITHDRAWAL: 'Retiro mínimo',
  PAYMENT_TRANSACTION_WITHDRAWAL: 'Retiro de transacción',
  PAYMENT_DAILY_WITHDRAWAL: 'Retiro diario',
  PAYMENT_MONTHLY_WITHDRAWAL: 'Retiro mensual',
  WITHDRAW_LIMITS: 'Límites de retiro',
  WITHDRAW_LIMITS_DESC:
    'Nebeus tiene límites de retiro para cada criptomoneda y moneda FIAT por razones de cumplimiento financiero, prevención de fraude y lavado de dinero.<br/><br/>Los límites de retiro se calculan por separado para cada criptomoneda y moneda FIAT.',

  // date
  DATE_MONTH_PLURAL_NO_BRACKETS: '%{count} meses',
  DATE_MONTH_PLURAL: '%{count} mes(es)',
  START_DATE: 'Fecha de inicio',
  END_DATE: 'Fecha de finalización',
  DATE: 'Fecha',

  // app
  CONFIRM_OTP_AUTHENTICATE: 'Autentificaciónr',
  CONFIRM_OTP_INSTRUCTIONS_SMS:
    'Por favor, ingresa el código de confirmación de 4 dígitos que enviamos a tu número de teléfono vinculado a tu cuenta de Nebeus.',
  CONFIRM_OTP_INSTRUCTIONS_EMAIL:
    'Por favor, ingresa el código de confirmación de 4 dígitos que enviamos a tu correo electrónico vinculado a tu cuenta de Nebeus.',
  CONFIRM_OTP_INPUT_LABEL: 'Ingresar código de confirmación',
  CONFIRM_OTP_RESEND_CODE: 'Reenviar código',
  CONFIRM_OTP_RESEND: 'Reenviar',
  CONFIRM_OTP_RESEND_IN: 'Reenviar en', // por ejemplo: Reenviar en 00:59 (segundos)
  CONFIRM_OTP_RESEND_IN_SECONDS: ' Reenviar en %{seconds} segundos',
  CONFIRM_OTP_INCORRECT_CODE: 'Código incorrecto',
  CONFIRM_OTP_CODE_SENT: 'Código enviado',
  NEWS: 'Noticias',
  NEWS_VIEW_MORE: 'Ver más',
  NEWS_ITEM_TIME_AGO: 'Hace %{time}',
  CONFIRM_OTP_DIDNT_RECEIVE: '¿No recibiste el código?',

  // EXCHANGE
  EXCHANGE_SUCCESS_TEXT:
    'Tu exchange ha sido confirmado y tu %{currencyCode} ya está en tu cuenta de Nebeus.',

  // STAKING
  STAKING: 'Staking',
  STAKED_ASSETS: 'Activos en staking',
  STAKING_RPY: 'RPY', // Recompensas por año
  STAKING_OVERVIEW_TITLE: 'Haz staking y obtén hasta %{maxRPY}% RPY',
  STAKING_OVERVIEW_TEXT:
    'Las recompensas se pagan en intervalos de 30 días.<br/>Unstake en cualquier momento.',
  STAKING_START: 'Iniciar staking',
  STAKING_STAKED_CURRENCY: 'Moneda en staking',
  STAKING_STAKED_AMOUNT: 'Cantidad en staking',
  STAKING_REWARD_PAYOUT: 'Pago de recompensas',
  STAKING_STAKED_BALANCE: 'Saldo en staking',
  STAKING_PAYOUT_INTERVAL: 'Mensual',
  STAKING_REWARDS_PER_WEEK: 'Recompensas por semana',
  STAKING_REWARDS_PER_YEAR: 'Recompensas por año',
  STAKING_APP_OPERATIONS_DESC: 'Staking flexible hasta %{rpy}% RPY',
  STAKING_SUCCESS_TITLE: 'Staking exitoso',
  STAKING_SUCCESS_SUBTITLE:
    'Tu staking de %{currencyCode} se ha confirmado.<br/>' +
    'Por favor, ten en cuenta que pueden pasar hasta 10 minutos para que tu %{currencyCode} aparezca en tu saldo de staking de Nebeus. ',
  STAKING_TOTAL_STAKED_VALUE: 'Valor total en staking',
  STAKING_STAKE: 'Stake',
  STAKING_UNSTAKE: 'Unstake',
  STAKING_PLACEHOLDER_AMOUNT_TO_UNSTAKE: 'Ingresar cantidad para unstake',
  STAKING_ENTER_UNSTAKE_AMOUNT: 'Cantidad para unstake',
  STAKING_UNSTAKE_AMOUNT: 'Cantidad a unstake',
  STAKING_UNSTAKE_SUCCESS_TITLE: 'Unstaking exitoso',
  STAKING_UNSTAKE_SUCCESS_SUBTITLE:
    'Tu unstaking de %{currencyCode} se ha confirmado.<br/>Por favor, ten en cuenta que pueden pasar hasta 10 minutos para que tu %{currencyCode} desbloqueado aparezca en tu cuenta principal de Nebeus. ',
  STAKING_STAKED_CURRENCIES: 'Monedas en staking',
  STAKING_HISTORY: 'Historial de staking',
  STAKING_TOTAL_REWARDS: 'Recompensas totales',
  STAKING_NEXT_PAYOUT: 'Próximo pago (en %{days} días)',

  // errors
  ERROR: 'Error',
  ERROR_EMAIL_NOT_FOUND: 'Correo electrónico no encontrado',
  ERROR_TRY_AGAIN: 'Inténtalo de nuevo',
  NO_RESULTS_FOR: 'Sin resultados para', // [USING DESCRIPTION] Sin resultados para "Bitcoin",
  NO_CURRENCIES_FOUND: 'No se encontraron monedas.',
  API_ERROR: 'Error de red',
  ERROR_SEEMS_PROBLEM: 'Parece haber un problema',
  ERROR_SOMETHING_BROKE: 'Algo se rompió',
  ERROR_BOUNDARY_TEXT: 'Oops, algo salió mal',
  ERROR_NOTIFICATION_DISALLOWED: 'Por favor, permite el envío de notificaciones para continuar',

  // loans
  CRYPTO_BACKED_LOANS: 'Préstamos respaldados por criptomonedas',
  LOANS_APP_OPERATIONS_DESC: 'Hasta %{ltv}% LTV',

  // vault
  VAULT: 'Caja fuerte',
  VAULT_APP_OPERATIONS_DESC: 'Almacena y asegura tus criptomonedas',
  VAULT_INSURED_COLD_STORAGE_VAULT: 'Almacenamiento en frío asegurado',
  VAULT_BENEFIT_1: 'Seguro de $250 millones para tus Bitcoin y Ethereum',
  VAULT_BENEFIT_2: 'Asegura y almacena de forma segura tus criptomonedas con BitGo',
  VAULT_TOTAL_VALUE: 'Valor total en almacenamiento frío',
  VAULT_WITHDRAW: 'Retirar de la caja fuerte',
  VAULT_WITHDRAW_TEXT:
    'Cuando solicites retirar tus fondos, BitGo realizará una verificación de identidad y generará una transacción que debe firmarse sin conexión mediante tecnología Multi-Sig. Después de que BitGo y Nebeus co-firmen, los fondos se retirarán.<br/><br/>Debido a este riguroso proceso de seguridad, la retirada puede tardar hasta 48 horas en completarse.',
  VAULT_DEPOSIT: 'Depositar en la caja fuerte',
  VAULT_DEPOSIT_TEXT:
    "Tus fondos se enviarán a BitGo y se almacenarán en un dispositivo de almacenamiento en frío ubicado en una caja fuerte de grado bancario Clase III, cubierto por una póliza de seguro de $250 millones de Lloyd's of London.<br/><br/>Los depósitos y retiros están protegidos por verificación de identidad y tecnología Multi-Sig.",
  VAULT_INTRO_TITLE: '¿Qué es una Caja Fuerte de Nebeus?',
  VAULT_INTRO_TEXT_P1:
    "La Caja Fuerte de Nebeus es un dispositivo de almacenamiento en frío custodiado por BitGo, el mayor custodio de criptomonedas del mundo, y asegurado por una póliza de seguro de $250 millones de Lloyd's of London.<br/><br/>" +
    'Después de que deposites fondos en la caja fuerte de Nebeus, se transfieren a BitGo. BitGo luego almacena tus fondos en un dispositivo de almacenamiento en frío y lo coloca físicamente en una caja fuerte de grado bancario Clase III.',
  VAULT_INTRO_TEXT_P2:
    'Los depósitos y retiros están protegidos por verificación de identidad y tecnología Multi-Sig.<br/><br/>' +
    'El seguro protege tus fondos contra hacks de terceros, copia, pérdida, robo de claves privadas, robo interno y default.',
  VAULT_OPERATION_DEPOSITED: 'Depositado en la Caja Fuerte',
  VAULT_OPERATION_WITHDRAW_DONE: 'Retirar %{currencyCode}',
  VAULT_OPERATION_WITHDRAW_DONE_TEXT:
    'Recibirás una notificación por correo electrónico tan pronto como tus fondos lleguen a tu cuenta de Nebeus. El proceso puede tardar hasta 48 horas.',

  // DIGITAL ACCOUNT
  DIGITAL_ACCOUNT: 'Cuenta IBAN',
  CARD: 'Tarjeta',
  DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_TITLE: '¿Deseas cambiar a una suscripción estándar?',
  DIGITAL_ACCOUNT_DOWNGRADE_TO_STD_DESC:
    'Si cambias tu suscripción a una Cuenta Estándar de Nebeus, todas tus tarjetas VISA activas serán cerradas. ¿Estás seguro de que deseas continuar?',
  DIGITAL_ACCOUNT_GET_CARD_BUY_SUB:
    'Para obtener tu tarjeta Nebeus, por favor compra una suscripción de Cuenta Hodler o Cuenta Whale.',
  DIGITAL_ACCOUNT_GET_CARD_UPG_SUB:
    'Para obtener tu tarjeta Nebeus, por favor actualiza tu Cuenta IBAN a una cuenta Hodler o Whale.',

  BANK_CARD_VIRTUAL: 'Tarjeta Virtual',
  DA_BENEFIT_DIGITAL_ACCOUNT_DESC:
    'Tu cuenta IBAN personal para uso diario.</br>' +
    'Una cuenta IBAN para euros y transferencias en EUR.</br>' +
    'Una cuenta Sort Code para libras esterlinas y transferencias en GBP.</br>' +
    'Proporcionada por Nebeus en asociación con Modulr Finance, autorizada por el Banco Central de Holanda.',
  DA_BENEFIT_TRANSFERS: 'Transferencias entre IBANs y Criptomonedas',
  DA_BENEFIT_TRANSFERS_DESC:
    'Transfiere dinero entre tus Cuentas IBAN y Cripto. La Cuenta Estándar de Nebeus no tiene límites temporales en las transferencias entre cuentas.',
  DA_BENEFIT_PAYMENTS: 'Transferencias y Pagos Bancarios',
  DA_BENEFIT_PAYMENTS_DESC:
    'Realiza y recibe transferencias bancarias ilimitadas:</br>' +
    '</br>' +
    'Recibe fondos de terceros, como amigos, familiares o tu empleador.</br>' +
    'Realiza transferencias para pagar tus facturas y facturas.</br>' +
    'Envía dinero a amigos y terceros.</br>',
  DA_BENEFIT_VIRTUAL_CARD: 'Tarjeta Virtual de Nebeus',
  DA_BENEFIT_VIRTUAL_CARD_DESC:
    'Comienza a usar Nebeus como usas tu banco con la tarjeta de débito VISA de Nebeus.</br></br>' +
    'Gasta directamente desde tu Cuenta IBAN sin necesidad de transferir tus ganancias en criptomonedas a otro banco.</br></br>' +
    '<b>La Tarjeta Virtual Nebeus solo está disponible en las Cuentas Hodler y Whale Money.<b/>',
  DA_BENEFIT_PHYSICAL_CARD: 'Tarjeta Física de Nebeus',
  DA_BENEFIT_OTHER: 'Otros Beneficios',
  DA_BENEFIT_COMING_SOON: 'Próximamente.',
  GET_BANK_VIRTUAL_CARD: 'Obtén tu tarjeta virtual gratuita',
  BANK_CARD_FREEZE: 'Congelar',
  BANK_CARD_UNFREEZE: 'Descongelar',
  BANK_CARD_HIDE_DETAILS: 'Ocultar Detalles',
  BANK_CARD_SHOW_DETAILS: 'Mostrar Detalles',
  BANK_CARD_CURRENCY_ACCOUNT: 'Moneda asociada a la cuenta',
  BANK_CARD_CURRENCY: 'Moneda asociada a la Tarjeta',
  BANK_CARD_KBA: 'Preguntas de Seguridad de VISA',
  BANK_CARD_TERMINATE: 'Cancelar Tarjeta',
  BANK_CARD_VIRTUAL_WORD: 'Virtual',
  BANK_CARD_CVC_LONG: 'Código CVC2/CVV2',
  BANK_CARD_BLOCKED_TEXT:
    'Tu tarjeta ha sido bloqueada por Nebeus debido a actividad sospechosa. Por favor, contacta a soporte.',
  BANK_CARD_BLOCKED_BY_PAYMENT_FAILED:
    'El pago automático falló. Por favor, añade fondos a tu Cuenta IBAN.',
  BANK_CARD_GET_NEW: 'Obtener Nueva Tarjeta',
  BANK_CARD_ADVERTISEMENT_TITLE: 'Tu Tarjeta Virtual de <span>Nebeus</span>',
  BANK_CARD_ADVERTISEMENT_DESC: 'Gasta directamente desde tu Cuenta IBAN',
  BANK_CARD_ADVERTISEMENT_DESC_ALT:
    'Gasta directamente desde tu Cuenta IBAN sin necesidad de enviar el dinero a tu cuenta bancaria tradicional',
  BANK_CARD_GET: 'Obtener Tarjeta',
  BANK_CARD_BENEFIT_1_TITLE: 'Conectada a tu Nebeus IBAN',
  BANK_CARD_BENEFIT_1_BUTTON_TITLE: 'Conectada a la Cuenta IBAN',
  BANK_CARD_BENEFIT_1_MODAL_TITLE: 'Conectada a tu Cuenta IBAN de Nebeus',
  BANK_CARD_BENEFIT_1_DESC:
    'Al igual que cualquier otro banco, tu tarjeta VISA Nebeus está conectada a tu Cuenta IBAN en euros. \n' +
    '\n' +
    'Consume directamente desde Nebeus sin necesidad de transferir tus ganancias en criptomonedas a otro banco.',
  BANK_CARD_BENEFIT_2_TITLE: 'Euros o Libras Esterlinas',
  BANK_CARD_BENEFIT_2_DESC:
    'Elige si deseas que tu tarjeta sea en euros o en libras esterlinas.\n' +
    '\n' +
    'Tu tarjeta estará conectada a tu Cuenta IBAN en euros o a tu cuenta con Sort code en libras esterlinas, según la moneda que elijas.',
  BANK_CARD_BENEFIT_3_TITLE: 'Gasta en cualquier moneda FIAT',
  BANK_CARD_BENEFIT_3_MODAL_TITLE: 'Gasta en cualquier Moneda a Nivel Global',
  BANK_CARD_BENEFIT_3_DESC:
    'Gasta en cualquier moneda FIAT a nivel global y obtén las tasas de conversión internacionales de VISA.',
  BANK_CARD_BENEFIT_4_TITLE: 'Tarjetas Virtuales Ilimitadas',
  BANK_CARD_BENEFIT_4_BUTTON_TITLE: 'Tarjeta Virtual Nebeus',
  BANK_CARD_BENEFIT_4_DESC:
    'Tu primera tarjeta virtual es <b>gratuita</b>, pero puedes obtener un número ilimitado de tarjetas virtuales por una tarifa mensual que puedes cancelar en cualquier momento.\n' +
    '\n' +
    'Cada tarjeta adicional tendrá un cargo de \n' +
    '€%{priceAmount}/mes',

  BANK_CARD_KBA_DESCRIPTION:
    'Las Preguntas de Seguridad de VISA son una capa adicional de seguridad proporcionada por VISA, también conocida como Autentificación Basada en el Conocimiento, que se utiliza para verificar que estás realizando las transacciones. Al hacer una transacción, puedes copiar y pegar las respuestas cuando VISA te las solicite.',
  FIRST_PET_NAME: 'Nombre de tu primera mascota',
  MATERNAL_GRANDMOTHER_MAIDEN_NAME: 'Apellido de soltera de tu abuela materna',
  FAVOURITE_CHILDHOOD_FRIEND: 'Amigo/a favorito/a de la infancia',
  FIRST_CAR: 'Primer coche',
  CITY_PARENTS_MET: 'Ciudad donde tus padres se conocieron',

  BANK_CARD_TERMINATE_TITLE: '¿Cancelar Tarjeta?',
  BANK_CARD_TERMINATE_FINAL_TITLE: 'Confirmación final.',
  BANK_CARD_TERMINATE_SUBTITLE: '¿Estás seguro/a de que deseas terminar tu tarjeta Nebeus?',
  BANK_CARD_CLOSED: 'Tarjeta eliminada',
  BANK_CARD_PICK_CURRENCY: 'Elige la moneda asociada a la Tarjeta',
  BANK_CARD_PICK_CURRENCY_DESC:
    'Por favor, elige la moneda y la cuenta a la que deseas conectar tu tarjeta. No podrás cambiar esto en el futuro.',
  BANK_CARD_UNLIMITED_DESC:
    'Tu primera tarjeta virtual fue gratuita, pero puedes obtener un número ilimitado de tarjetas virtuales por %{currency}%{amount}/mes que puedes cancelar en cualquier momento.',

  DA_SUBSCRIPTION_INCREASE_LIMITS: 'Aumentar límites',
  DA_SUBSCRIPTION_STANDARD: 'Estándar',
  DA_SUBSCRIPTION_STANDARD_DESC:
    'Cuenta IBAN en euros o GBP Sort Code para uso diario, inversión y HODLing.',
  DA_SUBSCRIPTION_HODLER: 'Hodler',
  DA_SUBSCRIPTION_HODLER_DESC:
    'Límites de transferencia más altos entre tus cuentas. La mejor opción para los HODLers.',
  DA_SUBSCRIPTION_WHALE: 'Whale',
  DA_SUBSCRIPTION_WHALE_DESC:
    'Transferencias ilimitadas, trading ilimitado, todo ilimitado: <br/>' +
    'la mejor opción para los grandes inversores de criptomonedas.',
  DA_BENEFITS_MONEY_ACCOUNT_TITLE: 'Cuenta IBAN',
  DA_BENEFITS_MONEY_ACCOUNT_DESC:
    'Tu cuenta personal IBAN en euros o GBP Sort code para uso diario, inversión en criptomonedas y HODLing.<br/><br/>' +
    'Recibe pagos de terceros, paga tus facturas, realiza transferencias bancarias y envía fondos al instante a tu cuenta de criptomonedas para invertir en cripto.<br/><br/>' +
    'Utiliza tu Cuenta IBAN de Nebeus como cualquier otra cuenta bancaria.',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO: 'Transferencias IBAN y Cripto',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_MODAL_TITLE:
    'Transferencias entre tu Cuenta IBAN y tu Cuenta de Cripto',

  DA_BENEFITS_BANK_TRANSFERS: 'Transferencias y Pagos Bancarios',
  DA_BENEFITS_BANK_TRANSFERS_DESC:
    'Realiza y recibe transferencias ilimitadas SEPA instantáneas y GBP Faster Payments:<br/><br/>' +
    '● Recibe fondos de terceros como amigos y familiares.<br/>' +
    '● Recibe tu salario en Nebeus y utiliza Nebeus como tal cómo tu banco.<br/>' +
    '● Realiza transferencias para pagar tus facturas y recibos.<br/>' +
    '● Envía dinero a amigos y terceros.',
  DA_BENEFITS_OTHER_PERKS: 'Otros Beneficios',
  DA_BENEFITS_COMING_SOON: 'Próximamente.',
  DA_OPEN_STANDARD_ACCOUNT: 'Abrir Cuenta IBAN Estándar de Nebeus',
  DA_SELECT_ACCOUNT_FOR_SUB_PAYMENT: 'Selecciona la Cuenta IBAN para el pago',
  DA_SUBSCRIPTION_GET_SUCCESS_DESC_DEPOSIT_NEED:
    'Para activar la suscripción, por favor deposita dinero en tu Cuenta IBAN. Nebeus deducirá automáticamente el pago de la suscripción de tu cuenta.',
  DA_SUBSCRIPTION_GET_SUCCESS_DESC:
    'Tu suscripción a la Cuenta IBAN %{subName} está activa. ¡Deposita dinero en tu Cuenta IBAN para comenzar a disfrutar de todos los beneficios!',
  DA_SUBSCRIPTION_STD_OPEN_DESC:
    'Tu Cuenta IBAN Estándar está confirmada. Puedes gestionar tu plan en la configuración y puedes cancelarlo en cualquier momento.',
  DA_SUBSCRIPTION_STANDARD_GET_SUCCESS:
    'Tu Cuenta IBAN personal para uso diario, inversión en cripto y HODLing.',
  DA_SUBSCRIPTION_HODLER_GET_SUCCESS:
    'Tu Cuenta IBAN con límites más altos. La mejor opción para los hodlers de criptomonedas.',
  DA_SUBSCRIPTION_WHALE_GET_SUCCESS:
    'Disfruta de transferencias ilimitadas entre tu Cuenta Cripto y tu Cuenta IBAN para un trading ilimitado.',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_SHORT: 'Verificación pendiente',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_TITLE: 'Se están verificando tus documentos de identidad',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_DESC:
    'Estamos verificando que los documentos que enviaste al abrir tu Cuenta Nebeus cumplan con los requisitos de la Cuenta Nebeus.',
  DA_SUBSCRIPTION_VERIFICATION_PENDING_IMPORTANT:
    'El proceso puede tardar hasta 24 horas. Nuestro equipo de soporte se pondrá en contacto contigo si faltan algunos documentos o necesitan actualizarse.',
  DA_SUBSCRIPTION_DOWNGRADE_TO_STD_IMPORTANT:
    'Estás intentando cambiar a una suscripción inferior. Dado que la tarjeta solo está disponible para las suscripciones Hodler y Whale, primero debes cancelar la tarjeta. Haz clic en el botón para cancelar la tarjeta.',
  DA_SUBSCRIPTION_CANCEL_QUESTION: '¿Cancelar la suscripción?',
  DA_SUBSCRIPTION_CANCEL_DESC:
    'Si cancelas ahora, tu suscripción seguirá activa hasta el final de tu mes pagado: %{endDate}.',
  DA_SUBSCRIPTION_WILL_BE_CLOSED: 'La suscripción actual será cancelada',
  DA_SUBSCRIPTION_CANCELLED: 'Suscripción cancelada',
  DA_SUBSCRIPTION_CANCELLED_DESC:
    'Has cancelado con éxito tu suscripción. Seguirá activa hasta el final de tu mes pagado: %{endDate}.',

  // loans
  LOAN: 'Préstamo',
  LOANS: 'Préstamos',
  LOANS_QUICK: 'Préstamo rápido',
  LOANS_QUICK_SHORT: 'Rápido',
  LOANS_FLEX_SHORT: 'Flexible',
  LOANS_FLEX: 'Préstamo flexible',
  LOANS_COLLATERAL_FIELD_LABEL: 'Necesito un préstamo en',
  LOANS_COLLATERAL: 'Colateral',
  LOAN_PAYMENTS: 'Pagos',
  LOANS_TOTAL_REPAYMENT: 'Reembolso total del préstamo',
  LOANS_INTEREST_RATE: 'Tasa de interés',
  LOANS_LTV: 'LTV',
  LOANS_INTEREST_LTV_FULL: 'Loan-To-Value o relación valor-préstamo',
  LOANS_LOAN_TERM: 'Plazo del préstamo',
  LOANS_COLLATERAL_AMOUNT_TYPE: 'Monto y tipo de colateral',
  LOANS_LOAN_EXCHANGE_RATE: 'Tasa de cambio del préstamo',
  LOANS_LOAN_ORIGINATION_FEE: 'Comisión de origen del préstamo',
  LOANS_EARLY_REPAYMENT_OPTION: 'Opción de pago anticipado',
  LOANS_LOAN_AMOUNT: 'Monto del préstamo',
  LOANS_TOTAL_COST: 'Costo total del préstamo (intereses totales)',
  LOANS_GET_LOAN: 'Confirmar',
  LOANS_ZERO_RISK_LEARN_ABOUT: 'Aprende sobre nuestros préstamos sin riesgo',
  LOANS_FAQ_TEXT:
    '<b>Préstamos respaldados por criptomonedas de Nebeus</b><br/>' +
    'No vendas tus criptomonedas. Úsalas como colateral para obtener un préstamo respaldado por criptomonedas que puedes usar para tus gastos diarios o necesidades a largo plazo más ambiciosas.<br/><br/>' +
    '<b>Préstamos rápidos</b><br/>' +
    'Los préstamos rápidos de Nebeus son préstamos en efectivo al 0% de interés y con un LTV del 50% durante 3 meses. La única comisión que cobramos es una comisión de origen del préstamo del 2.5% al emitir tu préstamo. Luego puedes pagar tu préstamo en cualquier momento y de forma gratuita.<br/><br/>' +
    '<b>Préstamos flexibles</b><br/>' +
    'Los préstamos flexibles de Nebeus son préstamos que puedes personalizar según tus necesidades individuales. Puedes obtener hasta 250,000 en moneda fiduciaria o Stablecoins durante un plazo de hasta 36 meses. Puedes elegir tu LTV, aumentándolo hasta un máximo del 80%, y tu tasa de interés del préstamo se ajustará automáticamente según el LTV que elijas.',

  LOANS_ZERO_RISK_TITLE: 'Préstamos sin riesgo',

  LOANS_EXCESS_RELEASE: 'Liberación de Colateral Excedente',
  LOANS_EXCESS_RELEASE_DESC:
    '<b>Liberación de Colateral Excedente</b><br/>Nebeus supervisará la volatilidad del mercado y liberará automáticamente parte de tu colateral utilizado para tu préstamo si los precios de los criptomonedas aumentan más del 10%. Esta es una excelente opción si estás solicitando un préstamo a largo plazo.',

  LOANS_AUTO_MC: 'Gestión Automática de Llamadas de Margen',
  LOANS_AUTO_MC_DESC:
    '<b>Gestión automática de Llamada de Margen</b><br/>Nebeus supervisará la volatilidad del mercado y repondrá automáticamente el colateral de tu préstamo desde tu cuenta principal de Nebeus cuando te acerques a una Llamada de Margen. Al activar esta opción, asegúrate de tener siempre fondos adicionales en tu cuenta principal de Nebeus, en la moneda que estés utilizando como colateral para tu préstamo.<br/>' +
    '<br/>' +
    'Además, tendrás 3 días para salvar tus criptomonedas en caso de una Llamada de Margen. Nebeus repondrá automáticamente el colateral de tu préstamo desde tu cuenta principal de Nebeus en un plazo de 10 días después de una Llamada de Margen. Tus criptomonedas estarán a salvo y tu préstamo continuará como siempre.<br/>' +
    '<br/>' +
    'Esta opción te brinda tranquilidad y protección contra la volatilidad.',
  LOANS_ONLY_INTEREST_REPAYMENT: 'Pago de intereses',
  LOANS_ONLY_INTEREST_REPAYMENT_DESC:
    '<b>Pago de intereses</b><br/>Paga solo los intereses de tu préstamo cada mes y paga el capital del préstamo al final del plazo del préstamo o cuando desees cerrar tu préstamo.<br/>' +
    '<br/>' +
    '💡 Esta opción es excelente si deseas obtener un préstamo para diversificar tu cartera de inversiones invirtiendo tu préstamo en otro activo.',
  LOAN_FIELD_DESC:
    'Para los Préstamos de Nebeus, puedes pedir prestado entre %{minAmount} y %{maxAmount} en moneda FIAT o Stablecoins durante un máximo de 36 meses.',
  COLLATERAL_FIELD_DESC:
    '<b>¿Qué es el colateral?</b><br/>El colateral es la criptomoneda que utilizas para obtener tu préstamo. Después de obtener tu préstamo y durante el plazo del préstamo, tu colateral cripto se mantiene en Nebeus como un depósito de seguridad para emitir tu préstamo. <br/>' +
    '<br/>' +
    'Además, tu colateral estará cubierto por una póliza de seguro de $100 millones de Lloyd’s of London durante todo el período de tu préstamo.',
  LTV_FIELD_DESC:
    '<b>¿Qué es una relación LTV?</b><br/>El LTV determina el tamaño del préstamo que puedes obtener en relación con la cantidad de colateral de criptomonedas que depositas. Entonces, si depositas 1000€ en criptomonedas y eliges una relación LTV del 80%, obtendrás un préstamo de 800€. Cuanto mayor sea la relación LTV, mayor será el monto.',
  LOAN_AGREEMENT: 'Acuerdo de préstamo',
  LOAN_AGREEMENT_IMPORTANT_TEXT:
    'Al aceptar, aceptas la Información Precontractual y el Acuerdo de Préstamo. ',
  LOAN_AGREEMENT_DESC:
    'Después de aceptar, te enviaremos tu copia del Acuerdo de Préstamo a tu correo electrónico.',
  LOAN_AGREEMENT_PRE_CONTRACT_INFO: 'Información precontractual',
  LOAN_GET_SUCCESS_TITLE: '¡Acabas de obtener un préstamo!',
  LOAN_GET_SUCCESS_SUBTITLE:
    'Acabas de confirmar tu préstamo respaldado por criptomonedas de Nebeus, y tus fondos ya están en tu cuenta de Nebeus. ',
  LOAN_ID: 'ID del préstamo',
  LOANS_PAYMENTS_COLLATERAL: 'Pagos y garantía',
  LOAN_MC_RATE: 'Tasa de llamada de margen',
  LOANS_REPAY: 'Reembolsar préstamo',
  LOANS_ADD_COLLATERAL: 'Agregar garantía',
  LOANS_OUTSTANDING_BALANCE: 'Saldo pendiente',
  LOANS_LIQUIDATION_RISK: 'Riesgo de liquidación',
  LOANS_LIQUIDATION_RISK_DANGER: 'Peligroso',
  LOANS_LIQUIDATION_RISK_HEALTHY: 'Saludable',
  LOANS_LIQUIDATION_RISK_AVERAGE: 'Promedio',
  LOANS_AUTO_MC_SHORT: 'Gestión automática de llamadas de margen',
  LOANS_MARKETING_AUTO_MC_DESC:
    'Obtén tu préstamo y deja que Nebeus supervise automáticamente la volatilidad de las criptomonedas',
  LOANS_MARKETING_FIAT_AND_STABLECOINS_TITLE:
    '<span>Préstamos</span> en FIAT y criptomonedas estables',
  LOANS_MARKETING_FIAT_AND_STABLECOINS_DESC: 'Hasta 250,000 en cualquier moneda',
  LOANS_MARKETING_SAVE_LOAN_TITLE: '3 días para salvar<br/> tu préstamo',
  LOANS_MARKETING_SAVE_LOAN_DESC:
    'Préstamos sin riesgo: recupera tus criptomonedas<br/> en caso de llamada de margen',
  LOANS_MARKETING_FLEX_LOANS_TITLE:
    '<span>Préstamos flexibles:</span><br/>' + 'Hasta 80% LTV y <br/>' + 'hasta 36 meses',
  LOANS_MARKETING_QUICK_LOANS_TITLE:
    '<span>Préstamos rápidos:</span><br/>' + '0% de interés a 50% LTV \n' + 'durante 3 meses',
  LOANS_MARKETING_EXCESS_RELEASE_DESC:
    'Recupera tu garantía si el precio de las criptomonedas aumenta más del 10%',
  LOANS_NUMBER: 'Número de préstamos',
  LOANS_NO_ACTIVE_ITEMS: 'Ups, no tienes préstamos activos',
  LOANS_NO_ACTIVE_ITEMS_SUBTITLE:
    'No tienes ningún préstamo activo, pero puedes obtener uno siguiendo unos simples pasos.',
  LOANS_NO_HISTORY_ITEMS: 'Sin historial por el momento',
  LOANS_NO_HISTORY_ITEMS_SUBTITLE:
    'Pero todo puede cambiar, y puedes empezar a hacer historia si obtienes un préstamo ahora.',
  LOANS_DOWNLOAD_STATEMENT: 'Descargar declaración del préstamo',
  LOANS_PAYMENTS_LEFT: 'Pagos restantes',
  LOANS_MONTHLY_REPAYMENT: 'Pago mensual',
  LOANS_ISSUED: 'Préstamo emitido',
  LOANS_MANUAL_PAYMENT: 'Pago manual',
  LOAN_MOVEMENT: 'Movimiento',
  LOANS_COLLATERAL_ADDED: 'Garantía agregada',
  LOANS_COLLATERAL_ADDED_MANUALLY: 'Garantía agregada manualmente',
  LOANS_COLLATERAL_INITIAL_PAYMENT: 'Pago inicial',
  LOANS_COLLATERAL_LIQUIDATED_BY_MC: 'Liquidación por llamada de margen',
  LOANS_COLLATERAL_PAYOUT_BACK: 'Pago de reembolso',
  LOANS_REPAYMENT: 'Reembolso del préstamo',
  LOANS_EARLY_REPAYMENT_FEE: 'Comisión por reembolso anticipado',
  LOANS_OUTSTANDING_INTEREST_RATE_PAYMENT: 'Pago pendiente de tasa de interés',
  LOANS_OUTSTANDING_FINES_FOR_LATE: 'Multa pendiente por reembolso tardío',
  LOANS_OTHER_OUTSTANDING_LATE_PAYMENTS: 'Otros pagos atrasados pendientes',
  LOANS_REPAY_FULL_AMOUNT: 'Reembolsar el monto total',
  LOANS_REPAID_PART: 'Pago recibido',
  LOANS_REPAID_PART_DESC:
    'Acabas de reembolsar una parte del saldo pendiente de tu préstamo y hemos recibido el pago correctamente. ',
  LOANS_REPAID: 'Reembolsado',
  LOANS_REPAID_FULL: 'Préstamo reembolsado',
  LOANS_REPAID_FULL_DESC:
    'Acabas de reembolsar el saldo total y pendiente de tu préstamo. Tu préstamo está ahora cerrado y tu garantía será devuelta a tu cuenta de Nebeus.',
  LOANS_TOTAL_OUTSTANDING_BALANCE: 'Saldo total pendiente',
  LOANS_REPAY_LOAN: 'Reembolsar préstamo',
  LOANS_REPAY_LESS_90_PERCENT: 'Por favor, reembolsa menos del 90% del monto o el monto total.',
  LOANS_OUTSTANDING_BALANCE_INFO:
    '<b>Saldo pendiente total</b><br/>El saldo pendiente total incluye el reembolso pendiente del préstamo, el pago restante de la tasa de interés y cualquier multa por reembolso tardío que haya ocurrido.',
  LOANS_ADD_LOAN_COLLATERAL_REDUCE_RISK: 'Reducir el riesgo de liquidación',
  LOANS_ADD_LOAN_COLLATERAL: 'Agregar garantía de préstamo',
  LOANS_CURRENT_COLLATERAL: 'Garantía actual',
  LOANS_CURRENT_LIQ_RISK: 'Riesgo de liquidación actual',
  LOANS_COLLATERAL_APPROACHING_TO_MC_DESC:
    'Los mercados son muy volátiles en este momento, y tu préstamo está acercándose a una llamada de margen. Por favor, agrega la cantidad mínima recomendada como garantía para reducir el riesgo de liquidación de tu préstamo. Si no lo haces, tu préstamo podría ser liquidado si los precios de las criptomonedas siguen cayendo.',
  LOANS_ADD_COLLATERAL_NOTE:
    'Nota: El riesgo de liquidación se basa en datos de mercado actuales y puede cambiar dependiendo de las condiciones del mercado.',
  LOANS_ADD_COLLATERAL_MANUAL_FAQ_TITLE: 'Agregar garantía de préstamo',
  LOANS_ADD_COLLATERAL_MANUAL_FAQ_DESC:
    'Al agregar manualmente garantía de préstamo, reduces el riesgo de que tu préstamo entre en una llamada de margen cuando los mercados de criptomonedas son volátiles.',
  LOANS_LOAN_IS_HEALTHY: '¡Tu préstamo está saludable!',
  LOANS_ADD_COLLATERAL_HEALTHY_DESC:
    'Acabas de reducir el riesgo de liquidación de tu préstamo, y ahora tu préstamo está saludable.',
  LOANS_ADD_COLLATERAL_BELOW_MIN_DESC:
    'Acabas de agregar correctamente garantía a tu préstamo, pero la cantidad agregada está por debajo del mínimo recomendado.<br/><br/>Recomendamos agregar un adicional de ' +
    '<span>%{amountLabel}</span> para reducir el riesgo de liquidación.',
  LOANS_ADD_COLLATERAL_CONFIRM_DESC:
    'Estás a punto de agregar garantía a tu préstamo. No podrás retirar tu garantía de préstamo hasta el final del plazo de tu préstamo o hasta que cierres tu préstamo manualmente.',
  LOANS_ADD_COLLATERAL_FUNDS_FLOW: 'Flujo de fondos',
  LOANS_RISK_REDUCED: 'Riesgo reducido',
  LOANS_COLLATERAL_ADDED_TITLE: '¡Se agregó garantía de préstamo <span>exitosamente!</span>',
  LOANS_COLLATERAL_ADDED_DESC:
    'Acabas de agregar garantía de préstamo a tu préstamo y has reducido el riesgo de que tu préstamo entre en una llamada de margen cuando los mercados de criptomonedas son volátiles.',

  LOANS_LOAN_UNDER_MC: 'Tu préstamo está en una llamada de margen',
  LOANS_LOAN_UNDER_MC_SUBTITLE: 'Tienes %{days} días para salvar tu préstamo.',
  LOANS_LOAN_AUTO_PAYMENT_FAILED: 'Pago automático fallido',
  LOANS_LOAN_AUTO_PAYMENT_FAILED_DESC:
    'El pago automático del préstamo ha fallado porque no tienes suficientes fondos en tu cuenta. Por favor, deposita la cantidad requerida para que Nebeus pueda cobrar automáticamente el pago de tu cuenta.',
  LOANS_LOAN_AUTO_PAYMENT_FAILED_SUBTITLE:
    'Deposita fondos en tu cuenta %{currencyLabel} o reembolsa manualmente tu préstamo.',
  LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED: 'Pago automático exitoso',
  LOANS_LOAN_AUTO_PAYMENT_SUCCEEDED_SUBTITLE: 'Cargo exitoso de pago de préstamo.',

  LOANS_LOAN_MC_AVOIDED_TITLE: 'Tu préstamo ha sido salvado',
  LOANS_LOAN_MC_AVOIDED_SUBTITLE: 'Se ha evitado una llamada de margen.',

  LOANS_LOAN_UNDER_MC_DESC:
    'Tu préstamo está en una llamada de margen. Tienes %{days} días para pagar el monto total de tu préstamo o agregar colateral. <br/>' +
    '<br/>' +
    'Si no lo haces, tus criptomonedas podrían ser liquidadas si los precios de las criptomonedas no aumentan en un plazo de 3 días después de ingresar a la llamada de margen. Después de la liquidación, el colateral excedente será devuelto a tu cuenta de Nebeus.',
  LOANS_LOAN_UNDER_MC_AUTO_MANAGEMENT_DESC:
    'Tu préstamo está en una llamada de margen y no tienes suficientes fondos en tu cuenta de Nebeus en %{currencyLabel}. Tienes %{days} días para salvar tu préstamo depositando el mínimo recomendado en tu cuenta en %{currencyCode}, de modo que Nebeus pueda reponer automáticamente el colateral de tu préstamo. Tus criptomonedas se salvarán y tu préstamo continuará como de costumbre.<br/>' +
    '<br/>' +
    'Si lo deseas, también puedes pagar la totalidad de tu préstamo y tus criptomonedas se salvarán.\n' +
    '<br/>' +
    'Si no lo haces, tus criptomonedas podrían ser liquidadas si los precios de las criptomonedas no aumentan en un plazo de 3 días después de ingresar a la llamada de margen.',
  LOANS_MC_AVOIDED: 'Llamada de margen evitada',
  LOANS_MC_AVOIDED_AUTO_DESC:
    'Nebeus ha reemplazado automáticamente el colateral de tu préstamo. ¡Tu préstamo y tus criptomonedas se han salvado!',
  LOANS_MC_AVOIDED_DESC:
    'Nebeus ha monitoreado la volatilidad del mercado de criptomonedas y los precios han vuelto a subir. Tu llamada de margen se ha cancelado automáticamente y tu préstamo y criptomonedas se han salvado.',
  LOANS_TEMPLATE_IS_MISSING:
    'Falta la plantilla. Por favor, contacta a nuestro soporte para solucionarlo',

  // Smart trader
  SMART_TRADER: 'Smart Trader',
  SMART_TRADER_WELCOME: 'Bienvenido a Nebeus Smart Trader',
  SMART_TRADER_DESCRIPTION:
    'Nebeus Smart Trader te permite operar con criptomonedas y definir tu <b>take profit y stop loss spread</b> para que Nebeus te informe sobre las fluctuaciones de precios, permitiéndote operar sin tener que monitorear el mercado cada minuto.',
  SMART_TRADER_WELCOME_SUBTITLE:
    'Nebeus te informará cuando sea el momento de comprar o vender, pero no comprará o venderá automáticamente por ti.',
  SMART_TRADER_PICK_ASSETS_TITLE:
    '¿Te gustaría comprar %{currencyLabel} o usar tus activos existentes?',
  SMART_TRADER_PICK_ASSETS_SUBTITLE:
    '¿Quieres usar tus criptomonedas existentes para activar Smart Trader o comprar %{currencyLabel} para comenzar?.',
  SMART_TRADER_USE_EXISTING_ASSETS: 'Usar Activos Existentes',
  SMART_TRADER_SELECT_PAIR_TITLE: 'Selecciona tu par de trading',
  SMART_TRADER_SELECT_PAIR_SUBTITLE: 'Selecciona tu par de trading y el valor que deseas operar.',
  SMART_TRADER_INSUFFICIENT_FUNDS:
    'Parece que no tienes %{currencyLabel} en tu cuenta de Nebeus. Por favor, deposita %{currencyLabel} para comenzar a usar Nebeus Smart Trader.',
  SMART_TRADER_CONFIGURE_TITLE: 'Configura tu operación',
  SMART_TRADER_CONFIGURE_SUBTITLE:
    'Establece tus objetivos de take profit y stop loss para que Nebeus pueda monitorear la volatilidad del mercado e informarte sobre los cambios.',
  SMART_TRADER_YOUR_FUNDS: 'Tus fondos',
  SMART_TRADER_YOUR_PROFIT: 'Tu ganancia',
  SMART_TRADER_YOUR_LOSS: 'Tu pérdida',
  SMART_TRADER_TAKE_PROFIT: 'Take profit',
  SMART_TRADER_STOP_LOSS: 'Stop loss',
  SMART_TRADER_CURRENT_VALUE_IN: 'Valor actual en %{currencyCode}',
  SMART_TRADER_MAX_LOSS: 'Pérdida máxima',
  SMART_TRADER_TARGET_PROFIT: 'Ganancia objetivo',
  SMART_TRADER_ACTIVATED_TITLE: 'Smart Trader Activado',
  SMART_TRADER_ACTIVATED_SUBTITLE:
    'Has seleccionado exitosamente tus niveles de trading. Nebeus Smart Trader está ahora activado y está monitoreando los cambios en el mercado. \n\nConsulta tu panel de control para mantenerte actualizado, y Nebeus también te enviará notificaciones sobre los cambios.',
  SMART_TRADER_CHANGE_SPREAD: 'Cambiar Take Profit / Stop Loss',
  SMART_TRADER_TRADE_CREATED: 'Smart Trader iniciado',
  SMART_TRADER_BUY_OPERATION: ' %{currencyCode} Comprado',
  SMART_TRADER_SELL_OPERATION: '%{currencyCode} Vendido',
  SMART_TRADER_STOP_LOSS_TRIGGERED: 'Pérdida máxima alcanzada',
  SMART_TRADER_TAKE_PROFIT_TRIGGERED: 'Ganancia objetivo alcanzada',
  SMART_TRADER_STOP_LOSS_TAKE_PROFIT_CHANGED: 'Niveles de spread cambiados',
  SMART_TRADER_EXCHANGE_SUBTITLE:
    'Intercambia instantáneamente criptomonedas que deseas utilizar para Nebeus Smart Trader.',
  SMART_TRADER_EXCHANGE_SUCCESS_SUBTITLE:
    'Tu intercambio se ha confirmado, y tus criptomonedas están listas para ser utilizadas con Nebeus Smart Trader.',
  SMART_TRADER_SETUP: 'Configurar Nebeus Smart Trader',

  CRYPTO_BANK_CARD_ORDER_TITLE: 'Solicitar una tarjeta Nebeus',
  CRYPTO_BANK_CARD_SHIPPING_DETAILS: 'Detalles del envío',
  CRYPTO_BANK_CARD_SHIPPING_COUNTRY: 'País de envío',
  CRYPTO_BANK_CARD_SHIPPING_COUNTRY_PLACEHOLDER: 'País de envío',
  CRYPTO_BANK_CARD_SHIPPING_CHOOSE_METHOD: 'Elige tu método de envío',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_2: 'Recarga instantánea con Stablecoins',
  BANK_CARD_FEATURES: 'Características de la tarjeta',
  CRYPTO_BANK_CARD_FEATURE_1: 'Vincula Múltiples Criptomonedas',
  CRYPTO_BANK_CARD_FEATURE_2: 'Recarga instantánea con Stablecoins',
  CRYPTO_BANK_CARD_FEATURE_3: 'Moneda Designada',
  CRYPTO_BANK_CARD_FEATURE_4: 'Límites de gasto flexibles para controlar tus gastos',
  CRYPTO_BANK_CARD_FEATURE_5: 'Retiros de efectivo gratuitos en cajeros automáticos',
  CRYPTO_BANK_CARD_FEATURE_2_TITLE: 'Recarga instantánea con Stablecoins',
  CRYPTO_BANK_CARD_FEATURE_2_DESC:
    'Disfruta de la comodidad de recargar al instante tu tarjeta con stablecoins como USDT (Tether) y USDC (USD Coin).',
  CRYPTO_BANK_CARD_FEATURE_3_TITLE: 'Moneda Designada',
  CRYPTO_BANK_CARD_FEATURE_3_DESC:
    'Selecciona la moneda de tu tarjeta y elige entre una gama de opciones que incluyen EUR, USD y GBP, según tus gustos y necesidades personales.',
  CRYPTO_BANK_CARD_FEATURE_4_TITLE: 'Límites de gasto flexibles para controlar tus gastos',
  CRYPTO_BANK_CARD_FEATURE_4_DESC:
    'Aprovecha los límites de gasto diario de hasta 5,000 EUR, USD o GBP, y los límites mensuales de hasta 35,000 EUR, USD o GBP, para tener un mayor control sobre tus gastos.',
  CRYPTO_BANK_CARD_FEATURE_5_TITLE: 'Retiros de efectivo gratuitos en cajeros automáticos',
  CRYPTO_BANK_CARD_FEATURE_5_DESC:
    'Disfruta de la comodidad de que los primeros 200 EUR, USD o GBP en retiros de cajeros automáticos sean gratuitos.\n\nAccede fácilmente a tus fondos con un límite de retiro mensual de 2,000 EUR, USD o GBP.',

  SIGN_SEND_OTP_BY_PHONE_TITLE: 'Mejora de seguridad',
  SIGN_SEND_OTP_BY_PHONE_SUBTITLE: '¿Te gustaría recibir los códigos OTP por <b>teléfono</b>?',
  SIGN_SEND_OTP_BY_PHONE_NUMBER_ATTACHMENT:
    'Los códigos temporales de acceso serán enviados a tu teléfono: <b>%{phoneNumber}</b>',
  SIGN_UP_ENTER_NAME_TITLE: '¿Cuál es tu nombre?',
  SIGN_UP_ENTER_NAME_SUBTITLE:
    'Esta información se utilizará más adelante para verificar tu identidad.',
  SIGN_IN_OTP_PHONE_SENT: 'Contraseña de un solo uso enviada a tu teléfono',
  PLACEHOLDER_DATE_FORMAT_1: 'DD.MM.AAAA',
  CONFIRMED: 'Confirmado',
  LIFETIME: 'Vitalicia',
  SELECT_CURRENCY: 'Seleccionar moneda',
  RECIPIENTS_NAME: 'Nombre del Destinatario',
  COST: 'Costo',
  SENDER: 'Remitente',
  RECIPIENTS_ACCOUNT_NUMBER: 'Número de cuenta del destinatario',
  RECIPIENTS_BANK_CARD_NUMBER: 'Número de tarjeta bancaria del destinatario',
  REQUEST_BUSINESS_ACCOUNT: 'Solicitar cuenta para empresa',
  NOT_LIMITED: 'Sin límite',
  TEMPORARILY_UNAVAILABLE: 'Temporalmente no disponible',
  ACKNOWLEDGE_AND_CLOSE: 'Aceptar y Cerrar',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  DATE_OF_BIRTH: 'Fecha de nacimiento',
  PHONE_NUMBER: 'Número de teléfono',
  CITY: 'Ciudad',
  SURNAME: 'Apellido',
  EDIT: 'Editar',
  REMOVE: 'Eliminar',
  FULL_NAME: 'Nombre completo',
  EMAIL: 'Correo electrónico',
  COUNTRY: 'País',
  ADDRESS: 'Dirección',
  DATE_FROM: 'Fecha desde',
  DATE_TO: 'Fecha hasta',
  DELIVERY: 'Entrega',
  TOTAL_AMOUNT: 'Monto total',
  TOTAL_AMOUNT_IN_CURRENCY: 'Monto total en %{currencyCode}',
  SELECT_ACCOUNT_FOR_PAYMENT: 'Seleccionar cuanta para el pago',
  EXPLORE: 'Explorar',
  CARD_ISSUE: 'Emisión de tarjeta',
  ORDER_NOW: 'Pedir ahora',
  VALIDATION_NO_SPACES: 'El campo no debe contener espacios',
  VALIDATION_NAME_TWO_WORDS: 'El nombre completo debe tener al menos dos palabras',
  VALIDATION_MAX: 'El campo debe contener como máximo %{max} caracteres',
  VALIDATION_ONLY_LETTERS: '%{fieldName} solo puede contener letras',
  VALIDATION_ONLY_LETTERS_AND_NUMBERS: '%{fieldName} solo puede contener letras y números ',
  VALIDATION_PHONE_INVALID: 'Número de teléfono no válido',
  PROFILE_SAVED_CRYPTO_WALLETS_EDIT_TITLE: 'Editar tu billetera digital',
  PROFILE_SAVED_CRYPTO_WALLETS_EDIT_SUBTITLE:
    'Actualiza aquí tu dirección de billetera digital para guardarla en tu cuenta de Nebeus.',
  VERIFICATION_SUBTITLE: 'Necesitas verificar tu identidad antes de usar Nebeus',
  VERIFICATION_ALREADY_VERIFIED: 'Ya estás verificado',
  VERIFICATION_NOT_VERIFIED: 'No verificado',
  ACCOUNT_STATEMENT_DOWNLOAD: 'Descargar extracto de cuenta (.pdf)',
  ACCOUNT_STATEMENT_DESC:
    'Descarga un extracto de cuenta que muestra todos los movimientos de fondos de una moneda específica en un período de tiempo seleccionado. Puedes descargar el estado de cuenta en formato PDF.',
  ACCOUNT_TRANSACTION_STATUS_CANCELLED: 'Cancelado',
  PAYMENT_DEPOSIT_BANK_CARD_TO_TRADING:
    'Tu transferencia se está procesando. <br/><br/>Ten en cuenta que pueden pasar hasta 72 horas para que los fondos aparezcan en tu cuenta de Nebeus.',
  PAYMENT_SEND_FROM_TRADING_TO_BANK_CARD_SUCCESS:
    'Tu transferencia se está procesando. <br/><br/>Ten en cuenta que este proceso a veces puede retrasarse si tu banco no trabaja con tecnología de pago directo.',
  PAYMENT_BENEFICIARY: 'Beneficiario',
  PAYMENT_BENEFICIARY_ACCOUNT: 'Cuenta del beneficiario',
  PAYMENT_BENEFICIARY_BANK: 'Banco del beneficiario',
  PAYMENT_BENEFICIARY_BANK_SWIFT: 'SWIFT del banco del beneficiario',
  PAYMENT_INTERMEDIARY_BANK: 'Banco intermediario',
  PAYMENT_INTERMEDIARY_BANK_SWIFT: 'SWIFT del banco intermediario',
  PAYMENT_DETAILS: 'Detalles del pago',
  PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE:
    'Por favor, incluye los siguientes detalles de pago al iniciar tu transferencia',
  PAYMENT_REFERENCE_CODE_INCLUDE_MESSAGE_DESC:
    'Al iniciar tu transferencia, es importante incluir los detalles de pago proporcionados. No incluir el código de referencia de la transferencia puede causar retrasos en el proceso. Tu atención a este detalle contribuirá a un proceso más rápido.',
  PAYMENT_SAVED_WALLETS_SUCCESS_REMOVED: 'Billetera digital eliminada',
  PAYMENT_SAVED_WALLETS_SUCCESS_EDIT: 'Billetera digital editada',
  PAYMENT_SEND_TO_WIRE_SUCCESS_MESSAGE:
    'Has enviado con éxito %{currencyAmountLabel} a %{accountName}<br/><br/>Ten en cuenta que las transferencias bancarias pueden tardar hasta 2 días hábiles en llegar y ser procesadas por el banco del receptor.',
  CRYPTO_NEWS: 'Noticias Cripto',
  ERROR_BANK_CARD_SEND:
    'Hay problemas con tu tarjeta bancaria. Inténtalo de nuevo o contacta con el soporte de Nebeus',
  ERRORS_INVALID_DATE: 'Fecha no válida',
  ERRORS_DATE_PERIOD_YEAR: 'El período no puede ser más largo que un año',
  ERRORS_DATE_FUTURE: 'No se permite una fecha en el futuro',
  VAULT_INSURED: 'Almacenamiento en frío asegurado',
  VAULT_INSURED_DESC: 'Almacenamiento en frío con un seguro de $250M.',
  DIGITAL_ACCOUNT_REQUESTED: 'solicitado',
  DA_SUBSCRIPTION_REQUESTED_TITLE: 'Tu aplicación ha sido aceptada',
  DA_SUBSCRIPTION_REQUESTED_DESC: 'Necesitamos algo de tiempo para crear una Cuenta IBAN para ti.',
  DIGITAL_ACCOUNT_CANCEL_SUBSCRIPTION_BANK_CARD_WILL_BE_CLOSED:
    'Si cancelas tu suscripción, todas tus tarjetas Nebeus VISA activas se cerrarán automáticamente. ¿Estás seguro de que deseas continuar?',
  BANK_CARD_FROZEN: 'Tarjeta congelada',
  BANK_CARD_BLOCKED: 'Tarjeta bloqueada',
  LOAN_FIELD_TITLE: '¿Cuánto puedes pedir prestado?',
  COLLATERAL_FIELD_TITLE: '¿Qué es el colateral?',
  LTV_FIELD_TITLE: '¿Qué es la relación LTV?',
  RECEIVE_OTP_BY_EMAIL: 'Recibir códigos de confirmación por correo electrónico',
  RECEIVE_OTP_BY_PHONE: 'Recibir códigos de confirmación por SMS',
  CLOSE_ACCOUNT_FINAL_TITLE: 'Confirmación final...',
  CLOSE_ACCOUNT_FINAL_SUBTITLE: '¿Deseas cerrar tu cuenta de Nebeus?',
  CLOSE_ACCOUNT_TITLE: 'Nos da pena que te vayas...',
  CLOSE_ACCOUNT_SUBTITLE: '¿Estás seguro de que quieres cerrar tu cuenta de Nebeus?',
  CLOSE_ACCOUNT_CHECK_LIST_TITLE: 'Antes de cerrar tu cuenta...',
  CLOSE_ACCOUNT_CHECK_LIST_SUBTITLE: 'Asegúrate de confirmar todo lo siguiente:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE: 'Confirma que:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_TITLE2: 'Cuando cierres tu cuenta:',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_1_TEXT:
    '&#x2022; No hay préstamos activos;<br/>&#x2022; No hay programas de cripto-renting activos;<br/>&#x2022; No hay cripto haciendo staking;<br/>&#x2022; No hay cripto en el almacenamiento en frío de Nebeus.',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_2_TEXT:
    '&#x2022; Retirado todas las criptomonedas a una billetera externa;<br/>&#x2022; Retirado todo tu dinero fiat a tu cuenta bancaria o tarjeta bancaria.',
  CLOSE_ACCOUNT_CHECK_LIST_BLOCK_3_TEXT:
    '&#x2022; Todas las suscripciones activas (si las hay) se cancelarán y cerrarán;<br/>&#x2022; Si quedan fondos cripto o fiat en Nebeus, podrían perderse.',
  WATCH_LIST: 'Lista de seguimiento',
  WATCH_LIST_EMPTY_TITLE: 'Tu lista de seguimiento está vacía',
  WATCH_LIST_EMPTY_SUBTITLE: 'Comienza por agregar tus monedas favoritas aquí',
  EXCHANGE_MARKETS: 'Mercados',
  TRADE_DIRECTION_INVALID: 'El par de intercambio no está permitido',
  USER_NOT_FOUND: 'Usuario no encontrado',
  TRADE_WITH_THIS_CURRENCY_ALREADY_EXIST: 'El intercambio con esta moneda ya existe',
  TRADE_CREATED: 'Intercambio creado',
  STOP_LOSS_OR_TAKE_PROFIT_AMOUNT_MISMATCH:
    'Cantidad de stop loss o take profit incorrecta. Por favor, verifica los valores',
  TRADE_NOT_FOUND: 'Intercambio no encontrado',
  CRYPTO_BANK_CARD_SHIPPING_CITY: 'Ciudad de envío',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_3: 'Límites de gasto flexibles y más',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_TITLE: 'Tu tarjeta Nebeus está esperando confirmación',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_FAILED_TITLE:
    'Lo sentimos, se ha rechazado tu solicitud de tarjeta',
  CRYPTO_BANK_CARD_FEATURE_6_TITLE: 'Retiros gratuitos en cajeros automáticos',
  CRYPTO_BANK_CARD_FEATURE_6_DESC:
    'Disfruta de la conveniencia de los primeros 200 EUR, USD o GBP en retiros en cajeros automáticos sin cargo.',
  LOANS_TOTAL_COLLATERAL: 'Colateral total de préstamos',
  FEE_AND_LIMITS: 'Comisiones y Límites',
  AND: 'y', // Context:  Terms of use and Fees and Limits

  TERMS_OF_BUSINESS: 'Términos del Negocio',
  PLACEHOLDER_CVC_CODE: 'Ingresa el código CVC',
  NO_TRANSACTIONS_YES: 'Aún no hay transacciones',
  GO_TO_CARD_SECTION: 'Ir a la sección Tarjetas',
  ANSWER: 'Respuesta',
  ACTIVATION_REQUIRED: 'Activación requerida',
  ACTIVATE: 'Activar',
  ACTIVATE_CARD: 'Activar tarjeta',
  CVC_CODE: 'Código CVC',
  HIDE_ZERO_BALANCE_ACCOUNTS: 'Ocultar billeteras con saldo cero',
  SHOW_ZERO_BALANCE_ACCOUNTS: 'Mostrar billeteras con saldo cero',
  AVAILABLE_ACCOUNTS: 'billeteras disponibles',
  THIS_MONTH_EXPENSES: 'Gastos del mes',
  VALIDATION_CVC_CODE_INCORRECT: 'Código CVC incorrecto',
  VALIDATION_FIELD_ONLY_NUMBERS: 'El campo debe tener solo números',
  IBAN_BANK_CARD_AD_CARD_TITLE: 'Obtén una tarjeta Visa con cuenta IBAN',
  IBAN_BANK_CARD_CREATE_SUCCESS_TITLE: '¡Éxito!<br/>La Tarjeta Virtual Nebeus Visa está lista',
  IBAN_BANK_CARD_CREATE_SUCCESS_SUBTITLE:
    '¡Felicidades! Puedes empezar a usar tu nueva tarjeta de inmediato',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P1:
    'Las transferencias entre tu cuenta IBAN y tu cuenta de Criptomonedas tienen límites según tu suscripción de la cuenta IBAN de Nebeus. Cuanto mayor sea la suscripción, mayores serán los límites.<br/><br/>Para ver una lista completa de los límites, lee ',
  DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P2: 'este artículo en nuestro centro de ayuda.',
  BANK_CARDS_DESCRIPTION: 'Obtén tu tarjeta Nebeus',
  CRYPTO_BANK_CARD_ORDERED_SUCCESS_TITLE: '¡Éxito! Se ha pedido la Tarjeta Cripto Nebeus!',
  CRYPTO_BANK_CARD_ORDERED_SUCCESS_TEXT:
    'Tu pedido está siendo procesado. El estado del pedido se mostrará en el panel principal.',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_TITLE: 'Tu Tarjeta Nebeus ha sido pedida',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_TEXT:
    '¿Ha llegado tu Tarjeta Nebeus? ¡Actívala y comienza a usarla!',
  CRYPTO_BANK_CARD_CONNECTED_BALANCE: 'Balance conectado',
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT: 'Conectar billetera',
  CRYPTO_BANK_CARD_SWITCH_CONNECT_ACCOUNT: 'Cambiar billetera conectada',
  CRYPTO_BANK_CARD_ACTIVATE_CARD: 'Activa tu tarjeta primero',
  CRYPTO_BANK_CARD_ACTIVATE_CARD_TITLE: 'Activar Tarjeta Nebeus',
  CRYPTO_BANK_CARD_ACTIVATE_CARD_SUBTITLE:
    'Ingresa el código CVC en la parte trasera de la tarjeta para activarla y empezar a usarla',
  CRYPTO_BANK_CARD_ACTIVATED_TITLE: 'Tu tarjeta ha sido activada con éxito',
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT_CURRENCY: 'Conectar billetera %{currencyCode}',
  CRYPTO_BANK_CARD_CONNECTED: 'Moneda de la tarjeta modificada a %{currencyCode}',
  CRYPTO_BANK_CARD_CURRENT_CARD_CURRENCY_ACCOUNT: 'Moneda actual de la tarjeta',
  CRYPTO_BANK_CARD_ALREADY_EXIST: 'Tarjeta Cripto pedida',
  ERROR_TFA_CODE_INVALID: 'El código es inválido',
  ERROR_OTP_INVALID: 'El código OTP proporcionado no es válido',
  ERROR_INVALID_CREDENTIALS: 'Credenciales inválidas',
  ERROR_VERIFY_ACCOUNT: 'Por favor, verifica tu cuenta',
  ERROR_SOMETHING_WRONG_TRY_LATER: 'Algo salió mal. Inténtalo de nuevo más tarde.',
  ERROR_SOMETHING_WRONG_TRY_LATER_2:
    'Ups, algo parece haber salido mal. Por favor, inténtalo de nuevo.',
  ERROR_WALLET_NOT_FOUND: 'Billetera no encontrada',
  ERROR_SOMETHING_WRONG_WITH_ACCOUNT:
    'Parece haber un problema con tu cuenta. Por favor, contacta al equipo de soporte de Nebeus.',
  ERROR_EMAIL_REGISTER_IMPOSSIBLE:
    'No es posible registrarse con esta dirección de correo electrónico',
  ERROR_CANT_SEND_OTP_TO_EMAIL: 'No se pudo enviar el email a tu dirección de correo electrónico',
  CURRENCY_ACCOUNT_BALANCE: 'Balance de la billetera %{currencyCode}',

  PAYMENT_DEPOSIT_USD_WIRE_NOTE:
    'Deposita solo desde una cuenta con el mismo nombre de titular que tu cuenta Nebeus.',

  PLACEHOLDER_ADDRESS_STREET_AND_NUMBER: 'Calle y número',
  PLACEHOLDER_ADDRESS_MORE_DETAILS: 'Más detalles de la dirección (opcional)',
  CONFIRM_AND_FINISH: 'Confirmar & Terminar',
  SELECT_NETWORK: 'Seleccionar red',
  POSTCODE: 'Código postal',
  SELECT: 'Seleccionar',
  PASSCODE: 'Código de acceso',
  SCAN: 'Escanear',
  DATE_RANGE_IN_DAYS: '%{day1}-%{day2} días',
  DUE_DATE: 'Fecha de vencimiento',
  CHANGE: 'Cambiar',
  QUANTITY: 'Cantidad',
  QUANTITY_SHORT: 'Cant.',
  ADD: 'Añadir',
  DISCOUNT: 'Descuento',
  TOTAL: 'Total',
  GET: 'Obtén',
  REASON: 'Razón',
  SAVE_CHANGES: 'Guardar cambios',
  SAVE_AS_PDF: 'Guardar como pdf',
  SHARE: 'Compartir',
  OPTIONAL_WITH_STAR: '*Opcional',
  ACTIONS: 'Acciones',
  HAPPY_BIRTHDAY: 'Feliz cumpleaños!',
  ADD_NEW_ADDRESS: 'Añadir nueva dirección',
  NEW_ADDRESS: 'Nueva dirección',
  CURRENCIES: 'Monedas',
  VALIDATION_FIELD_MUST_BE_NUMBER: 'El campo debe ser un número válido',
  VALIDATION_NEW_PIN_SAME_AS_OLD: 'El nuevo código PIN no puede ser igual al antiguo',
  VALIDATION_GREATER_THAN_ZERO: 'El campo debe ser mayor que cero',
  NEBEUS_PRODUCTS: 'Productos de Nebeus',
  TOTAL_ASSETS_TITLE: 'Total de activos',
  WALLET: 'Billetera',
  IBAN: 'IBAN',
  MONEY: 'Finanzas',
  CRYPTO: 'Crypto',
  PAYMENT_METHOD: 'Método de pago',
  PAYMENT_METHOD_NEBEUS_IBAN_ACCOUNT: 'a la cuenta IBAN de Nebeus',
  PAYMENT_METHOD_NEBEUS_CRYPTO_ACCOUNT: 'a la cuenta Cripto de Nebeus',
  PAYMENT_METHOD_NEBEUS_CASH_ACCOUNT: 'a Cuenta FIAT de Nebeus',
  CHARGE_ACCOUNT: 'Cuenta debitada',
  CHARGE_AMOUNT: 'Monto del cargo',
  EXCHANGE_OPERATION_SUCCESSFULLY: 'Completada con éxito',
  PROFILE_PERSONAL_DATA: 'Datos Personales',
  REFERRAL_LINKS: 'Enlaces de invitación',
  REFERRAL_LINK: 'Enlace de invitación',
  REFERRAL_LINK_GENERATE: 'Generar enlace de referido',
  VERIFICATION_COUNTRY_NOT_ALLOWED: 'Lo sentimos, el servicio ya no está disponible en tu país',
  ACCOUNT_STATEMENT_DOWNLOADED: 'El extracto de cuenta se descargó correctamente',
  ACCOUNTS_OPERATION_DETAILS: 'Detalles de la operación',
  ACCOUNTS_GET_A_RECEIPT: 'Obtener un recibo',
  ACCOUNT_OPERATION_STATUS: 'Estado de la operación',
  RENTING_TERM: 'Plazo',
  RENTING_TOTAL_EARNINGS: 'Ganancias totales',
  PAYMENT_MINIMUM_DEPOSIT_AMOUNT: 'Cantidad mínima de depósito',
  PAYMENT_CRYPTO_ADDRESS_ASYNC_CREATING_WARNING:
    'Tu dirección de billetera %{currencyCode} se está generando. Por lo general, lleva hasta 30 minutos. Ahora puedes disfrutar de todos los beneficios de la billetera, excepto depositar en dicha dirección.',
  PAYMENT_NETWORK_NOTICE:
    'Asegúrate de que la red que seleccionaste coincida con la red de tu dirección de retiro.',
  PAYMENT_NETWORK_SELECTED: 'Red %{networkName} seleccionada',
  PAYMENT_NETWORK_IMPORTANT_TEXT:
    'Por favor, asegúrate de que la red que seleccionaste coincida con la red de tu dirección de retiro. Enviar fondos utilizando la red incorrecta puede resultar en pérdida permanente.<br/><br/>¿Confirmar el envío de fondos utilizando la red %{networkName}?',
  ERROR_BOUNDARY_TRANSLATION_ERROR_TEXT:
    'Este error puede ocurrir debido al uso de un traductor. Desactiva el traductor en el sitio web para continuar usando Nebeus.',
  BANK_CARD_SEND_PIN: 'Enviar PIN',
  BANK_CARD_CHANGE_PIN: 'Cambiar PIN',
  BANK_CARD_SEND_PASSCODE: 'Enviar código de acceso',
  BANK_CARD_SEND_PIN_SUCCESS: 'PIN enviado con éxito',
  BANK_CARD_SEND_PASSCODE_SUCCESS: 'Código de acceso enviado con éxito a tu correo electrónico',
  BANK_CARD_PASS_CODE_DESC_TITLE: 'Código de acceso Mastercard',
  BANK_CARD_PASS_CODE_DESC_TEXT:
    'El código de acceso Mastercard es un código de 6 dígitos utilizado para compras en línea. Por favor, no compartas este código. Será solicitado por nuevos comerciantes de pago.',
  BANK_CARD_PIN_CODE_DESC_TITLE: 'Código PIN',
  BANK_CARD_PIN_CODE_DESC_TEXT:
    'El código PIN es un código de 4 dígitos. Se utiliza en cajeros automáticos y terminales punto de venta. Tu tarjeta es sin contacto, pero debes realizar la primera transacción, ya sea en un cajero automático para consultar tu saldo o insertándola en un terminal punto de venta para realizar un pago, para activarla',
  BANK_CARD_GET_NEW_VIRTUAL: 'Obtener nueva tarjeta virtual',
  BANK_CARD_GET_NEW_PHYSICAL: 'Obtener nueva tarjeta',
  IBAN_ASSETS: 'Activos en IBAN en %{currencyCode}',
  IBAN_ACCOUNT_ASSETS: '%{currencyCode} IBAN account assets',
  SWITCH_IBAN: 'Cambiar IBAN',
  IBAN_ACCOUNT_CURRENCY: 'Cuenta IBAN en %{currencyCode}',
  LOANS_LOAN_PRE_MC: 'Tu préstamo está cerca de la Llamada de Margen',
  LOANS_LOAN_PRE_MC_SUBTITLE: 'Reduce el riesgo de liquidación de tu préstamo',
  LOANS_LOAN_AUTO_MC_FAILED_TITLE: 'Fallo en la gestión automática de la llamada de margen',
  LOANS_LOAN_EXCESS_COLLATERAL_RELEASE_TITLE: 'Se ha liberado el exceso de colateral',
  CRYPTO_VIRTUAL_BANK_CARD_USER_DETAILS: 'Información adicional del usuario',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS: 'Dirección de envío',
  CRYPTO_VIRTUAL_BANK_CARD_ORDERED_SUCCESS_TITLE:
    'La tarjeta virtual de Nebeus se ha pedido con éxito',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TITLE: 'Tu tarjeta Virtual ha sido creada',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_VIRTUAL_TEXT: '¡Actívala y comienza a usarla!',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TITLE:
    'Tu tarjeta Nebeus está pendiente de pago',
  CRYPTO_BANK_CARD_ORDER_PAY_BY_CARD: 'Pagar con tarjeta',
  CRYPTO_BANK_CARD_ACTIVATED_SUBTITLE:
    'Activa tu nueva tarjeta contactless con tu primera transacción, ya sea en un cajero automático para consultar tu saldo o insertándola en un terminal punto de venta para realizar un pago. Después de eso, estará lista para su uso contactless!',
  CRYPTO_BANK_CARD_ALREADY_ORDERED:
    'Ya has pedido la Tarjeta Cripto. No puedes pedir más de una tarjeta física',
  CRYPTO_BANK_CARD_SHIPPING_CALCULATING_COSTS: 'Calculando los costos de envío...',
  BANK_CARD_OLD_PIN: 'PIN antiguo',
  BANK_CARD_NEW_PIN: 'PIN nuevo',
  BANK_CARD_PIN_CHANGE_SUCCESS: 'El código PIN se ha cambiado con éxito',
  CRYPTO_VIRTUAL_BANK_CARD_AD_TITLE: 'Obtén la Tarjeta Virtual Mastercard de Nebeus',
  APP_VERIFICATION_FACE_ID: 'Face ID',
  APP_VERIFICATION_TOUCH_ID: 'Touch ID',
  APP_VERIFICATION_BIOMETRY: 'Biométrica',
  ERROR_OTP_CODE_VALIDATION_FAILURE: 'El código OTP no es válido o ha caducado',
  ERROR_CANT_CREATE_WALLET_ISSUER_NOT_WOUND:
    'No se pudo crear la billetera. Emisor no encontrado. Por favor, ponte en contacto con el equipo de soporte de Nebeus o intenta más tarde.',
  ERROR_CANT_ACTIVATE_CRYPTO_CARD:
    'No se pudo activar la tarjeta. Probablemente, el código CVC es incorrecto.',
  ERROR_BLOCKED_USER:
    'Tu cuenta está limitada. Ponte en contacto con el soporte de Nebeus para obtener detalles.',
  NEBEUS_CRYPTO_VIRTUAL_CARD: 'Tarjeta Virtual Cripto de Nebeus',
  USER_FEATURE_BLOCKED:
    'Esta función no está disponible temporalmente. Por favor, ponte en contacto con el soporte de Nebeus',
  INVOICES: 'Facturas',
  INVOICE: 'Factura',
  INVOICES_NO_INVOICES: 'Todavía no tienes facturas',
  INVOICES_NO_INVOICES_SUBTITLE:
    'Puedes empezar a crear una factura o comenzar ingresando detalles sobre tus clientes',
  INVOICES_ADD_YOUR_CLIENT_DETAILS: 'Añadir detalles del cliente',
  INVOICES_CREATE: 'Crar una factura',
  INVOICE_DATE: 'Fecha de la factura',
  INVOICE_COMPANY_OR_CLIENT_NAME: 'Nombre de la empresa/ cliente',
  INVOICE_USER_DETAILS: 'Detalles personales/Tu empresa',
  INVOICES_CLIENT_DETAILS: 'Detalles del cliente',
  INVOICES_SAVE_FOR_FUTURE_INVOICES: 'Guardar para futuras facturas',
  INVOICES_USE_SAVED_DETAILS: 'Usar detalles guardados',
  INVOICE_DETAILS: 'Detalles de la factura',
  INVOICE_NUMBER: 'Número de factura',
  INVOICE_DUE_DATE: 'Fecha de vencimiento',
  INVOICE_CURRENCY: 'Moneda de la factura',
  INVOICE_REFERENCE: 'Orden de compra/ Referencia*',
  INVOICE_ITEMS: 'Ítems de la factura',
  INVOICE_PRODUCT_OF_SERVICE_WITH_NUMBER: 'Producto o servicio %{count}',
  INVOICE_DISCOUNT: 'Descuento en la factura',
  INVOICE_NOTES: 'Notas de la Factura',
  INVOICE_NOTES_PLACEHOLDER: 'Any terms or other sales related information*',
  INVOICE_SUB_TOTAL: 'Sub total',
  NEBEUS_CRYPTO_ACCOUNT: 'Cuenta Cripto de Nebeus',
  NEBEUS_IBAN_ACCOUNT: 'Cuenta IBAN de Nebeus',
  NEBEUS_CASH_ACCOUNT: 'Cuenta FIAT de Nebeus',
  INVOICE_GET_MONEY_ACCOUNT_DESC: 'Obtén tu cuenta IBAN',
  INVOICE_CREATED_TITLE: 'Tu factura ha sido creada',
  INVOICE_CREATED_SUBTITLE:
    'La factura ha sido creada. Ahora puedes guardarla en tu dispositivo y compartirla con alguien más',
  INVOICE_VIEW_INVOICE: 'Ver factura',
  INVOICE_REMOVE: 'Eliminar factura',
  INVOICE_REMOVED: 'Factura #%{invoiceId} eliminada',
  INVOICE_USE_AS_TEMPLATE: 'Usar como plantilla',
  INVOICE_BACK_TO_INVOICES: 'Volver a Facturas',
  INVOICE_DISCOUNT_MUST_BE_LESS_THAT_TOTAL: 'El descuento debe ser menor que el monto total',
  INVOICES_NO_CLIENTS: 'No tienes detalles sobre tus clientes',
  INVOICE_CLIENT_ADDED: 'Detalles del cliente añadidos',
  INVOICE_CLIENT_REMOVED: 'Detalles eliminados',
  INVOICE_REMOVE_DETAILS: 'Eliminar detalles',
  INVOICE_VALIDATION_DUE_DATE_BEFORE_INVOICE_DATE:
    'La fecha de vencimiento no puede ser anterior a la fecha de la factura',
  INVOICES_MANAGE: 'Gestionar Facturas',
  INVOICES_MANAGE_MODE_OFF: 'Cancelar gestión',
  INVOICE_DELETE_MULTIPLE: 'Eliminar %{count} factura(s)',
  INVOICE_DELETED: '%{count} factura(s) eliminadas',
  FOLLOWED_CURRENCIES_FOLLOW: 'Lista de seguimiento',
  FOLLOWED_CURRENCIES_MANAGE: 'Gestionar',
  MANAGE_CURRENCIES: 'Gestionar Monedas',

  SIGN_ALREADY_HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
  SIGN_DONT_HAVE_ACCOUNT: '¿No tienes una cuenta?',
  PAYMENT_SEND_FIAT_IMPORTANT_NOTE:
    '<b>Verifica Detalles del Beneficiario:-</b> Por favor, verifica cuidadosamente los detalles de la cuenta del destinatario. Información incorrecta puede causar retrasos o transferencias erróneas. Una vez que se inicia una transferencia, no se puede revertir.<b>Cuidado con el Fraude y las Estafas:</b> Ten precaución con solicitudes no solicitadas de transferencias de dinero, incluso si parecen provenir de contactos conocidos. Siempre confirma la legitimidad de la solicitud de manera independiente.',
  CRYPTO_BANK_CARD_ORDERED_NOT_PAID_SUCCESS_TITLE: 'Solicitud de Tarjeta Nebeus Recibida',
  CRYPTO_BANK_CARD_NEED_MIN_DEPOSIT_ORDERED_SUCCESS_TEXT:
    '¡Excelentes noticias! Tu solicitud de tarjeta Nebeus está siendo gestionada. Para finalizar el proceso, asegúrate de tener un saldo mínimo de %{minAmountInEur} euros en cualquiera de tus cuentas. Después de la confirmación de la tarjeta, utiliza esos fondos libremente. Revisa tu panel de control para ver el estado del pedido.',
  CRYPTO_BANK_CARD_NOT_PAYED_ORDERED_SUCCESS_TEXT:
    'Tu orden será procesada tan pronto como deposites fondos en tu billetera %{currencyLabel}. %{additionalConditionText} El estado del pedido se mostrará en el panel de control.',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT:
    'Por favor, deposita %{paymentLabel} en tu billetera %{currencyLabel} tan pronto como sea posible para cubrir el costo de entrega.',
  CRYPTO_BANK_CARD_PRE_ORDER_CARD_PENDING_NOT_PAID_TEXT_BY_CARD:
    'Por favor, deposita %{paymentLabel} en tu billetera %{currencyLabel} tan pronto como sea posible o paga ahora con tarjeta para cubrir el costo de entrega por DHL.',
  CRYPTO_BANK_CARD_ORDER_CARD_MIN_AMOUNT_RULE:
    '¿Listo para hacer que las cosas sucedan? Para proceder con la emisión de la tarjeta, asegúrate de tener un mínimo de %{minAmountInEur} euros en cualquiera de tus cuentas. Después de la confirmación, utiliza libremente estos fondos sin restricciones.',
  CRYPTO_BANK_CARD_ISSUANCE_RULE_REMEMBER:
    'Recuerda que para la emisión de la tarjeta, se necesita %{minAmountLabel} en cualquiera de tus cuentas por fines operativos. Después de pedirla, utiliza libremente los fondos.',
  CRYPTO_BANK_CARD_ISSUANCE_RULE:
    'Para la emisión de la tarjeta, necesitas tener al menos %{minAmount} euros en cualquiera de tus cuentas. Después de la confirmación, estos fondos están disponibles para tu uso sin restricciones.',
  NEBEUS_ALL_RIGHT_RESERVED: '© %{currentYear} Nebeus. Todos los derechos reservados.',

  SIGN_OPEN_BUSINESS_ACCOUNT_P1: 'Abrir',
  SIGN_OPEN_BUSINESS_ACCOUNT_P2: 'una cuenta de Empresa',
  FEES_AND_CONDITIONS: 'Tarifas y condiciones',
  CURRENCY_ACCOUNT: 'Cuenta en %{currencyCode}',
  SEND_CURRENCY: 'Enviar %{currencyLabel}',
  NEBEUS_ACCOUNT: 'Cuenta Nebeus en %{currencyCode}',
  NETWORK_DETAILS: 'Detalles de la red',
  RECIPIENT_DETAILS: 'Detalles del destinatario',
  SENDER_BANK_CARD_NUMBER: 'Número de tarjeta bancaria del remitente',
  ACCOUNT_CONNECTION_REQUIRED: 'Se requiere conexión de cuenta',
  EMAIL_CONFIRMATION: 'Confirmación por correo electrónico',
  SMS_CONFIRMATION: 'Confirmación por SMS',
  ALL_FEES_INCLUDED: 'Todas las tarifas incluidas',
  WALLET_DETAILS: 'Detalles de la billetera',
  SELECT_ACCOUNT: 'Seleccionar cuenta',
  ORDER_CARD: 'Pedir tarjeta',
  ANY_EXPENSES: 'Cualquier gasto',
  GOT_IT_THANKS: 'Entendido, gracias',
  YOUR_ORDER: 'Tu pedido',
  ACTION_REQUIRED: 'Acción requerida',
  VALIDATION_DATE_FUTURE: 'La fecha no debe ser en el futuro',
  FIAT: 'Fiat',
  REFERRAL_LINK_NEBEUS_PROGRAM: 'Programa de invitación de Nebeus',
  REFERRAL_LINK_MARKETING_AD_SHORT_TITLE: '¡Gana 20€!',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_1: 'Registrarse con tu enlace',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_3: 'Pedir la Tarjeta Nebeus',
  REFERRAL_LINK_MARKETING_AD_COPY_BUTTON: 'Copiar el enlace de invitación',
  REFERRAL_LINK_COPIED: 'Enlace de invitación copiado',
  REFERRAL_LINK_INVITE_SENT: 'Invitación enviada',
  REFERRAL_LINK_INVITE_TEXT:
    'Únete a mí y a una comunidad de usuarios que disfrutan Nebeus. Regístrate con mi enlace.',
  REFERRAL_LINK_MAX_USING_CONDITION:
    'Un máximo de 5 usuarios pueden usar el mismo código de invitación',
  ACCOUNT_TRANSACTION_STATUS_REJECTED: 'Rechazado',
  RENTING_EARN_PERCENT: 'Gana %{percent}%',
  PAYMENT_SEND_TO_EXTERNAL_WALLET: 'Enviar a billetera externa',
  PAYMENT_NEBEUS_SEND_TO_MONEY_ACCOUNT: 'Enviar a cuenta IBAN',
  PAYMENT_NEBEUS_SEND_TO_CRYPTO_ACCOUNT: 'Enviar a cuenta Cripto',
  PAYMENT_ENTER_CARD_DETAILS: 'Ingresar detalles de la tarjeta',
  PAYMENT_FOR: 'por %{value}',
  PAYMENT_DEPOSIT_AMOUNT: 'Cantidad del depósito',
  PAYMENT_SEND_CURRENCY_IMPORTANT_ADDITIONAL_LABEL:
    'Solo envíe %{currencyCode} a esta dirección utilizando la red %{network}, y asegúrese de agregar el %{additional} a su transacción. Enviar cualquier otra moneda o usar una red diferente o no agregar el %{additional} puede resultar en una pérdida permanente.',
  PAYMENT_LIMIT_DAILY_WITHDRAWAL: 'Retiro diario',
  PAYMENT_LIMIT_MONTHLY_WITHDRAWAL: 'Retiro mensual',
  PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY: 'Completado exitosamente',
  PAYMENT_OPERATION_COMPLETED: 'Operación completada',
  PAYMENT_OPERATION_INITIATED: 'Operación iniciada',
  PAYMENT_OPERATION_FAILED: 'Operación fallida',
  PAYMENT_DEPOSIT_CURRENCY_BY_BANK_CARD: 'Depositar %{currencyCode} con tarjeta bancaria',
  PAYMENT_SEND_CURRENCY_BY_BANK_CARD: 'Enviar %{currencyCode} a tarjeta bancaria',
  PAYMENT_SEND_BY_SEPA: 'Enviar por SEPA',
  PAYMENT_SEND_BY_WIRE: 'Enviar por transferencia bancaria',
  PAYMENT_LIMITS_LEFT: '%{value} restante',
  PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL:
    'Hemos identificado una transacción que no cumple con nuestras políticas. Retira %{currencyAmountLabel} a una billetera externa de inmediato.',
  PAYMENT_TRANSACTION_MANDATORY_WITHDRAWAL_SUBMIT_BUTTON: 'Retirar fondos ahora',
  LOAN_MIN_LTV_WITH_NO_OPTIONS_VALIDATION:
    'Para crear un préstamo con un LTV mínimo, debes seleccionar al menos una función de automatización.',
  NEBEUS_COLD_STORAGE: 'Almacenamiento en frío de Nebeus',
  VAULT_COLD_STORAGE: 'Bóveda de almacenamiento',
  BANK_CARD_PHYSICAL_WORD: 'Física',
  BANK_CARD_ISSUE_RIGHT_NOW: 'En este momento',
  BANK_CARD_ISSUE_DEPENDS_ON_COUNTRY: 'Depende del país de entrega',
  LOANS_EARLY_REPAYMENT_FEE_DESC:
    'Si pagas tu préstamo dentro de los primeros tres meses, se te cobrará una tarifa de amortización anticipada del 2.5%.',
  BANK_CARDS_CURVE_APP_AD_TITLE:
    'Usa tu tarjeta Nebeus de forma contactless a través de la aplicación Curve',
  CRYPTO_BANK_CARD_CHOOSE_TYPE: 'Elige el tipo de tarjeta Nebeus',
  CRYPTO_BANK_CARD_AD_CARD_TITLE: 'Pide tu tarjeta Nebeus ahora',
  CRYPTO_BANK_CARD_AD_CARD_TITLE_WITH_COUNTRY:
    'Pide tu tarjeta Nebeus ahora en <span>%{country}</span>',
  CRYPTO_BANK_CARD_AD_CARD_FEATURE_1: 'Fiat & Cripto',
  CRYPTO_BANK_CARD_FEATURE_1_TITLE: 'Enlaza cuentas de Fiat o Cripto',
  CRYPTO_BANK_CARD_FEATURE_1_DESC:
    'Solo puedes usar una cuenta a la vez de Fiat o criptomonedas para tu tarjeta de débito. Puedes cambiar la cuenta vinculada en cualquier momento según tus necesidades.',
  CRYPTO_BANK_CARD_FEATURE_5_TITLE_SHORT:
    'Retiros en cajeros automáticos (gratuitos hasta 200€ mensuales)',
  CRYPTO_BANK_CARD_FEATURE_7_TITLE: 'Opciones de entrega',
  CRYPTO_BANK_CARD_FEATURE_7_DESC:
    'Elige entre tiempos de entrega estándar y entrega rápida por un cargo adicional para recibir tu tarjeta rápidamente.<br/><br/>La Tarjeta Nebeus está diseñada para darte la máxima flexibilidad y comodidad en la gestión de tus criptomonedas. Nuestra tarjeta Mastercard está diseñada para ofrecerte una experiencia sin contratiempos, ya sea que estés comprando en línea, retirando efectivo o intercambiando criptomonedas.',
  CRYPTO_BANK_CARD_CONNECT: 'Conectar',
  CRYPTO_BANK_CARD_CONNECT_ACCOUNT_SUBTITLE:
    'Solo puedes usar una cuenta a la vez de Fiat o criptomonedas para tu tarjeta de débito. Puedes cambiar la cuenta vinculada en cualquier momento según tus necesidades.',
  CRYPTO_BANK_CARD_MONTHLY_LIMIT: 'Límite mensual',
  CRYPTO_BANK_CARD_MONTHLY_LIMIT_RESET_DATE: 'Fecha de reinicio de los límites',
  NEBEUS_VIRTUAL_IBAN_CARD: 'Tarjeta IBAN de Nebeus',
  NEBEUS_CRYPTO_CARD: 'Tarjeta Nebeus',
  IBAN_CARD_FEATURES: 'Características de la Tarjeta IBAN',
  CRYPTO_CARD_FEATURES: 'Características de la Tarjeta Nebeus',
  CRYPTO_CARD_INTRO_TITLE: 'Tarjeta Nebeus mejorada',
  CRYPTO_CARD_INTRO_SUBTITLE:
    '¡La tarjeta Nebeus ahora admite Fiat! Explora los términos y tarifas actualizados.',
  CRYPTO_CARD_INTRO_DESC:
    'La tarjeta Nebeus es tu tarjeta de débito todo en uno, ¡ahora con Fiat agregado! Nuestra tarjeta, tanto en su versión virtual como física, te brinda la libertad de realizar transacciones sin complicaciones. Puedes efectuar pagos, realizar depósitos y más, utilizando fondos de tus cuentas de trading de fiat y criptomonedas.',
  CRYPTO_CARD_INTRO_NEW_CONDITIONS_AND_FEES: 'Nuevas condiciones y tarifas',
  CRYPTO_CARD_FEE_SYSTEM_TITLE: 'Sistema de tarifas flexible',
  CRYPTO_CARD_FEES_AND_CONDITION_SYSTEM_TITLE: 'Tarifas y condiciones de la tarjeta Nebeus',
  CRYPTO_CARD_FEE_SYSTEM_DESC:
    'Nuestro sistema de comisiones mensuales es flexible, ajustándose a tus gastos en fiat y criptomonedas. El gasto con tu cuenta en fiat incurre en una tarifa mensual.',
  CRYPTO_CARD_FEE_OPTION_CRYPTO: 'Si pagas con Criptomonedas',
  CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_MORE: 'Gasta más de %{amountLabel}',
  CRYPTO_CARD_FEE_OPTION_CRYPTO_CONDITION_LESS: 'Gasta menos de  %{amountLabel}',
  CRYPTO_CARD_FEE_OPTION_FIAT: 'Si pagas con Fiat',
  CRYPTO_CARD_FEE_OPTION_CRYPTO_AND_FIAT: 'Si pagas con Criptomonedas y Fiat',
  CRYPTO_CARD_FEES_AND_CONDITIONS: 'Tarifas y condiciones de la Tarjeta Nebeus',
  TRANSACTION_CRYPTO_TRANSFER: 'Transferencia de Criptomonedas',
  TRANSACTION_REVERSAL_PAYMENT: 'Devolución',
  TRANSACTION_UNIVERSAL_CARD: 'Tarjeta Nebeus',
  TRANSACTION_UNIVERSAL_CARD_CASH_WITHDRAWAL: 'Retiro de efectivo con la Tarjeta Nebeus',
  TRANSACTION_INCOME: 'Pago de ingresos',
  TRANSACTION_APPLE_PAY: 'Apple pay',
  TRANSACTION_SEPA: 'Transferencia SEPA',
  TRANSACTION_WIRE: 'Transferencia bancaria',
  TRANSACTION_LOAN: 'Préstamo',
  TRANSACTION_MONEY_SUBSCRIPTION: 'Subscripción IBAN',
  TRANSACTION_MONEY_VIRTUAL_CARD: 'Tarjeta IBAN virtual',
  TRANSACTION_MONEY_INCOME: 'Ingreso IBAN',
  TRANSACTION_REF_REWARD: 'Recompensa del Programa de Invitaciones',
  TRANSACTION_RENTING: 'Renting',
  TRANSACTION_BANK_CARD_WITHDRAWAL: 'Retiro con tarjeta bancaria de la cuenta de Criptomonedas',
  TRANSACTION_BANK_CARD_DEPOSIT: 'Depósito con tarjeta bancaria en la cuenta de Criptomonedas',
  TRANSACTION_BETWEEN_ACCOUNTS: 'Entre cuentas',
  TRANSACTION_TO_NEBEUS_USER: 'A usuario de Nebeus ',
  TRANSACTION_STAKING: 'Staking',
  TRANSACTION_VAULT: 'Bóveda',
  TRANSACTION_WIDGET: 'Widget de Exchange Cripto',
  TRANSACTION_EXCHANGE: 'Exchange',

  DA_PROVIDER_MIGRATION_DESC:
    'Estamos cambiando de proveedores para mejorar nuestros servicios y necesitamos tu aprobación para transferir tus fondos a la nueva cuenta vIBAN sin costo alguno. Si decides negar esta transferencia, nos comunicaremos rápidamente para discutir la devolución de cualquier fondo restante. Consulta más detalles en tu correo electrónico',

  IBAN_CARD_MIGRATION_DESC:
    'Además, te ofrecemos una Tarjeta Virtual Nebeus gratuita conectada a tu cuenta de trading, para que compres fácilmente con fiat y cripto.¿Te gustaría aceptar la nueva tarjeta junto con sus <a href="%{termLink}" rel="noopener noreferrer" target="_blank">términos y condiciones</a>?',

  CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_TITLE: '¡Actualiza a Google Pay!',
  CARD_WITH_GOOGLE_WALLET_CARD_EXIST_AD_DESC:
    'Actualiza tu Tarjeta Virtual Nebeus para una experiencia sin contacto con Google Pay. Simplemente cierra tu tarjeta existente y pide una nueva de forma gratuita. Agrégala manualmente a tu Billetera para acceso rápido donde sea que vayas.',
  CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_TITLE: '¡Google Pay Disponible Ahora!',
  CARD_WITH_GOOGLE_WALLET_CARD_ABSENT_AD_DESC:
    'Pide la Tarjeta Virtual Nebeus compatible con Google Pay. Agrégala manualmente a tu Billetera y disfruta de pagos con criptomonedas estés donde estés.',

  WELCOME_TITLE: 'Send and receive money effortlessly all over the world',
  SIGN_UP_ENTER_OTP_TITLE: 'Verifica tu correo electrónico con un código',
  SIGN_ENTER_OTP_SUBTITLE_EMAIL:
    'Por favor, introduce el código de confirmación de 6 dígitos que se envió a tu correo electrónico<b> %{value}</b>.',
  SIGN_ENTER_OTP_SUBTITLE_PHONE:
    'Por favor, introduce el código de confirmación de %{codeLength} dígitos que se envió a tu móvil<b> %{value}</b>.',
  SIGN_UP_ENTER_PHONE_OTP_TITLE: 'Verifica tu número de teléfono con un código',
  SIGN_UP_ENTER_PHONE_NUMBER_TITLE: 'Ingresa tu número de teléfono',
  SIGN_UP_ENTER_PHONE_NUMBER_SUBTITLE: 'Te enviaremos un código de verificación.',
  SIGN_UP_SELECT_COUNTRY: 'Selecciona país',
  SIGN_UP_COUNTRY_SEARCH: 'Busca tu país de residencia',
  SING_UP_ACCOUNT_TYPE_TITLE: '¿Qué tipo de cuenta te gustaría abrir?',
  SING_UP_ACCOUNT_TYPE_SUBTITLE: 'Podrás agregar otra cuenta más adelante también.',
  SING_UP_ACCOUNT_TYPE_PERSONAL_TITLE: 'Cuenta personal',
  SING_UP_ACCOUNT_TYPE_BUSINESS_TITLE: 'Cuenta corporativa',
  PLACEHOLDER_EMAIL: 'Correo electrónico',
  PLACEHOLDER_PHONE_NUMBER: 'Ingresa tu teléfono',
  PLACEHOLDER_WHERE_DO_YOU_LIVE: '¿Dónde vives?',
  NO_QUESTION: '¿No?',
  ARE_YOU_SURE: '¿Estás seguro?',
  ONE_TIME_PAYMENT: 'Pago único',
  RECIPIENT_ADDRESS: 'Dirección del destinatario',
  BETA: 'Beta',
  SEARCH_ADDRESS: 'Buscar dirección',
  OR: 'o',
  TYPE_TO_SEARCH: 'Escribe para buscar',
  LOADING_WITH_DOTS: 'Cargando...',
  IMPORTANT_NEWS: '¡Noticias importantes!',
  REFERRAL_LINK_MARKETING_AD_TITLE: '¡Invita a un amigo, gana 20€!',
  REFERRAL_LINK_MARKETING_AD_SUBTITLE:
    'Invita a un amigo a unirse a Nebeus usando tu enlace de invitación.',
  REFERRAL_LINK_MARKETING_AD_CONDITION_TITLE: 'Tu invitado debe',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_2: 'Verificar identidad',
  REFERRAL_LINK_MARKETING_AD_CONDITION_STEP_4:
    'Hacer un compra de al menos 5€ sin retiros en cajeros automáticos',
  REFERRAL_LINK_MARKETING_AD_TEXT:
    'Cuando tu amigo complete los pasos, recibirás la recompensa de €20. Comparte el amor, cosecha las recompensas.',
  ACCOUNTS_OPEN_ACCOUNT: 'Abrir cuenta',
  ACCOUNT_TRANSACTIONS_LAST: 'Últimas transacciones',
  PAYMENT_RECIPIENT_TYPE: 'Tipo de destinatario',
  PAYMENT_RECIPIENT_TYPE_PERSON: 'Persona',
  PAYMENT_RECIPIENT_TYPE_BUSINESS: 'Empresa',
  ERROR_COUNTRY_IS_NOT_ALLOWED_FOR_OPERATION: 'Todavía no operamos en este país',
  DA_PROVIDER_MIGRATION_SUCCESS: 'La transferencia ha sido aceptada',
  IBAN_CARD_MIGRATION_TITLE: 'Obtén tu Tarjeta Virtual Nebeus gratis',
  IBAN_CARD_BALANCE: 'Balance de tarjeta IBAN',
  LOANS_ONLY_ZERO_PAYMENT: 'Zero payments',
  LOANS_ONLY_ZERO_PAYMENT_DESC:
    'Los intereses se acumularán mensualmente, y el prestatario deberá pagar el saldo total en forma de una gran suma global tanto del capital como de los intereses acumulados al vencimiento',
  LOANS_REPAYMENT_PARTIAL_IS_NOT_ALLOWED: 'No se permite la devolución parcial',
  LOANS_REPAYMENT_FULL_IS_NOT_ALLOWED: 'No se permite la devolución total',
  SESSION_EXPIRED_WARNING_TITLE: 'Sesión expirada',
  SESSION_EXPIRED_WARNING_SUBTITLE:
    'Tu sesión está por finalizar. Por motivos de seguridad, serás desconectado después de %{seconds} segundos. ¿Deseas continuar la sesión?',
  SESSION_EXPIRED_WARNING_CONTINUE_BUTTON: 'Continuar sesión',
  SESSION_EXPIRED_WARNING_UNABLE_TO_CONTINUE: 'Unable to continue session',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS_1: 'Nombre y número de calle',
  CRYPTO_BANK_CARD_SHIPPING_ADDRESS_2: 'Piso, apartamento, suite (opcional)',
  CRYPTO_BANK_CARD_SHIPPING_POST_CODE: 'Código postal',
  CRYPTO_BANK_CARD_ORDER_CARD_ACCOUNT_VERIFICATION_SUBTITLE:
    'Verifica tu identidad para obtener tu tarjeta Nebeus',
  CRYPTO_CARD_COUNTRY_IS_NOT_ALLOWED:
    'Este país no está permitido para solicitar una tarjeta Nebeus',
  UNLOCKED_SEPA_WIRE_PAYMENT_FOR_EU_USERS_TITLE: '¡Nuevos métodos de pago están disponibles!',
  UNLOCKED_SEPA_WIRE_PAYMENT_FOR_EU_USERS_DESCRIPTION:
    '¡Grandes noticias para nuestros clientes de la UE! Hemos agregado nuevos métodos de pago para ti.<br/><br/>Now, you can also use SEPA and Wire transfer for deposit and withdraw EUR and GBP',
  ONBOARDING_CARD_OFFER_TITLE: 'Order a Nebeus Card for Free connected to your crypto and fiat',
  ONBOARDING_CARD_OFFER_SUBMIT_BUTTON: 'Pide tu Tarjeta Nebeus de forma gratuita',
  ONBOARDING_CARD_OFFER_REJECT_BUTTON: 'Tal vez más tarde',
  ONBOARDING_CARD_OFFER_TYPE_TITLE: '¿Qué tipo de tarjeta deseas solicitar?',
  BANK_CARD_OFFER_ONBOARDING_SUCCESS_TITLE: 'Tu solicitud para pedir la tarjeta ha sido recibida',
  BANK_CARD_OFFER_ONBOARDING_SUCCESS_DESCRIPTION:
    'Tu pedido está siendo procesado. Te mantendremos informado sobre el estado de tu pedido a medida que avance.',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE: 'Solicita tu tarjeta',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_SUBTITLE: 'Esto es lo que debes hacer a continuación',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE_STEP_1: 'Ingresa tu dirección de envío',
  ONBOARDING_CARD_OFFER_INSTRUCTIONS_TITLE_STEP_2: 'Verifica tu identidad',
  ONBOARDING_CARD_OFFER_DELIVERY_TITLE: '¿Cuál es tu dirección de envío?',
  ONBOARDING_CARD_OFFER_ADDRESS_ENTER_MANUALLY: 'Ingresar dirección manualmente',
  RATE_APP_TITLE: 'Mejora tu experiencia calificando a Nebeus',
  RATE_APP_SUCCESS: '¡Gracias por tu opinión!',
  RATE_APP_REQUEST_TITLE: '¿Disfrutando Nebeus?',
  RATE_APP_REQUEST_SUBTITLE_TRUSTPILOT: 'Por favor, deja una reseña en Trustpilot',
  RATE_APP_REQUEST_SUBTITLE_APP_STORE: 'Por favor, deja una reseña en App Store',
  RATE_APP_REQUEST_SUBTITLE_GOOGLE_PLAY: 'Por favor, deja una reseña en Google Play',
  RATE_APP_TRUSTPILOT_BUTTON: 'Dejar una reseña en Trustpilot',
  RATE_APP_AGREE: 'Escribir reseña',
  RATE_APP_LATER: 'Quizás más tarde',
  RATE_APP_NEVER: 'No preguntar de nuevo',
  ADDRESS_CITY: 'Ciudad',
  ADDRESS_ADDRESS_1: 'Nombre y número de calle',
  ADDRESS_ADDRESS_2: 'Piso, apartamento, suite (opcional)',
  ADDRESS_POST_CODE: 'Código postal',
  ADDRESS_COUNTRY: 'País',
  ADDRESS_ENTER_MANUALLY: 'Ingresar dirección manualmente',
  NOTIFICATIONS_MARK_ALL_AS_READ: 'Marcar todo como leído',
  NOTIFICATIONS_TYPE_SYSTEM: 'Sistema',
  NOTIFICATIONS_TYPE_ANNOUNCEMENT: 'Anuncio',
  NOTIFICATIONS_NO_DATA: 'Parece que aún no tienes notificaciones',
  'PENDING...': 'Pendiente...',
  CREATE: 'Crear',
  TOKENS_COUNT: '%{count} tokens',
  NOT_ALLOWED: 'No permitido',
  FIXED: 'Fijo',
  ID: 'ID',
  VALIDATION_IBAN_OUTCOME_ADDRESS:
    "El campo debe contener caracteres alfanuméricos (al menos uno), guiones (-), apóstrofes ('), comas (,), puntos (.), barras (/) y espacios.",
  PROFILE_EMAIL_NEWS_NOTIFICATION: 'Recibir noticias por e-mail',
  VERIFICATION_VERIFY_IDENTITY: 'Verificar identidad',
  API_ERROR_LIMIT: 'Se ha alcanzado el límite de solicitudes. Intenta de nuevo en unos minutos',
  LOANS_TITLE: 'Obtén tu propio préstamo cripto',
  LOANS_COLLATERAL_MIRROR_YOU_SEND: 'Envías',
  LOANS_COLLATERAL_MIRROR_YOU_GET: 'Recibes',
  LOAN_REPAYMENT: 'Reembolso',
  LOAN_REPAYMENT_MONTHLY: 'Mensual',
  LOAN_REPAYMENT_ONCE: 'Una vez',
  LOANS_TOKENS_AVAILABLE: '%{count} tokens disponibles',
  LOAN_FEATURES: 'Características del préstamo',
  LOANS_REPAYMENT_AMOUNT: 'Monto del reembolso',
  LOANS_TEMPLATE_QUICK_TITLE: 'Rápido',
  LOANS_TEMPLATE_FLEXIBLE_TITLE: 'Flexible',
  LOANS_TEMPLATE_BULLET_TITLE: 'Bullet',
  LOANS_TEMPLATE_ONLY_INTEREST_TITLE: 'Solo interés',
  LOANS_TEMPLATE_MIRROR_TITLE: 'Mirror',
  LOANS_TEMPLATE_QUICK_TITLE_FULL: 'Préstamo rápido',
  LOANS_TEMPLATE_FLEXIBLE_TITLE_FULL: 'Préstamo Flexible',
  LOANS_TEMPLATE_BULLET_TITLE_FULL: 'Préstamo Bullet',
  LOANS_TEMPLATE_ONLY_INTEREST_TITLE_FULL: 'Préstamo Solo interés',
  LOANS_TEMPLATE_MIRROR_TITLE_FULL: 'Préstamo Mirror',
  LOANS_MONTHLY_PAYMENT_ONLY_INTEREST: 'Pago mensual (Solo intereses)',
  LOAN_AMOUNT_RANGE: 'Rango del préstamo',
  LOANS_TEMPLATE_FLEXIBLE_DESC_LONG:
    'Los préstamos flexibles de Nebeus son préstamos que puedes personalizar según tus necesidades individuales. Puedes pedir hasta 250,000 en moneda FIAT o Stablecoins por hasta 36 meses. Puedes elegir tu LTV, elevándolo hasta un 80%, y tu tasa de interés del préstamo se ajustará automáticamente dependiendo del LTV que selecciones.',
  LOANS_TEMPLATE_FLEXIBLE_DESC:
    'Paga mensualmente tanto los intereses como el monto del préstamo, con la opción de reembolsar anticipadamente',
  LOANS_TEMPLATE_BULLET_DESC:
    'Paga una vez al final del término para cubrir el monto original que pediste más los intereses',
  LOANS_TEMPLATE_ONLY_INTEREST_DESC:
    'Paga mensualmente solo los intereses. El monto que pediste se liquida en tu pago final',
  LOANS_TEMPLATE_MIRROR_DESC:
    'Igualamos tu BTC depositado, duplicando tu posición al agregar el nuevo BTC como garantía también',
  LOANS_ZERO_RISK_CARD_TITLE: '3 días para salvar tu préstamo',
  LOANS_ZERO_RISK_DESCRIPTION:
    'Nebeus te da 3 días para salvar tu préstamo y recuperar tu cripto en caso de una llamada de margen.<br/><br/><b>Si has activado la gestión automática de llamadas de margen</b><br/>Todo lo que tienes que hacer es agregar cripto a tu cuenta principal de Nebeus (BTC o ETH, dependiendo de qué garantía estás usando). Nebeus repondrá automáticamente la garantía de tu préstamo desde tu cuenta principal de Nebeus dentro de los diez días posteriores a una llamada de margen. Tu cripto se salvará y tu préstamo continuará como de costumbre.<br/><br/><b>Si no has activado la gestión automática de llamadas de margen</b><br/>Tendrás las siguientes dos opciones:<br/> Reembolsar tu préstamo dentro de los 3 días posteriores a la llamada de margen. Una vez que lo hagas, Nebeus devolverá la cantidad total de tu garantía en cripto a tu cuenta de Nebeus.<br/>Agregar garantía a tu préstamo. Una vez que lo hagas, tu cripto se salvará y tu préstamo continuará como de costumbre.<br/><br/><b>¿Qué más?</b><br/>Si no agregaste cripto a tu cuenta y no reembolsaste tu préstamo durante el período de tres días posteriores a una llamada de margen, pero mientras tanto, los precios del cripto han subido, Nebeus monitorizará automáticamente esta volatilidad y cancelará la llamada de margen. Tu cripto se salvará y tu préstamo continuará como si nada hubiera pasado.',
  LOAN_TYPE: 'Tipo de préstamo',
  LOANS_COLLATERAL_DOUBLED: 'Garantía duplicada',
  LOANS_EARLY_REPAYMENT: 'Reembolso anticipado',
  LOANS_ADD_COLLATERAL_HELP_CALCULATION:
    'Al agregar<span>%{amountLabel}</span>, reducirás el riesgo de liquidación de tu préstamo a <span>%{percent}%</span>',
  LOANS_MIN_DEPOSIT_FOR_SAVE_LOAN: 'Depósito mínimo para salvar tu préstamo',
  LOANS_PICK_TEMPLATE: 'Elige tu tipo de préstamo',

  IBAN_ACCOUNT_CREATING_PROMPT_TITLE: '¿Te gustaría crear un IBAN?',
  IBAN_ACCOUNT_CREATING_PROMPT_SUBTITLE:
    'Necesitas financiar tu cuenta de Nebeus con <b>un mínimo de %{amountLabel}</b> desde una cuenta bancaria con IBAN a tu nombre',
  IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_TITLE: 'Sí, soy el titular de la cuenta',
  IBAN_ACCOUNT_CREATING_PROMPT_ACCOUNT_HOLDER_SUBTITLE:
    'Confirmo que soy el titular de la cuenta bancaria desde la cual realizaré el depósito.',
  IBAN_ACCOUNT_CREATING_DEPOSIT_TITLE: 'Cómo pagar desde tu cuenta bancaria',
  IBAN_ACCOUNT_CREATING_DEPOSIT_SUBTITLE:
    'Transfiere <b>un mínimo de %{amountLabel}</b> a tu cuenta Nebeus',
  IBAN_ACCOUNT_CREATING_DEPOSIT_SENT_BUTTON: 'Fondos enviados',
  IBAN_ACCOUNT_CREATING_STATUS_TITLE: 'Estado de creación del IBAN',
  IBAN_CREATING_PENDING_PROVIDER:
    'Tu pago ha sido recibido, los datos están siendo procesados, por favor espera...',
  IBAN_CREATING_APPROVED_BY_PROVIDER: 'Tu solicitud ha sido confirmada. Creando IBAN....',
  IBAN_CREATING_REJECTED_BY_MIN_AMOUNT:
    'Tu pago ha sido recibido, pero la cantidad es menor que %{amountLabel}. Por favor, inténtalo de nuevo con más de %{amountLabel}',
  IBAN_CREATING_REJECTED_BY_PROVIDER:
    'Hay problemas con tu solicitud. Por favor, contacta con soporte',
  USER_VERIFICATION_QUESTIONNAIRE: 'Cuestionario de verificación',

  DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_TITLE: '¡Cripto-impulso!💰',
  DEPOSIT_FROM_EXTERNAL_WALLET_TO_CRYPTO_TRADING_BODY:
    'Acabas de recibir %{amountLabel} en tu Billetera.',

  DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_TITLE: '¡Dinero Recibido! 🏦',
  DEPOSIT_FROM_SEPA_WIRE_TO_IBAN_WALLET_BODY:
    'Has recibido un depósito de %{amountLabel} a tu IBAN virtual.',

  SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_TITLE: 'Dinero Enviado 💸',
  SEND_FROM_IBAN_WALLET_TO_SEPA_WIRE_BODY:
    'Tu transferencia de %{amountLabel} se ha realizado correctamente.',

  SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_TITLE: '¡Dinero en camino! 💸',
  SEND_FROM_TRADING_TO_EXTERNAL_CRYPTO_WALLET_BODY:
    'Has transferido %{amountLabel} a una Billetera de criptomonedas externa.',

  CRYPTO_CARD_PURCHASE_TITLE: '%{amountLabel} gastados en tu Tarjeta Cripto 💳',
  CRYPTO_CARD_PURCHASE_BODY: 'Acabas de comprar en %{merchant}',

  CRYPTO_CARD_ATM_WITHDRAWAL_TITLE: 'Retiro Completado 🏧',
  CRYPTO_CARD_ATM_WITHDRAWAL_BODY:
    'Has retirado %{amountLabel} en efectivo de un cajero automático con tu Tarjeta Cripto.',

  CRYPTO_CARD_ACTIVATED_TITLE: 'Y¡Tu Tarjeta Cripto está activa! ✅ ',
  CRYPTO_CARD_ACTIVATED_BODY: 'Lista para aprovechar al máximo.',

  NOTIFICATION_MARKETING_CURVE_TITLE: 'Now go contactless!',
  NOTIFICATION_MARKETING_CURVE_BODY:
    'Use your Nebeus card through the Curve app for contactless & convenient payments',

  SEND_TO_NEBEUS_USER_TITLE: 'Transferencia Enviada 💸',
  SEND_TO_NEBEUS_USER_BODY:
    '¡Riqueza compartida! Tu transferencia de %{amountLabel} ha sido completada.',

  SEND_FROM_TRADING_TO_MONEY_TITLE: 'Funds have been transferred 💸',
  SEND_FROM_TRADING_TO_MONEY_BODY:
    '%{amountLabel} has been successfully transferred to IBAN account from trading account',
  SEND_FROM_MONEY_TO_TRADING_TITLE: 'Funds have been transferred 💸',
  SEND_FROM_MONEY_TO_TRADING_BODY:
    '%{amountLabel} has been successfully transferred to trading account from iban account',

  SEND_FROM_TRADING_TO_SEPA_WIRE_TITLE: 'Transacción Completada🌟',
  SEND_FROM_TRADING_TO_SEPA_BODY:
    'Su transferencia SEPA de %{amountLabel} desde su billetera se realizó correctamente',
  SEND_FROM_TRADING_TO_WIRE_BODY:
    'Su transferencia Wire de %{amountLabel} desde su billetera se realizó correctamente',

  DEPOSIT_FROM_SEPA_WIRE_TO_TRADING_TITLE: '¡Tu saldo ha aumentado!  🎉',
  DEPOSIT_FROM_SEPA_TO_TRADING_BODY:
    'Has recibido un deposito SEPA de %{amountLabel}. Tu Billetera acaba de ser recargada.',
  DEPOSIT_FROM_WIRE_TO_TRADING_BODY:
    'Has recibido un deposito Wire de %{amountLabel}. Tu Billetera ha sido regargada con éxito.',
};
