import { transformDigitalWallets } from 'modules/accounts/services/transformers';
import { DigitalWallet, Transaction } from 'modules/accounts/types';
// import digitalAccountServicesMocked from 'modules/digitalAccount/services/mocked';
import {
  AllowedCountries,
  DigitalSubscription,
  GetTransactionsHistoryParams,
} from 'modules/digitalAccount/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  CountryResp,
  ModulrSubscriptionResponse,
  ModulrSubscriptionResponseList,
  ModulrTransactionHistoryResponseList,
  ModulrWalletResponseList,
  ReferenceCodeResp,
  ReferenceStatusResp,
  SwitchDefaultWalletRequest,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode } from 'types';

import { transformDigitalTransactions, transformSubscriptions } from './transformers';

// cli-import

const digitalAccountServices = {
  getSubscriptions: async (): Promise<DigitalSubscription[]> =>
    AxiosInstance.get<ModulrSubscriptionResponseList, ModulrSubscriptionResponseList>(
      '/v1/modulr/subscription',
    ).then((response) => transformSubscriptions(response.subscriptions)),

  createSubscription: async ({
    id,
    paymentCurrency,
  }: {
    id: DigitalSubscription['id'];
    paymentCurrency?: CurrencyCode;
  }): Promise<DigitalSubscription> =>
    AxiosInstance.post<ModulrSubscriptionResponse, ModulrSubscriptionResponse>(
      `/v1/modulr/subscription/${id}/purchase`,
      {
        currencyCode: paymentCurrency || 'EUR',
      },
    ).then((response) => transformSubscriptions([response.subscriptionInfo])[0]),

  cancelSubscription: async ({ id }: { id: number }): Promise<DigitalSubscription> =>
    AxiosInstance.post<ModulrSubscriptionResponse, ModulrSubscriptionResponse>(
      `/v1/modulr/subscription/${id}/cancel`,
    ).then((response) => transformSubscriptions([response.subscriptionInfo])[0]),

  getTransactionsHistory: async ({
    pageLimit,
    pageNumber,
    currencyCode,
  }: GetTransactionsHistoryParams): Promise<Transaction[]> => {
    return AxiosInstance.get<
      ModulrTransactionHistoryResponseList,
      ModulrTransactionHistoryResponseList
    >('/v1/transaction/modulr/history', {
      params: { pageSize: pageLimit, pageNumber, currencyCode },
    }).then((response) => transformDigitalTransactions(response, currencyCode));
  },

  switchDefaultIban: (payload: SwitchDefaultWalletRequest): Promise<DigitalWallet[]> =>
    AxiosInstance.post<
      ModulrWalletResponseList,
      ModulrWalletResponseList,
      SwitchDefaultWalletRequest
    >('/v1/iban/switch-default-wallet', payload).then(transformDigitalWallets),

  getIbanAccountCreatingReferenceCode: (): Promise<string> =>
    AxiosInstance.get<ReferenceCodeResp, ReferenceCodeResp>(
      '/v1/iban/creating-reference-code',
    ).then((response) => response.code),

  getCreatingIbanAccountStatus: (): Promise<ReferenceStatusResp> =>
    AxiosInstance.get<ReferenceStatusResp, ReferenceStatusResp>('/v1/iban/creating-status'),

  getAllowedCountries: (): Promise<AllowedCountries> =>
    AxiosInstance.get<CountryResp[], CountryResp[]>('/v1/iban/allowed-countries'),
  // cli-service
};

// export default digitalAccountServicesMocked;
export default digitalAccountServices;
