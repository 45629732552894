const isDev = process.env.NODE_ENV === 'development';

const apiUrl = process.env.REACT_APP_API_URL as string;
// const apiUrl = 'https://a32.nebeus.com/api';

const appConfig = {
  apiUrl,
  // maintenanceApiUrl: apiUrl + '/v1/maintenance',
  maintenanceApiUrl: 'https://staging.backend.nebeus.com/api' + '/v1/maintenance', // TODO: Change when maintenance will be deployed on prod
  isDev,
  env: process.env.NODE_ENV,
  customEnvironment: process.env.REACT_APP_ENVIRONMENT,
  accessTokenKey: 'g4h23g4hj2b342hj',
  refreshTokenKey: 'jkl2434nj12mndas09',
  sentryUrl: process.env.REACT_APP_SENTRY_URL,
  beamerApiKey: process.env.REACT_APP_BEAMER_API_KEY,
  lastCommitHash: process.env.REACT_APP_LAST_COMMIT_HASH,
  version: process.env.REACT_APP_VERSION,
  firebaseConfig: process.env.REACT_APP_FB_CONFIG,
  firebaseCloudMessagingToken: process.env.REACT_APP_FCM_TOKEN,
  googleAnalyticKey: process.env.REACT_APP_GOOGLE_ANALYTIC_KEY,
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  googlePlacesApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  recaptchaKey: '6LcWsMYpAAAAAE1-hMwLpxJNi3e0jAphtI1tu9mk',
  testUsersIds: [
    'bf00041f-e9e1-4a51-8832-baf2744f2e83',
    '2a4ecbf6-a32e-42ea-ad2b-8a2d14cc2d46',
    'ed1b6b1b-69f3-4a9a-b11e-7b4a8534d29b',
    'fee32564-779a-4c97-bac6-2144fe21630c',
    'e41f2b57-c5a8-486f-b3cd-2c7883a35179',
    'cf0cd449-cd52-4b49-91f3-7b24cf337607',
  ],
};

export default appConfig;
