import clsx from 'clsx';

import { ButtonHTMLAttributes, DetailedHTMLProps, FC, MouseEventHandler, useCallback } from 'react';

import { Loader } from 'components/ui/Loader';

import useFlag from 'hooks/useFlag';

import { Size } from 'types';

import classes from './Button.module.scss';

export type ButtonVariant =
  | 'darkGreyOutlined'
  | 'blackWhite'
  | 'whiteBlack'
  | 'transparentGreen'
  | 'outlinedBlack'
  | 'lightGreen'
  | 'darkGreenOutlined'
  | 'transparentBlack'
  | 'redOutlined'
  | 'red'
  | 'gold'
  | 'goldOutlined'
  | 'pastelYellowBlack'
  | 'outlinedWhite'
  | 'greenTextOutlined'
  | 'pastelYellow'
  | 'linenGreen'
  | 'greenWhite'
  | 'greyishGreen'
  | 'outlinedKindaBlack'
  | 'creamyGreen'
  | 'creamyBlack';

export interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: ButtonVariant;
  fullWidth?: boolean;
  showShadow?: boolean;
  loading?: boolean;
  forwardRef?: (ref: HTMLButtonElement | null) => void;
  size?: Size;
  handleLoading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  className,
  variant = 'greyishGreen',
  fullWidth = false,
  loading: loadingFromProps,
  disabled,
  showShadow,
  forwardRef,
  size = 'md',
  handleLoading,
  onClick,
  ...props
}) => {
  const localLoading = useFlag(false);

  const loading = loadingFromProps || localLoading.state;

  const handleClickWithLoadingHandler = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      localLoading.on();
      await onClick?.(e);
      localLoading.off();
    },
    [onClick, localLoading],
  );

  return (
    <button
      translate="no"
      ref={forwardRef}
      type="button"
      className={clsx(classes.root, className, classes[variant], classes[`size-${size}`], {
        [classes.fullWidth]: fullWidth,
        [classes.showShadow]: showShadow,
        [classes.disabled]: disabled || loading,
        [classes.loading]: loading,
      })}
      disabled={loading || disabled}
      onClick={handleLoading ? handleClickWithLoadingHandler : onClick}
      {...props}
    >
      {loading ? <Loader size="xs" /> : children}
    </button>
  );
};
