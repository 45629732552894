import { FC, useCallback, useMemo, useRef } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import useDrawer from 'modules/app/hooks/useDrawer';
import invoicingDrawerTemplates from 'modules/invoicing/constants/drawerTemplates';
import {
  ClientDetailsFormValues,
  clientDetailsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/ClientDetailsForm';
import {
  InvoiceDetailsFormValues,
  invoiceDetailsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceDetailsForm';
import {
  InvoiceDiscountFormValues,
  invoiceDiscountValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceDiscountForm';
import {
  InvoiceNotesFormValues,
  invoiceNotesValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceNotesForm';
import {
  InvoiceServiceItemsFormValues,
  invoiceServiceItemsValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/InvoiceServiceItemsForm';
import {
  PaymentDetailsFormValues,
  invoicePaymentValidationSchema,
} from 'modules/invoicing/views/CreateInvoice/components/PaymentDetailsForm';
import { InvoiceDetails } from 'modules/invoicing/views/CreateInvoice/steps/InvoiceDetails';
import { InvoiceDiscount } from 'modules/invoicing/views/CreateInvoice/steps/InvoiceDiscount';
import { InvoiceNotes } from 'modules/invoicing/views/CreateInvoice/steps/InvoiceNotes';
import { InvoiceServiceItems } from 'modules/invoicing/views/CreateInvoice/steps/InvoiceServiceItems';
import { PaymentDetails } from 'modules/invoicing/views/CreateInvoice/steps/PaymentDetails';
import { SummaryAmount } from 'modules/invoicing/views/components/SummaryAmount';

import { SubmitButton } from 'components/form';

import { useTranslation } from 'libs/i18n';
import { makeValidate } from 'libs/yup';

import { getDeepKeys } from 'utils/arrayUtils';
import { formatDDMMYY } from 'utils/date';
import eventEmitter from 'utils/eventEmitter';

import { ClientDetails } from './steps/ClientDetails';
import { CurrentUserCard, CurrentUserFormValues } from './steps/CurrentUserCard';

export interface CreateInvoiceFormValues
  extends ClientDetailsFormValues,
    InvoiceDetailsFormValues,
    InvoiceServiceItemsFormValues,
    InvoiceDiscountFormValues,
    InvoiceNotesFormValues,
    PaymentDetailsFormValues,
    CurrentUserFormValues {
  isClientSaved: boolean;
  saveClient: boolean;
}

interface CreateInvoiceFormProps extends FormRenderProps<CreateInvoiceFormValues> {}
const CreateInvoiceForm: FC<CreateInvoiceFormProps> = ({ handleSubmit, values, errors }) => {
  const translate = useTranslation();

  const formRef = useRef<HTMLFormElement | null>(null);

  const validServiceItems = useMemo(() => {
    return values.invoiceServiceItems
      .filter((_, index) => {
        if (!errors || !errors.invoiceServiceItems) {
          return true;
        }
        return !errors.invoiceServiceItems[index];
      })
      .map((i) => ({
        ...i,
        quantity: +i.quantity,
        amount: +i.amount,
      }));
  }, [errors, values.invoiceServiceItems]);

  const onSubmit = useCallback(() => {
    handleSubmit();

    const errorFields = getDeepKeys(errors || {});

    const fieldNamesSorted = Array.from(formRef.current?.querySelectorAll('input') || [])
      .map((i) => i.name)
      .filter((name) => errorFields.includes(name));

    if (fieldNamesSorted.length) {
      const firstErrorField = formRef.current?.querySelector(
        `input[name="${fieldNamesSorted[0]}"]`,
      );

      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    if (errorFields.length && errorFields.every((i) => i.startsWith('payment'))) {
      eventEmitter.emit('invoiceFormPaymentValidationError');
    }
  }, [handleSubmit, errors]);

  return (
    <form ref={formRef} className="column gap-3" onSubmit={handleSubmit}>
      <CurrentUserCard />
      <ClientDetails />
      <InvoiceDetails />
      <InvoiceServiceItems />
      <InvoiceDiscount />
      <InvoiceNotes />
      <PaymentDetails />
      <SummaryAmount
        discountAmount={values.discountAmount ? +values.discountAmount : 0}
        serviceItems={validServiceItems}
        currencyCode={values.invoiceCurrency || undefined}
      />
      <SubmitButton onClick={onSubmit} behaviour="validateOnClick" variant="greyishGreen">
        {translate('CONFIRMATION')}
      </SubmitButton>
    </form>
  );
};

const emptyInitialValues: CreateInvoiceFormValues = {
  issuerAddress: '',
  issuerPostCode: '',
  issuerName: '',
  issuerCity: '',
  issuerCountry: '',
  isClientSaved: false,
  saveClient: false,
  clientAddress1: '',
  clientAddress2: '',
  clientCity: '',
  clientCountry: '',
  clientName: '',
  clientEmail: '',
  clientPostCode: '',
  invoiceNumber: '',
  invoiceDate: formatDDMMYY(new Date()),
  invoiceDueDate: '',
  invoiceCurrency: null,
  invoiceReference: '',
  invoiceServiceItems: [{ name: '', quantity: '', amount: '' }],
  discountAmount: '',
  discountReason: '',
  invoiceNotes: '',
  payment: {
    type: null,
    currencyCode: null,
  },
};
export interface CreateInvoiceProps {
  initialValues?: CreateInvoiceFormValues;
}

const validate = makeValidate(
  clientDetailsValidationSchema
    .concat(invoiceDetailsValidationSchema)
    .concat(invoiceServiceItemsValidationSchema)
    .concat(invoiceDiscountValidationSchema)
    .concat(invoiceNotesValidationSchema)
    .concat(invoicePaymentValidationSchema),
);

const CreateInvoice: FC<CreateInvoiceProps> = ({ initialValues = emptyInitialValues }) => {
  const drawer = useDrawer();

  const handleSubmit = useCallback(
    (values: CreateInvoiceFormValues) => {
      drawer.open(
        invoicingDrawerTemplates.createInvoiceConfirmation({ createInvoiceValues: values }),
      );
    },
    [drawer],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      render={CreateInvoiceForm}
      initialValues={initialValues}
      validate={validate}
    />
  );
};

export default CreateInvoice;
