import clsx from 'clsx';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import ibanAccountDrawerTemplates from 'modules/digitalAccount/constants/drawerTemplates';
import {
  selectActiveDigitalSubscription,
  selectEndingDigitalSubscription,
} from 'modules/digitalAccount/store/selectors';

import classes from './IbanAccountSubscriptionMark.module.scss';

const IbanAccountSubscriptionMark = () => {
  const drawer = useDrawer();

  const activeSub = useSelector(selectActiveDigitalSubscription);
  const endingSub = useSelector(selectEndingDigitalSubscription);

  const usingSub = activeSub || endingSub;

  const handleClick = useCallback(() => {
    if (usingSub) {
      drawer.open(
        ibanAccountDrawerTemplates.digitalAccountSubscriptionManagement({ subscription: usingSub }),
      );
    }
  }, [usingSub, drawer]);

  return usingSub ? (
    <div
      onClick={handleClick}
      className={clsx(classes.root, usingSub && classes[`variant-${usingSub.type}`])}
    >
      <span>{usingSub.type}</span>
    </div>
  ) : null;
};

export default IbanAccountSubscriptionMark;
