import { FC, useCallback } from 'react';
import { createPortal } from 'react-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import invoicingDrawerTemplates, {
  INVOICE_VIEW_HEADER_CLASSNAME,
} from 'modules/invoicing/constants/drawerTemplates';
import { requestDownloadInvoicePdf, requestRemoveInvoice } from 'modules/invoicing/store/thunks';
import { Invoice } from 'modules/invoicing/types';
import FullInvoiceView from 'modules/invoicing/views/components/FullInvoiceView';
import { useDispatch } from 'store';

import { Icon, Loader } from 'components/ui';
import { MenuListItem } from 'components/ui/MenuList';

import useAsyncPortalTarget from 'hooks/useAsyncPortalTarget';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

export interface InvoiceFullProps {
  invoice: Invoice;
}
const InvoiceFull: FC<InvoiceFullProps> = ({ invoice }) => {
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const translate = useTranslation();

  const openActions = useCallback(() => {
    if (!invoice) {
      return;
    }
    const menu: MenuListItem[] = [
      {
        icon: 'copy',
        label: translate('INVOICE_USE_AS_TEMPLATE'),
        handler: () => {
          drawer.replaceAll(
            invoicingDrawerTemplates.createInvoice({
              initialValues: {
                issuerAddress: invoice.issuer.address,
                issuerPostCode: invoice.issuer.postCode,
                issuerName: invoice.issuer.name,
                issuerCity: invoice.issuer.city,
                issuerCountry: invoice.issuer.country,
                isClientSaved: false,
                saveClient: false,
                clientAddress1: invoice.client.address,
                clientAddress2: invoice.client.address2,
                clientCity: invoice.client.city,
                clientCountry: invoice.client.country,
                clientName: invoice.client.name,
                clientEmail: invoice.client.email,
                clientPostCode: invoice.client.postCode,
                invoiceNumber: `#${invoice.invoiceDetails.number}`,
                invoiceDate: invoice.invoiceDetails.fromDate,
                invoiceDueDate: invoice.invoiceDetails.toDate,
                invoiceCurrency: invoice.invoiceDetails.currencyCode,
                invoiceReference: invoice.invoiceDetails.reference || '',
                invoiceServiceItems: invoice.invoiceServiceItems.map((i) => ({
                  ...i,
                  quantity: i.quantity.toString(),
                  amount: i.amount.toString(),
                })),
                discountAmount: invoice.invoiceDiscount.amount
                  ? invoice.invoiceDiscount.amount.toString()
                  : '',
                discountReason: invoice.invoiceDiscount.reason || '',
                invoiceNotes: invoice.notes || '',
                payment: {
                  type: invoice.payment.type,
                  currencyCode: invoice.payment.currencyCode,
                },
              },
            }),
          );
        },
      },
      {
        label: translate('SAVE_AS_PDF'),
        handler: async () => {
          const { success } = await dispatch(requestDownloadInvoicePdf(invoice));
          if (success) {
            successToast(translate('SUCCESS!'));
          }
        },
        asyncHandler: true,
        icon: 'arrowDownSafe',
      },
      {
        label: translate('INVOICE_REMOVE'),
        handler: async () => {
          await dispatch(requestRemoveInvoice(invoice.id));
          successToast(translate('INVOICE_REMOVED', { invoiceId: invoice.id }));
          drawer.replaceAll(invoicingDrawerTemplates.invoices());
        },
        icon: 'crossSafe',
      },
    ];

    // TODO: For future (mobile)
    // @ts-ignore
    // if (window.navigator.share) {
    //   menu.push({
    //     label: translate('SHARE'),
    //     handler: () => {
    //       // TODO: Invoicing share
    //     },
    //     icon: 'doc',
    //   });
    // }
    drawer.open(
      commonDrawerTemplates.menuList({
        menu,
      }),
    );
  }, [dispatch, drawer, invoice, translate]);

  const portalTarget = useAsyncPortalTarget(`.${INVOICE_VIEW_HEADER_CLASSNAME}`);

  return invoice ? (
    <div className="column mt-1">
      <FullInvoiceView invoice={invoice} />
      {portalTarget &&
        createPortal(
          <Icon onClick={openActions} className="pointer" name="dotsSquared" color="black40" />,
          portalTarget,
        )}
    </div>
  ) : (
    <div className="column flex-1 jcc aic">
      <Loader />
    </div>
  );
};

export default InvoiceFull;
