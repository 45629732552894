import { AccountType } from 'modules/accounts/types';
import { LoanTemplateType } from 'modules/loans/types';

import { CurrencyCode } from 'types';

const routesByName = {
  welcome: '/',
  signIn: '/signin',
  signUp: '/signup',
  emergencyResetTFA: '/emergency-reset-tfa',
  dashboard: '/dashboard',
  cryptoRenting: (tab?: 'new' | 'active' | 'history') =>
    tab ? `/crypto-renting/${tab}` : '/crypto-renting',
  cryptoRentingPayoutSchedule: (rentingId: string = ':rentingId') =>
    '/crypto-renting/payout-schedule/' + rentingId,
  accounts: (accountType?: AccountType) =>
    accountType ? '/accounts' + `/${accountType}` : '/accounts',
  accountPage: (currencyCode: CurrencyCode, isDigitalAccount: boolean = false) =>
    `/accounts/${isDigitalAccount ? AccountType.digital : AccountType.crypto}/${currencyCode}`,
  accountTransactionsHistory: 'accounts/crypto/history',
  vault: '/vault',
  staking: (tab?: 'assets' | 'overview') => (tab ? `/staking/${tab}` : '/staking'),
  stakingItem: (code: CurrencyCode) => `/staking/assets/${code}`,

  loans: (tab?: 'new' | 'active' | 'history', templateType?: LoanTemplateType) =>
    tab ? `/loans/${tab}${templateType ? '/' + templateType.toLowerCase() : ''}` : '/loans',
  loansPaymentAndCollateral: (loanId: string = ':loanId') =>
    '/loans/payment-and-collateral/' + loanId,
  loanConfirmation: '/loans/confirmation',

  exchange: '/exchange',
  smartTrader: '/smart-trader',
  smartTradeDashboard: (currencyCode?: CurrencyCode) =>
    currencyCode ? '/smart-trader/' + currencyCode : ':currencyCode',
  iban: '/iban',

  crashApp: '/crash-app-0000',
  publicConfig: '/config-0000',

  orderCryptoBankCard: '/crypto-bank-card/order',
  activateCryptoCard: '/crypto-card/activate',
};

export default routesByName;
