import { DigitalSubscriptionType } from 'modules/digitalAccount/types';

import { DescriptionListItem } from 'components/ui/DescriptionList';

import { TranslateFunc, TranslationKey } from 'libs/i18n';

import { LINK_DIGITAL_ACCOUNTS_LIMITS_AND_FEES } from 'utils/links';

export const translationKeyTitleBySubType: { [key in DigitalSubscriptionType]: TranslationKey } = {
  standard: 'DA_SUBSCRIPTION_STANDARD',
  hodler: 'DA_SUBSCRIPTION_HODLER',
  whale: 'DA_SUBSCRIPTION_WHALE',
};
export const translationKeyDescBySubType: { [key in DigitalSubscriptionType]: TranslationKey } = {
  standard: 'DA_SUBSCRIPTION_STANDARD_DESC',
  hodler: 'DA_SUBSCRIPTION_HODLER_DESC',
  whale: 'DA_SUBSCRIPTION_WHALE_DESC',
};
export const translationKeySubDescSecondaryBySubType: {
  [key in DigitalSubscriptionType]: TranslationKey;
} = {
  standard: 'DA_SUBSCRIPTION_STANDARD_GET_SUCCESS',
  hodler: 'DA_SUBSCRIPTION_HODLER_GET_SUCCESS',
  whale: 'DA_SUBSCRIPTION_WHALE_GET_SUCCESS',
};

export const getSubscriptionBenefitsList = (
  type: DigitalSubscriptionType,
  translate: TranslateFunc,
): DescriptionListItem[] => {
  const subName = translate(translationKeyTitleBySubType[type]);

  return [
    {
      title: subName + ' ' + translate('DIGITAL_ACCOUNT'),
      description: translate('DA_BENEFITS_MONEY_ACCOUNT_DESC'),
      icon: 'people',
    },
    {
      title: translate('DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO'),
      buttonTitle: translate('DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO'),
      modalTitle: translate('DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_MODAL_TITLE'),
      description:
        translate('DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P1') +
        `<a href="${LINK_DIGITAL_ACCOUNTS_LIMITS_AND_FEES}" rel="noopener noreferrer" target="_blank">${translate(
          'DA_BENEFITS_TRANSFER_MONEY_AND_CRYPTO_DESC_P2',
        )}</a>`,
      icon: 'refresh-square',
    },
    {
      title: translate('DA_BENEFITS_BANK_TRANSFERS'),
      description: translate('DA_BENEFITS_BANK_TRANSFERS_DESC'),
      icon: 'transfer-arrows',
    },
    {
      title: translate('DA_BENEFIT_VIRTUAL_CARD'),
      description: translate('DA_BENEFIT_VIRTUAL_CARD_DESC'),
      icon: 'chip',
    },
    // {
    //   title: translate('DA_BENEFIT_PHYSICAL_CARD'),
    //   description: translate('DA_BENEFITS_COMING_SOON'),
    //   icon: 'card',
    // },
    // {
    //   title: translate('DA_BENEFITS_OTHER_PERKS'),
    //   description: translate('DA_BENEFITS_COMING_SOON'),
    //   icon: 'vertical-lines',
    // },
  ];
};

export const MIN_DEPOSIT_AMOUNT_TO_CREATE_IBAN_ACCOUNT_IN_EUR = 5;
