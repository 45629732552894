import { compareDesc, parse } from 'date-fns';

import { Field, useFormState } from 'react-final-form';

import { SimpleCurrencyPickerInputField, TextInputField } from 'components/form';

import useDateParser from 'hooks/useDateParser';

import { getTranslation, useTranslation } from 'libs/i18n';
import yup from 'libs/yup';

import { fiatCurrencies } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './InvoiceDetailsForm.module.scss';

export interface InvoiceDetailsFormValues {
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceCurrency: CurrencyCode | null;
  invoiceReference: string;
}

export const invoiceDetailsValidationSchema = yup.object().shape(
  {
    invoiceNumber: yup
      .number()
      .transform((_, originalValue) => +originalValue?.replace('#', ''))
      .typeError(getTranslation('VALIDATION_FIELD_MUST_BE_NUMBER'))
      .integer(getTranslation('VALIDATION_FIELD_ONLY_NUMBERS'))
      .test(
        'minNumber',
        getTranslation('VALIDATION_MIN', { min: 1 }),
        (value) => !!value && value.toString().length >= 1,
      )
      .test(
        'maxNumber',
        getTranslation('VALIDATION_MAX', { max: 20 }),
        (value) => !!value && value.toString().length <= 20,
      )
      .min(1, getTranslation('VALIDATION_GREATER_THAN_ZERO'))
      .requiredDefault(),
    invoiceDate: yup.string().dateDDMMYYYY().requiredDefault(),
    invoiceDueDate: yup
      .string()
      .dateDDMMYYYY()
      .test(
        'validDistance',
        getTranslation('INVOICE_VALIDATION_DUE_DATE_BEFORE_INVOICE_DATE'),
        (value: string | undefined, ctx) => {
          if (!value) {
            return true;
          }
          const isEarlierThanInvoiceDate =
            compareDesc(
              parse(ctx.parent.invoiceDate, 'dd.MM.yyyy', new Date()),
              parse(value, 'dd.MM.yyyy', new Date()),
            ) === -1;

          return !isEarlierThanInvoiceDate;
        },
      )
      .requiredDefault(),
    invoiceCurrency: yup
      .string()
      .typeError(getTranslation('VALIDATION_REQUIRED'))
      .requiredDefault(),
    // @ts-ignore
    invoiceReference: yup.string().when('invoiceReference', (val?: string) => {
      // @ts-ignore
      if (val?.length > 0) {
        return yup
          .string()
          .min(4, getTranslation('VALIDATION_MIN', { min: 4 }))
          .max(60, getTranslation('VALIDATION_MAX', { max: 60 }));
      }
      return yup.string().optional();
    }),
  },
  [['invoiceReference', 'invoiceReference']],
);

const parseInvoiceNumber = (value: string) => `#${value.replace('#', '')}`;

const invoiceCurrencies = fiatCurrencies;
export const InvoiceDetailsForm = () => {
  const translate = useTranslation();

  const formState = useFormState();
  const parseDate = useDateParser(formState.values);

  return (
    <div className="column gap-2">
      <Field
        name="invoiceNumber"
        placeholder={translate('INVOICE_NUMBER')}
        component={TextInputField}
        parse={parseInvoiceNumber}
      />
      <div className="row gap-2">
        <Field
          name="invoiceDate"
          placeholder={translate('INVOICE_DATE')}
          component={TextInputField}
          parse={parseDate}
          inputMode="numeric"
        />
        <Field
          name="invoiceDueDate"
          placeholder={translate('INVOICE_DUE_DATE')}
          component={TextInputField}
          parse={parseDate}
          inputMode="numeric"
        />
      </div>
      <Field
        name="invoiceCurrency"
        placeholder={translate('INVOICE_CURRENCY')}
        component={SimpleCurrencyPickerInputField}
        currenciesList={invoiceCurrencies}
      />
      <Field
        name="invoiceReference"
        placeholder={translate('INVOICE_REFERENCE')}
        component={TextInputField}
        underComponent={
          <span className={classes.optionalLabel}>{translate('OPTIONAL_WITH_STAR')}</span>
        }
      />
    </div>
  );
};
