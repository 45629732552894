import { useCallback, useEffect, useState } from 'react';

import eventEmitter from 'utils/eventEmitter';

const usePersistedState = <T>(key: string): [T | null, (newValue: T) => void] => {
  const localStorageValue = localStorage.getItem(key);

  const initialValue = localStorageValue ? JSON.parse(localStorageValue) : null;
  const [state, setState] = useState<T | null>(initialValue);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (newValue: T) => {
      localStorage.setItem(key, JSON.stringify(newValue));
      eventEmitter.emit('updatePersistedState', { key, value: newValue });
    },
    [key],
  );

  const handleEvent = useCallback(
    (payload: { key: string; value: T }) => {
      if (payload.key === key) {
        setState(payload.value);
      }
    },
    [key],
  );

  useEffect(() => {
    const unsub = eventEmitter.subscribe('updatePersistedState', handleEvent);

    return () => {
      unsub();
    };
  }, [handleEvent]);

  return [state, handleChange];
};

export default usePersistedState;
