import { FC } from 'react';

import { translationKeyTitleBySubType } from 'modules/digitalAccount/constants/config';
import { DigitalSubscriptionType } from 'modules/digitalAccount/types';

import { useTranslation } from 'libs/i18n';

import classes from './SubscriptionProcessTitle.module.scss';

interface SubscriptionProcessTitleProps {
  subscriptionType: DigitalSubscriptionType;
  additional?: string;
}

export const SubscriptionProcessTitle: FC<SubscriptionProcessTitleProps> = ({
  subscriptionType,
  additional,
}) => {
  const translate = useTranslation();
  return (
    <h3 className={classes.root}>
      <span>Nebeus {translate(translationKeyTitleBySubType[subscriptionType])} </span>
      {!additional && <br />}
      {translate('DIGITAL_ACCOUNT')} {additional}
    </h3>
  );
};
