import clsx from 'clsx';
import { sumBy } from 'lodash';

import { FC, ReactNode, useMemo, useRef } from 'react';

import { AmountLabel } from 'components/ui/AmountLabel';
import { DotSeparatedViews } from 'components/ui/DotSeparatedViews';
import { Icon } from 'components/ui/Icon';

import useFlag from 'hooks/useFlag';

import { useTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './AssetsCard.module.scss';

export interface Asset {
  label: string;
  amount: number;
  currencyCode?: CurrencyCode;
}
interface AssetsCardProps {
  assets: Asset[];
  currencyCode: CurrencyCode;
  sumByField?: string;
  className?: string;
  label?: string;
  children?: ReactNode;
  colors?: string[];
}

const defaultColors = ['#F8DF95', '#76BE98', '#343434', '#FA758D'];

export const AssetsCard: FC<AssetsCardProps> = ({
  label,
  currencyCode,
  assets,
  children,
  className,
  sumByField = 'amount',
  colors = defaultColors,
}) => {
  const translate = useTranslation();

  const assetsRef = useRef<HTMLDivElement | null>(null);

  const totalAmount = useMemo(() => sumBy(assets, sumByField), [sumByField, assets]);

  const percentAmounts = useMemo(
    () => assets.map((asset) => (asset.amount / totalAmount) * 100),
    [assets, totalAmount],
  );

  const expanded = useFlag(false);

  return (
    <div className={clsx(classes.root, className)}>
      <div onClick={expanded.toggle} className="pointer">
        <div className="row aic jcsb gap-2">
          <div className="column gap-1-5">
            <span className="label">{label || translate('TOTAL_ASSETS_TITLE')}:</span>

            <AmountLabel
              className={classes.totalAmountLabel}
              amount={totalAmount}
              currencyCode={currencyCode}
              size="xl4"
              showCurrencySymbol
              useCounter
            />
          </div>
          <Icon
            className={clsx(classes.arrow, expanded.state && classes.expanded)}
            name="chevronRight"
          />
        </div>
        <div className={classes.progressLine}>
          {percentAmounts.map((p, index) => (
            <div
              key={index}
              style={{ width: Math.ceil(p).toString() + '%', background: colors[index] }}
            />
          ))}
        </div>
      </div>
      <div
        ref={assetsRef}
        className={classes.assetsContainer}
        style={{ height: expanded.state ? assetsRef.current?.scrollHeight : 0 }}
      >
        <div className={classes.inner}>
          {assets.map((asset, index) => (
            <div key={asset.label} className="row jcsb">
              <DotSeparatedViews
                leftComponent={
                  <div className="row aic">
                    <div className={classes.assetCircle} style={{ background: colors[index] }} />
                    <span className={classes.assetLabel}>{asset.label}:</span>
                  </div>
                }
                rightComponent={
                  <span className={clsx(classes.assetLabel, 'tar')}>
                    {formatCurrencyWithSymbol(asset.amount, asset.currencyCode || currencyCode)}
                  </span>
                }
              />
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};
