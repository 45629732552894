import axios, { CanceledError } from 'axios';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import authServices from 'modules/auth/services';
import { logout } from 'modules/auth/store/externalThunks';
import { selectUserProfile } from 'modules/user/store/selectors';
import store from 'store';

import appConfig from 'constants/appConfig';

import analytic from 'libs/analytic';
import { AnalyticEvents } from 'libs/analytic/events';
import { requestAnalytic } from 'libs/analytic/requestAnalytic';
import { translateApiCode } from 'libs/axios/ApiCodes';
import ApiError from 'libs/axios/ApiError';
import UtilitiesAxiosInstance from 'libs/axios/UtilitiesAxiosInstance';
import { getTranslation } from 'libs/i18n';

import eventEmitter, { eventEmitterEventNames } from 'utils/eventEmitter';

const AxiosInstance = axios.create({ baseURL: appConfig.apiUrl });

AxiosInstance.interceptors.request.use((config) => {
  const authToken = authServices.getAccessToken();

  if (authToken) {
    config.headers!.Authorization = `Bearer ${authToken}`;
  }

  config.headers!['X-Correlation-ID'] = uuidv4();

  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.url) {
      requestAnalytic(response.config.url, true, response.config.data);
    }
    return response.data;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        await authServices.refreshToken();
        return AxiosInstance.request({
          ...error.config,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      store.dispatch(logout());
    }

    if (error instanceof CanceledError) {
      return;
    }

    requestAnalytic(error?.response?.config?.url, false, error?.config?.data);

    let analyticEvent = AnalyticEvents.appNetworkErrorNoStatus;

    const statusCode = error.response?.status || error.request?.status;
    const method = error?.config?.method;

    if (statusCode >= 500) {
      analyticEvent = AnalyticEvents.appNetworkError500;
    } else if (statusCode < 500 && statusCode >= 400) {
      analyticEvent = AnalyticEvents.appNetworkError400;
    }

    let errorString = error.response?.data;

    try {
      if (error.request.responseType === 'blob' && error.response?.data instanceof Blob) {
        errorString = JSON.parse(await error.response.data.text());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error in AxiosInstance');
    }

    analytic.sendEvent(analyticEvent, {
      endpoint: error.config.url,
      error: errorString,
      axiosData: {
        method,
        code: error.code,
        message: error.message,
        requestStatusCode: error.request?.status,
        responseStatusCode: error.response?.status,
      },
    });

    if (statusCode >= 500) {
      eventEmitter.emit(eventEmitterEventNames.internalServerError);
    }

    if (appConfig.env !== 'development') {
      try {
        if (statusCode >= 500) {
          const user = selectUserProfile(store.getState());

          UtilitiesAxiosInstance.post('/front-end/catch-backend-error', {
            id: user?.id || 'No user',
            email: user?.email || 'No user',
            statusCode,
            environment: appConfig.customEnvironment,
            requestUrl: error?.response?.config?.url || 'No url',
            requestMethod: error?.response?.config?.method || 'No method',
            message:
              error.response?.data?.message ||
              error.response?.data?.code ||
              error.message ||
              'No message',
            correlationId: get(error, 'config.headers["X-Correlation-ID"]') || '-',
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Cant send backend error log: ', e);
      }
    }

    const fallback =
      statusCode >= 500
        ? getTranslation('API_ERROR')
        : statusCode === 429
        ? getTranslation('API_ERROR_LIMIT')
        : error.response?.data?.message || getTranslation('API_ERROR');

    throw new ApiError(
      translateApiCode(error.response?.data.code) || fallback,
      error.response?.data,
      statusCode,
    );
  },
);

export default AxiosInstance;
