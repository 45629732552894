export const template = `
<html lang="en">
  <head>
    <title>Loan agreement</title>
    <style>
      body {
        margin: 0;
        padding: 24px;
        color: #2a2b2a;
        font-family: SegoeUI, sans-serif;
      }

      .link {
        text-decoration: underline;
      }
      b {
        font-weight: 600;
      }
      p,
      span {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        padding: 0;
      }
      h1,
      h2,
      h3,
      h4 {
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
      h1 {
        font-size: 28px;
        line-height: 34px;
        margin: 24px 0;
      }
      h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 16px 0;
      }
      h3 {
        font-size: 20px;
        line-height: 24px;
        margin: 12px 0;
      }
      .ml-2 {
        margin-left: 16px;
      }
      .mt-2 {
        margin-top: 16px;
      }
      .mt-4 {
        margin-top: 32px;
      }
    </style>
  </head>
  <body>
    <div>
      <h3>Rintral Trading SL</h3>
      <span> C/ de la Llacuna, 11, 08005, Barcelona, Spain </span>
    </div>

    <h1 class="py-3">Loan Agreement</h1>

    <div>
      <h2>Parties</h2>

      <div class="ml-2">
        <b>{userName}</b> of {userAddress} (Borrower)<br />
        <b>Rintral Trading SL</b> incorporated and registered in Spain with company number B66096686
        whose registered office is at C/ de la Llacuna, 11, 08005, Barcelona, Spain (Lender)
      </div>
    </div>

    <p class="mt-2">
      The Lender has agreed to provide the Borrower with a secured term loan of {loanAmount}
      {loanCurrency}.<br />
      The Lender and the Borrower shall be hereinafter jointly referred to as the
      <b>'Parties'</b>.
    </p>

    <h2>Agreed Terms</h2>

    <h3>1. 1. Loan terms</h3>
    <p>
      Calculated according to Bank of Spain Circular 5/2012 (BOE N 161 from 06.07.2012) and with law
      16/2011, of June 24, on consumer credit contracts.
    </p>

    <h3>1.2. Definitions</h3>
    <p>
      The following definitions apply in this agreement.<br /><br />

      <b>Agreement:</b> this consumer loan agreement.<br /><br />

      <b>BTC:</b> The Bitcoin Cryptocurrency.<br /><br />

      <b>Business Day:</b> a day other than a Saturday, Sunday or public holiday in Spain when banks
      are open for business.<br /><br />

      <b>Customer's Account or Nebeus Account:</b> A User's designated user account on the Nebeus
      desktop platform or Nebeus's mobile application, allowing a User to access and use Nebeus's
      Services, and view and manage his or her personal information and profile. A Nebeus Account is
      not a bank account, deposit account, savings accounts, checking account, or any other type of
      asset account and should not be characterized as a banking product or service.<br /><br />

      <b>Calendar Day:</b> any day of the week, including Saturday, Sunday or public holiday in
      Spain.<br /><br />

      <b>Collateral Pledge:</b> cryptocurrency funds given by the Borrower to Nebeus, used and held
      by Nebeus as a guarantee and security for issuing the Loan to the Borrower.<br /><br />

      <b>Digital Asset or Cryptocurrency:</b> A digital representation of value in which encryption
      techniques are used to regulate the generation of digital units and verify the transfer of
      assets, operating independently from a central bank.<br /><br />

      <b>ETH:</b> The cryptocurrency of the Ethereum network<br /><br />

      <b>Loan:</b> the sum of {loanAmount} {loanCurrency} lent or to be lent by the Lender to the
      Borrower or (as the context requires) the total amount outstanding of that sum.<br /><br />

      <b>Margin call:</b> occurs when the value of the collateral provided to back the Loan reaches
      the set rate due to the cryptocurrency used as collateral (BTC, ETH, or any other currency
      accepted as collateral on Nebeus) losing in value, and the Borrower has not repaid the Loan by
      then, the Collateral Pledge is sold to cover the Loan premium.<br /><br />

      <b>Repayment Date:</b> each of the dates set out in clause 6 below.<br /><br />

      <b>Security:</b> any charge (whether fixed or floating, legal or equitable), pledge, lien,
      assignment by way of security orn other security interest securing any obligation of any
      person or any other agreement or arrangement having a similar effect.<br /><br />

      <b>Security Period:</b> the period starting on the date of this deed and ending on the date on
      which the Lender is satisfied that all the Secured Liabilities have been unconditionally and
      irrevocably paid and discharged in full and no further Secured Liabilities are capable of
      being outstanding.<br /><br />

      <b>Secured Liabilities:</b> all present and future obligations and liabilities of the Borrower
      to the Lender, whether actual or contingent and whether owed jointly or severally, as
      principal or surety or in any other capacity, under or in connection with the Facility
      Agreement or this deed together with all interest (including, without limitation, default
      interest) accruing in respect of those obligations or liabilities.<br /><br />

      <b>Term of the Loan:</b> period of months from the date the Loan is approved, the funds are
      transferred to the Customer\`s Nebeus account, and until the date that the Customer repays the
      totality of the Loan back to Nebeus.<br /><br />

      <b>An origination fee:</b> a one-time fee charged by a lender for processing a new loan
      application. It is a percentage (2%) of the total loan amount and is used to cover the costs
      of underwriting, processing, and approving the loan. The lender is authorized to deduct the
      fee from the Loan Principal Amount. For loans with Interest Only Repayment, no origination fee
      will be charged.<br /><br />

      <b>USDC:</b> a Digital Asset that is Pegged to a distinct asset<br /><br />

      <b> USDT:</b> a Digital Asset that is Pegged to a distinct asset<br /><br />
    </p>

    <h3>1.3 Interpretation</h3>
    <p>
      In this agreement, clause, schedule, and paragraph headings shall not affect the
      interpretation of this agreement; a reference to a person shall include a reference to an
      individual, firm, company, corporation, partnership, unincorporated body of persons,
      government, state or agency of a state or any association, trust, joint venture, or consortium
      (whether or not having separate legal personality); unless the context otherwise requires,
      words in the singular shall include the plural, and in the plural shall include the singular;
      unless the context otherwise requires, a reference to one gender shall include a reference to
      the other genders; a reference to a party shall include that party's successors, permitted
      assigns and permitted transferees and this agreement shall be binding on, and ensure to the
      benefit of, the parties to this agreement and their respective [personal representatives,]
      successors, permitted assigns and permitted transferees; a reference to a statute or statutory
      provision is a reference to it as amended, extended or re-enacted from time to time; a
      reference to a statute or statutory provision shall include all subordinate legislation made
      from time to time under that statute or statutory provision; a reference to a time of day is
      to London and Madrid time;a reference to writing or written includes fax and email; an
      obligation on a party not to do something includes an obligation not to allow that thing to be
      done; a reference to this agreement (or any provision of it) or to any other agreement or
      document referred to in any Finance Document is a reference to this agreement, that provision
      or such other agreement or document as amended (in each case, other than in breach of the
      provisions of this agreement) from time to time; unless the context otherwise requires, a
      reference to a clause or Schedule is to a clause of, or Schedule to, this agreement and a
      reference to a paragraph is to a paragraph of the relevant Schedule; any words following the
      terms including, include, in particular, for example or any similar expression shall be
      construed as illustrative and shall not limit the sense of the words, description, definition,
      phrase or term preceding those terms; a reference to a document in agreed form is to that
      document in the form agreed by the Lender and the Borrower and initialled by or on their
      behalf for identification; a reference to an amendment includes a novation, re-enactment,
      supplement or variation (and amended shall be construed accordingly); a reference to assets
      includes present and future properties, undertakings, revenues, rights and benefits of every
      description; a reference to an authorization includes an approval, authorization, consent,
      exemption, filing, license, notarization, registration and resolution; a reference to a
      certified copy of a document means a copy certified to be a true, complete and up-to-date copy
      of the original document, in writing and signed by a director or the secretary of the party
      delivering the document; a reference to continuing in relation to an Event of Default means an
      Event of Default that has not been remedied or waived; a reference to determines or determined
      means, unless the contrary is indicated, a determination made at the discretion of the person
      making it; a reference to a disposal of any asset, undertaking or business includes a sale,
      lease, license, transfer, loan or other disposal by a person of that asset, undertaking or
      business (whether by a voluntary or involuntary single transaction or series of transactions);
      and a reference to a regulation includes any regulation, rule, official directive, request or
      guideline (whether or not having the force of law) of any governmental, inter-governmental or
      supranational body, agency, department or regulatory, self-regulatory or other authority or
      organization; and unless the context otherwise requires, any reference to European Union law
      that is directly applicable or directly effective in the UK at any time is a reference to it
      as it applies in England and Wales from time to time including as retained, amended, extended,
      re-enacted or otherwise given effect on or after 11pm on 31 January 2020.
    </p>

    <h3>2. Loan</h3>
    <p>
      2.1 The Lender grants to the Borrower a secured EUR term Loan of a total principal amount not
      exceeding {loanAmount} {loanCurrency} on the terms, and subject to the conditions, of this
      Agreement.
    </p>

    <h3>3. Purpose of Loan</h3>
    <p>
      3.1 The Borrower shall use all money borrowed under this agreement for any purpose.<br />
      3.2 The Lender is not obliged to monitor or verify how any amount advanced under this
      Agreement is used.
    </p>

    <h3>4. Term</h3>
    <p>
      4.1 The Agreement will be in force from the date of its signature by the Borrower. The
      Agreement shall also be deemed to have expired and therefore be terminated on the date of the
      last expiry date provided for in the Clause 6 of this Agreement.<br />
      4.2 This Agreement shall take full effect as between the Parties when, after the time limit
      for the Borrower to exercise its right of withdrawal set out in the Clause 5, the Borrower has
      not done so.
    </p>

    <h3>5. Right of withdrawal</h3>
    <p>
      5.1 In accordance with the provisions of Royal Legislative Decree 1/2007, of 16 November,
      which approves the revised text of the General Law for the Defense of Consumers and Users and
      other complementary laws, and Law 16/2011, of 24 June, on consumer loan agreements, the
      Borrower shall have a period of fourteen (14) Calendar Days to withdraw from the Agreement
      without indicating the reasons or incurring any penalties. If the Borrower withdraws from the
      Agreement, the Lender shall be entitled to receive fair and objectively justified compensation
      for all costs directly arising from the Loan, and an early repayment fee, equal to 2,5% of the
      full Loan amount, will be charged.<br />
      5.2 If the Borrower intends to exercise this right, he/she must exercise this right through
      their Nebeus Account by closing and repaying the Loan, or by notifying the Lender through
      Nebeus's support ticketing system,(found at
      <a
        rel="noopener noreferrer"
        target="_blank"
        class="link"
        href="https://help.nebeus.com/help-center/"
        >support.nebeus.com</a
      >) where a member of Nebeus's support staff will give instructions to the Borrower on how to
      exercise this right.<br />
      5.3 The Borrower who exercises the right of withdrawal shall pay to the Lender the total
      principal loan amount, the interest accrued on that Loan between the date of disposal of the
      Loan and the effective date of repayment of the Loan, fair and objectively justified
      compensation for all costs directly arising from the Loan, and an early repayment fee, equal
      to 2,5% of the full Loan amount, without delay. The Borrower will not withdraw from the
      Agreement, and the Agreement will remain in effect until the above-mentioned payments are made
      to the Lender.<br />
      5.4 The right of withdrawal set out in this Clause shall not be applicable to those loan
      agreements with a value of more than €75,000.
    </p>

    <h3>6. Repayment</h3>
    <p>
      6.1 The Borrower will repay to the Lender the Loan together with accrued interest in full by
      repaying the amounts set out below in Appendix A on each Repayment Date, on that Repayment
      Date or, if earlier, following a demand by the Lender at any time after an Event of Default
      has occurred, together in each case with all accrued interest and other amounts outstanding
      under this agreement. Repayment Date Amount Indicated in the Loans section of the Customer's
      Account, and in the Appendix A.<br />
      6.2 Monthly payments are set to be withdrawn automatically from the Customer's Account in the
      currency in which the loan was issued (Euro, GBP, USD, USDC or USDT).<br />
      6.3 Three days before the monthly payment is due the Borrower will receive an email
      reminder.<br />
      6.4 One day before the monthly payment is due the Borrower will receive the second email
      reminder.
    </p>

    <h3>7. Early repayment</h3>
    <p>
      The Borrower may choose to repay his outstanding Loan balance early in full.<br />
      7.1 In the case of Quick Loans, the Borrower shall not be charged any interest costs, as the
      interest rate for Quick Loans is 0%. No early repayment penalty fee will be charged for Quick
      Loans. However, the Borrower might be charged any outstanding fines or late payments, if any
      are outstanding.<br />
      7.2 In the case of Flexible Loans, the Borrower shall be charged the remaining amount of the
      Loan interest and an early repayment penalty fee of 2,5%, which is charged for Flexible Loans
      when repaying early. The Borrower might also be charged any outstanding fines or late payments
      if any are outstanding.<br />
      The Borrower may request, free of charge, a statement of account in the form of a repayment
      chart. In the event of early repayment of a Flexible Loan, the Lender shall be entitled to
      receive fair and objectively justified compensation for all costs directly arising from the
      early repayment of the Loan. Such compensation will be equal to 2,5% of the full Loan
      amount.<br />
      Notwithstanding the above, no compensation shall exceed the amount of interest that the
      Borrower would have paid during the period of time between the early repayment and the agreed
      date of termination of the Loan Agreement. For clarification purposes, by application of the
      provisions of Article 4 of Law 16/2011 of 24 June on Consumer Loan Agreements, the above shall
      not apply to loan agreements for amounts exceeding €75,000.
    </p>

    <h3>8. Interest</h3>
    <p>
      8.1 The Borrower shall pay a nominal interest on the Loan at the rate of {interestRate}% per
      annum as varied from time to time.<br />
      8.2 Interest shall accrue daily and be payable monthly, in arrear, on the last Business Day of
      each month or on the corresponding date if the contract is terminated early for any reason.<br />
      8.3 Interest shall be payable from the time of payment.<br />
      8.4 The formula agreed upon by the parties to calculate the amount of accrued interest shall
      be as follows:<br /><br />

      PV = P x ( 1 - ( 1 + r )-n) / r<br /><br />

      PV = present value of an ordinary annuity<br />
      P = value of each payment<br />
      r = interest rate per period<br />
      n = number of periods
    </p>

    <h3>9. Default Interest</h3>
    <p>
      9.1 Overdue and unpaid Loan installments and unpaid amounts, including commissions and
      expenses charged to the Borrower, will accrue to the Lender default interest from the due date
      until full repayment, without the need for a notice to be sent to the Borrower by the
      Lender.<br />
      9.2 In the event of a late payment, a fee in the amount of 0.1% of the Loan premium will be
      charged daily for 10 calendar days before the collateral amount needed to cover the loan is
      sold and the loan is closed.<br />
      9.3 On 3rd, 6th and 9th calendar day after the payment due date the Borrower will receive late
      payment notification emails.
    </p>

    <h3>10. Annual Percentage Rate</h3>
    <p>
      10.1 The Annual Percentage Rate is set at 0.67 %.<br />
      10.2 Annual Percentage Rate ("APR") means the total cost of the Loan, expressed as an annual
      percentage of the amount of Loan granted. For information purposes, it should be noted that
      this APR is obtained by applying the formula contained in Law 16/2011 of 24 June on Consumer
      Loan Agreements (Annex I of that Law).<br />
      10.3 The APR formula is also included in Annex VII of Bank of Spain Circular 5/2012 of 27
      June, to credit institutions and payment service providers on transparency of banking services
      and responsibility in the granting of loans.<br />
      10.4 For the calculation of the APR, all costs are considered, including interest,
      commissions, taxes and any other costs that the Borrower must pay in connection with the Loan
      Agreement and that are known to the Lender, with the exception of notary fees.<br />
      10.5 The calculation is made on the assumption that the Agreement will remain in force
      throughout its duration and that the Parties will fulfill their obligations under the agreed
      terms and conditions.
    </p>

    <h3>11. Costs and Taxes</h3>
    <p>
      11.1 The Borrower will pay to, or reimburse, the Lender on demand, on a standard basis, all
      costs and liabilities incurred by the Lender, in relation to suing for, or recovering, the
      Loan.
      <br />
      11.2 Any expenses, taxes or liens of any kind arising as a result of the execution and
      performance of this Agreement, or the fees accrued by notaries public, or from registrations
      of guarantees in any Registry, shall be borne by the Borrower.
    </p>

    <h3>12. Assignment and transfer</h3>
    <p>
      12.1 The Borrower may not assign or transfer any of their rights or obligations under this
      Agreement. 12.2 The Lender reserves the right to freely assign to any third party the rights
      and obligations arising from this Agreement, as well as any other guarantee formalized on the
      occasion of this Agreement, communicating this assignment to the Borrower. All without
      prejudice to the provisions of Article 31 of Law 16/2011 of 24 June on Consumer Loan
      Agreements.
    </p>

    <h3>13. Events of Default</h3>
    <p>
      13.1 The following will be considered as Events of Default: Non payment at the end of tenth
      day following the repayment due date, bankruptcy of the Borrower, death or declaration of
      incapacity of the Borrower.<br />
      When, due to unforeseen circumstances or force major, the Borrower is unable to comply with
      the essential obligations assumed under this Agreement. When any other essential obligation of
      this Agreement is breached by the Borrower.<br />
      13.2 In case of Event of Default by the Borrower and in accordance with the provisions of
      Article 1.124 of the Civil Code, the Lender may choose to terminate this Agreement or demand
      its compliance, in both cases claiming the corresponding damages.<br />
      13.3 Both the termination of this Agreement and the requirement for its compliance shall be
      communicated to the Borrower by registered letter with acknowledgement of receipt, telegram,
      telex, or in any other reliable manner, addressed to the address indicated in this Agreement
      or to that other address duly communicated in writing to the Lender.<br />
      13.4 Likewise, at the occurrence of the Event of Default provided for in the section "a" and
      "b" of the Clause 13.1., a portion of the Collateral Pledge is liquidated to cover the loan
      premium. The remaining crypto balance is then deposited back to Borrower\`s account in the form
      of the original crypto used.
    </p>

    <h3>14. Account Statement</h3>
    <p>
      14.1 The Borrower is entitled to receive a statement of account in the form of a repayment
      chart free of charge on request and at any time during the term of this Agreement,
      particularly in the event of capital repayment.
    </p>

    <h3>15. Covenants</h3>
    <p>
      15.1 The Borrower covenants with the Lender that, as from the date of this Agreement and until
      all of the liabilities underthis Agreement have been discharged, the Lender will not create,
      or permit to subsist, any Security on or over theCollateral Pledge other than Security created
      by this Agreement.<br />
      15.2 The Borrower shall, on demand, pay to the Lender and discharge the Secured Liabilities
      when they become due.
    </p>

    <h3>16. Guarantee Deposit/Collateral Pledge</h3>
    <p>
      16.1. As a continuing security for the payment and discharge of the Secured Liabilities, the
      Borrower with full title guarantee charges to the Lender by way of first fixed charge, all of
      its present and future right, title, benefit and interest in and to the Collateral Pledge held
      in their Customer account deposited on the Nebeus platform.<br />
      16.2. The Borrower is the sole legal and beneficial owner of the Collateral Pledge, and no
      other security agreement, financing statement, or other security instrument covering the
      Collateral Pledge exists.<br />
      16.3. The Collateral Pledge is free from any Security other than the Security created by this
      agreement.
    </p>

    <h3>17. Margin Call</h3>
    <p>
      17.1 The Lender provides the Borrower with cryptocurrency rate at which the margin call would
      take place (when).<br />
      17.2 The Borrower can access their Customer account which shows the value of the collateral
      against the Secured Asset.<br />
      17.3 Once the value of the collateral provided reaches the set rate due to the cryptocurrency
      (BTC, ETH, or any other currency used as collateral for the Loan) losing value and the
      Borrower has not repaid the loan, then the Collateral Pledge is sold to cover the loan
      premium.<br />
      17.4 Once the overall value of the Security cushion falls by 20%, the Borrower is notified by
      email which prompts him, in order to avoid the onset of a margin call, to increase the
      Collateral Pledge by at least 20%.<br />
      17.5 If the price of the cryptocurrency (BTC, ETH, or any other currency used as collateral
      for the Loan) keeps decreasing,the Borrower is notified daily and encouraged to increase the
      amount of the collateral.<br />
      17.6 If the value of the Security cushion falls 100% (i.e. the loan amount given and the value
      of the crypto pledged become the same) margin call happens and the crypto used as collateral
      is automatically sold to cover the Loan.
    </p>

    <h3>18. Bankruptcy</h3>
    <p>
      Either:<br />
      the Borrower stops or suspends payment of any of its debts or is unable to pay any of its
      debts as they fall due; or a petition for a bankruptcy order is presented or a bankruptcy
      order is made against the Borrower or the Borrower makes an application for a bankruptcy order
    </p>

    <h3>19. Notices</h3>
    <p>
      19.1 Any notice or other communication given under this Agreement must be in writing.<br />
      19.2 Any notice or other communication to be given under this Agreement must be given to the
      relevant party at the relevant address stated at the start of this agreement or as otherwise
      specified by the relevant party in writing to the other party.
    </p>

    <h3>20. No adverse claims</h3>
    <p>
      20.1 The Borrower has not received, or acknowledged notice of, any adverse claim by any person
      in respect of the Collateral Pledge or any interest in it.
    </p>

    <h3>21. No adverse covenants</h3>
    <p>
      21.1 There are no covenants, agreements, reservations, conditions, interests, rights or other
      matters whatsoever that materially and adversely affect the Collateral Pledge.
    </p>

    <h3>22. No breach of laws</h3>
    <p>
      22.1 There is no breach of any law or regulation that materially and adversely affects the
      Collateral Pledge.
    </p>

    <h3>23. Enforceable security</h3>
    <p>
      23.1 This agreement is signed as a deed and constitutes and will constitute the legal, valid,
      binding and enforceable obligations of the Borrower, and is, and will continue to be,
      effective security over all and every part of the Secured Assets in accordance with its terms.
    </p>

    <h3>24. Payment of account charges</h3>
    <p>
      24.1 The Borrower shall promptly pay all charges and other outgoings in respect of the
      Collateral Pledge and, on demand, produce evidence of payment to the Lender.
    </p>

    <h3>25. Security becomes enforceable on Event of Default</h3>
    <p>
      25.1 The security constituted by this deed shall become immediately enforceable if an Event of
      Default occurs.
    </p>

    <h3>26. Order of application of proceeds</h3>
    <p>
      26.1 All monies received or recovered by the Lender under this deed or in connection with the
      realization or enforcement of all or part of the Collateral Pledge constituted by this deed,
      shall (subject to the claims of any person having prior rights and by way of variation of the
      LPA 1925) be applied in the following order of priority (but without prejudice to the Lender's
      right to recover any shortfall from the Borrower):<br />
      in or towards payment of all costs, liabilities, charges and expenses incurred by or on behalf
      of the Lender (and any delegate, attorney or agent appointed by it) under or in connection
      with this deed;<br />
      in or towards payment of the Secured Liabilities in any order and manner that the Lender
      determines; and in payment of the surplus (if any) to the Borrower or other person entitled to
      it.
    </p>

    <h3>27. Rights and remedies</h3>
    <p>
      27.1 The rights and remedies provided under this deed are cumulative and are in addition to,
      and not exclusive of, any rights and remedies provided by law.
    </p>

    <h3>28. Personal Data Processing</h3>
    <p>
      28.1 By virtue of this Agreement, the Borrower is hereby informed of the fact that any
      personal data included therein shall be inserted in one or more personal data files of the
      Lender as defined in Law 3/2018, 5th December on the Protection of Personal Data and the
      guarantee of digital rights.<br />
      28.2 In order to comply with current legislation, the Borrower is hereby informed of his right
      to access his personal data; to rectify his personal data, if erroneous; to cancel his
      personal data; and/or to object to data processing, right to oblivion, right to portability
      and data limitation, all this, in the event that personal data are incorrect or incomplete.
      These rights may be exercised by the Borrower requesting the exercise of the corresponding
      right to the following e-mail direction {userEmail}, by addressing a letter duly signed and
      including his full name, address and photocopy of his I.D. or any other valid document.
    </p>

    <h3>29. Entire agreement, Amendments and Invalidity</h3>
    <p>
      29.1 This Agreement, along with any exhibits, appendices, schedules, and amendments hereto,
      encompasses the entire agreement of the parties, and supersedes all previous understandings
      and agreements between the Parties.<br />
      29.2 No modification or waiver of the rights conferred by this contract shall be valid unless
      made in writing and signed by the relevant parties.<br />
      29.3 If any provision (or part of a provision) of this deed is or becomes invalid, illegal or
      unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid,
      legal and enforceable. If such modification is not possible, the relevant provision (or part
      of a provision) shall be deemed deleted. Any modification to or deletion of a provision (or
      part of a provision) under this clause shall not affect the legality, validity and
      enforceability of the rest of this deed.
    </p>

    <h3>30. Counterparts</h3>
    <p>
      30.1 This deed may be executed in any number of counterparts, each of which when executed and
      delivered shall constitute a duplicate original, but all the counterparts shall together
      constitute one deed.<br />
      30.2 Transmission of the executed signature page of a counterpart of this deed by fax or email
      (in PDF, JPEG or other agreed format) shall take effect as delivery of an executed counterpart
      of this deed. If either method of delivery is adopted, without prejudice to the validity of
      the deed thus made, each party shall provide the others with the original of such counterpart
      as soon as reasonably possible thereafter.<br />
      30.3 No counterpart shall be effective until each party has executed and delivered at least
      one counterpart.
    </p>

    <h3>31. Third party rights</h3>
    <p>
      31.1 Except as expressly provided elsewhere in this deed, a person who is not a party to this
      deed shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to
      enforce, or enjoy the benefit of, any term of this deed. This does not affect any right or
      remedy of a third party which exists, or is available, apart from that Act.<br />
      31.2 The rights of the parties to rescind or agree any amendment or waiver under this deed are
      not subject to the consent of any other person.
    </p>

    <h3>32. Delivery</h3>
    <p>
      32.1 Any notice or other communication given to a party under or in connection with this deed
      shall be:<br /><br />

      sent to:<br /><br />

      The Borrower at:<br /><br />
      <b>Address:</b> {userAddress} <br />
      <b>Email:</b> {userEmail}<br />
      <b>Attention</b> {userName} <br /><br />

      (ii) the Lender at:<br />
      <b>Rintral Trading SL</b><br />
      C/ de la Llacuna, 11, 08005, Barcelona, Spain
      <br />
      <br />

      <b>Email:</b> <a href="mailto:info@nebeus.com">info@nebeus.com</a>
      <br />
      <br />

      or to any other address or fax number/emails as is notified in writing by one party to the
      other from time to time.<br /><br />

      32.2 This clause does not apply to the service of any proceedings or other documents in any
      legal action or, where applicable, any arbitration or other method of dispute resolution.
    </p>

    <h3>33. Governing law</h3>
    <p>
      33.1 This agreement and any dispute or claim (including non-contractual disputes or claims)
      arising out of or in connection with it or its subject matter or formation will be governed by
      and construed in accordance with the law of Spain.<br />
      33.2 The parties agree to submit expressly to the scope of application of Law 16/2011 of 24
      June on Consumer Loan Agreements as a priority.
    </p>

    <h3>34. Jurisdiction</h3>
    <p>
      In relation to any legal action or proceedings arising out of or in connection with this
      Agreement, the Parties irrevocably submit to the exclusive jurisdiction of the Courts and
      Tribunals of the domicile of the Borrower. waiving their right to any other forum to which
      they may be entitled to. This agreement takes effect on {agreementDate}.
    </p>

    <p class="mt-4">
      Signed by <b>{userName}</b>:<br />
      ....................<br />
      [ELECTRONIC SIGNATURE OF BORROWER]<br /><br /><br />

      Signed by Sergey Romanovskiy, on behalf of Rintral Trading SL:<br />
      ....................<br />
      [ELECTRONIC SIGNATURE OF LENDER]
    </p>
  </body>
</html>


`;
