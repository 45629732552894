import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'App';
import { PersistGate } from 'redux-persist/integration/react';
import ResizeObserver from 'resize-observer-polyfill';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux/es';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import MaintenanceCheck from 'modules/app/views/MaintenanceCheck';
import { isReactNative } from 'modules/reactNative/utils';
import store, { persistor } from 'store';

import appConfig from 'constants/appConfig';

import { Loader } from 'components/ui';

import analytic from 'libs/analytic';
import Firebase from 'libs/firebase';
import SentryErrorBoundary from 'libs/sentry/SentryErrorBoundary';
import { onSentryError } from 'libs/sentry/utils';

import { ReactComponent as Sprite } from 'assets/sprite.svg';

import { isMacOS } from 'utils/common';

import 'styles/index.scss';

if (!isMacOS) {
  require('styles/_beautyScroll.scss');
}

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (!appConfig.isDev) {
  Sentry.init({
    dsn: appConfig.sentryUrl,
    debug: appConfig.isDev,
    environment: appConfig.env,
    release: appConfig.version,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
Firebase.init();
analytic.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
if (isReactNative) {
  document.body.classList.add('reactNative');
}

root.render(
  <Sentry.ErrorBoundary onError={onSentryError} fallback={SentryErrorBoundary} showDialog>
    <GoogleReCaptchaProvider reCaptchaKey={appConfig.recaptchaKey} useEnterprise>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <BrowserRouter>
            <MaintenanceCheck>
              <App />
            </MaintenanceCheck>
            <Sprite />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  </Sentry.ErrorBoundary>,
);
