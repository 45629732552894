import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useDrawer from 'modules/app/hooks/useDrawer';
import { selectIsUserAuthed } from 'modules/auth/store/selectors';
import cryptoBankCardDrawerTemplates from 'modules/cryptoBankCard/constants/drawerTemplates';
import {
  selectBankCardsOrdersReducer,
  selectBankCardsReducer,
  selectIsCryptoBankCardAllowed,
} from 'modules/cryptoBankCard/store/selectors';
import { requestBankCardsOrders } from 'modules/cryptoBankCard/store/thunks';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import { selectTemplatesReducer } from 'modules/cryptoRenting/store/selectors';
import { requestTemplates } from 'modules/cryptoRenting/store/thunks';

import localStorageKeys from 'constants/localStorageKeys';

import usePersistedState from 'hooks/usePersistedState';
import useStoreEntity from 'hooks/useStoreEntity';

import { DEVICE_PLATFORM } from 'utils/device';

const ONE_DAY_IN_MS = 60 * 60 * 24 * 1000;
const ONE_WEEK_IN_MS = ONE_DAY_IN_MS * 7;

const useAdBanners = () => {
  const drawer = useDrawer();

  const authed = useSelector(selectIsUserAuthed);
  const isCryptoBankCardAllowed = useSelector(selectIsCryptoBankCardAllowed);

  const cryptoBankCardsReducer = useSelector(selectBankCardsReducer);
  const { entityReducer: bankCardOrdersReducer } = useStoreEntity(
    selectBankCardsOrdersReducer,
    requestBankCardsOrders,
  );

  const [lastTimeViewedCryptoCardAd, setLastTimeViewedCryptoCardAd] = usePersistedState<number>(
    localStorageKeys.cryptoBankCardAdvertisementSidebarRejectTime,
  );

  // // Referral links promo
  // useEffect(() => {
  //   if (authed && userProfileReducer.data?.verified) {
  //     const referralLinksLastShowedTime = localStorage.getItem(
  //       localStorageKeys.referralLinksLastShowedTime,
  //     );
  //     if (
  //       !referralLinksLastShowedTime ||
  //       Date.now() - Number(referralLinksLastShowedTime) > ONE_WEEK_IN_MS
  //     ) {
  //       localStorage.setItem(localStorageKeys.referralLinksLastShowedTime, Date.now().toString());
  //       drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [authed, userProfileReducer.data?.verified]);

  const {
    entityReducer: {
      meta: { loaded: rentingTemplatesLoaded },
    },
  } = useStoreEntity(selectTemplatesReducer, requestTemplates, { staleAllowed: true });

  // Renting new coins
  useEffect(() => {
    if (authed && rentingTemplatesLoaded) {
      const rentingAdLastShowedTime = localStorage.getItem(
        localStorageKeys.rentingNewCoinsBannerCloseTime,
      );
      if (
        !rentingAdLastShowedTime ||
        Date.now() - Number(rentingAdLastShowedTime) > ONE_WEEK_IN_MS
      ) {
        localStorage.setItem(
          localStorageKeys.rentingNewCoinsBannerCloseTime,
          Date.now().toString(),
        );
        drawer.open(rentingDrawerTemplates.rentingNewCoinsAd());
      }
    }
    // eslint-disable-next-line
  }, [authed, rentingTemplatesLoaded]);

  useEffect(() => {
    // Shows for users who allowed bank card and who didn't see earlier
    // this promo or users who haven't orders and cards and cards allowed
    // for him and user didn't see promo earlier or per one day
    if (
      (!lastTimeViewedCryptoCardAd && isCryptoBankCardAllowed) ||
      (authed &&
        !bankCardOrdersReducer.data.length &&
        cryptoBankCardsReducer.meta.loaded &&
        !cryptoBankCardsReducer.data.length &&
        isCryptoBankCardAllowed &&
        (!lastTimeViewedCryptoCardAd || Date.now() - lastTimeViewedCryptoCardAd > ONE_DAY_IN_MS))
    ) {
      setLastTimeViewedCryptoCardAd(Date.now());
      drawer.open(cryptoBankCardDrawerTemplates.introducing());
    }
    // eslint-disable-next-line
  }, [
    bankCardOrdersReducer.data.length,
    authed,
    isCryptoBankCardAllowed,
    cryptoBankCardsReducer.meta.loaded,
  ]);

  // Card with Google wallet ad
  useEffect(() => {
    if (
      DEVICE_PLATFORM === 'ANDROID' &&
      isCryptoBankCardAllowed &&
      cryptoBankCardsReducer.meta.loaded &&
      !localStorage.getItem(localStorageKeys.virtualCardGoogleWalletAdShowedTime)
    ) {
      localStorage.setItem(
        localStorageKeys.virtualCardGoogleWalletAdShowedTime,
        Date.now().toString(),
      );

      const userHasVirtualCard = !!cryptoBankCardsReducer.data.find((c) => c.isVirtual);

      drawer.open(
        cryptoBankCardDrawerTemplates.googleWalletOptionAd({ cardExist: userHasVirtualCard }),
      );
    }
    // eslint-disable-next-line
  }, [isCryptoBankCardAllowed, cryptoBankCardsReducer.meta.loaded]);
};

export default useAdBanners;
