import {
  DigitalWallet,
  GetIssuersApiResponse,
  Issuer,
  TradingWallet,
  Transaction,
  Wallet,
} from 'modules/accounts/types';
import { selectUserDefaultCurrencyCode } from 'modules/user/store/selectors';
import store from 'store';
import { transformList } from 'store/generators/transformers';

import {
  BaseCardTransactionHistoryResult,
  BaseTransactionHistoryResult,
  Issuer as IssuerApi,
  ModulrWalletResponseList,
  MoneyWalletResponse,
  TradingWallet as TradingWalletAPI,
  TradingWalletResponse,
  TradingWalletTransactionResponseList,
} from 'libs/swagger/nebeusApiTypes';

import { getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CurrencyCode } from 'types';

const sortWalletsByAmount = (a: Wallet, b: Wallet) => {
  return b.amountInDefaultCurrency - a.amountInDefaultCurrency;
};
export const transformWallets = (result: TradingWalletResponse[]): TradingWallet[] => {
  const transformed = transformList<TradingWalletResponse, TradingWallet>(result, [
    ['currency', 'currencyCode'],
    // @ts-ignore
    ['currency', 'currencyLabel', { custom: getCurrencyLabelByCode }],
    ['issuerId'],
    ['address', 'address'],
    ['coinSerial', 'coinSerial'],
    [
      'exist',
      'exist',
      {
        custom: (_, entity) =>
          isFiat(entity.currency as CurrencyCode) || !!entity.address || entity.currency === 'NBT',
      },
    ],
    ['availableAmount', 'amount', { convertToNumber: true }],
    ['defaultCurrencyAmount', 'amountInDefaultCurrency'],
    ['defaultCurrency', 'defaultCurrencyCode'],
  ]);

  const fiat: TradingWallet[] = [];
  const crypto: TradingWallet[] = [];
  transformed.forEach((w) => {
    if (isFiat(w.currencyCode)) {
      fiat.push(w);
    } else {
      crypto.push(w);
    }
  });

  fiat.sort(sortWalletsByAmount);
  crypto.sort(sortWalletsByAmount);

  return [...fiat, ...crypto];
};

export const transformDigitalWallets = (result: ModulrWalletResponseList): DigitalWallet[] =>
  transformList<MoneyWalletResponse, DigitalWallet>(result.modulrWallets, [
    ['availableAmount', 'amount', { convertToNumber: true }],
    // @ts-ignore
    ['currency', 'currencyLabel', { custom: getCurrencyLabelByCode }],
    ['currency', 'currencyCode'],
    ['issuerId'],
    ['coinSerial'],
    ['bankName'],
    ['bic'],
    // @ts-ignore
    ['iban', 'iban', { custom: (value) => value || '-' }],
    // @ts-ignore
    ['sortCode', 'sortCode', { custom: (value) => value || '-' }],
    // @ts-ignore
    ['accountNumber', 'accountNumber', { custom: (value) => value || '-' }],

    ['ownerName'],
    ['exist', 'exist', { custom: () => true }],
    ['defaultCurrencyAmount', 'amountInDefaultCurrency'],
    ['defaultCurrency', 'defaultCurrencyCode'],
    ['defaultCurrency', 'isDigitalWallet', { custom: () => true }],
    ['default'],
  ]);

export const transformCreateWalletResponse = (result: TradingWalletAPI): TradingWallet => ({
  currencyCode: result.currencyCode as CurrencyCode,
  currencyLabel: getCurrencyLabelByCode(result.currencyCode as CurrencyCode),
  issuerId: result.issuerId,
  address: result.bitGoAddress,
  coinSerial: result.sdkCoinSerial,
  exist: true,
  amount: 0,
  amountInDefaultCurrency: 0,
  defaultCurrencyCode: selectUserDefaultCurrencyCode(store.getState()),
});
export const transformIssuers = (result: GetIssuersApiResponse): Issuer[] =>
  transformList<IssuerApi, Issuer>(result.issuers, [
    ['id'],
    ['active'],
    ['currency.code', 'currencyCode'],
    ['currency.symbol', 'currencySymbol'],
    ['currency.name', 'currencyLabel'],
  ]);

const statusMap: { [key: string]: Transaction['status'] } = {
  CREATED: 'created',
  PROCESSED: 'completed',
  REJECTED: 'rejected',
  FAILED: 'failed',
  MONITORING_REJECT: 'failed',
  PENDING: 'pending',
  MONITORING_APPROVED: 'pending',
  DECLINED: 'failed',
  CANCELLED: 'cancelled',
};
export const transformTradingTransactions = (
  response: TradingWalletTransactionResponseList,
  currencyCode?: CurrencyCode,
) =>
  transformList<BaseTransactionHistoryResult, Transaction>(response.tradingTransactions, [
    ['id'],
    [
      'id',
      'uniqId',
      {
        custom: (_, t) => `${t.id}${t.description}${t.currencyCode || currencyCode}${t.amount}`,
      },
    ],
    ['requestIdentifier'],
    ['amount'],
    ['amountInDefaultCurrency'],
    ['defaultCurrency', 'defaultCurrencyCode'],
    [
      'currencyCode',
      'currencyCode',
      // @ts-ignore
      { custom: (code: CurrencyCode | string) => code || currencyCode },
    ],
    ['dateCreated'],
    [
      'status',
      'status',
      // @ts-ignore
      { custom: (status: string) => statusMap[status?.toUpperCase()] || 'completed' },
    ],
    [
      'description',
      'description',
      // @ts-ignore
      { custom: (description: string, transaction) => description || transaction.type },
    ],
    ['type', 'operationId'],
    ['amount', 'isIncome', { custom: (amount) => amount > 0 }],
    ['additionalInfo'],
  ]);
export const transformBankCardTransactionResponse = (
  response: BaseCardTransactionHistoryResult[],
) =>
  transformList<BaseCardTransactionHistoryResult, Transaction>(response, [
    ['id'],
    [
      'id',
      'uniqId',
      {
        custom: (_, t) => `${t.id}${t.description}${t.currencyCode}${t.amount}`,
      },
    ],
    ['requestIdentifier'],
    ['amount'],
    ['amountInDefaultCurrency'],
    ['defaultCurrency', 'defaultCurrencyCode'],
    ['currencyCode'],
    ['dateCreated'],
    [
      'status',
      'status',
      // @ts-ignore
      { custom: (status: string) => statusMap[status?.toUpperCase()] || 'completed' },
    ],
    [
      'description',
      'description',
      // @ts-ignore
      { custom: (description: string, transaction) => description || transaction.type },
    ],
    ['type', 'operationId'],
    ['amount', 'isIncome', { custom: (amount) => amount > 0 }],
    ['additionalInfo'],
  ]);
