import { FC, useCallback, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import { DigitalWallet } from 'modules/accounts/types';
import useDrawer from 'modules/app/hooks/useDrawer';
import IbanAccountSubscriptionMark from 'modules/digitalAccount/views/IbanAccountSubscriptionMark';
import paymentDrawerTemplates from 'modules/payment/constants/drawerTemplates';
import usePaymentInit from 'modules/payment/hooks/usePaymentInit';
import {
  requestSendFromCryptoToDigitalAccount,
  requestSendFromDigitalToCryptoAccount,
} from 'modules/payment/store/thunks';
import { PaymentOperationId } from 'modules/payment/types';
import { AmountForm, WalletBalanceCard } from 'modules/payment/views/components';
import { PaymentAmountFormValues } from 'modules/payment/views/components/AmountForm';
import useAmountFormValidation from 'modules/payment/views/components/AmountForm/hooks/useAmountFormValidation';
import { WithdrawalLimitsCard } from 'modules/payment/views/components/WithdrawalLimitsCard';
import { useDispatch } from 'store';

import { getTranslation, useTranslation } from 'libs/i18n';
import { makeValidate } from 'libs/yup';

import { formatCurrencyWithSymbol } from 'utils/currency';
import { formatDDMMYY_HHMM } from 'utils/date';

import { FiatCurrencyCode } from 'types';

interface FormValues extends PaymentAmountFormValues {}
export interface SendToDAOrCAProps {
  isDigitalAccount: boolean;
  currencyCode: FiatCurrencyCode;
}

interface SendToDAOrCAFormProps extends FormRenderProps<FormValues> {
  isDigitalAccount: boolean;
}

const SendToDAOrCAForm: FC<SendToDAOrCAFormProps> = ({ handleSubmit, isDigitalAccount }) => {
  return (
    <form onSubmit={handleSubmit}>
      <AmountForm
        operationId={
          isDigitalAccount
            ? PaymentOperationId.sendFromMoneyToTrading
            : PaymentOperationId.sendFromTradingToMoney
        }
        needConfirmation={false}
      />
    </form>
  );
};
const SendToDAOrCA: FC<SendToDAOrCAProps> = ({ isDigitalAccount, currencyCode }) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const translate = useTranslation();

  usePaymentInit();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const thunk = isDigitalAccount
        ? requestSendFromDigitalToCryptoAccount
        : requestSendFromCryptoToDigitalAccount;

      const { success, error } = await dispatch(
        thunk({
          amount: +values.currencyAmount.amount,
          currency: values.currencyAmount.currency as DigitalWallet['currencyCode'],
        }),
      );

      const text = getTranslation(
        isDigitalAccount ? 'PAYMENT_SEND_TO_CRYPTO_ACCOUNT' : 'PAYMENT_SEND_TO_DIGITAL_ACCOUNT',
      );

      const drawerTemplate = paymentDrawerTemplates.finishedOperation({
        currencyCode: values.currencyAmount.currency,
        amount: +values.currencyAmount.amount * -1,
        isDeposit: false,
        isSuccess: success,
        description: success ? text : error?.message || getTranslation('ERROR_SOMETHING_BROKE'),
        summaryBlocks: [
          [
            {
              label: translate('DESCRIPTION'),
              value: translate(
                isDigitalAccount
                  ? 'PAYMENT_NEBEUS_SEND_TO_CRYPTO_ACCOUNT'
                  : 'PAYMENT_NEBEUS_SEND_TO_MONEY_ACCOUNT',
              ),
            },
            { label: translate('DATE_&_TIME'), value: formatDDMMYY_HHMM(new Date()) },
            {
              label: translate('ACCOUNT_OPERATION_STATUS'),
              value: translate(
                success ? 'PAYMENT_OPERATION_COMPLETED_SUCCESSFULLY' : 'PAYMENT_OPERATION_FAILED',
              ),
            },
          ],
          [
            {
              label: translate('PAYMENT_FROM'),
              value: 'Nebeus ' + translate(isDigitalAccount ? 'DIGITAL_ACCOUNT' : 'CRYPTO_ACCOUNT'),
            },
            {
              label: translate('PAYMENT_TO'),
              value:
                'Nebeus ' + translate(!isDigitalAccount ? 'DIGITAL_ACCOUNT' : 'CRYPTO_ACCOUNT'),
            },
          ],
          [
            {
              label: translate('PAYMENT_RECIPIENT_WILL_GET'),
              value: formatCurrencyWithSymbol(
                values.currencyAmountWithCommission?.amount || values.currencyAmount.amount,
                currencyCode,
              ),
            },
            {
              label: translate('PAYMENT_NEBEUS_FEE'),
              value: formatCurrencyWithSymbol(values.commissionAmount || 0, currencyCode),
            },
            {
              label: translate('PAYMENT_TOTAL_WITHDRAWN'),
              value: formatCurrencyWithSymbol(
                values.currencyAmount.amount,
                values.currencyAmount.currency,
              ),
            },
          ],
        ],
      });

      if (success) {
        drawer.replaceAll(drawerTemplate);
      } else {
        drawer.replace(drawerTemplate);
      }
    },
    [currencyCode, translate, isDigitalAccount, dispatch, drawer],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      currencyAmount: { amount: '', currency: currencyCode },
      commissionAmount: null,
    }),
    [currencyCode],
  );

  const { amountFormSchema } = useAmountFormValidation({
    operationId: isDigitalAccount
      ? PaymentOperationId.sendFromMoneyToTrading
      : PaymentOperationId.sendFromTradingToMoney,
    isDigitalAccount,
    currencyCode,
  });

  const validate = useMemo(() => makeValidate(amountFormSchema), [amountFormSchema]);

  return (
    <div className="mt-1 column gap-3">
      <WalletBalanceCard currencyCode={currencyCode} isDigitalAccount={isDigitalAccount} />
      <WithdrawalLimitsCard
        currencyCode={currencyCode}
        operationId={
          isDigitalAccount
            ? PaymentOperationId.sendFromMoneyToTrading
            : PaymentOperationId.sendFromTradingToMoney
        }
        rightAdornment={<IbanAccountSubscriptionMark />}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        // @ts-ignore
        render={SendToDAOrCAForm}
        validate={validate}
        isDigitalAccount={isDigitalAccount}
      />
    </div>
  );
};

export default SendToDAOrCA;
