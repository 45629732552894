import { SideBarStackItem } from 'modules/app/types';
import { ConfirmOTPCodeProps } from 'modules/app/views/ConfirmOTPCode';
import { DrawerTemplateReturnType } from 'modules/app/views/Sidebar/commonDrawerTemplates';
import { selectPaymentOperationsLimits } from 'modules/payment/store/selectors';
import { PaymentOperationId } from 'modules/payment/types';
import { AccountDetailsProps } from 'modules/payment/views/AccountDetails';
import { AddEditSavedCryptoWalletProps } from 'modules/payment/views/AddEditSavedCryptoWallet';
import { DepositByDetailsProps } from 'modules/payment/views/DepositByDetails';
import { DepositCryptoProps } from 'modules/payment/views/DepositCrypto';
import { FinishedOperationProps } from 'modules/payment/views/FinishedOperation';
import { SelectCryptoNetworkProps } from 'modules/payment/views/SelectCryptoNetwork';
import { SelectCryptoNetworkConfirmationProps } from 'modules/payment/views/SelectCryptoNetworkConfirmation';
import { SendSEPAProps } from 'modules/payment/views/SendSEPA';
import { SendToDAOrCAProps } from 'modules/payment/views/SendToDAOrCA';
import { SendWireProps } from 'modules/payment/views/SendWire';
import store from 'store';

import { getTranslation } from 'libs/i18n';

import { formatCurrencyWithSymbol, getCurrencyLabelByCode, isFiat } from 'utils/currency';

import { CryptoCurrencyCode, CurrencyCode, FiatCurrencyCode } from 'types';

const depositCrypto = (props: DepositCryptoProps): DrawerTemplateReturnType => ({
  key: 'paymentDepositCrypto',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel:
      getTranslation('DEPOSIT') + ` ${getCurrencyLabelByCode(props.currencyCode)}`,
  },
});
const selectCryptoNetwork = (props: SelectCryptoNetworkProps): DrawerTemplateReturnType => ({
  key: 'paymentSelectCryptoNetwork',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation('NETWORK'),
  },
});
const selectCryptoNetworkConfirmation = (
  props: SelectCryptoNetworkConfirmationProps,
): DrawerTemplateReturnType => ({
  key: 'paymentSelectCryptoNetworkConfirmation',
  props,
  sideBarProps: {
    swipeModal: true,
  },
});
const paymentProcess = (
  {
    isDeposit = true,
    isDigitalAccount = false,
    currencyCode,
  }: {
    isDeposit?: boolean;
    isDigitalAccount?: boolean;
    currencyCode?: CurrencyCode;
  } = {
    isDeposit: true,
    isDigitalAccount: false,
  },
): Omit<SideBarStackItem, 'active'> =>
  currencyCode
    ? isDeposit && !isFiat(currencyCode)
      ? depositCrypto({ currencyCode } as { currencyCode: CryptoCurrencyCode })
      : {
          key: 'paymentPickOperation',
          props: { isDeposit, isDigitalAccount, currencyCode },
          sideBarProps: {
            navigationHeaderLabel:
              getTranslation(isDeposit ? 'DEPOSIT' : 'SEND') +
              ` ${getCurrencyLabelByCode(currencyCode)}`,
          },
        }
    : {
        key: 'paymentPickCurrency',
        props: { isDeposit, isDigitalAccount },
        sideBarProps: {
          withoutBottomPadding: true,
          navigationHeaderLabel: getTranslation(
            isDeposit ? 'PAYMENT_DEPOSIT_FUNDS' : 'PAYMENT_SEND_FUNDS',
          ),
        },
      };

const depositOrSendByBankCard = ({
  isDeposit,
  currencyCode,
}: {
  currencyCode: CurrencyCode;
  isDeposit: boolean;
}): DrawerTemplateReturnType => {
  const operationId = isDeposit
    ? PaymentOperationId.depositFromBankCardToTradingWallet
    : PaymentOperationId.sendFromTradingWalletToBankCard;

  const limits = selectPaymentOperationsLimits(store.getState());

  const limit = limits.find(
    (l) => l.operationId === operationId && l.currencyCode === currencyCode,
  );

  const steps = [
    getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_1`),
    getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_2`),
  ];

  if (limit) {
    steps.push(
      getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEP_3`, {
        minLabel: formatCurrencyWithSymbol(limit.min, currencyCode),
        maxLabel: formatCurrencyWithSymbol(limit.max, currencyCode),
      }),
    );
  }
  if (isDeposit) {
    steps.push(getTranslation(`PAYMENT_BANK_CARD_DEPOSIT_FAQ_STEP_4`));
  }
  return {
    key: 'paymentDepositSendBankCard',
    props: {
      currencyCode,
      isDeposit,
      operationId: isDeposit
        ? PaymentOperationId.depositFromBankCardToTradingWallet
        : PaymentOperationId.sendFromTradingWalletToBankCard,
    },
    sideBarProps: {
      navigationHeaderLabel: getTranslation('PAYMENT_BANK_CARD'),
      navigationHeaderProps: {
        className: 'jcsb',
        infoIcon: {
          title: getTranslation(`PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_TITLE`),
          description: getTranslation(
            `PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_DESCRIPTION`,
          ),
          stepsConfig: {
            title: getTranslation(
              `PAYMENT_BANK_CARD_${isDeposit ? 'DEPOSIT' : 'SEND'}_FAQ_STEPS_TITLE`,
            ),
            steps,
          },
        },
      },
    },
  };
};

const paymentMethods = (): DrawerTemplateReturnType => ({
  key: 'paymentMethods',
  sideBarProps: { swipeModal: true, navigationHeaderLabel: getTranslation('PAYMENT_METHODS') },
});

const paymentOperationDepositByDetails = (
  operationId: PaymentOperationId,
  currencyCode: CurrencyCode,
): DrawerTemplateReturnType => ({
  key: 'paymentDepositByDetails',
  props: {
    operationId,
    currencyCode,
  } as DepositByDetailsProps,
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      operationId === PaymentOperationId.depositFromWireToMoneyWallet && currencyCode === 'GBP'
        ? 'PAYMENT_BANK_TRANSFER'
        : (operationId === PaymentOperationId.depositFromSepaToTradingWallet &&
            currencyCode === 'EUR') ||
          operationId === PaymentOperationId.depositFromSepaToMoneyWallet
        ? 'PAYMENT_BANK_TRANSFER_SEPA'
        : 'PAYMENT_BANK_TRANSFER_WIRE',
    ),
    navigationHeaderProps: {
      className: 'jcsb',
      // @ts-ignore
      infoIcon:
        operationId === PaymentOperationId.depositFromWireToTradingWallet
          ? undefined
          : {
              title: getTranslation(
                operationId === PaymentOperationId.depositFromWireToMoneyWallet
                  ? 'PAYMENT_DEPOSIT_WIRE_FAQ_TITLE'
                  : 'PAYMENT_DEPOSIT_SEPA_FAQ_TITLE',
              ),
              description:
                operationId === PaymentOperationId.depositFromWireToMoneyWallet
                  ? ''
                  : getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_DESC'),
              stepsConfig: {
                title: getTranslation(
                  operationId === PaymentOperationId.depositFromWireToMoneyWallet
                    ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE'
                    : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE',
                ),
                steps: [
                  operationId === PaymentOperationId.depositFromSepaToMoneyWallet
                    ? undefined
                    : getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_1'),
                  getTranslation(
                    operationId === PaymentOperationId.depositFromWireToMoneyWallet
                      ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_1'
                      : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_2',
                  ),
                  getTranslation(
                    operationId === PaymentOperationId.depositFromWireToMoneyWallet
                      ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_2'
                      : 'PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_STEP_3',
                  ),
                ].filter((i) => !!i),
              },
            },
    },
  },
});

const paymentSendNebeusUser = (currencyCode: CurrencyCode): DrawerTemplateReturnType => ({
  key: 'paymentSendNebeusUser',
  props: {
    currencyCode,
  },
  sideBarProps: {
    navigationHeaderLabel: getTranslation('PAYMENT_NU_NAVIGATION_TITLE'),
    navigationHeaderProps: {
      className: 'jcsb',
      infoIcon: {
        title: getTranslation('PAYMENT_NU_FAQ_TITLE'),
        description: getTranslation(`PAYMENT_NU_FAQ_DESCRIPTION`),
        stepsConfig: {
          title: getTranslation('PAYMENT_NU_FAQ_STEPS_TITLE'),
          // @ts-ignore
          steps: [
            getTranslation('PAYMENT_NU_FAQ_STEP_1'),
            getTranslation('PAYMENT_NU_FAQ_STEP_2'),
            getTranslation('PAYMENT_NU_FAQ_STEP_3'),
          ],
        },
      },
    },
  },
});

const paymentOperationSendExternalWallet = (
  currencyCode: CryptoCurrencyCode,
): DrawerTemplateReturnType => ({
  key: 'paymentSendExternalWallet',
  props: { currencyCode },
  sideBarProps: {
    navigationHeaderLabel: getTranslation('PAYMENT_TO_EXTERNAL_WALLET'),
    navigationHeaderProps: {
      className: 'capitalize jcsb',
      infoIcon: {
        title: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_TITLE'),
        description: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_DESCRIPTION'),
        stepsConfig: {
          title: getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEPS_TITLE'),
          steps: [
            getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_1'),
            getTranslation('PAYMENT_TO_EXTERNAL_WALLET_FAQ_STEP_2'),
          ],
        },
      },
    },
  },
});

const paymentOperationSendSEPA = (isDigitalAccount: boolean): DrawerTemplateReturnType => ({
  key: 'paymentSendSEPA',
  props: {
    isDigitalAccount,
  } as SendSEPAProps,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('PAYMENT_BANK_TRANSFER_SEPA'),
    navigationHeaderProps: {
      className: 'jcsb',
      // @ts-ignore
      infoIcon: {
        title: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_TITLE'),
        description: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_DESC'),
        stepsConfig: {
          title: getTranslation('PAYMENT_DEPOSIT_SEPA_FAQ_STEPS_TITLE'),
          steps: [
            getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_1'),
            getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_2'),
            getTranslation('PAYMENT_SEND_SEPA_FAQ_STEPS_STEP_3'),
          ],
        },
      },
    },
  },
});
const paymentOperationSendWire = (isDigitalAccount: boolean): DrawerTemplateReturnType => ({
  key: 'paymentSendWire',
  props: {
    isDigitalAccount,
  } as SendWireProps,
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      isDigitalAccount ? 'PAYMENT_BANK_TRANSFER' : 'PAYMENT_BANK_TRANSFER_WIRE',
    ),
    navigationHeaderProps: {
      className: 'jcsb',
      // @ts-ignore
      infoIcon: {
        title: getTranslation(
          isDigitalAccount ? 'PAYMENT_DEPOSIT_WIRE_FAQ_TITLE' : 'PAYMENT_SEND_WIRE_FAQ_TITLE',
        ),
        description: getTranslation(
          isDigitalAccount ? 'PAYMENT_SEND_WIRE_DA_FAQ_DESC' : 'PAYMENT_SEND_WIRE_FAQ_DESC',
        ),
        stepsConfig: {
          title: getTranslation(
            isDigitalAccount
              ? 'PAYMENT_DEPOSIT_WIRE_FAQ_STEPS_TITLE'
              : 'PAYMENT_SEND_WIRE_FAQ_STEPS_TITLE',
          ),
          steps: [
            getTranslation(
              isDigitalAccount
                ? 'PAYMENT_SEND_WIRE_DA_FAQ_STEPS_1'
                : 'PAYMENT_SEND_WIRE_FAQ_STEPS_1',
            ),
            getTranslation(
              isDigitalAccount
                ? 'PAYMENT_SEND_WIRE_DA_FAQ_STEPS_2'
                : 'PAYMENT_SEND_WIRE_FAQ_STEPS_2',
            ),
            getTranslation('PAYMENT_SEND_WIRE_FAQ_STEPS_3'),
          ],
        },
      },
    },
  },
});
const paymentOperationSendToDAOrCA = (
  isDigitalAccount: boolean,
  currencyCode: FiatCurrencyCode,
): DrawerTemplateReturnType => ({
  key: 'paymentSendToDAOrCA',
  props: {
    isDigitalAccount,
    currencyCode,
  } as SendToDAOrCAProps,
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      isDigitalAccount ? 'PAYMENT_NEBEUS_TO_CRYPTO_ACCOUNT' : 'PAYMENT_NEBEUS_TO_MONEY_ACCOUNT',
    ),
    navigationHeaderProps: {
      className: 'jcsb',
      // @ts-ignore
      infoIcon: {
        title: getTranslation(
          isDigitalAccount ? 'PAYMENT_SEND_TO_CA_FAQ_TITLE' : 'PAYMENT_SEND_TO_DA_FAQ_TITLE',
        ),
        description: getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_TITLE'),
        stepsConfig: {
          steps: [
            getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_1'),
            getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_2'),
            getTranslation('PAYMENT_SEND_TO_DA_CA_FAQ_STEPS_3'),
          ],
        },
      },
    },
  },
});

const depositByDetailsOperationCodes = [
  PaymentOperationId.depositFromSepaToTradingWallet,
  PaymentOperationId.depositFromWireToTradingWallet,
  PaymentOperationId.depositFromSepaToMoneyWallet,
  PaymentOperationId.depositFromWireToMoneyWallet,
];
const paymentOperation = ({
  operationId,
  currencyCode,
  isDigitalAccount = false,
}: {
  operationId: PaymentOperationId;
  currencyCode: CurrencyCode;
  isDigitalAccount?: boolean;
}): DrawerTemplateReturnType | null => {
  if (depositByDetailsOperationCodes.includes(operationId)) {
    return paymentOperationDepositByDetails(operationId, currencyCode);
  }

  switch (operationId) {
    // case PaymentOperationId.depositApplePay: {
    //   return paymentOperationApplePay(currencyCode);
    // }
    case PaymentOperationId.depositFromBankCardToTradingWallet: {
      return depositOrSendByBankCard({ currencyCode, isDeposit: true });
    }
    case PaymentOperationId.sendFromTradingWalletToBankCard: {
      return depositOrSendByBankCard({ currencyCode, isDeposit: false });
    }
    case PaymentOperationId.sendToNebeusUser: {
      return paymentSendNebeusUser(currencyCode);
    }
    case PaymentOperationId.sendCryptoToExternalWallet: {
      return isFiat(currencyCode)
        ? null
        : paymentOperationSendExternalWallet(currencyCode as CryptoCurrencyCode);
    }
    // @ts-ignore
    case PaymentOperationId.sendFromTradingToSepa: {
      if (currencyCode === 'EUR') {
        return paymentOperationSendSEPA(false);
      }
    }
    // @ts-ignore
    // eslint-disable-next-line no-fallthrough
    case PaymentOperationId.sendFromTradingToWire: {
      if (currencyCode === 'GBP') {
        return paymentOperationSendWire(false);
      }
    }
    // eslint-disable-next-line no-fallthrough
    case PaymentOperationId.sendFromMoneyToSepa: {
      return paymentOperationSendSEPA(true);
    }
    case PaymentOperationId.sendFromMoneyToWire: {
      return paymentOperationSendWire(true);
    }
    case PaymentOperationId.sendFromTradingToMoney: {
      return paymentOperationSendToDAOrCA(isDigitalAccount, currencyCode as FiatCurrencyCode);
    }
    case PaymentOperationId.sendFromMoneyToTrading: {
      return paymentOperationSendToDAOrCA(isDigitalAccount, currencyCode as FiatCurrencyCode);
    }

    default: {
      return null;
    }
  }
};

const addEditSavedCryptoWallet = (
  props?: AddEditSavedCryptoWalletProps,
): DrawerTemplateReturnType => ({
  key: 'paymentAddEditSavedCryptoWallet',
  props,
  sideBarProps: {
    swipeModal: true,
    navigationHeaderLabel: getTranslation(
      props?.walletIdForEditing
        ? 'PROFILE_SAVED_CRYPTO_WALLETS_EDIT_TITLE'
        : 'PROFILE_SAVED_CRYPTO_WALLETS_ADD_TITLE',
    ),
  },
});

const accountDetails = (props: AccountDetailsProps): DrawerTemplateReturnType => ({
  key: 'accountDetails',
  props,
  sideBarProps: {
    navigationHeaderLabel: getTranslation('ACCOUNT_DETAILS'),
    swipeModal: true,
  },
});
const finishedOperation = (props: FinishedOperationProps): DrawerTemplateReturnType => ({
  key: 'finishedOperation',
  props,
  sideBarProps: {
    navigationHeaderLabel:
      props.navigationHeaderLabel ||
      getTranslation(props.isSuccess ? 'PAYMENT_OPERATION_COMPLETED' : 'PAYMENT_OPERATION_FAILED'),
  },
});

const confirmOTPCode = (props: ConfirmOTPCodeProps): DrawerTemplateReturnType => ({
  key: 'confirmOTPCode',
  sideBarProps: {
    navigationHeaderLabel: getTranslation(
      props.otpByEmail ? 'EMAIL_CONFIRMATION' : 'SMS_CONFIRMATION',
    ),
  },
  props,
});

const paymentDrawerTemplates = {
  paymentProcess,
  depositOrSendByBankCard,
  depositCrypto,
  selectCryptoNetwork,
  selectCryptoNetworkConfirmation,
  paymentMethods,
  addEditSavedCryptoWallet,
  accountDetails,
  paymentOperation,
  finishedOperation,
  confirmOTPCode,
};

export default paymentDrawerTemplates;
