import { FormApi } from 'final-form';
import { debounce } from 'lodash';

import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useFetchRate from 'modules/exchange/hooks/useFetchRate';
import useRate from 'modules/exchange/hooks/useRate';
import { selectAllowedDirectionsReducer } from 'modules/exchange/store/selectors';
import {
  requestAllowedDirections,
  requestCalculateExchange,
  requestCalculateExchangeReversed,
} from 'modules/exchange/store/thunks';
import { ExchangeFormValues } from 'modules/exchange/views/ExchangeForm';
import { useDispatch } from 'store';

import useFlag from 'hooks/useFlag';
import useInterval from 'hooks/useInterval';
import usePrevious from 'hooks/usePrevious';

import { canRequest } from 'utils/common';
import { formatCurrency } from 'utils/currency';

import { CurrenciesDirection, CurrencyCode } from 'types';

const ONE_MINUTE_IN_MS = 1000 * 60;

const useDecorator = ({ from, to }: ExchangeFormValues, form: FormApi<ExchangeFormValues>) => {
  const dispatch = useDispatch();

  const { data: allowedDirections, meta: allowedDirectionsMeta } = useSelector(
    selectAllowedDirectionsReducer,
  );

  const calculating = useFlag(false);
  const debouncing = useFlag(false);

  useRate(from.currency, to.currency, { autoFetch: true });

  const { fetchRate } = useFetchRate();

  const abortController = useRef<AbortController | undefined>();

  const calculate = useCallback(
    async (
      from: CurrencyCode,
      to: CurrencyCode,
      amount: number,
      { reversed }: { reversed?: boolean } = {},
    ) => {
      calculating.on();
      debouncing.off();

      if (abortController.current) {
        abortController.current.abort();
      }

      const toField = reversed ? 'from' : 'to';
      const toCurrency = reversed ? from : to;

      // if (amount === 0 && !skipZeroValidation) {
      //   form.change(toField, { amount: '', currency: toCurrency });
      //   calculating.off();
      //   return;
      // }

      const controller = new AbortController();
      abortController.current = controller;

      const thunk = reversed ? requestCalculateExchangeReversed : requestCalculateExchange;

      const { data } = await dispatch(
        thunk(
          {
            fromCurrency: from,
            toCurrency: to,
            amount,
          },
          { axiosConfig: { signal: controller.signal } },
        ),
      );

      if (data) {
        form.batch(() => {
          form.change(toField, {
            amount:
              amount === 0
                ? ''
                : formatCurrency(data.amount || 0, toCurrency, {
                    round: reversed ? 'up' : 'down',
                  }),
            currency: toCurrency,
          });
          form.change('commissionAmount', data.commissionAmount);
          form.change('commissionPercent', data.commissionPercentage);
          form.change('exchangeRate', data.directRate);
          form.change('exchangeRateReversed', data.reversedRate);
        });
        calculating.off();
      }
    },
    [calculating, debouncing, form, dispatch],
  );
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    debouncing.on();
    form.batch(() => {
      const fromFormatted = formatCurrency(from.amount, from.currency);
      const toFormatted = formatCurrency(to.amount, to.currency);
      form.change('from', {
        amount: fromFormatted === '0' ? '' : fromFormatted,
        currency: from.currency,
      });
      form.change('to', {
        amount: toFormatted === '0' ? '' : toFormatted,
        currency: to.currency,
      });
    });
    calculate(from.currency, to.currency, Number(from.amount));
    // eslint-disable-next-line
  }, [from.currency, to.currency]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCalculate = useCallback(debounce(calculate, 1000), [from.currency, to.currency]);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }
    if (!form.getFieldState('to')?.active && !calculating.state) {
      debouncing.on();
      debouncedCalculate(from.currency, to.currency, Number(from.amount));
    }
    // eslint-disable-next-line
  }, [from.amount]);

  useEffect(() => {
    if (form.getFieldState('to')?.active && !calculating.state) {
      debouncing.on();
      debouncedCalculate(from.currency, to.currency, Number(to.amount), { reversed: true });
    }
    // eslint-disable-next-line
  }, [to.amount]);

  const prevFromCurrency = usePrevious<CurrencyCode>(from.currency);
  const prevToCurrency = usePrevious<CurrencyCode>(to.currency);

  useEffect(() => {
    if (from.currency === to.currency) {
      if (from.currency === prevFromCurrency) {
        form.change('from', { ...from, currency: prevToCurrency });
      } else {
        form.change('to', { ...to, currency: prevFromCurrency });
      }
    }
    // eslint-disable-next-line
  }, [from.currency, to.currency]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const refreshData = useCallback(() => {
    fetchRate(from.currency, to.currency, { force: true });
    calculate(from.currency, to.currency, Number(from.amount));
  }, [from.amount, fetchRate, calculate, from.currency, to.currency]);

  useInterval(refreshData, ONE_MINUTE_IN_MS);

  const getValidDirection = useCallback(
    (initialFrom: CurrencyCode, initialTo: CurrencyCode) => {
      const direction = `${initialFrom}/${initialTo}` as CurrenciesDirection;

      if (allowedDirectionsMeta.loaded && !allowedDirections.includes(direction)) {
        return (
          allowedDirections.find((d) => d.split('/')[0] === initialFrom) || `${initialFrom}/EUR`
        );
      }
      return null;
    },
    [allowedDirectionsMeta, allowedDirections],
  );

  const initFeePercent = useCallback(async () => {
    const validDirection = getValidDirection(from.currency, to.currency);

    let fromCurrency = from.currency;
    let toCurrency = to.currency;

    if (validDirection) {
      const [f, t] = validDirection.split('/');
      fromCurrency = f as CurrencyCode;
      toCurrency = t as CurrencyCode;
    }
    calculate(fromCurrency, toCurrency, 0);
    // const { success, data } = await dispatch(
    //   requestCalculateExchange({ fromCurrency, toCurrency, amount: 0 }),
    // );
    // if (success) {
    //   form.change('commissionPercent', data.commissionPercentage);
    // }
  }, [getValidDirection, calculate, from.currency, to.currency]);

  const initAllowedDirections = useCallback(async () => {
    if (canRequest(allowedDirectionsMeta)) {
      await dispatch(requestAllowedDirections());
    }
  }, [dispatch, allowedDirectionsMeta]);

  useEffect(() => {
    initAllowedDirections();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allowedDirectionsMeta.loaded) {
      initFeePercent();
    }
    // eslint-disable-next-line
  }, [allowedDirectionsMeta.loaded]);

  useEffect(() => {
    if (from.currency === to.currency) {
      // Resolved by reversing in useEffect above
      return;
    }
    if (allowedDirectionsMeta.loaded) {
      // Allowed direction will be not null only if current direction is disallowed
      const allowedDirection = getValidDirection(from.currency, to.currency);

      if (allowedDirection) {
        const allowedToCurrency = allowedDirection.split('/')[1] as CurrencyCode;
        form.change('to', { amount: to.amount, currency: allowedToCurrency });
      }
    }

    // eslint-disable-next-line
  }, [from.currency, to.currency, allowedDirectionsMeta]);

  return { calculating: calculating.state || debouncing.state };
};

export default useDecorator;
