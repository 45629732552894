import clsx from 'clsx';

import { FC } from 'react';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';

import { formatCurrencyWithSymbol, getCurrencyLabelByCode } from 'utils/currency';

import { CurrencyCode } from 'types';

import classes from './CurrencyAmountListItem.module.scss';

interface CurrencyAmountListItemProps {
  currencyCode: CurrencyCode;
  amount: number;
  secondaryCurrencyCode?: CurrencyCode;
  secondaryAmount?: number | null;
  active?: boolean;
  onPick?: (code: CurrencyCode) => void;
  showActiveIcon?: boolean;
  className?: string;
}
export const CurrencyAmountListItem: FC<CurrencyAmountListItemProps> = ({
  currencyCode,
  amount,
  secondaryAmount,
  secondaryCurrencyCode,
  active,
  onPick,
  showActiveIcon,
  className,
}) => {
  return (
    <div
      className={clsx(classes.root, className, active && classes.active)}
      onClick={
        onPick
          ? () => {
              onPick(currencyCode);
            }
          : undefined
      }
    >
      <div className="row aic gap-1-5">
        <CurrencyIcon key={showActiveIcon && active ? '1' : '2'} size={44} code={currencyCode} />
        <div className={classes.textBlock}>
          <span>{getCurrencyLabelByCode(currencyCode)}</span>
          <span>{currencyCode}</span>
        </div>
      </div>
      <div className={clsx(classes.textBlock, 'tar')}>
        <span>{formatCurrencyWithSymbol(amount, currencyCode)}</span>
        {typeof secondaryAmount === 'number' && secondaryCurrencyCode ? (
          <span>{formatCurrencyWithSymbol(secondaryAmount, secondaryCurrencyCode)}</span>
        ) : null}
      </div>
    </div>
  );
};
