import { compareDesc, format, formatDistanceStrict, isFuture, isValid, parse } from 'date-fns';

import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  selectDigitalAccountExistWallets,
  selectExistingWallets,
} from 'modules/accounts/store/selectors';
import { requestAccountStatement } from 'modules/accounts/store/thunks';
import useSideBar from 'modules/app/hooks/useSideBar';
import { useDispatch } from 'store';

import { SimpleCurrencyPickerInputField, SubmitButton, TextInputField } from 'components/form';
import { InfoIcon } from 'components/ui';

import { useTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import { CurrencyCode } from 'types';

export interface AccountStatementProps {
  isDigitalAccount: boolean;
  currencyCode?: CurrencyCode;
}
interface FormValues {
  fromDate: string;
  toDate: string;
  currencyCode: CurrencyCode | null;
}

interface AccountStatementFormProps extends FormRenderProps<FormValues> {
  currenciesList: CurrencyCode[];
}

const AccountStatementForm: FC<AccountStatementFormProps> = ({
  currenciesList,
  values,
  handleSubmit,
}) => {
  const translate = useTranslation();

  const parseDateInput = useCallback(
    (currentValue: string, fieldName: string) => {
      // @ts-ignore
      const prevValue = values[fieldName];

      const goForward = prevValue.length < currentValue.length;

      const lastChar = currentValue[currentValue.length - 1];

      if (isNaN(+lastChar) && goForward) {
        return currentValue.slice(0, -1);
      }
      if (currentValue.length > 10) {
        return currentValue.substring(0, 10);
      }

      if (goForward) {
        if (currentValue.length === 2 || currentValue.length === 5) {
          return currentValue + '.';
        }
      } else {
        if (prevValue[prevValue.length - 1] === '.') {
          return currentValue.slice(0, -1);
        }
      }

      return currentValue;
    },
    [values],
  );

  return (
    <div className="column gap-2">
      <div className="row jcsb aic">
        <h4>{translate('ACCOUNT_STATEMENT')}</h4>
        <InfoIcon
          title={translate('ACCOUNT_STATEMENT')}
          description={translate('ACCOUNT_STATEMENT_DESC')}
          showInSidebar
        />
      </div>
      <form className="column gap-4" onSubmit={handleSubmit}>
        <div className="column gap-2">
          <div className="row gap-2">
            <Field
              name="fromDate"
              component={TextInputField}
              label={translate('DATE_FROM')}
              placeholder={translate('PLACEHOLDER_DATE_FORMAT_1')}
              parse={parseDateInput}
              inputMode="numeric"
            />
            <Field
              name="toDate"
              component={TextInputField}
              label={translate('DATE_TO')}
              placeholder={translate('PLACEHOLDER_DATE_FORMAT_1')}
              parse={parseDateInput}
              inputMode="numeric"
            />
          </div>
          <Field
            name="currencyCode"
            component={SimpleCurrencyPickerInputField}
            currenciesList={currenciesList}
            label={translate('SELECT_CURRENCY')}
          />
        </div>
        <SubmitButton fullWidth>{translate('ACCOUNT_STATEMENT_DOWNLOAD')}</SubmitButton>
      </form>
    </div>
  );
};

const formatDate = (date: string) => {
  return format(parse(date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd HH:mm:ss');
};

const AccountStatement: FC<AccountStatementProps> = ({ isDigitalAccount, currencyCode = null }) => {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const sidebar = useSideBar();

  const digitalExistingWallets = useSelector(selectDigitalAccountExistWallets);
  const tradingExistingWallets = useSelector(selectExistingWallets);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { success } = await dispatch(
        requestAccountStatement({
          fromDate: formatDate(values.fromDate),
          toDate: formatDate(values.toDate).substring(0, 10) + ' 23:59:59',
          isDigitalAccount,
          currencyCode: values.currencyCode!,
        }),
      );

      if (success) {
        successToast(translate('ACCOUNT_STATEMENT_DOWNLOADED'));
        sidebar.close();
      }
    },
    [isDigitalAccount, translate, sidebar, dispatch],
  );

  const initialValues = useMemo<FormValues>(
    () => ({
      fromDate: '',
      toDate: '',
      currencyCode,
    }),
    [currencyCode],
  );

  const currenciesList = useMemo(() => {
    if (isDigitalAccount) {
      return digitalExistingWallets.map((w) => w.currencyCode);
    }
    return tradingExistingWallets.map((w) => w.currencyCode);
  }, [isDigitalAccount, tradingExistingWallets, digitalExistingWallets]);

  const validate = useCallback(
    (values: FormValues) => {
      const schema = yup.object().shape({
        currencyCode: yup.string().requiredDefault(),
        fromDate: yup
          .string()
          .requiredDefault()
          .length(10, translate('ERRORS_INVALID_DATE'))
          .test('validDate', translate('ERRORS_INVALID_DATE'), (value: string | undefined) => {
            if (!value) {
              return false;
            }
            try {
              return isValid(parse(value, 'dd.MM.yyyy', new Date()));
            } catch (e) {
              return false;
            }
          })
          .test('isFuture', translate('VALIDATION_DATE_FUTURE'), (value: string | undefined) => {
            if (!value) {
              return false;
            }

            try {
              const date = parse(value, 'dd.MM.yyyy', new Date());
              return !isFuture(date);
            } catch (e) {
              return false;
            }
          }),
        toDate: yup
          .string()
          .requiredDefault()
          .length(10, translate('ERRORS_INVALID_DATE'))
          .test('validDate', translate('ERRORS_INVALID_DATE'), (value: string | undefined) => {
            if (!value) {
              return false;
            }

            try {
              if (
                compareDesc(
                  parse(values.fromDate, 'dd.MM.yyyy', new Date()),
                  parse(value, 'dd.MM.yyyy', new Date()),
                ) === -1
              ) {
                return false;
              }
              return isValid(parse(value, 'dd.MM.yyyy', new Date()));
            } catch (e) {
              return false;
            }
          })
          .test('isFuture', translate('VALIDATION_DATE_FUTURE'), (value: string | undefined) => {
            if (!value) {
              return false;
            }

            try {
              const date = parse(value, 'dd.MM.yyyy', new Date());
              return !isFuture(date);
            } catch (e) {
              return false;
            }
          })
          .test(
            'noLongerOneYear',
            translate('ERRORS_DATE_PERIOD_YEAR'),
            (toDate: string | undefined) => {
              if (!toDate) {
                return false;
              }
              try {
                return (
                  parseInt(
                    formatDistanceStrict(
                      parse(values.fromDate, 'dd.MM.yyyy', new Date()),
                      parse(toDate, 'dd.MM.yyyy', new Date()),
                      { unit: 'day' },
                    ),
                  ) <= 366
                );
              } catch (e) {
                return false;
              }
            },
          ),
      });

      return makeValidate(schema)(values);
    },
    [translate],
  );
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      // @ts-ignore
      render={AccountStatementForm}
      currenciesList={currenciesList}
    />
  );
};

export default AccountStatement;
