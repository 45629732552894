import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import classes from 'modules/cryptoRenting/views/RentingNewCoinsAd/RentingNewCoinsAd.module.scss';

import routesByName from 'constants/routesByName';

import { Button, DrawerHeader, Image } from 'components/ui';

import { useTranslation } from 'libs/i18n';

const RentingNewCoinsAd = () => {
  const drawer = useDrawer();
  const translate = useTranslation();
  const navigate = useNavigate();

  const handleClickStartRenting = useCallback(() => {
    drawer.replace(rentingDrawerTemplates.rentingSetup({ templateName: 'Baobab' }));
    navigate(routesByName.cryptoRenting('new'));
  }, [drawer, navigate]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className="column gap-1">
          <DrawerHeader title={translate('RENTING_NEW_COINS_AD_TITLE')} />
          <p dangerouslySetInnerHTML={{ __html: translate('RENTING_NEW_COINS_AD_SUBTITLE') }} />
        </div>
        <div className={classes.imgCard}>
          <Image name="moneyBag" path="common" />
        </div>
      </div>

      <Button onClick={handleClickStartRenting}>{translate('RENTING_START_RENTING')}</Button>
    </div>
  );
};

export default RentingNewCoinsAd;
