import { FullLangCode } from 'modules/user/constants/language';
// import userServicesMocked from 'modules/user/services/mocked';
import {
  transformNotifications,
  transformProfileResponse,
} from 'modules/user/services/transformers';
import { Notification, Profile, UserChallenge, UserQuestionnaire } from 'modules/user/types';
import { ListFilterReqPayload } from 'store/generators/types';

import AxiosInstance from 'libs/axios/AxiosInstance';
import {
  OtpByEmailResponse,
  SubmitQuestionnaireRequest,
  SumsubTokenResponse,
  UserChallengesDto,
  UserMarketingMailingRequest,
  UserProfileAdditionalInfoResponse,
  UserProfileDto,
  UserQuestionnaireDto,
  UserRefLinkGenerateResp,
  UserRefLinkResp,
} from 'libs/swagger/nebeusApiTypes';
import {
  PageDtoNebeusNotificationDtoObject,
  Pageable,
} from 'libs/swagger/nebeusNotificationServiceApiTypes';

import { FiatCurrencyCode } from 'types';

// cli-import

export interface UserExtendedProfile {
  firstName: string;
  lastName: string;
  countryIso2Code: string;
  city: string;
  address: string;
  postCode: string;
  dateOfBirth: string | null;
  phoneNumber: string | null;
}
interface SaveFcmTokenRequest {
  appVersion: string;
  osVersion: string;
  platform: 'ANDROID' | 'IOS' | 'WEB';
  token: string;
}

const userServices = {
  getUserProfile: (): Promise<Profile> =>
    AxiosInstance.get<UserProfileDto, UserProfileDto>('/v1/user-profile').then(
      transformProfileResponse,
    ),
  updateDefaultCurrency: (code: FiatCurrencyCode): Promise<Profile> =>
    AxiosInstance.post<UserProfileDto, UserProfileDto>('/v1/user-profile/default/values', {
      defaultCurrency: code,
    }).then(transformProfileResponse),
  updateLanguage: (lang: FullLangCode): Promise<Profile> =>
    AxiosInstance.post<UserProfileDto, UserProfileDto>('/v1/user-profile/default/values', {
      defaultLanguage: lang,
    }).then(transformProfileResponse),

  getVerificationToken: (): Promise<SumsubTokenResponse> => AxiosInstance.get('/v1/sumsub/token'),

  toggleSendOtpViaEmail: (byEmail: boolean): Promise<OtpByEmailResponse> =>
    AxiosInstance.post<OtpByEmailResponse, OtpByEmailResponse>(
      `/v1/otp-code/by-email/${byEmail ? 'set' : 'unset'}`,
    ),
  userProfileExtended: async (): Promise<UserExtendedProfile> =>
    AxiosInstance.get<UserProfileAdditionalInfoResponse, UserProfileAdditionalInfoResponse>(
      '/v1/user-profile/additional-info',
    ).then((response) => ({
      ...response.userProfileAdditional,
      dateOfBirth: response.userProfileAdditional.dateOfBirth?.replaceAll('-', '.') || null,
    })),
  attachFCMToken: (payload: SaveFcmTokenRequest) =>
    AxiosInstance.post('/v1/notifications/fcms', payload),
  detachFCMToken: (token: string) => AxiosInstance.delete('/v1/notifications/fcms/' + token),
  getReferralLinks: (): Promise<string[]> =>
    AxiosInstance.get<UserRefLinkResp, UserRefLinkResp>('/v1/user/ref-link').then(
      (res) => res.links,
    ),
  generateReferralLink: (): Promise<string> =>
    AxiosInstance.post<UserRefLinkGenerateResp, UserRefLinkGenerateResp>(
      '/v1/user/ref-link/generate',
    ).then((res) => res.generatedLink),

  getNotifications: ({
    filter: { pageLimit, pageNumber },
  }: {
    filter: ListFilterReqPayload;
  }): Promise<Notification[]> =>
    AxiosInstance.get<PageDtoNebeusNotificationDtoObject, PageDtoNebeusNotificationDtoObject>(
      '/v1/notifications',
      { params: { page: pageNumber, size: pageLimit } as Pageable },
    ).then(transformNotifications),

  readNotification: (notificationId: Notification['id']) =>
    AxiosInstance.post(`/v1/notifications/${notificationId}/read`),

  readNotificationBatched: (ids: Notification['id'][]): Promise<void> =>
    AxiosInstance.post(`/v1/notifications/read-batched`, {
      ids,
    }),

  readAllNotifications: () => AxiosInstance.post('/v1/notifications/read'),
  getChallenges: (): Promise<UserChallenge[]> =>
    AxiosInstance.get<UserChallengesDto, UserChallengesDto>('/v1/challenge').then(
      (res) => res.challenges,
    ),
  getQuestionnaire: (id: UserQuestionnaire['id']): Promise<UserQuestionnaire> =>
    AxiosInstance.get<UserQuestionnaireDto, UserQuestionnaireDto>(
      `/v1/questionnaire/${id}/questions`,
    ),
  submitQuestionnaire: ({
    id,
    payload,
  }: {
    id: UserQuestionnaire['id'];
    payload: SubmitQuestionnaireRequest;
  }) => AxiosInstance.post(`/v1/questionnaire/${id}/submit`, payload),

  toggleSendNewsToEmail: (enabled: boolean) =>
    AxiosInstance.post<void, void, UserMarketingMailingRequest>(
      '/v1/user-profile/marketing-mailing',
      { enabled },
    ),
  // cli-service
};

// export default userServicesMocked;
export default userServices;
