import { FC, useMemo } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';

import { Button, Image } from 'components/ui';
import { ButtonProps } from 'components/ui/Button';
import { Icon, IconProps } from 'components/ui/Icon';
import { ImageProps } from 'components/ui/Image';

import { useTranslation } from 'libs/i18n';

import { voidFunc } from 'types';

export interface DynamicContentSidebarItemProps {
  imgProps?: ImageProps;
  iconProps?: IconProps;
  title: string;
  text: string;
  buttons?: { onClick: voidFunc; label: string; variant?: ButtonProps['variant'] }[];
}

const DynamicContentSidebarItem: FC<DynamicContentSidebarItemProps> = ({
  text,
  buttons,
  imgProps,
  iconProps,
}) => {
  const translate = useTranslation();
  const drawer = useDrawer();

  const validButtons = useMemo(() => buttons?.filter((b) => !!b.onClick) || [], [buttons]);

  return (
    <div className="column aic gap-6 flex-1">
      <p className="grey-400" dangerouslySetInnerHTML={{ __html: text }} />

      <div className="column aic jcc flex-1">
        {imgProps && <Image {...imgProps} />}
        {iconProps && <Icon {...iconProps} />}
      </div>
      <div className="row aic gap-1-5 w100 mt-4">
        {validButtons.map(
          (b) =>
            b.onClick && (
              <Button fullWidth key={b.label} onClick={b.onClick} variant={b.variant}>
                {b.label}
              </Button>
            ),
        )}
        {!validButtons.length ? (
          <Button fullWidth onClick={drawer.close}>
            {translate('CLOSE')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default DynamicContentSidebarItem;
