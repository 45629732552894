import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useDrawer from 'modules/app/hooks/useDrawer';
import { PushNotificationData } from 'modules/app/types';
import commonDrawerTemplates from 'modules/app/views/Sidebar/commonDrawerTemplates';
import rentingDrawerTemplates from 'modules/cryptoRenting/constants/drawerTemplates';
import { requestTemplates } from 'modules/cryptoRenting/store/thunks';
import exchangeDrawerTemplates from 'modules/exchange/constants/drawerTemplates';
import { useDispatch } from 'store';

import routesByName from 'constants/routesByName';

import { LINK_CURVE_MOBILE_APP, openExternalLink } from 'utils/links';
import { openHelpCenter } from 'utils/thirdPartyLibs';

const usePushNotificationClickHandler = () => {
  const drawer = useDrawer();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useCallback(
    async (notificationData: PushNotificationData) => {
      switch (notificationData.actionType) {
        case 'CRYPTO_CARD_CURVE_APP_ADVERTISEMENT': {
          openExternalLink(LINK_CURVE_MOBILE_APP);
          break;
        }
        case 'REFERRAL_PROGRAM_ADVERTISEMENT_FOR_PARTNER': {
          drawer.open(commonDrawerTemplates.referralLinksMarketingAd());
          break;
        }
        case 'USER_ACCOUNT_WAS_BLOCKED_BY_ADMIN': {
          openHelpCenter();
          break;
        }
        case 'MIRROR_LOAN_ADVERTISEMENT': {
          navigate(routesByName.loans('new', 'MIRROR'));
          break;
        }
        case 'MIRROR_LOAN_ADVERTISEMENT_2': {
          navigate(routesByName.loans('new', 'MIRROR'));
          break;
        }
        case 'RENTING_BAOBAB_ADVERTISEMENT': {
          const { success } = await dispatch(requestTemplates()); // TODO: Implement autofetch with loader in renting setup component
          if (success) {
            navigate(routesByName.cryptoRenting('new'));
            drawer.open(rentingDrawerTemplates.rentingSetup({ templateName: 'Baobab' }));
          }
          break;
        }
        case 'RENTING_BAOBAB_ADVERTISEMENT_2': {
          const { success } = await dispatch(requestTemplates()); // TODO: Implement autofetch with loader in renting setup component
          if (success) {
            navigate(routesByName.cryptoRenting('new'));
            drawer.open(rentingDrawerTemplates.rentingSetup({ templateName: 'Baobab' }));
          }
          break;
        }
        case 'TRADING_FEES_ADVERTISEMENT': {
          drawer.open(exchangeDrawerTemplates.exchange());
          break;
        }
        case 'TRADING_FEES_ADVERTISEMENT_2': {
          drawer.open(exchangeDrawerTemplates.exchange());
          break;
        }
        case 'SMART_TRADER': {
          navigate(routesByName.smartTradeDashboard(notificationData.currencyCode));
          break;
        }
      }
    },
    [drawer, dispatch, navigate],
  );
};

export default usePushNotificationClickHandler;
