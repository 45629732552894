import { Transaction } from 'modules/accounts/types';
import { DigitalSubscription, DigitalSubscriptionType } from 'modules/digitalAccount/types';
import { transformList } from 'store/generators/transformers';

import {
  BaseTransactionHistoryResult,
  ModulrSubscriptionDto,
  ModulrTransactionHistoryResponseList,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode } from 'types';

const subscriptionsOrderByType: { [key in DigitalSubscriptionType]: number } = {
  standard: 1,
  hodler: 2,
  whale: 3,
};

const sortSubscriptions = (a: DigitalSubscription, b: DigitalSubscription) =>
  subscriptionsOrderByType[a.type] - subscriptionsOrderByType[b.type];

export const transformSubscriptions = (
  subscriptions: ModulrSubscriptionDto[],
): DigitalSubscription[] =>
  transformList<ModulrSubscriptionDto, DigitalSubscription>(subscriptions, [
    ['id'],
    ['depositPending', 'isDepositPending'],
    ['endDate'],
    ['payCurrencyCode'],
    ['pricePerMonth'],
    ['startDate'],
    [
      'type',
      'type',
      // @ts-ignore
      { custom: (type: 'HODLER' | 'STANDARD' | 'WHALE') => type.toLowerCase() },
    ],
    ['active', 'isActive'],
    ['ending', 'isEnding'],
    ['verificationPending', 'isVerificationPending'],
  ]).sort(sortSubscriptions);

const statusMap: { [key: string]: Transaction['status'] } = {
  CREATED: 'created',
  PROCESSED: 'completed',
  REJECTED: 'rejected',
  FAILED: 'failed',
  MONITORING_REJECT: 'failed',
  DECLINED: 'failed',
  CANCELLED: 'cancelled',
  PENDING: 'pending',
  MONITORING_APPROVED: 'pending',
};
export const transformDigitalTransactions = (
  response: ModulrTransactionHistoryResponseList,
  currencyCodeFromReq?: CurrencyCode,
) =>
  transformList<BaseTransactionHistoryResult, Transaction>(response.modulrTransactions, [
    ['id'],
    [
      'id',
      'uniqId',
      {
        custom: (_, t) =>
          `${t.id}${t.description}${t.currencyCode || currencyCodeFromReq}${t.amount}`,
      },
    ],
    ['requestIdentifier'],
    ['amount'],
    ['amountInDefaultCurrency'],
    ['defaultCurrency', 'defaultCurrencyCode'],
    [
      'currencyCode',
      'currencyCode',
      // @ts-ignore
      { custom: (value: CurrencyCode) => value || currencyCodeFromReq },
    ],
    ['dateCreated'],
    [
      'status',
      'status',
      // @ts-ignore
      { custom: (status: string) => statusMap[status?.toUpperCase()] || 'completed' },
    ],
    ['description'],
    ['type', 'operationId'],
    ['direction', 'isIncome', { custom: (value) => value === 'IN' }],
  ]);
