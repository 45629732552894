import { cryptoBankCardActions } from '../store';
import { generateRequestThunk } from 'store/generators/thunks';
import { generateRequestDataThunk } from 'store/generators/thunks';

import { getTranslation } from 'libs/i18n';
import { successToast } from 'libs/toast';

import { CurrencyCode } from 'types';

import cryptoBankCardServices from '../services';
import { Config, CryptoBankCard, CryptoBankCardFeeConfig, CryptoBankCardOrder } from '../types';

// cli-import

export const requestBankCardsOrders = generateRequestDataThunk<CryptoBankCardOrder[]>({
  updateState: cryptoBankCardActions.updateBankCardsOrders,
  request: cryptoBankCardServices.getBankCardOrders,
});

export const requestOrderDebitCard = generateRequestThunk({
  request: cryptoBankCardServices.orderDebitCard,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestBankCardsOrders());
    },
  },
});
export const requestOrderVirtualCard = generateRequestThunk({
  request: cryptoBankCardServices.orderVirtualCard,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestBankCardsOrders());
    },
  },
});

export const requestOrderVirtualCardFromOnboarding = generateRequestThunk({
  request: cryptoBankCardServices.orderVirtualCardFromOnboarding,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestBankCardsOrders());
    },
  },
});
export const requestOrderPhysicalCardFromOnboarding = generateRequestThunk({
  request: cryptoBankCardServices.orderPhysicalCardFromOnboarding,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestBankCardsOrders());
    },
  },
});

export const requestAllowedDebitCardPaymentCurrencyCodes = generateRequestDataThunk<CurrencyCode[]>(
  {
    updateState: cryptoBankCardActions.updateAllowedDebitCardPaymentCurrencyCodes,
    request: cryptoBankCardServices.getAllowedDebitCardPaymentCurrencyCodes,
  },
);

export const requestConfig = generateRequestDataThunk<Config>({
  updateState: cryptoBankCardActions.updateConfig,
  request: cryptoBankCardServices.getConfig,
});
export const requestReadBankCardOrder = generateRequestThunk({
  request: cryptoBankCardServices.readBankCardOrder,
  options: {
    onSuccess: (dispatch) => {
      dispatch(requestBankCardsOrders());
    },
  },
});
export const requestAllowedCountries = generateRequestDataThunk<string[]>({
  updateState: cryptoBankCardActions.updateAllowedCountries,
  request: cryptoBankCardServices.getAllowedCountries,
});
export const requestBankCards = generateRequestDataThunk<CryptoBankCard[]>({
  updateState: cryptoBankCardActions.updateBankCards,
  request: cryptoBankCardServices.getBankCards,
  options: { showErrorToast: false },
});
export const requestBankCardDetails = generateRequestThunk({
  request: cryptoBankCardServices.getBankCardDetails,
});
export const requestAllowedLinkingCurrencies = generateRequestDataThunk<CurrencyCode[]>({
  updateState: cryptoBankCardActions.updateAllowedLinkingCurrencies,
  request: cryptoBankCardServices.getAllowedLinkingCurrencies,
});

export const requestFreezeBankCard = generateRequestThunk({
  request: cryptoBankCardServices.freezeBankCard,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestBankCards());
    },
  },
});
export const requestUnfreezeBankCard = generateRequestThunk({
  request: cryptoBankCardServices.unfreezeBankCard,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestBankCards());
    },
  },
});
export const requestTerminateBankCard = generateRequestThunk({
  request: cryptoBankCardServices.terminateBankCard,
  options: {
    onSuccess: () => {
      successToast(getTranslation('BANK_CARD_CLOSED'));
    },
  },
});
export const requestActivateBankCard = generateRequestThunk({
  request: cryptoBankCardServices.activateBankCard,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestBankCards());
    },
  },
});
export const requestLinkTradingWalletToBankCard = generateRequestThunk({
  request: cryptoBankCardServices.linkTradingWalletToCryptoCard,
  options: {
    onSuccess: async (dispatch) => {
      await dispatch(requestBankCards());
    },
  },
});

export const requestBankCardTransactions = generateRequestThunk({
  request: cryptoBankCardServices.getTransactionHistory,
});
export const requestBankCardExpenses = generateRequestThunk({
  request: cryptoBankCardServices.getCardExpenses,
});
export const requestSendPinCode = generateRequestThunk({
  request: cryptoBankCardServices.sendCardPinCode,
});
export const requestSendPassCode = generateRequestThunk({
  request: cryptoBankCardServices.sendCardPassCode,
});
export const requestChangePinCode = generateRequestThunk({
  request: cryptoBankCardServices.changeCardPinCode,
});

export const requestDeliveryPlans = generateRequestThunk({
  request: cryptoBankCardServices.getDeliveryPlans,
});

export const requestCardLimits = generateRequestThunk({
  request: cryptoBankCardServices.getCardLimits,
});
export const requestFeeConfig = generateRequestDataThunk<CryptoBankCardFeeConfig>({
  updateState: cryptoBankCardActions.updateFeeConfig,
  request: cryptoBankCardServices.getFeeConfig,
});
// cli-thunk
