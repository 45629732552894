import clsx from 'clsx';

import { FC } from 'react';

import { translationKeySubDescSecondaryBySubType } from 'modules/digitalAccount/constants/config';
import { DigitalSubscription } from 'modules/digitalAccount/types';

import { useTranslation } from 'libs/i18n';

import { formatCurrency, getCurrencySymbolByCode } from 'utils/currency';

import classes from './SubscriptionPriceCard.module.scss';

interface SubscriptionPriceCardProps {
  subscription: DigitalSubscription;
  className?: string;
  variant?: 'white' | 'blurred';
}

export const SubscriptionPriceCard: FC<SubscriptionPriceCardProps> = ({
  className,
  subscription,
  variant = 'white',
}) => {
  const translate = useTranslation();

  return (
    <div className={clsx(classes.root, className, classes['variant-' + variant])}>
      <span>
        {subscription.pricePerMonth > 0
          ? translate('PRICE_PER_MONTH', {
              currency: getCurrencySymbolByCode('EUR'),
              amount: formatCurrency(subscription.pricePerMonth, true),
            })
          : translate('FREE_LIFETIME_PLAN')}
      </span>
      <p>{translate(translationKeySubDescSecondaryBySubType[subscription.type])}</p>
    </div>
  );
};
